import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Divider, Row, Space } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import getDirtyValues from 'src/_metronic/helpers/getDirtyValues'
import { ClassesApi } from 'src/apis/classes'
import MinusSquared from 'src/common/CustomIcons/MinusSquared'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import RangePicker from 'src/components/base/rangeDateTime/RangePicker'
import SappDrawer from 'src/components/base/SappDrawer'
import FacilitySelect from 'src/components/base/select/common/FacilitySelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import AddCourse from 'src/components/classes/add-course/AddCourse'
import AddExam from 'src/components/classes/add-exam/AddExam'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { daysOfWeek } from 'src/constants'
import {
  CLASS_PROFILE,
  CONSTRUCTION_MODE_OPTIONS,
  OPTIONS_OF_DURATION,
  OPTIONS_OF_STATUS,
  OPTIONS_OF_TYPE_CLASS,
  TITLE_OPTIONS_CLASS,
} from 'src/constants/classes'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { DayOfWeek, daysOfWeekInNumber, ExamSubject, IRoom, Role } from 'src/type'
import { CONSTRUCTION_MODE, IClassDetail, IPostClass, StandardSchedule } from 'src/type/classes'
import { IClassType, ICourse } from 'src/type/courses'
import AddClassroom from '../../add-classroom'
import { classSchema } from './schema'

interface IProps {
  classDetail: IClassDetail
}

const WEEKDAYS: DayOfWeek[] = daysOfWeek.slice(0, 5).map((day) => day.value) // [0, 1, 2, 3, 4] -> Monday to Friday
const WEEKENDS: DayOfWeek[] = daysOfWeek.slice(5).map((day) => day.value) // [5, 6] -> Saturday, Sunday

const WEEKDAY_HOURS = { min: 18, max: 22 } // Allowed time for weekdays (18:00 - 22:00)
const WEEKEND_HOURS = { min: 8, max: 22 } // Allowed time for weekends (08:00 - 22:00)

const EditClass = ({ classDetail }: IProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [typeClass, setTypeClass] = useState<string | undefined>(undefined)

  const [modals, setModals] = useState({
    course: false,
    exam: false,
    classroom: false,
  })

  // Open a modal and close others
  const openModal = (type: 'course' | 'exam' | 'classroom') => {
    setModals({ course: false, exam: false, classroom: false, [type]: true })
  }

  // Close all modals
  const closeModal = () => {
    setModals({ course: false, exam: false, classroom: false })
  }

  const [selectedCourse, setSelectedCourse] = useState<ICourse | undefined>(classDetail?.course)
  const [selectedExam, setSelectedExam] = useState<ExamSubject | undefined>(
    classDetail?.examination_subject
  )
  const [selectedClassroom, setSelectedClassroom] = useState<IRoom | undefined>()
  const [openBlock, setOpenBlock] = useState<boolean>(false)
  const [statusBlock, setStatusBlock] = useState<string>(classDetail?.status)
  const [typeInstruction, setTypeInstruction] = useState(classDetail?.instruction_mode)
  const [warning, setWarning] = useState<{ course: string; duration: string }>({
    course: '',
    duration: '',
  })

  const isClassEnded = classDetail?.status === 'ENDED' ? true : false
  const { is_student_studying_normal_course, is_student_studying_foundation_course } = classDetail

  const getCourseCategoryId = () =>
    classDetail?.course?.course_categories?.[0]?.id ||
    classDetail?.examination_subject?.examination?.course_category_id
  const [courseCategoryId, setCourseCategoryId] = useState<string>(getCourseCategoryId())

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${CLASS_PROFILE}/${id}/overview`),
    })
  }

  const methods = useForm<IPostClass>({
    resolver: zodResolver(classSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: classDetail?.name,
      code: classDetail?.code,
      status: classDetail?.status,
      facility_id: classDetail?.facility_id,
      instruction_mode: classDetail?.instruction_mode,
      type: classDetail?.type,
      capacity: classDetail?.capacity,
      course_id: classDetail?.course_id,
      examination_subject_id: classDetail?.examination_subject_id,
      ...(classDetail?.started_at && { started_at: dayjs(classDetail.started_at).toDate() }),
      ...(classDetail?.finished_at && { finished_at: dayjs(classDetail.finished_at).toDate() }),
      ...(classDetail?.opening_at && { opening_at: dayjs(classDetail.opening_at).toDate() }),
      duration_type: classDetail?.duration_type,
      reason: classDetail?.reason,
      mentor_type: classDetail?.mentor_type,
      class_standard_schedules: classDetail?.class_standard_schedules?.map(
        (item: StandardSchedule) => ({
          ...item,
          day_of_week:
            item.day_of_week != null ? daysOfWeekInNumber[item.day_of_week] : item.day_of_week,
        })
      ),
      description: classDetail?.description,
      flexible_days: classDetail?.flexible_days,
      link_meeting: classDetail?.link_meeting,
      duration_at: {
        fromDate: classDetail?.started_at ? dayjs(classDetail?.started_at).toDate() : null,
        toDate: classDetail?.finished_at ? dayjs(classDetail?.finished_at).toDate() : null,
      },
    },
  })

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { isLoading, errors, dirtyFields },
  } = methods
  const type = watch('type')
  const constructionMode = watch('instruction_mode')
  const startDate = watch('started_at')
  const durationType = watch('duration_type')
  const schedules = watch('class_standard_schedules')

  const isHybrid = constructionMode === CONSTRUCTION_MODE.HYBRID
  const isOnline = constructionMode === CONSTRUCTION_MODE.ONLINE

  const showCreateHybridClass =
    type === IClassType.LESSON &&
    (constructionMode === CONSTRUCTION_MODE.OFFLINE ||
      constructionMode === CONSTRUCTION_MODE.BLENDED)

  // function scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // submit form
  const onSubmit = async (data: IPostClass) => {
    setOpenBlock(false)
    const dirtyDatas = getDirtyValues(dirtyFields, data)

    const paramsUpdate: IPostClass = {
      ...dirtyDatas,
      id: id,
      ...(data.class_standard_schedules &&
        dirtyFields.class_standard_schedules &&
        data.class_standard_schedules.length > 0 && {
          class_standard_schedules: data.class_standard_schedules,
        }),
    }

    if (Object.keys(dirtyDatas).length > 0) {
      updateClass({ ...paramsUpdate })
    } else {
      navigate(`${CLASS_PROFILE}/${id}/overview`)
    }
  }

  const queryClient = useQueryClient()

  const [loadingClass, setLoadingClass] = useState(false)

  const updateClass = async (paramsUpdate: IPostClass) => {
    setLoadingClass(true)
    try {
      const res = await ClassesApi.updateClass({ ...paramsUpdate })
      toast.success('Update Successfully!')
      navigate(`/classes/detail/${res?.data?.id}/overview`)
      scrollToTop()

      queryClient.invalidateQueries('classDetail')
    } catch (error) {
      // do nothing
    } finally {
      setLoadingClass(false)
    }
  }

  const handleChangeStatus = (e: string) => {
    setStatusBlock(e)
  }

  const handleCloseBlock = () => {
    setOpenBlock(false)
  }

  const [prevValue, setPrevValue] = useState<string>()
  const handleConfirm = (prevValues: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Course và Exam sẽ bị xóa đi khi bạn đổi Type class, bạn có muốn tiếp tục không?'],
      onClick: () => handleChangeType(prevValues),
      onClose: () => setValue('type', prevValue || classDetail?.type),
    })
  }
  const handleSelectChange = (prevValues: string) => {
    handleConfirm(prevValues)
  }

  const handleChangeType = (prevValues?: string) => {
    setPrevValue(prevValues)
    setSelectedExam(undefined)
    setCourseCategoryId('')
    setSelectedCourse(undefined)
  }

  const handleResetListExam = () => {
    if (
      !(classDetail?.type === IClassType.REVISION && classDetail.examination_subject_id !== null)
    ) {
      setSelectedExam(undefined)
    }
  }

  const { fields, remove, append, replace } = useFieldArray({
    control,
    name: 'class_standard_schedules',
  })

  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderEditRole = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  useEffect(() => {
    setValue('examination_subject_id', selectedExam?.id, { shouldDirty: true })
  }, [selectedExam])

  useEffect(() => {
    setValue('room_id', selectedClassroom?.id, { shouldDirty: true })
  }, [selectedClassroom])

  useEffect(() => {
    if (!!(selectedCourse?.id !== classDetail?.course_id)) {
      setValue('course_id', selectedCourse?.id, { shouldDirty: true })
    }

    if (
      classDetail?.course_id &&
      selectedCourse?.id &&
      classDetail?.course_id !== selectedCourse.id
    ) {
      setWarning((prev) => {
        return {
          ...prev,
          course: 'This action will reset the teacher assignment settings.',
        }
      })
    } else {
      setWarning((prev) => {
        return {
          ...prev,
          course: '',
        }
      })
    }
  }, [selectedCourse])
  return (
    <>
      {contextHolder}
      {classDetail && (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab title='Edit Class Information' />
          <div className='collapse show'>
            <div className='card-body card-body px-10 pt-8 pb-4'>
              <Row gutter={[24, 32]}>
                {/* NAME */}
                <Col span={24}>
                  <HookFormTextField
                    required
                    name='name'
                    control={control}
                    label={TITLE_OPTIONS_CLASS.name}
                    disabled={isClassEnded}
                  />
                </Col>
                <Col span={24}>
                  {/* CODE */}
                  <HookFormTextField
                    required
                    control={control}
                    name='code'
                    label={TITLE_OPTIONS_CLASS.code}
                    disabled={isClassEnded || constructionMode === CONSTRUCTION_MODE.HYBRID}
                  />
                </Col>
                {/* DURATION */}
                {/* STATUS */}
                <Col span={24}>
                  <HookFormSelectAntd
                    required
                    name='status'
                    control={control}
                    filterOption={true}
                    label={TITLE_OPTIONS_CLASS.status}
                    onSelect={handleChangeStatus}
                    disabled={
                      isClassEnded ||
                      is_student_studying_normal_course ||
                      constructionMode === CONSTRUCTION_MODE.HYBRID
                    }
                    options={OPTIONS_OF_STATUS}
                  />
                </Col>

                {!isHybrid && (
                  <>
                    {/* CONSTRUCTION MODE */}
                    <Col span={12}>
                      <HookFormSelectAntd
                        required
                        name='instruction_mode'
                        label={TITLE_OPTIONS_CLASS.constructionMode}
                        control={control}
                        filterOption={true}
                        disabled={
                          isClassEnded ||
                          is_student_studying_normal_course ||
                          constructionMode === CONSTRUCTION_MODE.HYBRID
                        }
                        onChange={(e) => setTypeInstruction(e)}
                        options={CONSTRUCTION_MODE_OPTIONS}
                      />
                    </Col>
                    {/* FACILITY */}
                    <Col span={12}>
                      <FacilitySelect
                        control={control}
                        label={TITLE_OPTIONS_AREA.facility}
                        name='facility_id'
                        placeholder='Facility'
                        disabled={isClassEnded || constructionMode === CONSTRUCTION_MODE.HYBRID}
                        classDetail={{
                          label: classDetail?.facility?.name,
                          value: classDetail?.facility?.id,
                        }}
                        onChange={(e) => {
                          if (e === '' || e === undefined) {
                            setValue('facility_id', null, { shouldDirty: true })
                          }
                        }}
                        allowClear={typeInstruction === CONSTRUCTION_MODE.ONLINE}
                        required={typeInstruction !== CONSTRUCTION_MODE.ONLINE}
                      />
                    </Col>
                    {/* TYPE */}
                    <Col span={12}>
                      <HookFormSelectAntd
                        required
                        label={TITLE_OPTIONS_CLASS.type}
                        name='type'
                        control={control}
                        placeholder=' '
                        filterOption={true}
                        onChange={(prevValues: any) => handleSelectChange(prevValues)}
                        disabled={
                          isClassEnded ||
                          is_student_studying_normal_course ||
                          is_student_studying_foundation_course ||
                          classDetail?.type === 'FOUNDATION' ||
                          (classDetail?.type === IClassType.REVISION &&
                            classDetail.examination_subject_id !== null) ||
                          constructionMode === CONSTRUCTION_MODE.HYBRID
                        }
                        options={OPTIONS_OF_TYPE_CLASS}
                      />
                    </Col>
                    {/* Số học viên tối đa */}
                    <Col span={12}>
                      <HookFormTextField
                        required
                        label={TITLE_OPTIONS_CLASS.maximumStudents}
                        control={control}
                        name='capacity'
                        type='number'
                        onChange={(e) => setValue('capacity', Number(e.target.value))}
                        disabled={isClassEnded || constructionMode === CONSTRUCTION_MODE.HYBRID}
                        minNumber={1}
                        requiredZero={true}
                      />
                    </Col>
                  </>
                )}
                {isOnline && (
                  <Col span={24}>
                    <Space size={'large'} align='center'>
                      <SappLabel required label={TITLE_OPTIONS_CLASS.duration} className='mb-0' />
                      <HookFormRadioGroup
                        direction='horizontal'
                        separator={false}
                        name='duration_type'
                        control={control}
                        disabled={isClassEnded || constructionMode === CONSTRUCTION_MODE.HYBRID}
                        justify='start'
                        gap={24}
                        labelClass='fw-semibold sapp-table-class-field'
                        options={[
                          {
                            label: `${OPTIONS_OF_DURATION.labelFixed}`,
                            value: `${OPTIONS_OF_DURATION.valueFixed}`,
                          },
                          {
                            label: `${OPTIONS_OF_DURATION.labelFlexible}`,
                            value: `${OPTIONS_OF_DURATION.valueFlexible}`,
                          },
                        ]}
                      />
                    </Space>
                  </Col>
                )}
                {/* Hybrid */}
                {showCreateHybridClass && (
                  <Col span={24}>
                    <Space size={'small'} align='center'>
                      <SappLabel
                        label={TITLE_OPTIONS_CLASS.createHybridClass}
                        className='mb-0'
                        htmlFor='create_hybrid_class'
                      />
                      <HookFormCheckBox
                        name='create_hybrid_class'
                        id='create_hybrid_class'
                        control={control}
                        defaultValue={false}
                        disabled={constructionMode === CONSTRUCTION_MODE.HYBRID}
                      />
                    </Space>
                  </Col>
                )}

                {isOnline && durationType === `${OPTIONS_OF_DURATION.valueFixed}` && (
                  <>
                    <Col span={12}>
                      <HookFormDateTime control={control} name='opening_at' label='Opening Date' />
                    </Col>
                    <Col md={12}>
                      <RangePicker
                        control={control}
                        names={['started_at', 'finished_at']}
                        allowClear={false}
                        label='Start & End Date'
                        formatFunction={(date) => date?.toDate()}
                        required
                      />
                      {(errors.started_at || errors.finished_at) && (
                        <small className='text-danger mt-2 lh-1'>
                          {errors?.started_at?.message ?? errors?.finished_at?.message ?? ''}
                        </small>
                      )}
                    </Col>
                  </>
                )}
                {isOnline && durationType === `${OPTIONS_OF_DURATION.valueFlexible}` && (
                  <Col span={24}>
                    <div className='position-relative'>
                      <HookFormTextField
                        label='Number of Days'
                        required
                        control={control}
                        onChange={(e) => setValue('flexible_days', Number(e.target.value))}
                        name='flexible_days'
                        placeholder=' '
                        type='number'
                        postFix={<div className='px-2'>days</div>}
                        minNumber={1}
                        maxNumber={100000}
                        requiredZero={true}
                      />
                    </div>
                  </Col>
                )}
                {constructionMode !== CONSTRUCTION_MODE.ONLINE && (
                  <>
                    <Col span={24}>
                      <Space direction='vertical' className={clsx('w-100')}>
                        <SappLabel label='Learning Schedules' required />
                        <HookFormDateTime
                          label='Start Date'
                          control={control}
                          name='started_at'
                          placeholder='01/01/2025'
                          required
                          disabled={constructionMode === CONSTRUCTION_MODE.HYBRID}
                          disabledDate={(current) => {
                            return current && current < dayjs().endOf('day')
                          }}
                          onChange={(data) => {
                            if (data !== null) {
                              const dayOfWeekLabel = dayjs(data).format('dddd')
                              const matchedDay = daysOfWeek.find(
                                (day) => day.label === dayOfWeekLabel
                              )

                              if (matchedDay) {
                                replace({
                                  day_of_week: matchedDay.value, // Ensure type safety
                                  start_time: null,
                                  end_time: null,
                                })
                              }
                            } else {
                              remove()
                            }
                          }}
                        />
                      </Space>
                    </Col>
                    {fields.map((field, index) => {
                      // Get already selected days (excluding the current field)
                      const takenDays =
                        schedules
                          ?.map((item, i) => (i !== index ? item.day_of_week : null))
                          .filter(Boolean) ?? []

                      // Filter days to exclude already selected ones
                      const availableDays = daysOfWeek.filter(
                        (day) => !takenDays.includes(day.value)
                      )
                      return (
                        <Col span={24} key={field.id}>
                          <Row gutter={[24, 24]}>
                            {/* 1st Col - Subject */}
                            <Col span={10}>
                              <HookFormSelectAntd
                                required
                                name={`class_standard_schedules.${index}.day_of_week`}
                                control={control}
                                options={availableDays}
                                label='Day Of Week'
                                disabled={index === 0 || isHybrid}
                              />
                            </Col>
                            <Col span={14}>
                              <RangePicker
                                required
                                control={control}
                                label='Start Time - End Time'
                                picker='time'
                                format='HH:mm'
                                disabled={isHybrid}
                                placeholder={['From', 'To']}
                                names={[
                                  `class_standard_schedules.${index}.start_time`,
                                  `class_standard_schedules.${index}.end_time`,
                                ]}
                                formatFunction={(date) => date?.format('HH:mm')}
                                disabledTime={(_, type) => {
                                  const selectedDay = getValues(
                                    `class_standard_schedules.${index}.day_of_week`
                                  )

                                  if (selectedDay === null || selectedDay === undefined) return {} // No restrictions if no day is selected

                                  const isWeekday = WEEKDAYS.includes(selectedDay)
                                  const isWeekend = WEEKENDS.includes(selectedDay)

                                  const { min: minHour, max: maxHour } = isWeekday
                                    ? WEEKDAY_HOURS
                                    : isWeekend
                                    ? WEEKEND_HOURS
                                    : { min: 0, max: 23 } // Default to full-day if invalid

                                  if (type === 'start') {
                                    return {
                                      disabledHours: () => [
                                        ...Array(minHour).keys(), // Disable hours before minHour
                                        ...Array.from(
                                          { length: 24 - maxHour - 1 },
                                          (_, i) => i + maxHour + 1
                                        ), // Disable hours after maxHour
                                      ],
                                    }
                                  }

                                  return {
                                    disabledHours: () => [
                                      ...Array(minHour).keys(),
                                      ...Array.from(
                                        { length: 24 - maxHour - 1 },
                                        (_, i) => i + maxHour + 1
                                      ),
                                    ],
                                    disabledMinutes: (selectedHour) => {
                                      if (selectedHour === maxHour) {
                                        return Array.from({ length: 59 }, (_, i) => i + 1) // Only allow 00 minutes at 22:00
                                      }
                                      return []
                                    },
                                  }
                                }}
                              />
                              {errors.class_standard_schedules?.[index]?.end_time?.message && (
                                <small className='text-danger mt-2 lh-1'>
                                  {errors.class_standard_schedules?.[index]?.end_time?.message ??
                                    ''}
                                </small>
                              )}
                            </Col>
                            {fields.length > 1 && index >= 1 && !isHybrid && (
                              <Col span={24}>
                                <Space
                                  className='text-gray-1000 cursor-pointer btn-delete-schedule'
                                  onClick={() => remove(index)}
                                >
                                  <MinusSquared />
                                  <span>Remove This Schedule</span>
                                </Space>
                              </Col>
                            )}
                            {index !== 2 && (
                              <Divider
                                variant='dashed'
                                style={{ borderColor: '#DBDFE9' }}
                                className='m-0'
                              />
                            )}
                          </Row>
                        </Col>
                      )
                    })}

                    {fields.length >= 1 && startDate && fields.length < 3 && !isHybrid && (
                      <Col span={24}>
                        <div
                          className='d-inline-flex align-items-center text-primary cursor-pointer btn-add-schedule'
                          style={{ gap: '8px' }}
                          onClick={() =>
                            append({
                              day_of_week: null,
                              start_time: null,
                              end_time: null,
                            })
                          }
                        >
                          <KTIcon iconName='plus-square' iconType='outline' sizeIcon='fs-1' />
                          <span>Add Schedule</span>
                        </div>
                      </Col>
                    )}
                  </>
                )}

                {!isHybrid && (
                  <>
                    {/* COURSE */}
                    <Col span={24}>
                      <SappLabel
                        label={TITLE_OPTIONS_CLASS.course}
                        required={constructionMode !== CONSTRUCTION_MODE.ONLINE}
                      />

                      <div>
                        <ButtonIcon
                          title={`${!selectedCourse?.id ? 'Add Course' : ''}`}
                          className={clsx(
                            'sapp-h-45px d-flex justify-content-start align-items-center opacity-100 w-100',
                            {
                              'disabled-background':
                                isClassEnded ||
                                is_student_studying_normal_course ||
                                is_student_studying_foundation_course ||
                                classDetail?.type === IClassType.FOUNDATION,
                            }
                          )}
                          customButton={`position-relative btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-font-medium text-gray-500 p-0 ${
                            selectedCourse?.id !== undefined ? 'position-relative' : ''
                          }`}
                          type='button'
                          onClick={() => {
                            if (getValues('type') !== '') {
                              openModal('course')
                              setTypeClass(getValues('type'))
                            }
                          }}
                          disabled={
                            isClassEnded ||
                            is_student_studying_normal_course ||
                            !allowRenderEditRole ||
                            is_student_studying_foundation_course ||
                            classDetail?.type === IClassType.FOUNDATION
                          }
                        >
                          {selectedCourse?.id !== undefined ? (
                            <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                              {selectedCourse?.name}
                            </div>
                          ) : (
                            <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                          )}
                          {allowRenderEditRole && (
                            <KTIcon
                              iconName='notepad-edit'
                              className={`fs-1 text-gray-500 ps-5 sapp-icon-last`}
                              iconType='outline'
                            />
                          )}
                        </ButtonIcon>
                      </div>
                      {errors.course_id && (
                        <small className='text-danger mt-2 lh-1'>
                          {errors.course_id?.message ?? ''}
                        </small>
                      )}
                      {warning?.course !== '' && (
                        <div className='row'>
                          <div className='col-lg-3 d-flex align-items-center'></div>
                          <div className='col-lg-9 fv-row position-relative'>
                            <div className='mt-5 sapp-text-danger'>{warning?.course}</div>
                          </div>
                        </div>
                      )}
                    </Col>
                    {/* EXAM */}
                    <Col span={24}>
                      <SappLabel
                        label={TITLE_OPTIONS_CLASS.exam}
                        className='col-lg-3 d-flex align-items-center'
                      />
                      <div
                        className={clsx('col-lg-9 fv-row position-relative w-100', {
                          'cursor-not-allowed':
                            isClassEnded ||
                            is_student_studying_normal_course ||
                            selectedCourse == null ||
                            (classDetail?.type === IClassType.REVISION &&
                              classDetail.examination_subject_id !== null),
                        })}
                      >
                        <ButtonIcon
                          title={`${selectedExam?.id !== undefined ? ' ' : 'Exam'}`}
                          className={clsx(
                            'sapp-h-45px d-flex justify-content-start align-items-center opacity-100 w-100',
                            {
                              'disabled-background':
                                isClassEnded ||
                                is_student_studying_normal_course ||
                                !allowRenderEditRole ||
                                selectedCourse == null ||
                                (classDetail?.type === IClassType.REVISION &&
                                  classDetail.examination_subject_id !== null),
                            }
                          )}
                          customButton={`btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-text-primary p-0 ${
                            selectedExam?.id !== undefined ? 'position-relative' : ''
                          }`}
                          type='button'
                          onClick={() => {
                            openModal('exam')
                          }}
                          disabled={
                            isClassEnded ||
                            is_student_studying_normal_course ||
                            !allowRenderEditRole ||
                            selectedCourse == null ||
                            (classDetail?.type === IClassType.REVISION &&
                              classDetail.examination_subject_id !== null)
                          }
                        >
                          {selectedExam?.id !== undefined ? (
                            <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                              {selectedExam?.examination.name ?? '-'}
                            </div>
                          ) : (
                            <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                          )}
                        </ButtonIcon>
                        {selectedExam?.id !== undefined &&
                          allowRenderEditRole &&
                          classDetail?.type !== 'REVISION' && (
                            <div className='sapp-icon-last'>
                              <ButtonIconOnly
                                iconName='trash'
                                onClick={() => {
                                  if (!isClassEnded && !is_student_studying_normal_course) {
                                    setSelectedExam(undefined)
                                    setCourseCategoryId('')
                                  }
                                }}
                                iconType='outline'
                                bg={`sapp-custom-bg-transparent ${
                                  isClassEnded || is_student_studying_normal_course ? 'pe-none' : ''
                                }`}
                                activeColor='danger'
                                disabled={!allowRenderEditRole}
                                iconGrayColor={'500'}
                              />
                            </div>
                          )}
                      </div>
                    </Col>
                    {/* CLASSROOM */}
                    {constructionMode !== CONSTRUCTION_MODE.ONLINE && (
                      <Col span={24}>
                        <SappLabel
                          label={'Classroom'}
                          required={constructionMode !== CONSTRUCTION_MODE.ONLINE}
                        />

                        <div className='position-relative'>
                          <ButtonIcon
                            title={`${!selectedClassroom?.id ? 'Add Classroom' : ''}`}
                            className={clsx(
                              'sapp-h-45px d-flex justify-content-start align-items-center opacity-100 w-100',
                              {
                                'disabled-background':
                                  isClassEnded ||
                                  is_student_studying_normal_course ||
                                  is_student_studying_foundation_course ||
                                  classDetail?.type === IClassType.FOUNDATION,
                              }
                            )}
                            customButton={`btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-font-medium text-gray-500 position-relative  p-0 ${
                              selectedClassroom?.id !== undefined ? 'position-relative' : ''
                            }`}
                            type='button'
                            onClick={() => {
                              if (getValues('type') !== '') {
                                openModal('classroom')
                                setTypeClass(getValues('type'))
                              }
                            }}
                            disabled={
                              isClassEnded ||
                              is_student_studying_normal_course ||
                              !allowRenderEditRole ||
                              is_student_studying_foundation_course ||
                              classDetail?.type === IClassType.FOUNDATION
                            }
                          >
                            {selectedClassroom?.id !== undefined ? (
                              <div className='px-4 sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4'>
                                {selectedClassroom?.name}
                              </div>
                            ) : (
                              <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                            )}
                          </ButtonIcon>
                          {selectedClassroom?.id !== undefined && (
                            <div
                              className='sapp-icon-last'
                              style={{
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                            >
                              <ButtonIconOnly
                                iconName='trash'
                                onClick={() => {
                                  if (!isClassEnded && !is_student_studying_normal_course) {
                                    setSelectedClassroom(undefined)
                                  }
                                }}
                                iconType='outline'
                                bg={`sapp-custom-bg-transparent ${
                                  isClassEnded || is_student_studying_normal_course ? 'pe-none' : ''
                                }`}
                                activeColor='danger'
                                disabled={!allowRenderEditRole}
                                iconGrayColor={'500'}
                              />
                            </div>
                          )}
                        </div>
                        {errors.room_id && (
                          <small className='text-danger mt-2 lh-1'>
                            {errors.room_id?.message ?? ''}
                          </small>
                        )}
                      </Col>
                    )}
                  </>
                )}

                {/*Link Meeting*/}
                {constructionMode &&
                  [CONSTRUCTION_MODE.HYBRID, CONSTRUCTION_MODE.LIVE_ONLINE].includes(
                    constructionMode
                  ) && (
                    <Col span={24}>
                      <HookFormTextField
                        required
                        label={'Meeting Link'}
                        control={control}
                        type='url'
                        name='link_meeting'
                      />
                    </Col>
                  )}

                {/* DESCRIBE */}
                <Col span={24}>
                  <HookFormEditor
                    height={352}
                    placeholder=' '
                    label={TITLE_OPTIONS_CLASS.describe}
                    name='description'
                    control={control}
                    math={true}
                    className='sapp-table-class-field'
                    disabled={isClassEnded}
                    resourceLocation={RESOURCE_LOCATION.COURSE}
                    object_id={undefined}
                  />
                </Col>
              </Row>
            </div>
            <SappDrawer
              open={openBlock}
              title='Block Class'
              width={'50%'}
              handleSubmit={() => {
                handleSubmit(
                  (data: any) => {
                    onSubmit(data)
                  },
                  (error) => console.log(error)
                )()
              }}
              handleClose={handleCloseBlock}
              confirmOnclose
              cancelButtonCaption={'Cancel'}
              okButtonCaption={'Save'}
              footerClassName='border-0'
            >
              <HookFormEditor
                label='Reason'
                placeholder=' '
                required
                control={control}
                name='reason'
                math={true}
                height={500}
                resourceLocation={RESOURCE_LOCATION.COURSE}
                object_id={undefined}
              />
            </SappDrawer>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary
                title='Discard'
                className='me-5'
                onClick={handleCancel}
                type='button'
              />
              <ButtonPrimary
                type='submit'
                title='Save'
                loading={loadingClass || isLoading}
                disabled={isClassEnded || !allowRenderEditRole || isLoading}
                onClick={() => {
                  if (statusBlock === 'BLOCKED') {
                    setOpenBlock(true)
                  } else {
                    handleSubmit(
                      (data: any) => {
                        onSubmit(data)
                      },
                      (error) => console.log(error)
                    )()
                  }
                }}
              />
            </div>
            <FormProvider {...methods}>
              <AddCourse
                id={id}
                open={modals.course}
                closeModal={closeModal}
                selectedCourse={selectedCourse}
                setSelectedCourse={setSelectedCourse}
                typeClass={typeClass}
                handleResetListExam={handleResetListExam}
                courseCategoryId={courseCategoryId}
                subject_id={classDetail?.examination_subject?.subject_id}
                program={classDetail?.examination_subject?.examination?.course_category?.name}
                subject={classDetail?.examination_subject?.subject?.name}
              />

              <AddExam
                id={selectedCourse?.id}
                open={modals.exam}
                closeModal={closeModal}
                course_id={selectedCourse?.id}
                course_category_id={selectedCourse?.course_categories?.[0]?.id}
                course_category_type={selectedCourse?.course_categories?.[0]?.name}
                selectedExam={selectedExam}
                setSelectedExam={setSelectedExam}
              />
              {constructionMode !== CONSTRUCTION_MODE.ONLINE && (
                <AddClassroom
                  open={modals.classroom}
                  closeModal={closeModal}
                  selectedClassroom={selectedClassroom}
                  setSelectedClassroom={setSelectedClassroom}
                />
              )}
            </FormProvider>
          </div>
        </div>
      )}
    </>
  )
}

export default EditClass

import { VALIDATION_FIELD } from 'src/constants'
import { z } from 'zod'

export const lessonFormSchema = z.object({
  name: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
  course_id: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
  course_section_ids: z.array(z.string()).min(1, { message: 'You must select at least one item' }),
  version: z.string().optional(),
  description: z.string().optional(),
  standard_study_hour: z.number().min(1),
  is_online: z.boolean(),
  is_review_allowed: z.boolean(),
})

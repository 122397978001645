export * from './student'
export * from './teacher'
export * from './staff'

export const USER_COMPANY_POSITION = [
  'Nhân viên',
  'Chuyên viên',
  'Trưởng nhóm/ Giảm sát',
  'Trưởng/Phó phòng',
  'GĐ/PGĐ',
]

export const USER_COMPANY_TYPE = [
  'Ngân hàng',
  'Chứng khoán',
  'Bảo hiểm',
  'Kiểm toán và Dịch vụ tư vấn',
  'Quản lý Quỹ',
  'Dịch vụ',
  'Hạ tầng/ Công nghệ thông tin/ Phần mềm',
  'Bệnh viện/ Y tế/ Dược phẩm',
  'Khác',
  'Thiết kế, Thi công và Xây dựng',
  'Bất động sản',
  'Năng lượng và Môi trường',
  'Thực phẩm và Nông nghiệp',
  'Công nghiệp',
  'Giao thông vận tải',
  'Chính phủ và Nhà nước',
  'Thương mại điện tử',
  'Giáo dục và đào tạo',
  'Làm đẹp và Chăm sóc sức khỏe',
  'Nhà hàng và Khách sạn',
  'Sản xuất và Thương mại',
]

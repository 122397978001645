import React, { FC } from 'react'

type Props = {
  data?: string | number | React.ReactNode
}

const UserCell: FC<Props> = ({ data }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{data ?? '-'}</span>
    </div>
  </div>
)

export default UserCell

import { Skeleton, Spin } from 'antd'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import { ArrowDownIcon } from 'src/common/icons'
import SappLabel from 'src/components/base/label/SappLabel'
import './HookFormSelect.scss'
import SAPPSelect from './SAPPSelect'
import { ISappSelectHookForm } from './type'

export interface HookFormSelectAntdProps extends ISappSelectHookForm {}
const HookFormSelectAntd = ({
  label,
  required,
  name,
  control,
  guideline,
  skeleton,
  defaultValue,
  className,
  classNameHeight,
  dropdownStyle,
  showSearch,
  suffixIcon = <ArrowDownIcon />,
  ...props
}: HookFormSelectAntdProps) => {
  return (
    <>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              {!skeleton ? (
                <SAPPSelect
                  {...field}
                  {...props}
                  className={clsx(className, `w-100 sapp-hook-form-select`)}
                  classNameHeight={classNameHeight}
                  onChange={(value, option) => {
                    field.onChange(value)
                    props.onChange && props.onChange(value, option)
                  }}
                  dropdownStyle={{
                    zIndex: '2000',
                    minWidth: '150px',
                    ...(dropdownStyle && dropdownStyle),
                  }}
                  placeholder={props.placeholder || 'Please Select'}
                  filterOption={
                    props.filterOption ??
                    (showSearch &&
                      ((input, option) => {
                        const optionText = (
                          option?.userName ??
                          option?.children ??
                          option?.label ??
                          ''
                        )
                          .toString()
                          .toLowerCase()
                        const optionEmail = (option?.email ?? '').toString().toLowerCase()
                        const trimmedInput = input?.toLowerCase()?.trimStart()?.trimEnd()
                        // Check if the trimmed option text includes the trimmed input

                        return (
                          optionText.includes(trimmedInput) || optionEmail.includes(trimmedInput)
                        )
                      }))
                  }
                  status={`${error || props.customError ? 'error' : ''}`}
                  suffixIcon={!props.isLocked ? suffixIcon : null}
                  onPopupScroll={(e) => {
                    const { target } = e
                    if (
                      Math.ceil((target as HTMLElement).scrollTop) +
                        Math.ceil((target as HTMLElement).offsetHeight) ===
                      Math.ceil((target as HTMLElement).scrollHeight)
                    ) {
                      if (props.handleNextPage) {
                        props.handleNextPage()
                      }
                    }
                  }}
                  value={field.value ? field.value : undefined}
                  notFoundContent={
                    props.loading ? props.notFoundContent ?? <Spin size='small' /> : 'No data'
                  }
                />
              ) : (
                <Skeleton.Input
                  active
                  size={props.size === 'middle' ? 'default' : props.size}
                  className='w-100'
                />
              )}

              <ErrorMessage>{error?.message}</ErrorMessage>
            </>
          )
        }}
      />
      <GuidelineField guideline={guideline} />
    </>
  )
}

export default HookFormSelectAntd

import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Row, Skeleton, Tree, TreeProps } from 'antd'
import { DataNode } from 'antd/es/tree'
import { forwardRef, Key, useEffect, useImperativeHandle, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import SwitcherClosed from 'src/common/CustomIcons/SwitcherClosed'
import SwitcherExpanded from 'src/common/CustomIcons/SwitcherExpanded'
import EmptyStateGraphic from 'src/common/graphic/EmptyStateGraphic'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { PROGRAM } from 'src/constants'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { ILessonContent, ILessonForm } from 'src/type'
import { SaveType } from '../create/LessonCreate'
import './lessonForm.styles.scss'
import { lessonFormSchema } from './schema'

type MappedLesson = {
  key: string
  title: string
  pos: string // added pos field
  children?: MappedLesson[]
}

function mapCourseSections(
  lessons: ILessonContent[],
  parentPos: string = '',
  checkedKeys: Key[]
): MappedLesson[] {
  return lessons.map((lesson, index) => {
    const pos = parentPos ? `${parentPos}-${index}` : `0-${index}`

    const children = lesson.child ? mapCourseSections(lesson.child, pos, checkedKeys) : undefined

    return {
      key: lesson.course_section_id,
      title: lesson.course_section.name,
      pos,
      children: children,
    }
  })
}

export type LessonFormMethods = {
  submitForm: () => void
  resetForm: () => void
}

interface LessonFormProps {
  onSubmit: any
  defaultValues: ILessonForm
  saveType?: SaveType
  setCourseContentEmpty?: React.Dispatch<React.SetStateAction<boolean>>
}

const reorderCheckedKeys = (checkedKeys: Key[], treeData: MappedLesson[]): string[] => {
  const orderedKeys: string[] = []

  const traverse = (nodes: DataNode[]) => {
    nodes.forEach((node) => {
      if (checkedKeys.includes(node.key as string)) {
        orderedKeys.push(node.key as string)
      }
      if (node.children) {
        traverse(node.children)
      }
    })
  }

  traverse(treeData)
  return orderedKeys
}

// Recursive helper to update disabled state based on activePrefix.
// If activePrefix is non-null, only nodes whose pos starts with it remain enabled.
function updateTreeData(nodes: MappedLesson[], activePrefix: string | null): MappedLesson[] {
  return nodes.map((node) => {
    const isDisabled = activePrefix ? !node.pos.startsWith(activePrefix) : false
    return {
      ...node,
      disabled: isDisabled,
      children: node.children ? updateTreeData(node.children, activePrefix) : undefined,
    }
  })
}

// Helper to find a node by key in the tree.
function findNodeByKey(nodes: MappedLesson[], key: Key): MappedLesson | null {
  for (const node of nodes) {
    if (node.key === key) return node
    if (node.children) {
      const found = findNodeByKey(node.children, key)
      if (found) return found
    }
  }
  return null
}

const LessonForm = forwardRef<LessonFormMethods, LessonFormProps>((props, ref) => {
  const { onSubmit, defaultValues, setCourseContentEmpty } = props
  const [treeData, setTreeData] = useState<MappedLesson[]>([])

  const { id, lessonId } = useParams()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<ILessonForm>({
    defaultValues,
    resolver: zodResolver(lessonFormSchema),
  })
  const isOnline = useWatch({ control, name: 'is_online' })
  const courseSectionIds = useWatch({ control, name: 'course_section_ids' })
  const { data, isLoading, isFetching } = useQuery({
    queryKey: id ? lessonKeys.contentList(id) : lessonKeys.base,
    queryFn: () =>
      id
        ? LessonApi.getContentList(id, {
            lesson_id: lessonId,
          })
        : Promise.reject('No id provided'),
    enabled: !!id,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const lessonData = res.data.data
      setCourseContentEmpty?.(lessonData.length === 0)

      // Map the course sections
      const mappedData = mapCourseSections(lessonData, undefined, courseSectionIds ?? [])

      // If CFA or CMA, determine the active section from selected keys
      let activeSection: string | null = null
      if (res.data.program === PROGRAM.CFA || res.data.program === PROGRAM.CMA) {
        if (res.data.lesson_content_ids.length > 0) {
          const firstCheckedKey = res.data.lesson_content_ids[0]
          const node = findNodeByKey(mappedData, firstCheckedKey)
          if (node) {
            activeSection = node.pos.split('-').slice(0, 2).join('-') // Extract first two segments as section
          }
        }
      }

      // Update tree data to disable sections not in the active section
      const updatedTree = activeSection ? updateTreeData(mappedData, activeSection) : mappedData
      setCourseContentEmpty?.(lessonData.length === 0)
      setTreeData(updatedTree)

      setValue('course_section_ids', res.data.lesson_content_ids)
    },
  })

  useEffect(() => {
    setValue('is_review_allowed', !!isOnline)
  }, [isOnline, setValue])

  const onCheck: TreeProps['onCheck'] = (checkedKeysValue, info) => {
    const allCheckedKeys = checkedKeysValue as Key[]

    // Determine the active section (if any checked keys exist)
    let newActiveSection: string | null = null
    if (allCheckedKeys.length > 0) {
      const firstCheckedKey = allCheckedKeys[0]
      const node = findNodeByKey(treeData, firstCheckedKey)
      if (node) {
        newActiveSection = node.pos.split('-').slice(0, 2).join('-') // Extract section prefix
      }
    }

    // If nothing is checked, enable everything
    const updatedTree =
      (data?.data.program === PROGRAM.CFA || data?.data.program === PROGRAM.CMA) && newActiveSection
        ? updateTreeData(treeData, newActiveSection)
        : mapCourseSections(data?.data.data ?? [], undefined, allCheckedKeys) // Reset to full enabled state

    // Reorder checked keys based on updated tree
    const sortedKeys = reorderCheckedKeys(allCheckedKeys, updatedTree)

    // Update state
    setTreeData(updatedTree)
    setValue('course_section_ids', sortedKeys)
  }

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)()
    },
    resetForm: () => {
      reset()
    },
  }))

  if (isLoading || isFetching) return <Skeleton />

  return (
    <div className=''>
      {data && data?.data.data.length === 0 && (
        <div className='grid place-items-center mt-12'>
          <div className='text-center'>
            <EmptyStateGraphic />
            <p className='mt-5 text-gray-1000'>Course content is empty!</p>
          </div>
        </div>
      )}
      {data && data?.data.data.length > 0 && (
        <>
          {/* Basic Information */}
          <div className='mb-8'>
            <h3 className='mb-8'>Basic information</h3>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <HookFormTextField control={control} name='name' label='Lesson Name' required />
              </Col>
              <Col span={12}>
                <HookFormTextField
                  control={control}
                  name='standard_study_hour'
                  label='Study Hour'
                  required
                  type='number'
                  minNumber={1}
                  onChange={(e) => setValue('standard_study_hour', parseInt(e.target.value))}
                />
              </Col>
              <Col>
                <HookFormCheckBox control={control} name='is_online' title='Online LMS' />
              </Col>
              <Col>
                <HookFormCheckBox
                  control={control}
                  name='is_review_allowed'
                  title='View on LMS'
                  disabled={isOnline}
                />
              </Col>
              <Col span={24}>
                <HookFormTextField
                  control={control}
                  name='description'
                  label='Description'
                  placeholder='Please describe the lesson'
                />
              </Col>
            </Row>
          </div>

          {/* Course Content*/}
          <div className='pb-6 position-relative'>
            <h3>Course Content</h3>
            {errors.course_section_ids && (
              <span className='error-message'>{errors.course_section_ids.message as string}</span>
            )}
          </div>
          <Tree
            treeData={treeData}
            checkable
            onCheck={onCheck}
            autoExpandParent={true}
            defaultCheckedKeys={data?.data.lesson_content_ids}
            checkedKeys={courseSectionIds}
            switcherIcon={({ expanded }) =>
              expanded ? (
                <SwitcherExpanded className='text-gray-1000' />
              ) : (
                <SwitcherClosed className='text-gray-1000' />
              )
            }
            className='lessonFormTree'
          />
        </>
      )}
    </div>
  )
})

export default LessonForm

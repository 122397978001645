import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import { ResultDashBoard } from 'test-result-view-package'
import 'test-result-view-package/dist/index.css'

interface IScoreDetail {
  metadata: {
    page_index: number
    page_size: number
    total_pages: number
    total_records: number
  }
  answers: Array<any>
}

export const GRADE_STATUS = {
  AWAITING_GRADING: 'AWAITING_GRADING',
  FINISHED_GRADING: 'FINISHED_GRADING',
}

const App = () => {
  const { id, classId } = useParams()
  const attemptId = id
  const useGetQuizAttempts = () => {
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>(null)

    const fetchQuizAttempts = async () => {
      setLoading(true)
      setError(null)
      try {
        const response = await CoursesAPI.getQuizAttempts(id ?? '')
        setData(response?.data)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      fetchQuizAttempts()
      fetchScoreDetails()
    }, [])

    return { data, loading, error, refetch: fetchQuizAttempts }
  }

  const useGetScoreDetail = () => {
    const [scoreDetails, setScoreDetails] = useState<IScoreDetail>({
      metadata: { total_pages: 0, total_records: 0, page_index: 0, page_size: 10 },
      answers: [],
    })

    const fetchScoreDetails = async (pageIndex: number = 1, pageSize: number = 10) => {
      try {
        const res = await CoursesAPI.getQuizAttemptsTable(attemptId as string, {
          page_index: pageIndex ?? 1,
          page_size: pageSize ?? 10,
        })
        if (res.data) {
          setScoreDetails({
            metadata: res.data.metadata,
            answers: [...scoreDetails.answers, ...res.data.answers],
          })
        }
      } catch {}
    }

    useEffect(() => {
      fetchScoreDetails(1, 10)
    }, [])

    const handleScoreDetailScroll = () => {
      if (scoreDetails.metadata.page_index < scoreDetails.metadata.total_pages) {
        fetchScoreDetails(scoreDetails.metadata.page_index + 1, scoreDetails.metadata.page_size)
      }
    }

    return { scoreDetails, setScoreDetails, handleScoreDetailScroll, fetchScoreDetails }
  }

  const useGetQuizAttemptsChart = () => {
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>(null)

    const fetchQuizAttemptsChartData = async () => {
      setLoading(true)
      setError(null)
      try {
        const response = await CoursesAPI.getQuizAttemptsChartData(id ?? '')
        setData(response?.data)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      fetchQuizAttemptsChartData()
    }, [])

    return { data, loading, error, refetch: fetchQuizAttemptsChartData }
  }

  const { scoreDetails, handleScoreDetailScroll, fetchScoreDetails } = useGetScoreDetail()
  const { data: questions, loading } = useGetQuizAttempts()
  const { data: chartData } = useGetQuizAttemptsChart()
  return (
    <div style={{ backgroundColor: '#f9f9f9', fontSize: '16px', paddingTop: '20px' }}>
      <ResultDashBoard
        questions={questions}
        chartData={chartData}
        scoreDetails={scoreDetails}
        onScoreDetailScroll={handleScoreDetailScroll}
        score={
          questions?.quizAttempt?.grading_status === GRADE_STATUS.FINISHED_GRADING
            ? questions?.quizAttempt?.score
            : chartData?.multiple_choice_score
        }
        onBack={() => {
          window.location.replace(`/classes/detail/${classId}/test-quiz`)
        }}
        isLoading={loading}
        explanationUrl='/test-result/{}'
      />
    </div>
  )
}

export default App

import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Control, FieldError } from 'react-hook-form'
import { Col } from 'react-bootstrap'
import { Select } from 'antd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { LIST_SECTION_OPTION } from 'src/constants/entrancetest'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { IStateEntranceTest } from 'src/type/entrancetest'

type Props = {
  error?: FieldError | undefined
  control: Control<any>
  index: number
  postionIndex?: number
  categorySections?: []
  section: any
  setValue: any
  getValue: any
  onChangeCategory: any
  setFileEditor?: Dispatch<SetStateAction<any>>
  setFileEditorNotPass?: Dispatch<SetStateAction<any>>
  id: string | undefined
  listInfo: IStateEntranceTest | undefined
}

const InformationTab = ({
  control,
  index,
  section,
  postionIndex,
  categorySections,
  setValue,
  getValue,
  onChangeCategory,
  setFileEditor,
  // setFileEditorNotPass,
  id,
  listInfo,
}: Props) => {
  const sectionDisable = index !== postionIndex

  useEffect(() => {
    if (section?.id) {
      Object.entries(section).map((e) => {
        setValue(`section.${index}.${e[0]}`, e[1])
      })
    }
  }, [section, sectionDisable])

  const handleResetCategory = () => {
    setValue(`section.${index}.question_category_id`, section?.question_category?.id)
  }

  return (
    <div className='w-full sapp-border-entrance-test'>
      <HookFormTextField
        label={LIST_SECTION_OPTION.name}
        required
        control={control}
        name={`section.${index}.name`}
        placeholder=' '
        disabled={sectionDisable || !listInfo?.can_edit_entrance_test_sections?.name}
      />
      <div className='my-10 d-flex w-100 gap-5'>
        <div className='sapp-flex-1'>
          <HookFormSelectAntd
            required
            name={`section.${index}.question_category_id`}
            control={control}
            defaultValue={section?.question_category?.id}
            placeholder=' '
            label={LIST_SECTION_OPTION.question_category_id}
            disabled={
              sectionDisable || !listInfo?.can_edit_entrance_test_sections?.question_category_id
            }
            onChange={(value) => {
              onChangeCategory(handleResetCategory, value, !!section?.question_category?.id, index)
            }}
            options={
              categorySections?.map((item: any) => ({ value: item.id, label: item.name })) ?? []
            }
          />
        </div>
        {/* </Col> */}
        {/* <Col md={6} className="mb-8"> */}
        <div className='sapp-flex-1'>
          <HookFormTextField
            label={LIST_SECTION_OPTION.pass_point}
            required
            defaultValue={70}
            control={control}
            name={`section.${index}.pass_point`}
            placeholder=' '
            type='number'
            disabled={sectionDisable || !listInfo?.can_edit_entrance_test_sections?.pass_point}
            postFix={<div className='px-4'>%</div>}
          />
        </div>
        {/* </Col> */}
      </div>
      <HookFormEditor
        label={LIST_SECTION_OPTION.pass_note}
        required
        height={300}
        defaultValue={getValue(`section.${index}.pass_note`) || section.pass_note}
        placeholder=''
        name={`section.${index}.pass_note`}
        control={control}
        math={true}
        disabled={sectionDisable || !listInfo?.can_edit_entrance_test_sections?.pass_note}
        className='w-100'
        resourceLocation={RESOURCE_LOCATION.ENTRANCE_TEST}
        object_id={getValue(`section.${index}.pass_note`) || section.pass_note ? id : undefined}
        setDataFile={setFileEditor}
      />
      <Col className='mt-8'>
        <HookFormEditor
          label={LIST_SECTION_OPTION.failure_note}
          required
          height={300}
          defaultValue={getValue(`section.${index}.failure_note`) || section.failure_note}
          placeholder=' '
          name={`section.${index}.failure_note`}
          control={control}
          math={true}
          disabled={sectionDisable || !listInfo?.can_edit_entrance_test_sections?.failure_note}
          className='w-100'
          resourceLocation={RESOURCE_LOCATION.ENTRANCE_TEST}
          object_id={
            getValue(`section.${index}.failure_note`) || section.failure_note ? id : undefined
          }
          setDataFile={setFileEditor}
        />
      </Col>
    </div>
  )
}

export default InformationTab

import { InfiniteData } from 'react-query'
import { createRequiredContext } from 'src/helper/createRequiredContext'
import { Program } from 'src/type'
import { TeachingProgramMode } from 'src/type/teacher'
import { GroupedTeacherTeachableRes } from 'src/type/users/teacher'

interface ITeachingProgramProvider {
  data: InfiniteData<GroupedTeacherTeachableRes>
  mode: TeachingProgramMode
  program: Program
  isPrimaryCategory: boolean | undefined
}

export const [TeachingProgramProvider, useTeachingProgramContext] =
  createRequiredContext<ITeachingProgramProvider>()

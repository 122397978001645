import { Select } from 'antd'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { KTCardBody } from 'src/_metronic/helpers'
import { ShopBlogAPI } from 'src/apis/short-course/blogs/blog'
import SappTooltip from 'src/common/SappTooltip'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { PageLink, SHOP_BLOG_STATUS } from 'src/constants'
import { CONFIRM_STATUS_CHANGE } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IBlogList, IBlogListItem } from 'src/type/shop/blog'
import {
  cleanParamsAPI,
  formatDate,
  formatDateTime,
  formatISOFromDate,
  formatISOToDate,
  generateSlug,
  getDateInfo,
  onLinkSocial,
} from 'src/utils'
import SAPPCheckbox from '../../base/checkbox/SAPPCheckbox'
import SappTable from '../../base/SappTable'
import HookFormSelectAntd from '../../base/select/HookFormSelectAntd'
import ShopActionBox from '../shared/ShopActionBox'

// define headers
const headers = [
  {
    label: 'Title',
    className: 'min-w-175px',
  },
  {
    label: 'Author',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Category',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-75px pe-5',
  },
  {
    label: 'Last Updated',
    className: 'min-w-250px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

interface IQueryPrams {
  title: string | null
  category_id: string | null
  author_id: string | null
  tag_id: string | null
  status: string | null
  sortType: string | null
  fromDate: Date | string
  toDate: Date | string
}

interface IProps {
  blogList: IBlogList | null | undefined
  setBlogList: Dispatch<SetStateAction<IBlogList | null | undefined>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  loadData: (page_index: number, page_size: number, object?: Object) => void
  queryParam: IQueryPrams
  getParams: (
    title: string,
    category_id: string,
    author_id: string,
    tag_id: string,
    status: string,
    sortType: string,
    fromDate?: Date | string,
    toDate?: Date | string
  ) => Object
  reloadPage: () => void
}

const TableBlogs = ({
  blogList,
  setBlogList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  loadData,
  queryParam,
  getParams,
  reloadPage,
}: IProps) => {
  const { control, setValue } = useForm()
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()

  /**
   * Một hàm dùng để change params trên router
   */
  const filterParamsPagination = () => {
    const dateQueryFromDate =
      queryParam?.fromDate instanceof Date ? getDateInfo(queryParam.fromDate) : null
    const dateQueryToDate =
      queryParam?.toDate instanceof Date ? getDateInfo(queryParam.toDate) : null
    return cleanParamsAPI(
      getParams(
        queryParam.title ?? '',
        queryParam.category_id ?? '',
        queryParam.author_id ?? '',
        queryParam.tag_id ?? '',
        queryParam.status ?? '',
        queryParam.sortType ?? '',
        queryParam.fromDate && dateQueryFromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        queryParam.toDate && dateQueryToDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : ''
      )
    )
  }

  /**
   * Call API get Categories
   */
  useEffect(() => {
    setLoading(true)
    loadData(1, 10, cleanedParams)
  }, [])

  /**
   * Một hàm dùng để đổi params trên router
   * @param {number} currentPage  - trang page hiện tại
   * @param {number} size  - lượng bản ghi của page
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      title: queryParam.title ?? '',
      category_id: queryParam.category_id ?? '',
      status: queryParam.status ?? '',
      sortType: queryParam.sortType ?? '',
      fromDate: queryParam.fromDate instanceof Date ? formatDate(queryParam.fromDate) : '',
      toDate: queryParam.toDate instanceof Date ? formatDate(queryParam.toDate) : '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  /**
   * Call API khi change pagination
   * @param {number} page_index  - trang page hiện tại
   * @param {number} page_size  - lượng bản ghi của page
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    loadData(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  /**
   * Một hàm dùng xoá môt Product
   * @param {string} id  - id của product muốn xoá =
   */
  const deleteBlog = async (id: string) => {
    await ShopBlogAPI.deleteBlog(id)
    toast.success('Blog deleted successfully!')
    reloadPage()
  }

  /**
   * Một hàm dùng xoá môt Product
   * @param {string} id  - id của product muốn xoá =
   */
  const duplicateBlog = async (id: string) => {
    await ShopBlogAPI.duplicateBlog(id)
    toast.success('Blog duplicated successfully!')
    reloadPage()
  }

  /**
   * Một hàm để active edit action
   * @param {string} id  - id của product muốn xoá =
   */
  const handleChangeStatus = async (data: IBlogListItem, status: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [CONFIRM_STATUS_CHANGE],
      onClick: async () => {
        setLoading(true)
        try {
          if (!status) return
          setLoading(true)
          await ShopBlogAPI.updateBlogStatus(data.id, status)
          toast.success('Update the status of blog successfully!')
          reloadPage()
        } catch (err) {
          // do nothing
        } finally {
          setLoading(false)
        }
      },
      onClose: () => {
        setValue(`status-${data?.id}`, data.status)
      },
    })
  }

  /**
   * Một hàm để active preivew action
   * @param {string} slug  - slug của product muốn xoá =
   */
  const handlePreview = async (slug: string) => {
    onLinkSocial(`${process.env.REACT_APP_WEB_SHORT_COURSE_URL}/blog/post/${slug}`)
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={blogList?.blogs}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {blogList?.blogs?.map((data, index) => {
          const isChecked = checkedList.includes(data.id)

          return (
            <tr key={index}>
              <td>
                <SAPPCheckbox
                  checkTarget='#kt_table_users .form-check-input'
                  checked={isChecked}
                  onChange={() => {
                    toggleCheck(data.id)
                  }}
                />
              </td>
              <td className='text-start'>
                <SappTooltip
                  title={data?.title}
                  link={`${PageLink.NEW_POST}/${data.id}`}
                  max_length={80}
                />
              </td>
              <td className='text-start'>{data?.shop_blog_author?.name}</td>
              <td className='text-start'>{data?.primary_shop_blog_category?.name}</td>
              <td className='text-start'>
                <HookFormSelectAntd
                  allowClear={false}
                  size='large'
                  name={`status-${data.id}`}
                  control={control}
                  dropdownStyle={{ maxWidth: 100 }}
                  placeholder='Status'
                  filterOption={true}
                  defaultValue={data?.status}
                  onChange={(status: any) => handleChangeStatus(data, status)}
                  options={SHOP_BLOG_STATUS}
                />
              </td>
              <td className='text-start'>{formatDateTime(new Date(data?.updated_at))}</td>
              <td className='text-start'>
                <ShopActionBox
                  id={data.id}
                  setLoading={setLoading}
                  reloadPage={reloadPage}
                  onDelete={deleteBlog}
                  onDuplicate={() => duplicateBlog(data.id)}
                  onEdit={() => navigate(`${PageLink.NEW_POST}/${data.id}`)}
                  onPreview={() => handlePreview(generateSlug(data?.title))}
                  menuList={['Edit', 'Preview', 'Duplicate', 'Delete']}
                />
              </td>
            </tr>
          )
        })}
      </SappTable>
      <PagiantionSAPP
        currentPage={blogList?.meta?.page_index || 1}
        pageSize={blogList?.meta?.page_size || 10}
        totalItems={blogList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableBlogs

import _ from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { KTCardBody } from 'src/_metronic/helpers'
import { UsersAPI } from 'src/apis/user'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/ui/table/SappTable'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { cleanParamsAPI } from 'src/utils'
import { useQueryResponse } from 'src/components/teacher-list/components/core/QueryResponseProvider'
import UserCell from 'src/components/user-management/UserCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import { STATUS_FORM, TEACHER_PROFILE } from 'src/constants'
import { CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, Role, ITeacherTableProps } from 'src/type'
import { ITeacherData, ITeacher } from 'src/type/teacher'
import { TeacherActionsCell } from './columns/TeacherActionsCell'
import { ITeacherFacility } from 'src/type/students'

const TeacherTable = ({
  pagination,
  setPagination,
  teacherList,
  handleChangeParams,
  queryParams,
  setSelected,
  selected,
}: ITeacherTableProps) => {
  const { isLoading, refetch } = useQueryResponse()
  const { confirm, contextHolder } = useConfirm()
  //* reset lại table về mặc định nếu không call API
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderEditStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderResetPassword = hasPermission(
    profileMe?.roles,
    TITLE_GR.PUT_RESET_PASSWORD_USER_GR
  )
  const allowRenderResetPasswordStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.RESET_PASSWORD_STAFF
  )
  const allowRenderBlock = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderBlockStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderEditEmail = hasPermission(profileMe?.roles, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR)
  const allowRenderEditEmailStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.CHANGE_EMAIL_STAFF
  )
  const allowRenderViewProfile = hasPermission(profileMe?.roles, TITLE_GR.VIEW_USER_GR)
  const allowRenderViewProfileStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.GET_STAFF)

  const allowRenderAction =
    allowRenderEdit ||
    allowRenderEditStaff ||
    allowRenderResetPassword ||
    allowRenderResetPasswordStaff ||
    allowRenderBlock ||
    allowRenderBlockStaff ||
    allowRenderEditEmail ||
    allowRenderEditEmailStaff ||
    allowRenderViewProfile ||
    allowRenderViewProfileStaff
  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })
  useEffect(() => {
    teacherList?.users?.map((item: ITeacherData | ITeacher, index: number) => {
      setValue(`status${index}`, item.status)
    })
  }, [teacherList?.users])
  const changeStatusUser = (id: string, status: any) => {
    UsersAPI.updateUserStatus({ id, status }).then(() => toast.success('Update Successfully!'))
  }

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: () =>
        changeStatusUser(
          id,
          EStatusUser.ACTIVE === value
            ? EStatusUser.ACTIVE
            : EStatusUser.INACTIVE === value
            ? EStatusUser.INACTIVE
            : EStatusUser.BLOCKED
        ),
      onClose: () => cancel(true),
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }
  const filterParams = cleanParamsAPI({ ...queryParams })

  useEffect(() => {
    setPagination((prev) => ({ ...prev, total: teacherList?.metadata?.total_records }))
  }, [teacherList])

  const columnsValue = [
    {
      title: 'Code',
      render: (record: ITeacherData) => <UserCell data={record?.code ?? ''} />,
    },
    {
      title: 'User',
      render: (_: ITeacherData, record: ITeacherData) => (
        <UserInfoCell
          user={record ?? ''}
          linkViewProfile={`${TEACHER_PROFILE}/${record?.id}/overview`}
        />
      ),
    },
    {
      title: 'Username',
      render: (record: ITeacherData) => <UserCell data={record?.username ?? ''} />,
    },
    {
      title: 'Phone',
      render: (record: ITeacherData) => <UserCell data={record?.user_contacts?.[0]?.phone ?? ''} />,
    },
    {
      title: 'Status',
      render: (_: ITeacherData, record: ITeacherData, index: number) => {
        return (
          <HookFormSelectAntd
            allowClear={false}
            size='large'
            name={`status${index}`}
            control={control}
            dropdownStyle={{ maxWidth: 100 }}
            placeholder='Status'
            filterOption={true}
            disabled={!allowRenderEdit}
            onChange={(selectedValue: unknown) =>
              handleChange(`status${index}`, selectedValue as string, record?.id)
            }
            classNameHeight='sapp-h-35px'
            options={STATUS_FORM}
          />
        )
      },
    },
    {
      title: 'Facility',
      render: (record: ITeacherData) => (
        <div className='d-flex align-items-start flex-column'>
          {record?.teacher_facilities.map((item: ITeacherFacility) => (
            <span className={`badge badge-light-dark fs-7 my-1 me-1`} key={item?.id}>
              {item?.facility?.name}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: 'Date',
      render: (record: ITeacherData) => (
        <DatetimeColumn updated_at={record?.updated_at} created_at={record?.created_at} />
      ),
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record: ITeacherData) => (
        <>
          {allowRenderAction && (
            <TeacherActionsCell
              id={record?.id}
              status={record?.status}
              email={record?.detail?.email as string}
            />
          )}
        </>
      ),
    },
  ]

  return (
    <KTCardBody className='py-4'>
      {contextHolder}
      <SappTable
        handleChangeParams={handleChangeParams}
        filterParams={filterParams}
        columns={columnsValue}
        fetchData={refetch}
        data={teacherList?.users ?? []}
        pagination={pagination}
        setPagination={setPagination}
        fetchTableData={refetch}
        loading={isLoading}
        showCheckbox
        setSelection={setSelected}
        selections={selected}
      />
    </KTCardBody>
  )
}

export { TeacherTable }

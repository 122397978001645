import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { HEADER_CLASS_LIST, PageLink } from 'src/constants'
import { OPTIONS_OF_DURATION } from 'src/constants/classes'
import { useConfirm } from 'src/hooks/use-confirm'
import {
  cleanParamsAPI,
  formatDateTime,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { CLASS_PROFILE, CLASS_PROFILE_URL } from 'src/constants/classes'
import SappTooltip from 'src/common/SappTooltip'
import { KTCardBody } from 'src/_metronic/helpers'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { INameAndId } from 'src/type/shop/common'
import { IClassList, IClassProduct } from 'src/type/shop/classs'

interface QueryParams {
  class_name: string
  class_code: string
  course_category_id: string
  facility_id: string | null
  sortType: string | null
  fromDate: Date
  toDate: Date
  status: string
  instruction_mode: string
  class_type: string
  subject: string
}

type QueryFunc = (
  class_name: string,
  class_code: string,
  course_category_id: string,
  facility_id: string,
  sortType: string,
  fromDate?: string | Date,
  toDate?: string | Date,
  status?: string,
  instruction_mode?: string,
  class_type?: string,
  subject?: string,
  dateField?: string
) => {
  class_name: string
  class_code: string
  course_category_id: string
  facility_id: string
  sortType: string
  fromDate?: string | Date
  toDate?: string | Date
  status?: string
  instruction_mode?: string
  class_type?: string
  subject?: string
  dateField?: string
}

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  classList: IClassList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  fetchClassList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: QueryFunc
  queryParams: QueryParams
  checkedList: string[]
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
  allowCheckMany?: boolean
  initValue?: INameAndId
  setDefaultChecked: (item: IClassProduct[]) => void
}

// define headers
const headers = [
  {
    label: 'Class',
    className: 'min-w-275px pe-5',
  },
  {
    label: 'Foundation',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Program',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Subjects',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Duration',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Opening',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Courses',
    className: 'min-w-250px pe-5',
  },
  {
    label: 'Exam',
    className: 'min-w-175px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

const TableClasses = ({
  classList,
  loading,
  setLoading,
  filterParams,
  fetchClassList,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  allowCheckMany,
  initValue,
  setDefaultChecked,
}: IProps) => {
  const { contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  // TODO: check xem có tồn tại params ở trên router không?
  const showQueryParams =
    queryParams.class_name ||
    queryParams.course_category_id ||
    queryParams.facility_id ||
    queryParams.sortType ||
    queryParams.fromDate ||
    queryParams.toDate ||
    queryParams.status ||
    queryParams.instruction_mode ||
    queryParams.class_type ||
    queryParams.subject
  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.class_name.trimStart().trimEnd() ?? '',
      queryParams.class_code ?? '',
      queryParams.course_category_id ?? '',
      queryParams.facility_id ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      queryParams.status ?? '',
      queryParams.instruction_mode ?? '',
      queryParams.class_type ?? '',
      queryParams.subject ?? '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )
  // Using validate for input
  const { setValue } = useForm<any>({
    mode: 'onChange',
  })

  useEffect(() => {
    setLoading(true)
    fetchClassList(page_index || 1, page_size || 10, filterParams)
    toggleCheck(initValue?.id)

    if (initValue?.id && classList?.classes) {
      setDefaultChecked(classList?.classes?.filter((item) => item.id === initValue?.id))
    }
  }, [])

  useEffect(() => {
    classList?.classes
      ?.map((e: any) => ({ label: e.status, value: e.status }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, classList?.classes])

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchClassList(page_index, page_size, filterParamsPagination)
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={classList?.classes}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          allowCheckMany && toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {HEADER_CLASS_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_CLASS_LIST} />
            ))}
          </>
        ) : (
          <>
            {classList?.classes?.map((e: IClassProduct) => {
              const isChecked = checkedList.includes(e.id)
              const exams =
                e?.acca_examination?.season ||
                e?.cfa_examination?.season ||
                e?.cma_examination?.season
              return (
                <tr key={e.id}>
                  <td className='text-start'>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      onChange={() => {
                        !allowCheckMany &&
                          checkedList.map((id: string) => {
                            toggleCheck(id)
                          })
                        toggleCheck(e.id)
                      }}
                    />
                  </td>
                  <td className='text-start'>
                    <SappTooltip
                      title={e?.name}
                      link={`${CLASS_PROFILE}/${e?.id}/${CLASS_PROFILE_URL.OVERVIEW}`}
                    />
                  </td>
                  <td className='text-start'>
                    {e?.normal_class_connections?.map((el: any) => {
                      return (
                        <div className='sapp-text-truncate-1'>{el?.foundation_class?.name}</div>
                      )
                    })}
                  </td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-1'>
                      {e?.course?.course_categories?.[0]?.name}
                    </div>
                  </td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-1'>
                      {e?.course?.course_categories?.[0]?.name === 'ACCA'
                        ? 'F' + e?.course?.course_difficulty
                        : e?.course?.course_difficulty}
                    </div>
                  </td>
                  <td className='text-start'>
                    {e?.duration_type !== OPTIONS_OF_DURATION.valueFlexible ? (
                      <div>
                        {(() => {
                          if (e?.started_at && e?.finished_at) {
                            const fromDate = new Date(e?.started_at)
                            const toDate = new Date(e?.finished_at)

                            return `${formatDateTime(fromDate, 'DD/MM/YYYY')} - ${formatDateTime(
                              toDate,
                              'DD/MM/YYYY'
                            )}`
                          } else {
                            return '--'
                          }
                        })()}
                      </div>
                    ) : (
                      <div>{e?.flexible_days ? `${e?.flexible_days} days` : '--'}</div>
                    )}
                  </td>
                  <td className='text-start'>
                    <div>
                      {' '}
                      {(() => {
                        if (e?.opening_at) {
                          const fromDate = new Date(e?.opening_at)
                          return `${formatDateTime(fromDate, 'dd/MM/yyyy')}`
                        } else {
                          return '--'
                        }
                      })()}
                    </div>
                  </td>
                  <td className='text-start'>
                    <SappTooltip
                      title={e?.course?.name}
                      link={`${PageLink.COURSE_FULL}/${e?.course_id}`}
                    />
                  </td>
                  <td className='text-start'>
                    {exams ? formatDateTime(new Date(exams), 'DD/MM/YYYY') : '--'}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <PagiantionSAPP
        currentPage={classList?.meta?.page_index || 1}
        pageSize={classList?.meta?.page_size || 10}
        totalItems={classList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableClasses

import { useEffect } from 'react'
import TeacherProfileDetail from './TeacherProfileDetail'

const OverviewTeacherProfile = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <TeacherProfileDetail />
}

export default OverviewTeacherProfile

import {FC} from 'react'
import SAPPBadge from 'src/components/base/SAPPBadge'
import {IStudentDetail} from 'src/type/students'

type Props = {
  user?: IStudentDetail
}

const UserStaffCell: FC<Props> = ({user}) => {
  if (user?.is_sapp_operator) {
    return <SAPPBadge className={`primary`} label={`Staff Account`} />
  }
  return <></>
}

export default UserStaffCell

import { debounce } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { StaffAPI } from 'src/apis/staffs'
import { staffKeys } from 'src/constants/queryKeyFactory'
import { IResponse, IStaffLevels } from 'src/type'

const useInfiniteStaffLevel = (enabled: boolean) => {
  const PAGE_SIZE = 10
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e: string) => {
    setSearchText(e)
  }, 350)

  const fetchLevels = async (page_index: number, page_size: number, text?: string) => {
    const params: Record<string, string> = {}
    if (text) {
      params.text = text
    }
    try {
      const res = await StaffAPI.getLevels(page_index, page_size, params)
      return res
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery<IResponse<IStaffLevels>>({
      queryKey: staffKeys.levels(searchText), // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchLevels(pageParam, PAGE_SIZE)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.success) {
          return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
            ? lastPage.data.metadata.page_index + 1
            : false
        }
      },
      enabled: enabled,
      refetchOnWindowFocus: false,
    })

  return {
    levels: data?.pages.flatMap((page) => page?.data?.staffs), // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
    debounceSearch,
  }
}

export default useInfiniteStaffLevel

import { sortType } from 'src/type'

export const lessonKeys = {
  base: ['lessons'] as const,
  list: (course_id: string, version?: string, page_index?: number, page_size?: number) =>
    ['lessons', { course_id, version, page_index, page_size }] as const,
  getOne: (id: string) => ['lessons', { id }] as const,
  contentList: (course_id: string, lesson_id?: string) =>
    [...lessonKeys.base, 'content-list', { course_id, lesson_id }] as const,
  versionHistoryBase: () => [...lessonKeys.base, 'version-history'] as const,
  versionHistory: (
    course_id: string,
    options?: { page_index?: number; page_size?: number; sortType?: sortType }
  ) => [...lessonKeys.versionHistoryBase(), { course_id, ...options }] as const,
}

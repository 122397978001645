import React, { useState } from 'react'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import HookFormSelectAntd, { HookFormSelectAntdProps } from '../HookFormSelectAntd'
import { useInfiniteDepartments } from 'src/hooks/useInfiniteQueryHooks'

interface DepartmentSelectProps extends Omit<HookFormSelectAntdProps, 'options'> {
  validateRequired?: boolean
  detailData?: { value: string; label: string }
}

const DepartmentSelect: React.FC<DepartmentSelectProps> = ({
  placeholder = 'Learning Experience',
  defaultValue = '',
  classNameHeight = 'sapp-h-40',
  allowClear = false,
  required = false,
  disabled = false,
  validateRequired = false,
  detailData,
  ...props
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const { departments, hasNextPage, fetchNextPage, isLoading, refetch, debounceSearch } =
    useInfiniteDepartments(dropdownVisible)

  const baseOptions =
    departments?.map((department) => ({
      label: department?.name,
      value: department?.id,
      unit: department?.unit,
    })) ?? []

  const options = detailData ? getSelectOptions(baseOptions, detailData) : baseOptions

  return (
    <HookFormSelectAntd
      {...props}
      placeholder={placeholder}
      defaultValue={defaultValue}
      classNameHeight={classNameHeight}
      allowClear={allowClear}
      required={validateRequired || required}
      disabled={disabled}
      filterOption={false}
      loading={isLoading}
      showSearch
      onSearch={debounceSearch}
      onDropdownVisibleChange={(open) => {
        setDropdownVisible(open)
        if (open && !departments?.length) {
          refetch()
        }
      }}
      handleNextPage={() => {
        hasNextPage && fetchNextPage()
      }}
      options={options}
    />
  )
}

export default DepartmentSelect

import { debounce } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { CommonAPI } from 'src/apis'
import { commonKeys } from 'src/constants/queryKeyFactory'
import { IResponse } from 'src/type'
import { IWardList } from 'src/type/common'

const useInfiniteWards = (enabled: boolean, district_code?: string) => {
  const PAGE_SIZE = 10
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e: string) => {
    setSearchText(e)
  }, 350)

  const fetchWards = async (
    page_index: number,
    page_size: number,
    search?: string,
    district_code?: string
  ): Promise<IResponse<IWardList>> => {
    try {
      const res = await CommonAPI.getWards(page_index, page_size, {
        search,
        district_code,
      })
      return res
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery<IResponse<IWardList>>({
      queryKey: commonKeys.districts(searchText, district_code), // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchWards(pageParam, PAGE_SIZE, searchText, district_code)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.success) {
          return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
            ? lastPage.data.metadata.page_index + 1
            : undefined
        }
      },
      enabled: enabled,
      refetchOnWindowFocus: false,
    })

  return {
    wards: data?.pages.flatMap((page) => page?.data?.wards), // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
    debounceSearch,
  }
}

export default useInfiniteWards

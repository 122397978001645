import { debounce } from 'lodash'
import { useRef, useState } from 'react'
import { FeatureSectionApi } from 'src/apis/feature-section'
import { IFeatureSectionList } from 'src/type/feature-section'

const useFeatureSections = () => {
  const [featureSections, setFeatureSections] = useState<IFeatureSectionList>()

  const [isLoading, setIsLoading] = useState(false) // State to track loading status
  const requestOngoingRef = useRef(false)

  const debounceSearchFeatureSections = debounce((params, pageSize = 10) => {
    getFeatureSections(params, pageSize)
  }, 500)

  const getFeatureSections = async (params: Object, pageSize = 10) => {
    setIsLoading(true)
    const res = await fetchFeatureSections(1, pageSize, params)
    setIsLoading(false)
    setFeatureSections(res?.data)
  }

  const fetchFeatureSections = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await FeatureSectionApi.getFeatureSections(page_index, page_size, params)
      return res
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleNextPageFeatureSections = async (params: Object) => {
    const totalPages = featureSections?.metadata?.total_pages
    const pageIndex = featureSections?.metadata?.page_index as number
    const pageSize = featureSections?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      setIsLoading(true)
      const res = await fetchFeatureSections(pageIndex + 1, pageSize, params)
      setIsLoading(false)
      if (res) {
        const results = featureSections.feature_sections.concat(res.data.feature_sections)
        setFeatureSections({
          metadata: res.data.metadata,
          feature_sections: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  return {
    debounceSearchFeatureSections,
    handleNextPageFeatureSections,
    featureSections,
    isLoading,
    getFeatureSections,
    setFeatureSections,
  }
}

export default useFeatureSections

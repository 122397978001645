/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { SAPPCalendar } from 'sapp-common-package'
import 'sapp-common-package/dist/index.css'
import { IEvent } from 'sapp-common-package/dist/types'
import { ClassesApi } from 'src/apis/classes'
import ClassroomApi from 'src/apis/classroom'
import ClassroomCalendarApi from 'src/apis/classroom-calendar'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import CreateAndUpdateEvent from 'src/components/classroom/calendar/CreateAndUpdateEvent'
import DeleteEventModal from 'src/components/classroom/calendar/DeleteEventModal'
import DetailCalendarRoom from 'src/components/classroom/calendar/DetailCalendarRoom'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { CALENDAR_COLOR_TYPES, CALENDAR_ROOM_TYPE, PageLink } from 'src/constants'
import { LANG_COURSES, LANG_SIDEBAR } from 'src/constants/lang'
import { ITabs, ROOM_MODE } from 'src/type'
import { IFacilities, IRoomList } from 'src/type/area'
import { clearField, convertUTCToLocal } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { useConfirm } from 'src/hooks/use-confirm'
import GridLayout from 'src/components/layout/grid'

const breadcrumbs: ITabs[] = [
  {
    link: '',
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.classroomCalendar,
  },
]

const initialValues: any = {
  mode: '',
  facility_id: '',
  room_id: '',
}

export interface ISelectEvent {
  id: string
  startDate?: Date
  endDate?: Date
  data?: {
    title?: string
    description?: string
  }
}

const ClassroomCalendar = () => {
  const [openEvent, setOpenEvent] = useState<boolean>(false)
  const [facilityList, setFacilityList] = useState<IFacilities>()
  const [classroomList, setClassroomList] = useState<IRoomList | null>()
  const fieldNames = ['mode', 'facility_id', 'room_id']
  const { confirm, contextHolder } = useConfirm()
  const [openDelete, setOpenDelete] = useState<{
    isOpen: boolean
    startDate?: Date
    endDate?: Date
    id: string
  }>({ isOpen: false, id: '', startDate: undefined, endDate: undefined })
  const [selectedEvent, setSelectedEvent] = useState<ISelectEvent | undefined>()
  const [openDetail, setOpenDetail] = useState<{
    id: string
    open: boolean
    date: Date | null
    status: 'view' | 'edit' | 'create' | null
    data: IEvent | null
  }>({
    id: '',
    open: false,
    date: null,
    status: null,
    data: null,
  })
  const [events, setEvents] = useState<any>([])
  const [currentTime, setCurrentTime] = useState<{ startDate: Date; endDate: Date }>({
    startDate: dayjs().startOf('month').startOf('week').add(1, 'day').toDate(),
    endDate: dayjs().endOf('month').endOf('week').add(1, 'day').toDate(),
  })
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const { control, getValues, reset, setValue, watch } = useForm({ mode: 'onSubmit' })
  const queryParams = {
    mode: searchParams.get('mode') ?? '',
    facility_id: searchParams.get('facility_id'),
    room_id: searchParams.get('room_id'),
  }

  const fetchFacility = async (pageIndex: number, pageSize: number, params?: object) => {
    try {
      const res = await ClassesApi.getFacilities({
        page_index: pageIndex,
        page_size: pageSize,
        params,
      })
      if (pageIndex === 1) {
        setFacilityList(res.data)
      } else {
        setFacilityList((prev: any) => {
          return {
            metadata: res.data.metadata,
            facilities: [...(prev.facilities ?? []), ...(res.data.facilities ?? [])],
          }
        })
      }
    } catch (error) {}
  }

  const fetchClassroom = async (pageIndex: number, pageSize: number, params?: object) => {
    try {
      const res = await ClassroomApi.getRoomList({
        page_index: pageIndex,
        page_size: pageSize,
        params,
      })
      if (pageIndex === 1) {
        setClassroomList(res.data)
      } else {
        setClassroomList((prev: any) => {
          return {
            metadata: res.data.metadata,
            classRooms: [...(prev.classRooms ?? []), ...(res.data.classRooms ?? [])],
          }
        })
      }
    } catch (error) {}
  }

  const fetchCalendar = async (params: {
    start_date: string
    end_date: string
    room_id?: string
  }) => {
    try {
      params = clearField(params)
      const res = await ClassroomCalendarApi.getScheduleOfRoom(params)
      setEvents(res.data)
    } catch (error) {}
  }

  const handleRefresh = () => {
    watch('room_id') &&
      fetchCalendar({
        start_date: currentTime.startDate.toISOString(),
        end_date: currentTime.endDate.toISOString(),
        room_id: watch('room_id') as string,
      })
  }

  const handleResetFilter = () => {
    setLoading(true)
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setClassroomList(null)
    setEvents([])
    setLoading(false)
    navigate(PageLink.CLASSROOM_CALENDAR)
    fetchCalendar({
      start_date: currentTime.startDate.toISOString(),
      end_date: currentTime.endDate.toISOString(),
    })
  }

  const handleChangeParams = () => {
    const queryParam = {
      mode: replaceValueAll(getValues('mode')),
      facility_id: replaceValueAll(getValues('facility_id')),
      room_id: replaceValueAll(getValues('room_id')),
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    setLoading(true)
    if (currentTime?.startDate && watch('room_id')) {
      fetchCalendar({
        start_date: currentTime.startDate.toISOString(),
        end_date: currentTime.endDate.toISOString(),
        room_id: watch('room_id') as string,
      })
    }
    handleChangeParams()
    setLoading(false)
  }

  const generateCalendarRoomColor = (type: string) => {
    switch (type) {
      case CALENDAR_ROOM_TYPE.LEARNING:
        return CALENDAR_COLOR_TYPES.BLUE_COLOR
      case CALENDAR_ROOM_TYPE.RENTAL:
        return CALENDAR_COLOR_TYPES.GREEN_COLOR
      default:
        return CALENDAR_COLOR_TYPES.YELLOW_COLOR
    }
  }

  const fetchAreaDetail = async (id: string) => {
    try {
      const res = await ClassroomApi.getAreaClassDetail(id)
      setFacilityList({
        metadata: {
          page_index: 0,
          page_size: 0,
          total_pages: 0,
          total_records: 0,
        },
        facilities: [{ ...res.data }],
      })
      await fetchClassroom(1, 10, { facility_id: id, mode: searchParams.get('mode') })
      onSubmit()
    } catch {}
  }

  const fetchClassRoomByMode = async () => {
    await fetchClassroom(1, 10, { mode: searchParams.get('mode') })
    onSubmit()
  }

  useEffect(() => {
    fetchCalendar({
      start_date: currentTime.startDate.toISOString(),
      end_date: currentTime.endDate.toISOString(),
    })
    !!searchParams.get('mode') && setValue('mode', searchParams.get('mode'))
    if (searchParams.get('facility_id')) {
      fetchAreaDetail(searchParams.get('facility_id') as string)
    } else if (searchParams.get('mode')) {
      fetchClassRoomByMode()
    }
  }, [])

  return (
    <PageLayouts pageTitle={LANG_COURSES.classroomCalendar} breadcrumbs={breadcrumbs}>
      <div className='card'>
        {contextHolder}
        <div className='pt-10 px-8'>
          <GridLayout>
            <HookFormSelectAntd
              control={control}
              name='mode'
              placeholder='Mode'
              required
              allowClear={false}
              options={Object.values(ROOM_MODE).map((mode) => ({
                label: capitalize(mode),
                value: mode,
              }))}
              onChange={(value) => {
                if (value) {
                  let params: { facility_id?: string; mode?: string } = { mode: value }
                  getValues('facility_id') && (params.facility_id = getValues('facility_id'))
                  if (value === ROOM_MODE.VIRTUAL) {
                    setValue('facility_id', '')
                    fetchClassroom(1, 10, { mode: ROOM_MODE.VIRTUAL })
                  } else {
                    fetchClassroom(1, 10, params)
                  }
                  setValue('room_id', '')
                }
              }}
              classNameHeight='sapp-h-40'
            />
            <HookFormSelectAntd
              name='facility_id'
              placeholder='Facility'
              disabled={watch('mode') === ROOM_MODE.VIRTUAL}
              onFocus={() => {
                if (!facilityList?.facilities.length) {
                  fetchFacility(1, 10)
                }
                return
              }}
              onChange={(value: string) => {
                if (value) {
                  let params: { facility_id: string; mode?: string } = { facility_id: value }
                  getValues('mode') && (params.mode = getValues('mode'))
                  setValue('room_id', '')
                  fetchClassroom(1, 10, params)
                }
                return
              }}
              onSearch={(value: string) => {
                if (value) {
                  fetchFacility(1, 10, { name: value })
                }
              }}
              onClear={() => {
                fetchFacility(1, 10)
                setValue('room_id', '')
                setClassroomList(null)
              }}
              control={control}
              defaultValue={queryParams?.facility_id ?? ''}
              options={(facilityList?.facilities ?? []).map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              classNameHeight='sapp-h-40'
            />
            <HookFormSelectAntd
              name='room_id'
              placeholder='Room'
              control={control}
              defaultValue={queryParams?.room_id ?? ''}
              options={(classroomList?.classRooms ?? []).map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              classNameHeight='sapp-h-40'
            />
          </GridLayout>
        </div>
        <div className='card-header card-header__course border-0 pt-5 '>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              disableOk={!watch('room_id')}
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          <ButtonIconPrimary
            title={'Add Event'}
            iconName='plus'
            onClick={() => {
              setOpenEvent(true)
              setOpenDetail({
                id: '',
                open: false,
                date: null,
                status: 'create',
                data: null,
              })
            }}
            size='small'
          />
        </div>
        <SAPPCalendar
          events={events.map((event: any) => ({
            id: event.is_holiday ? '' : event.id,
            title: event.name,
            startDate: convertUTCToLocal(`${event.start_date}T${event.start_time}`),
            endDate: convertUTCToLocal(`${event.end_date}T${event.end_time}`),
            type: generateCalendarRoomColor(event.room_type),
            description: event.description,
          }))}
          onOpenDetail={() => {}}
          onEventDetail={(event: IEvent) => {
            setOpenDetail({
              open: true,
              id: event?.id,
              date: event.startDate,
              status: 'view',
              data: event,
            })
          }}
          onOpenCreate={() => {}}
          onRefetchAPI={async (startDate: Date, endDate: Date) => {
            setCurrentTime({ startDate, endDate })
            fetchCalendar({
              start_date: startDate.toISOString(),
              end_date: endDate.toISOString(),
              room_id: watch('room_id'),
            })
          }}
          showWeeklyNorm={false}
        />
      </div>
      <DetailCalendarRoom
        open={openDetail}
        setOpen={setOpenDetail}
        roomId={watch('room_id')}
        onEdit={() => setOpenEvent(true)}
        onDeleteRecuring={(id: string, startDate?: Date, endDate?: Date) => {
          setOpenDelete({ isOpen: true, id, startDate, endDate })
        }}
        onDetailEvent={(event, current) => {
          setSelectedEvent({
            id: current.id,
            startDate: current.data?.startDate,
            endDate: current.data?.endDate,
            data: {
              title: current.data?.title ?? '',
              description: current.data?.description ?? '',
            },
          })
        }}
        onRefresh={handleRefresh}
      />
      <DeleteEventModal
        open={openDelete.isOpen}
        setOpen={setOpenDelete}
        onClose={() =>
          setOpenDelete({ isOpen: false, id: '', startDate: undefined, endDate: undefined })
        }
        event={openDelete}
        roomId={watch('room_id')}
        refresh={handleRefresh}
      />
      <CreateAndUpdateEvent
        open={openEvent}
        onClose={() => setOpenEvent(false)}
        setLoading={setLoading}
        id={openDetail.id}
        loading={loading}
        roomId={watch('room_id')}
        selectedEvent={selectedEvent}
        refresh={handleRefresh}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(ClassroomCalendar)

import { useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import ButtonDropdown from 'src/components/base/button/ButtonDropdown'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import { PageLink } from 'src/constants'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { IGetLessonRes, ILessonForm } from 'src/type'
import LessonForm from '../form'
import { LessonFormMethods } from '../form/LessonForm'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'

const LessonEdit = ({ data }: { data: IGetLessonRes }) => {
  const navigate = useNavigate()
  const { id, lessonId } = useParams()
  const lessonFormRef = useRef<LessonFormMethods>(null)
  const queryClient = useQueryClient()
  const [courseContentEmpty, setCourseContentEmpty] = useState(data.content.length === 0)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: ILessonForm) => {
      if (!lessonId) {
        return Promise.reject('No id provided')
      }
      return await LessonApi.editLesson(data, lessonId)
    },
    onSuccess: ({ success, error }) => {
      if (success === false) {
        toast.error(error?.message)
        return
      }
      navigate(`${PageLink.COURSES}/${id}/lessons`)
      queryClient.invalidateQueries(lessonKeys.base)
      toast.success('Edit Lesson Successfully!')
    },
  })

  // Trigger form submission and pass data to mutate
  const handleSave = () => {
    if (lessonFormRef.current) {
      lessonFormRef.current.submitForm()
    }
  }

  return (
    <CreateEditLayout
      classNameLayout='pt-11'
      pageTitle='Edit Lesson'
      onCancel={() => navigate(`${PageLink.COURSES}/${id}/${PageLink.LESSONS}`)}
      customPrimaryButton={
        <ButtonPrimary
          size='small'
          title='Save'
          onClick={handleSave}
          loading={isLoading}
          disabled={courseContentEmpty}
        />
      }
    >
      <LessonForm
        ref={lessonFormRef}
        onSubmit={mutate}
        defaultValues={{
          ...data,
          course_id: id!,
        }}
        setCourseContentEmpty={setCourseContentEmpty}
      />
    </CreateEditLayout>
  )
}

export default LessonEdit

import { Metadata } from 'src/type'

//#region Filter
export enum ExamStudentsFilters {
  pageIndex = 'page_index',
  pageSize = 'page_size',
  text = 'text',
  classCode = 'class_code',
  subjectId = 'subject_id',
  registeredExamDate = 'is_final_examination_subject',
}

export interface IExamStudentsFilters {
  [ExamStudentsFilters.pageIndex]: number
  [ExamStudentsFilters.pageSize]: number
  [ExamStudentsFilters.text]: string
  [ExamStudentsFilters.classCode]: string
  [ExamStudentsFilters.subjectId]: string
  [ExamStudentsFilters.registeredExamDate]: string
}

export const examDateRegisteredSelect = [
  {
    key: 'true',
    value: 'true',
    label: 'Yes',
  },
  {
    key: 'false',
    value: 'false',
    label: 'No',
  },
]

//#endregion

//#region List
export interface ExamStudentsList {
  success: boolean
  data: Data
}

export interface Data {
  metadata: Metadata
  data: Student[]
}

export interface Student {
  examination_subject_id: string | null
  id: string
  created_at: string
  updated_at: string
  is_final_examination_subject: boolean
  defer_examination_reason: string | null
  class: Class
  user: User
}

export interface Class {
  id: string
  code: string
  examination_subject_id: string
  examination_subject: ExaminationSubject
}

export interface ExaminationSubject {
  id: string
  subject_id: string
  code_exam: string
  start_date: string
  end_date: string
  subject: Subject
}

export interface Subject {
  id: string
  name: string
  code: string
}

export interface User {
  id: string
  created_at: string
  updated_at: string
  username: string
  hubspot_contact_id?: string
  detail: Detail
  user_contacts: UserContact[]
}

export interface Detail {
  id: string
  full_name: string
  cma_level: any
  level: any
  acca_level: any
}

export interface UserContact {
  id: string
  email: string
  phone: string
}
//#endregion

//#region Defer Modal
export interface StudentExamSubjects {
  id: string
  subject_id: string
  start_date: string
  end_date: string
  examination: Examination
  classes: Class[]
  current_user_exam: boolean
}

export interface Examination {
  id: string
  name: string
}

export interface Class {
  id: string
}

//#endregion

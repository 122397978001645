import { zodResolver } from '@hookform/resolvers/zod'
import { uniqueId } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { VALIDATION_FIELD } from 'src/constants'
import { z } from 'zod'
import { VALIDATION_GAP, defaultQuestionValues, validationQuestionSchema } from '../shared/consts'
import { IInputQuestionProps, IQuestion, QUESTION_TYPES } from '../shared/interfaces'
import { generateGroupsDragDrop } from '../shared/utils'
import LayoutQuestion from './LayoutQuestion'
import SappLabel from 'src/components/base/label/SappLabel'
import { ICommonQuestionProps } from 'src/type/question-bank'

interface IGroup {
  answer: string
  answerId: string
}
interface IInputProps extends IInputQuestionProps {
  groups?: IGroup[] | undefined
}
const defaultValues = {
  ...defaultQuestionValues,
}
const DragDropQuestion = ({ open, onClose, type, id, data }: ICommonQuestionProps) => {
  const note = [
    `Mỗi [dấu ngoặc vuông] được hiểu là một chỗ trống. Tương ứng với số lượng chỗ trống được thêm, hệ thống sẽ tự động thêm số lượng Input để nhập đáp án đúng cho chỗ trống đó. Số lượng Input luôn luôn bằng hoặc hơn số lượng chỗ trống. Có thể thêm nhiều Input mà không cần thêm chỗ trống nhằm mục đích gây nhiễu cho học viên.`,
  ]

  const [numberOfVacant, setNumberOfVacant] = useState<number>(0)

  const validationSchema = z.object({
    ...validationQuestionSchema,
    groups: z
      .array(
        z.object({
          answer: z
            .string({ required_error: VALIDATION_FIELD })
            .trim()
            .min(1, VALIDATION_FIELD)
            .max(1000),
          answerId: z.string(),
        })
      )
      .min(1, 'Answer is required')
      .refine((arr) => arr.length >= numberOfVacant, {
        message: VALIDATION_GAP,
      }),
  })

  const useFormProp = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useFormProp
  const { append, remove } = useFieldArray({
    control,
    name: 'groups',
  })

  const groups = watch('groups')
  const question = watch('question_content')
  const timeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(() => {
      const newGroup = generateGroupsDragDrop(question)
      if (newGroup.numberOfVacant !== undefined) {
        setNumberOfVacant(newGroup.numberOfVacant)
      }

      if (
        newGroup.numberOfVacant !== undefined &&
        newGroup.numberOfVacant > (groups?.length || 0)
      ) {
        for (let index = 0; index < newGroup.numberOfVacant! - (groups?.length || 0); index++) {
          append({ answer: '', answerId: uniqueId() })
        }
      }
      clearErrors('groups')
    }, 300)
  }, [question, setValue])

  const buildRequest = (data: IInputProps): { partialQuestion: Partial<IQuestion> } => {
    return {
      partialQuestion: {
        qType: QUESTION_TYPES.DRAG_DROP,
        answers: data.groups?.map((e, i) => ({
          is_correct: true,
          answer: (e.answer ?? '').trim(),
          answer_position: i + 1,
        })),
      },
    }
  }
  const [isBlocked, setIsBlocked] = useState(false)

  const fetchData = (data: IQuestion): Partial<IInputProps> => {
    if (!data) {
      return {}
    }
    if (data?.status === 'BLOCK') {
      setIsBlocked(true)
    }
    return {
      groups: data.answers?.map((e) => ({
        answer: e.answer,
        answerId: uniqueId(),
      })),
    }
  }

  return (
    <LayoutQuestion
      defaultValues={defaultValues}
      data={data}
      fetchData={fetchData}
      buildRequest={buildRequest}
      open={open}
      onClose={onClose}
      id={id}
      useForm={useFormProp}
      type={type}
      note={note}
    >
      {/* start:: answer */}
      <div className='mb-8'>
        <SappLabel label='Danh Sách Đáp Án' required />

        <div className='p-8 border rounded border-gray-300'>
          {groups?.map((group: IGroup, index) => {
            return group.answerId ? (
              <Row className={index !== 0 ? 'mt-7' : ''} key={group.answerId}>
                <Col md={12}>
                  <div className='d-flex align-items-center'>
                    <div className='w-100'>
                      <HookFormTextField
                        groupText={`Chỗ trống ${index + 1}`}
                        control={control}
                        name={`groups.${index}.answer` as const}
                        placeholder={'Đáp án ' + (index + 1)}
                        disabled={isBlocked}
                        onChange={() => trigger(`groups.${index}.answer`)}
                        // guideline={[
                        //   'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, không giới hạn ký tự.',
                        // ]}
                      ></HookFormTextField>
                    </div>
                    {/* start:: delete*/}
                    {groups.length > 1 && (
                      <div className='ms-4'>
                        <ButtonIconOnly
                          iconName={'trash'}
                          disabled={isBlocked}
                          activeColor='danger'
                          onClick={() => {
                            if (groups.length <= numberOfVacant) {
                              setError('groups', { message: VALIDATION_GAP })
                              return
                            }
                            remove(index)
                          }}
                        />
                      </div>
                    )}
                    {/* end:: delete*/}
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )
          })}
          <Row>
            {' '}
            <Col md={12}>
              {' '}
              <ErrorMessage>{errors['groups']?.message}</ErrorMessage>
            </Col>
          </Row>

          <Row className='mt-5'>
            {/* start:: Add New*/}
            <Col md={12}>
              <ButtonIcon
                title={'Thêm đáp án gây nhiễu'}
                className={`${
                  (groups?.length || 0) > 1 ? 'sapp-btn_add-more-50' : 'w-100'
                } h-45px d-flex justify-content-start align-items-center mt-xl-0 mt-4`}
                customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0'
                type='button'
                disabled={isBlocked}
                onClick={() => {
                  append({ answer: '', answerId: uniqueId() })
                  clearErrors('groups')
                }}
              >
                <KTIcon iconName='plus' className='fs-2 text-gray-500 p-3' />
              </ButtonIcon>
            </Col>
            {/* end:: Add New*/}
            <Col></Col>
          </Row>
        </div>
      </div>
      {/* end:: answer*/}
    </LayoutQuestion>
  )
}
export default DragDropQuestion

import React, { useState } from 'react'
import { CoursesAPI } from 'src/apis/courses'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import { CLASS_STATUS_ENUM } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { ICourse, ICourseList } from 'src/type/courses'
import './AddCourse.scss'
import TableCourses from './tableCourses'

interface IProps {
  id?: any
  open: boolean
  closeModal: () => void
  selectedCourse?: ICourse
  setSelectedCourse: React.Dispatch<React.SetStateAction<ICourse | undefined>>
  typeClass?: string
  courseCategoryId?: string
  handleResetListExam?: any
  subject_id?: string
  program?: string
  subject?: string
}

const AddCourse = ({
  open,
  id,
  closeModal,
  selectedCourse,
  setSelectedCourse,
  typeClass,
  courseCategoryId,
  handleResetListExam,
  subject_id,
  program,
  subject,
}: IProps) => {
  const [courseList, setCourseList] = useState<ICourseList>()
  const [loading, setLoading] = useState(true)

  const getParams = (
    name: string,
    course_category_ids: string,
    subject_id: string,
    status: Array<string>,
    course_types: Array<string>,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    name,
    course_category_ids,
    subject_id,
    status,
    course_types,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const queryParams = {
    name: '',
    course_category_ids: '',
    subject_id: '',
    status: [],
    course_types: [],
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchCourseList = async (currentPage: number, pageSize: number, params?: any) => {
    try {
      const res = await CoursesAPI.get({
        page_index: currentPage,
        page_size: pageSize,
        params: { ...params, status: [CLASS_STATUS_ENUM.PUBLISH, CLASS_STATUS_ENUM.LOCK] },
      })
      setCourseList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const toggleCheck = (course: ICourse) => {
    setSelectedCourse(course)
  }
  const { confirm, contextHolder } = useConfirm()

  const handleSubmit = () => {
    if (id) {
      handleResetListExam()
    }
    closeModal()
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: closeModal,
    })
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Course</div>
          <div className='d-flex justify-content-between align-items-center'>
            {selectedCourse && <div className='fw-bold fs-6 text-primary me-8'>1 Selected</div>}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              // loading={loading}
              isListScreen
            />
          </div>
        </div>
        {open && (
          <div className='px-7'>
            <TableCourses
              selectCourse={selectedCourse}
              courseList={courseList}
              loading={loading}
              setLoading={setLoading}
              handleChangeParams={() => {}}
              fetchCourseList={fetchCourseList}
              filterParams={{}}
              getParams={getParams}
              queryParams={queryParams}
              toggleCheck={toggleCheck}
              toggleCheckAll={() => {}}
              isCheckedAll={false}
              typeClass={typeClass}
              courseCategoryId={courseCategoryId}
              subject_id={subject_id}
              program={program}
              subject={subject}
            />
          </div>
        )}
      </SappModal>
    </>
  )
}
export default AddCourse

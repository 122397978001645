import React, { useState } from 'react'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import { useInfiniteDistricts } from 'src/hooks/useInfiniteQueryHooks'
import HookFormSelectAntd from '../HookFormSelectAntd'

interface DistrictSelectProps {
  control: any
  name: string
  placeholder?: string
  defaultValue?: string
  classNameHeight?: 'sapp-h-45px' | 'sapp-h-35px' | 'sapp-h-40'
  allowClear?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  validateRequired?: boolean
  detailData?: { value: string; label: string }
  onChange?: ((val: any) => void) | undefined
  province_code?: string
}

const DistrictSelect: React.FC<DistrictSelectProps> = ({
  control,
  name,
  placeholder = 'District',
  defaultValue = '',
  classNameHeight = 'sapp-h-40',
  allowClear = false,
  required = false,
  disabled = false,
  label,
  validateRequired = false,
  detailData,
  onChange,
  province_code,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const { districts, hasNextPage, fetchNextPage, isLoading, refetch, debounceSearch } =
    useInfiniteDistricts(dropdownVisible, province_code)

  const baseOptions =
    districts?.map((district) => ({
      label: district?.name,
      value: district?.code,
    })) ?? []

  const options = detailData ? getSelectOptions(baseOptions, detailData) : baseOptions

  return (
    <HookFormSelectAntd
      control={control}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      classNameHeight={classNameHeight}
      allowClear={allowClear}
      required={validateRequired || required}
      disabled={disabled}
      label={label}
      filterOption={false}
      loading={isLoading}
      showSearch
      onSearch={debounceSearch}
      onDropdownVisibleChange={(open) => {
        setDropdownVisible(open)
        if (open && !districts?.length) {
          refetch()
        }
      }}
      handleNextPage={() => {
        hasNextPage && fetchNextPage()
      }}
      options={options}
      onChange={onChange}
    />
  )
}

export default DistrictSelect

import React, { useEffect, useState } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import { PROGRAM } from 'src/constants'
import useSubjects from 'src/hooks/useSubjects'
import { ISubject } from 'src/type/subject'
import HookFormSelectAntd from './HookFormSelectAntd'

interface ISelectSubject<T extends FieldValues> {
  control: Control<T>
  isListLoading?: boolean
  filters: T
  program?: PROGRAM
}

const HookFormSelectSubject = <T extends FieldValues>({
  control,
  isListLoading,
  filters,
  program,
}: ISelectSubject<T>) => {
  const { subjects, setSubjects, handleNextPageSubject, debounceSearchSubject, isLoading } =
    useSubjects() // Use the hook
  const [dropdownSubjectsOpen, setDropdownSubjectsOpen] = useState(false)

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  useEffect(() => {
    setSubjects(undefined)
    if (dropdownSubjectsOpen && program) {
      // Fetch subjects only when dropdown is open and program changes
      debounceSearchSubject({ category_name: program })
    }
  }, [dropdownSubjectsOpen, program])

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownSubjectsOpen(open)
  }

  return (
    <HookFormSelectAntd
      control={control}
      name='subject_id'
      placeholder='Subject'
      allowClear
      handleNextPage={(e: any) =>
        handleNextPageSubject({
          name: e,
          category_name: program,
        })
      }
      onDropdownVisibleChange={handleDropdownVisibleChange}
      showSearch
      loading={isListLoading || isLoading}
      defaultValue={filters?.subject_id}
      options={
        subjectCourse?.map((subject) => ({
          label: subject.label,
          value: subject.value,
        })) ?? []
      }
      classNameHeight='sapp-h-40'
    />
  )
}

export default HookFormSelectSubject

import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Row } from 'antd'
import clsx from 'clsx'
import { useEffect } from 'react'
import { SubmitHandler, useFieldArray, useForm, useFormState } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import SappLabel from 'src/components/base/label/SappLabel'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { PageLink, PROGRAM } from 'src/constants'
import { EXAM_DETAIL_URL } from 'src/constants/exam'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import { useConfirm } from 'src/hooks/use-confirm'
import useCourseCategories from 'src/hooks/useCourseCategories'
import useSubjects from 'src/hooks/useSubjects'
import {
  EDateTime,
  ExamRouteParams,
  ExamSubjectFormACCA,
  ExamSubjectPost,
  IExamForm,
  IExamPost,
  IExamPut,
  IResponse,
} from 'src/type'
import { ISubject } from 'src/type/subject'
import { formatDateTime } from 'src/utils'
import styles from './ExamForm.module.scss'
import schemaMap from './schema'

interface IProps {
  defaultValues?: any
  submitAction: (payload: IExamPost | IExamPut) => Promise<IResponse<any>>
}

const ExamForm = ({ defaultValues, submitAction }: IProps) => {
  const { program: programRoute } = useParams<ExamRouteParams>()

  const program = programRoute?.toUpperCase() as PROGRAM
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  // Use useFormState to track the dirty state

  const { control, handleSubmit, reset } = useForm<IExamForm>({
    resolver: zodResolver(schemaMap[program]),
    defaultValues: defaultValues || {},
  })

  const { isDirty } = useFormState({ control })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'exam_subject',
  })

  const { courseCategories } = useCourseCategories(program)

  const {
    subjects,
    handleNextPageSubject,
    debounceSearchSubject,
    isLoading: isSubjectsLoading,
  } = useSubjects()

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  const { confirm, contextHolder } = useConfirm()

  const { mutate, isLoading } = useMutation({
    mutationFn: submitAction,
    onSuccess: ({ data, error }) => {
      if (data === null) {
        toast.error(error?.message)
      }

      toast.success(data?.message)

      navigate(`${PageLink.EXAMS}/${program}/${data?.data?.id}/${EXAM_DETAIL_URL.OVERVIEW}`)
      defaultValues && queryClient.invalidateQueries('ExamDetail')
    },
  })

  const onSubmit: SubmitHandler<IExamForm> = async (data) => {
    let examSubjects: ExamSubjectPost[] | ExamSubjectFormACCA[] | undefined

    switch (program) {
      case PROGRAM.CMA:
        const examDates = data.exam_subject[0].exam_date
        examSubjects = subjects?.subjects?.map((subject: ISubject) => {
          return {
            subject_id: subject.id,
            start_date: formatDateTime(new Date(examDates?.fromDate), EDateTime.backendFormat),
            end_date: formatDateTime(new Date(examDates?.toDate), EDateTime.backendFormat),
          }
        })
        break

      case PROGRAM.CFA:
        examSubjects = data?.exam_subject?.map((exam) => ({
          subject_id: exam?.subject_id,
          start_date: formatDateTime(new Date(exam?.exam_date?.fromDate), EDateTime.backendFormat),
          end_date: formatDateTime(new Date(exam?.exam_date?.toDate), EDateTime.backendFormat),
        }))
        break

      case PROGRAM.ACCA:
        examSubjects = data?.exam_subject?.map((exam: any) => ({
          subject_id: exam?.subject_id,
          end_date: formatDateTime(new Date(exam?.end_date), EDateTime.backendFormat),
        }))
        break

      default: // Default format
        examSubjects = data?.exam_subject?.map((exam) => ({
          subject_id: exam?.subject_id,
          start_date: exam?.exam_date?.fromDate,
          end_date: exam?.exam_date?.toDate,
        }))
        break
    }

    // Prepare the payload
    const payload: IExamPost | IExamPut = {
      ...data,
      ...(data?.name && { name: formatDateTime(new Date(data?.name), EDateTime.monthFormat) }),
      ...(data?.early_registration_deadline && {
        early_registration_deadline: formatDateTime(
          new Date(data?.early_registration_deadline),
          EDateTime.backendFormat
        ),
      }),
      ...(data?.registration_opening_date && {
        registration_opening_date: formatDateTime(
          new Date(data?.registration_opening_date),
          EDateTime.backendFormat
        ),
      }),
      ...(data?.standard_registration_deadline && {
        standard_registration_deadline: formatDateTime(
          new Date(data?.standard_registration_deadline),
          EDateTime.backendFormat
        ),
      }),
      ...(data?.registration_closing_date && {
        registration_closing_date: formatDateTime(
          new Date(data?.registration_closing_date),
          EDateTime.backendFormat
        ),
      }),
      course_category_id: courseCategories?.id,
      exam_subject: examSubjects,
    }

    mutate(payload)
  }

  const appendDefault = () =>
    append(
      {
        exam_date: { fromDate: '', toDate: '' },
        subject_id: '',
        allow_delete: true,
        ...(program === PROGRAM.ACCA && {
          subject: {
            label: '',
            value: '',
          },
        }),
      },
      {
        shouldFocus: false,
      }
    )

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${PageLink.EXAMS}/${program}`),
    })
  }

  useEffect(() => {
    reset()
    remove()
    debounceSearchSubject({ category_name: program })

    if (defaultValues) {
      if (program === PROGRAM.CMA) {
        append(
          { ...defaultValues.exam_subjects[0] },
          {
            shouldFocus: false,
          }
        )
      } else {
        defaultValues?.exam_subjects.map((item: any) =>
          append(
            { ...item },
            {
              shouldFocus: false,
            }
          )
        )
      }
    } else {
      appendDefault()
    }
  }, [program])

  return (
    <div className={clsx(['p-9', styles.formContainer])}>
      {contextHolder}
      {(program === PROGRAM.CFA || program === PROGRAM.ACCA) && (
        <div>
          <HookFormDateTime
            control={control}
            name='name'
            label='Kì thi'
            picker='month'
            format={EDateTime.monthFormat}
            required
            disabled={defaultValues ? !defaultValues?.allow_update_name : false}
          />
        </div>
      )}
      {program === PROGRAM.CFA && (
        <>
          <div>
            <HookFormDateTime
              control={control}
              name={'registration_opening_date'}
              label={'Mở đăng kí'}
            />
          </div>
          <div>
            <HookFormDateTime
              control={control}
              name={'early_registration_deadline'}
              label={'Hạn đăng kí sớm'}
            />
          </div>
          <div>
            <HookFormDateTime
              control={control}
              name={'standard_registration_deadline'}
              label={'Hạn đăng kí chuẩn'}
            />
          </div>
        </>
      )}

      <div>
        {fields?.length > 0 && <SappLabel label='Lịch thi' className='mb-6' />}
        {fields.map((field, index) => {
          return (
            <Row
              className={clsx('justify-content-between position-relative', styles.examSchedule)}
              gutter={[12, 12]}
              key={field.id}
            >
              {/* 1st Col - Subject */}
              {(program === PROGRAM.ACCA || program === PROGRAM.CFA) && (
                <Col span={24} md={11}>
                  <HookFormSelectAntd
                    label='Subject'
                    control={control}
                    name={`exam_subject.${index}.subject_id`}
                    required
                    handleNextPage={(e: any) =>
                      handleNextPageSubject({
                        name: e,
                        category_name: program,
                      })
                    }
                    showSearch
                    allowClear
                    placeholder='Subject'
                    disabled={!field.allow_delete}
                    options={subjectCourse ? getSelectOptions(subjectCourse, field?.subject) : []}
                  />
                </Col>
              )}

              {/* 2nd Col - Date */}
              {program === PROGRAM.CFA && (
                <Col flex={'auto'}>
                  <RangeDateTimePicker
                    control={control}
                    label='Ngày thi'
                    name={`exam_subject.${index}.exam_date`}
                    allowClear={true}
                    placeholder={['From', 'To']}
                    required
                  />
                </Col>
              )}
              {program === PROGRAM.CMA && (
                <Col flex={'auto'}>
                  <RangeDateTimePicker
                    control={control}
                    name={`exam_subject.${index}.exam_date`}
                    label='Ngày thi'
                    allowClear={true}
                    placeholder={['From', 'To']}
                    required
                    disabled={defaultValues ? !defaultValues?.allow_update_name : false}
                  />
                </Col>
              )}

              {program === PROGRAM.ACCA && (
                <Col flex={'auto'}>
                  <HookFormDateTime
                    control={control}
                    name={`exam_subject.${index}.end_date`}
                    label='Ngày thi'
                    required
                  />
                </Col>
              )}

              {/* 3rd Col - Trash Button*/}
              {fields?.length >= 2 && field.allow_delete === true && (
                <Col span={2} md={1} className={clsx(styles.trashExamDate)}>
                  <div className={styles.trashFiller} />
                  <ButtonIconOnly
                    iconName='trash'
                    activeColor='danger'
                    onClick={() => remove(index)}
                    className={styles.trashIcon}
                  />
                </Col>
              )}
            </Row>
          )
        })}
      </div>

      {program !== PROGRAM.CMA && fields.length < (subjects?.meta.total_records || 0) && (
        <div className='mt-5'>
          <ButtonIcon
            title='Thêm ngày thi'
            className='d-flex align-items-center px-3 py-4 sapp-color-import-student border border-gray-300 rounded btn btn-primary'
            customButton='btn-sm bg-white'
            onClick={appendDefault}
            loading={isSubjectsLoading}
          >
            <KTIcon iconName='plus' iconType='solid' className='text-inverse-light mt-1' />
          </ButtonIcon>
        </div>
      )}

      {program === PROGRAM.CMA && (
        <div>
          <HookFormDateTime
            control={control}
            name={'registration_closing_date'}
            label={'Ngày đăng ký cuối'}
          />
        </div>
      )}
      <SAPPDialogButtonsCancelSubmit
        className='justify-content-end d-flex'
        okOnClick={handleSubmit(onSubmit)}
        cancelButtonCaption='Cancel'
        okButtonCaption='Save'
        cancelClick={handleCancel}
        disabled={isDirty === false}
        loading={isLoading}
      />
    </div>
  )
}

export default ExamForm

import { multiply, round } from 'lodash'
import { useState } from 'react'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import Progress from 'src/components/base/Progress'
import SappTabs from 'src/components/tabs/SappTabs'
import { ITabs } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import LoadingCard from '../my-profile/overview-profile/loading/LoadingCard'
import './Card.scss'
import ModalPreviewImage from './ModalPreviewImage'
import MoreInfoUser from './MoreInfoUser'
import { IStaffDetail } from 'src/type/staffs.'

interface IProps {
  userDetail: IStudentDetail | IStaffDetail | undefined
  tabs: ITabs[]
  loading: boolean
  progress: number
}

const Card = ({ userDetail, tabs, loading, progress }: IProps) => {
  const [openPreview, setOpenPreview] = useState(false)
  const showAvatar = userDetail?.detail?.avatar?.['160x160']
  const showAvatarOrigin = userDetail?.detail?.avatar?.ORIGIN
  const userContact =
    userDetail && 'user_contacts' in userDetail
      ? userDetail?.user_contacts?.find((user) => user.is_default)
      : undefined
  return (
    <>
      {loading ? (
        <LoadingCard />
      ) : (
        <div className='card sapp-card--profile mb-5 mb-xl-8'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='me-7 mb-4'>
                {/* Start Show Avatar Profile */}
                <div
                  className={`symbol symbol-100px symbol-lg-160px symbol-fixed position-relative ${
                    showAvatar || showAvatarOrigin ? 'cursor-pointer' : ''
                  } ${showAvatar ? '' : 'object-fit-cover'}`}
                  onClick={() => setOpenPreview(showAvatar || showAvatarOrigin ? true : false)}
                >
                  <img src={showAvatar ?? showAvatarOrigin ?? avatar} alt='avatar' />
                </div>
                {/* End Show Avatar User */}
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      {/* Start Name User */}
                      <div className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                        {userDetail?.detail?.full_name}
                      </div>
                      {/* End Name User */}
                      <div>
                        <i className='ki-outline ki-verify fs-1 text-primary'></i>
                      </div>
                    </div>
                    <MoreInfoUser
                      email={userDetail?.detail?.email ?? userContact?.email}
                      phone={userDetail?.detail?.phone ?? userContact?.phone}
                      university={
                        userDetail && 'university' in userDetail.detail
                          ? userDetail?.detail?.university?.name
                          : undefined
                      }
                      status={userDetail?.status}
                    />
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  {/* Start Progress */}
                  <Progress title='Completion' percent={round(multiply(progress, 100), 0)} />
                  {/* End Progress */}
                </div>
              </div>
            </div>
            {/* Start Tabs */}
            <SappTabs tabs={tabs} />
            {/* End Tabs */}
          </div>
          <ModalPreviewImage
            openPreview={openPreview}
            setOpenPreview={setOpenPreview}
            avatarResize={userDetail?.detail?.avatar?.ORIGIN}
          />
        </div>
      )}
    </>
  )
}

export default Card

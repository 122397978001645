export const headers = [
  {
    label: '#',
  },
  {
    label: 'Test Name',
    className: 'min-w-350px',
  },
  {
    label: 'Type',
    className: 'min-w-150px',
  },
  {
    label: 'Mode',
    className: 'min-w-100px',
  },
  {
    label: 'Thời gian mở',
    className: 'min-w-150px',
  },
  {
    label: 'Thời hạn nộp',
    className: 'min-w-150px',
  },
  {
    label: 'Đã làm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Manual Grading',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Đã chấm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Thời hạn chấm',
    className: 'min-w-150px',
  },
  {
    label: '',
  },
]

import {
  MESSAGE_VALIDATION_USERNAME,
  PASSWORD_NOT_MATCH,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PHONE,
  VALIDATION_FIELD,
} from 'src/constants'
import { phoneRegExp, usernamePattern } from 'src/utils'
import { z } from 'zod'

export const staffSchema = ({
  requiredJobPosition,
  requiredProgram,
}: {
  requiredJobPosition: boolean
  requiredProgram: boolean
}) => {
  return {
    full_name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    username: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(usernamePattern), {
        message: MESSAGE_VALIDATION_USERNAME,
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
    email: z.string({ required_error: VALIDATION_FIELD }).email(),
    phone: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    avatar: z.any(),
    status: z.string().optional(),
    sex: z.string({ required_error: VALIDATION_FIELD }).optional(),
    course_category_id: requiredProgram ? z.string().nullable() : z.string().optional().nullable(),
    department_id: z.string(),
    position_levels: requiredJobPosition
      ? z
          .array(
            z.object({
              staff_position_id: z.string().min(1, VALIDATION_FIELD),
              staff_level_id: z.string().min(1, VALIDATION_FIELD),
            })
          )
          .min(1, { message: 'You should have at least 1 Job Position' })
      : z
          .array(
            z.object({
              staff_position_id: z.string().min(1, VALIDATION_FIELD).optional(),
              staff_level_id: z.string().min(1, VALIDATION_FIELD).optional(),
            })
          )
          .optional(),
    roles: z.array(z.any()),
  }
}

export const staffCreateSchema = ({
  requiredJobPosition,
  requiredProgram,
}: {
  requiredJobPosition: boolean
  requiredProgram: boolean
}) =>
  z
    .object(
      Object.assign({}, staffSchema({ requiredJobPosition, requiredProgram }), {
        password: z.string().optional().default(''),
        confirmPassword: z.string().optional().default(''),
      })
    )
    .refine((data) => data.password === data.confirmPassword, {
      message: PASSWORD_NOT_MATCH,
      path: ['confirmPassword'],
    })

import { useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { QuestionBankAPI } from 'src/apis/question-bank'
import DragDropQuestion from 'src/components/question-bank/create-question/DragDropQuestion'
import EssayQuestion from 'src/components/question-bank/create-question/EssayQuestion'
import FillUpQuestion from 'src/components/question-bank/create-question/FillUpQuestion'
import MatchingQuestion from 'src/components/question-bank/create-question/MatchingQuestion'
import MultiChoiceQuestion from 'src/components/question-bank/create-question/MultiChoiceQuestion'
import OneChoiceQuestion from 'src/components/question-bank/create-question/OneChoiceQuestion'
import SelectMissingWordQuestion from 'src/components/question-bank/create-question/SelectMissingWordQuestion'
import TrueFalseQuestion from 'src/components/question-bank/create-question/TrueFalseQuestion'
import { QUESTION_TYPES } from 'src/components/question-bank/shared/interfaces'
import { PageLink } from 'src/constants'
import { ICommonQuestionProps } from 'src/type/question-bank'

const Question = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  // const [activeQuestForm, setActiveQuestForm] = useState<QUESTION_TYPES | null>(null)
  const [reload, setReload] = useState<boolean>(false)
  const [question, setQuestion] = useState<ICommonQuestionProps>({
    id: undefined,
    open: false,
    type: '',
    data: undefined,
    onClose: () => {},
  })
  const handleReload = () => setReload(!reload)
  const handleCloseAddNew = (isCancel?: boolean) => {
    if (handleReload) {
      handleReload()
    }

    // setActiveQuestForm(null)
    if (question?.data?.is_single_question && id) {
      navigate(PageLink.TOPICS, { replace: true })
    } else if (question?.data?.is_independent_question) {
      navigate(`${PageLink.TOPIC}/${id}`)
    } else {
      navigate(`${PageLink.TOPICS}`)
    }
  }

  const fetch = async () => {
    if (!id) return
    const res = await QuestionBankAPI.getQuestionById(id)
    setQuestion({
      id: id,
      open: true,
      onClose: handleCloseAddNew,
      data: res.data,
      type: res.data.qType ?? '',
    })
  }
  useLayoutEffect(() => {
    fetch()
  }, [id])

  return (
    <>
      {(() => {
        switch (question?.data?.qType ?? null) {
          case QUESTION_TYPES.TRUE_FALSE:
            return <TrueFalseQuestion {...question} type='True/False' />
          case QUESTION_TYPES.ONE_CHOICE:
            return <OneChoiceQuestion {...question} type='One choice' />
          case QUESTION_TYPES.MULTIPLE_CHOICE:
            return <MultiChoiceQuestion {...question} type='Multiple choice' />
          case QUESTION_TYPES.MATCHING:
            return <MatchingQuestion {...question} type='Matching' />
          case QUESTION_TYPES.SELECT_WORD:
            return <SelectMissingWordQuestion {...question} type='Select word' />
          case QUESTION_TYPES.FILL_WORD:
            return <FillUpQuestion {...question} type='Fill up' />
          case QUESTION_TYPES.DRAG_DROP:
            return <DragDropQuestion {...question} type='Drag Drop' />
          case QUESTION_TYPES.ESSAY:
            return <EssayQuestion {...question} type='Constructed' />
          default:
            return <></>
        }
      })()}
    </>
  )
}

export default Question

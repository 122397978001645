import GradeIcon from 'src/components/grade/GradeIcon'
import CommentAndGrade from './CommenAndGrade/CommentAndGrade'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { QUESTION_TYPES } from 'src/components/question-bank/shared/interfaces'
import OneChoiceQuestion from 'src/components/questionType/OneChoiceQuestion'
import MultiChoiceQuestion from 'src/components/questionType/MultipleChoiceQuestion'
import MatchingQuestion from 'src/components/questionType/MatchingQuestion'
import DragNDropPreview from 'src/components/questionType/DragNDropPreview'
import SelectWord from 'src/components/questionType/SelectWordQuestion'
import AddWordPreview from 'src/components/questionType/FillText'
import EssayQuestionPreview from 'src/components/questionType/ConstructedQuestion'
import { useForm } from 'react-hook-form'
import { GradeAPI } from 'src/apis/grade'
import { secondsToMinutesAndSeconds } from 'src/utils'
import {
  Answer,
  QuestionAnswer,
  GradeValue,
  GradingDetail,
  IFilterTabProps,
  IAttemptAnswerData,
  GradeStatus,
} from 'src/type/grade'
import { Skeleton } from 'antd'
import EditorReader from '../base/editor/EditorReader'
import Solution from './solution/Solution'
import ModalPreviewFile from '../question-bank/preview-question/ModalPreviewFile'
import { replaceTextAlignCenterToWebKitCenter, formatGrade } from 'src/utils/grade'
import _ from 'lodash'
import clsx from 'clsx'
import { STATUS_ALL } from 'src/constants/grade'

interface QuestionDetailsProps {
  attemptAnswerData: IAttemptAnswerData
  questionGradingId: string
  getQuizAttemptInfo: () => void
  gradeStatus: string
  setFilteredTabs: Dispatch<SetStateAction<IFilterTabProps[]>>
  getMaxHeight?: string
}
interface IFormValues {
  answer: string
}

const QuestionDetails = ({
  attemptAnswerData,
  questionGradingId,
  getQuizAttemptInfo,
  gradeStatus,
  setFilteredTabs,
  getMaxHeight,
}: QuestionDetailsProps) => {
  const [gradeValue, setGradeValue] = useState<GradeValue[]>([])
  const [listGrades, setListGrades] = useState<GradingDetail[]>([])
  const [activeQuestion, setActiveQuestion] = useState<QuestionAnswer[]>([])
  const [questionCache, setQuestionCache] = useState<{ [key: string]: QuestionAnswer | null }>({})
  const [loading, setLoading] = useState<boolean>(true)
  const { control: controlAnswer, setValue } = useForm<IFormValues>()
  const [openSolutionId, setOpenSolutionId] = useState<string | null>(null)
  const [grade, setGrade] = useState<number | null>(0)
  const [componentPosition, setComponentPosition] = useState<object>({
    top: 100,
    left: '35%',
    width: 624,
    height: 224,
  })
  const [activeRequirement, setActiveRequirement] = useState<number>(0)
  const [editingItem, setEditingItem] = useState<GradingDetail | null>(null)
  const [openPreviewFile, setOpenPreviewFile] = useState({ status: false, url: '' })
  const isEssay = activeQuestion && activeQuestion[0]?.question?.qType === QUESTION_TYPES.ESSAY

  const fetchActiveQuestion = async () => {
    setLoading(true)

    // Lọc ra các câu hỏi chưa có trong cache
    const idsToFetch = attemptAnswerData?.attempt_answers.filter(
      (questionId) => !questionCache[questionId]
    )

    // Nếu tất cả các câu hỏi đều có trong cache, chỉ cần lấy từ cache
    if (idsToFetch.length === 0) {
      const questionsFromCache = attemptAnswerData?.attempt_answers
        .map((questionId) => questionCache[questionId])
        .filter((question) => question !== null) as QuestionAnswer[]
      setActiveQuestion(questionsFromCache || [])
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } else {
      try {
        // Gọi API cho tất cả các câu hỏi chưa có trong cache
        const responses = await Promise.all(
          attemptAnswerData?.attempt_answers.map(async (questionId) => {
            if (!questionCache[questionId]) {
              const res = await GradeAPI.getQuizAttemptGradingsAnswer(questionId)
              return { id: questionId, data: res.data.answer }
            } else {
              return { id: questionId, data: questionCache[questionId] }
            }
          })
        )

        // Cập nhật cache và set dữ liệu cho activeQuestion
        const newCache = { ...questionCache }
        responses.forEach((response) => {
          newCache[response?.id] = response.data
        })
        setQuestionCache(newCache)

        // Sắp xếp lại mảng activeQuestion theo thứ tự idQuestion
        const activeQuestions = attemptAnswerData?.attempt_answers.map(
          (questionId) => newCache[questionId]
        )

        // Cập nhật state activeQuestion với mảng câu hỏi
        const updatedQuestion =
          (activeQuestions.filter((question) => question !== null) as QuestionAnswer[]) || []
        setActiveQuestion(updatedQuestion)
      } catch (error) {
        console.error('Lỗi khi gọi API cho các câu hỏi:', error)
        setActiveQuestion([])
      } finally {
        setLoading(false)
      }
    }
  }

  const fetchGrade = async (updatedQuestion: QuestionAnswer[]) => {
    try {
      const response = await GradeAPI.getQuestionGrading(questionGradingId)
      if (response) {
        setGrade(response?.data?.question_grade ?? null)
        setGradeValue(
          (response?.data?.answer_gradings || []).map((grading) => ({
            ...grading,
            grading_file: grading.grading_file
              ? {
                  file_key: grading.grading_file.file_key,
                  file_name: grading.grading_file.file_name,
                }
              : null,
          }))
        )

        // Lấy requirement_id từ activeRequirement
        const activeRequirementId = updatedQuestion[activeRequirement]?.requirement_id

        // Lọc answer_gradings dựa trên requirement_id
        const gradingForRequirement = response?.data?.answer_gradings?.find(
          (grading) => grading?.requirement_id === activeRequirementId
        )

        setListGrades([
          ...(gradingForRequirement?.details?.map((detail) => ({
            begin: detail.begin,
            end: detail.end,
            comment: detail.comment,
            grade: detail?.grade || detail?.grade === 0 ? detail.grade : null,
            status: detail?.status,
          })) || []),
          ...(gradingForRequirement?.grading_file
            ? [
                {
                  begin: 0,
                  end: 0,
                  comment: '',
                  grade:
                    gradingForRequirement?.file_grade || gradingForRequirement?.file_grade === 0
                      ? gradingForRequirement?.file_grade
                      : null,
                  validate: 'file',
                  grading_file: {
                    file_key: gradingForRequirement?.grading_file?.file_key || '',
                    file_name: gradingForRequirement?.grading_file?.file_name || '',
                  },
                },
              ]
            : []),
        ])

        if (response?.data?.qType === QUESTION_TYPES.ESSAY) {
          const isGraded = response?.data?.graded
          setFilteredTabs((prevTabs) =>
            prevTabs.map((tab) =>
              tab.id === attemptAnswerData?.id
                ? { ...tab, status: isGraded ? GradeStatus.GRADED : GradeStatus.UNGRADED }
                : tab
            )
          )
        }
      }
    } catch (error) {
      console.error('Error fetching grade:', error)
    }
  }

  // Function to handle requirement clicks and set active
  const handleRequirementClick = (index: number) => {
    setActiveRequirement(index)
  }

  useEffect(() => {
    fetchActiveQuestion()
    setActiveRequirement(0)
    setListGrades([])
  }, [attemptAnswerData?.attempt_answers])

  useEffect(() => {
    if (activeQuestion?.length > 0 && activeRequirement >= 0) {
      fetchGrade(activeQuestion)
    }
  }, [activeQuestion, activeRequirement])

  const handleGenarateSelectAnswer = (
    defaultValue: { answer_position: number; answer_id: string }[]
  ): string[] => {
    const newArr: string[] = []
    if (defaultValue?.length > 0) {
      const sorted = defaultValue.sort(
        (
          a: { answer_position: number; answer_id: string },
          b: { answer_position: number; answer_id: string }
        ) => a.answer_position - b.answer_position
      )
      const obj: { [key: number]: string } = {}
      for (const e of sorted) {
        obj[e.answer_position - 1] = e.answer_id
      }
      for (let i = 0; i < sorted[sorted.length - 1].answer_position; i++) {
        newArr.push(obj[i])
      }
    }

    return newArr
  }

  function createArrayWithValues(
    myAnswerArray: {
      answer_id: string
    }[],
    answers: {
      id: string
      answer: string
      answer_position: number
    }[]
  ) {
    const resultArray = []
    if (myAnswerArray.length > 0) {
      for (const el of myAnswerArray) {
        const item = answers.find((e) => e.id === el.answer_id)
        if (item) {
          resultArray.push({
            id: item.id,
            value: item.answer,
            idAnswer: el.answer_id,
          })
        } else {
          resultArray.push({
            id: '',
            value: '',
            idAnswer: '',
          })
        }
      }
    }
    return resultArray
  }

  interface IAnswer {
    id: string
    answer: string
    answer_position: number
    question_id: string
    is_correct: boolean
  }

  function getCorrect(answers: IAnswer[], questionType: QUESTION_TYPES) {
    switch (questionType) {
      case QUESTION_TYPES.ONE_CHOICE:
      case QUESTION_TYPES.TRUE_FALSE:
        return Object.fromEntries(answers.map((answer) => [answer.id, answer.is_correct]))
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        return Object.fromEntries(
          (answers || []).map((originalAnswer) => [originalAnswer.id, originalAnswer.is_correct])
        )
      case QUESTION_TYPES.FILL_WORD:
      case QUESTION_TYPES.SELECT_WORD:
      case QUESTION_TYPES.MATCHING:
      case QUESTION_TYPES.DRAG_DROP:
        return answers || []
      default:
        return []
    }
  }

  const getGradeForRequirement = (questionData: QuestionAnswer) => {
    const grading = gradeValue.find((g) => g?.requirement_id === questionData?.requirement_id)
    const grade = grading?.answer_grade

    return formatGrade(grade)
  }

  const renderQuestions = (questionData: QuestionAnswer, index: number) => {
    if (loading) {
      return (
        <>
          <Skeleton active paragraph={{ rows: 16 }} />
        </>
      )
    }

    const question = questionData?.question
    const answer = questionData?.answer || []
    if (!question || !activeQuestion) {
      return <div>Không có dữ liệu câu hỏi</div>
    }

    return (
      <div>
        {question?.question_topic?.description && question.qType !== QUESTION_TYPES.ESSAY && (
          <EditorReader text_editor_content={question?.question_topic?.description} />
        )}
        {!isEssay && question.question_topic?.files?.length > 0 && (
          <div className='topic-resource'>
            <div className='border my-6' />
            <div className='fs-4 fw-bold mb-2'>Topic Resource:</div>
            {question.question_topic?.files.map((e, index: number) => {
              return (
                <div
                  className='fs-4 cursor-pointer sapp-text-gray-800'
                  onClick={() =>
                    setOpenPreviewFile({
                      status: true,
                      url: e?.resource?.url || '',
                    })
                  }
                  key={index}
                >
                  {e?.resource?.name}
                </div>
              )
            })}
          </div>
        )}
        {(() => {
          switch (question?.qType) {
            case QUESTION_TYPES.ONE_CHOICE:
            case QUESTION_TYPES.TRUE_FALSE:
              return (
                <OneChoiceQuestion
                  data={question}
                  control={controlAnswer}
                  corrects={
                    getCorrect(question?.answers, question?.qType) as { [key: string]: boolean }
                  }
                  setValue={setValue}
                  name={`${questionData?.id}`}
                  defaultValues={questionData?.question_answer_id}
                />
              )
            case QUESTION_TYPES.MULTIPLE_CHOICE:
              return (
                <MultiChoiceQuestion
                  data={question}
                  control={controlAnswer}
                  corrects={
                    getCorrect(question?.answers, question?.qType) as { [key: string]: boolean }
                  }
                  setValue={setValue}
                  name={`${questionData?.id}`}
                  defaultValues={questionData?.answer?.map((e: Answer) => e?.answer_id)}
                />
              )
            case QUESTION_TYPES.MATCHING:
              return (
                <MatchingQuestion
                  data={question}
                  defaultAnswer={questionData?.answer}
                  corrects={questionData?.answer_matching_mapping}
                  solution={question?.solution}
                />
              )
            case QUESTION_TYPES.DRAG_DROP:
              return (
                <DragNDropPreview
                  data={question}
                  defaultAnswer={createArrayWithValues(answer, question?.answers)}
                  corrects={getCorrect(question?.answers || [], question?.qType) as IAnswer[]}
                />
              )
            case QUESTION_TYPES.SELECT_WORD:
              return (
                <SelectWord
                  data={question}
                  defaultAnswer={handleGenarateSelectAnswer(questionData?.answer)}
                  corrects={
                    getCorrect(question?.answers || [], question?.qType) as {
                      id: string
                      answer: string
                      is_correct: boolean
                      answer_position: number
                    }[]
                  }
                  solution={question?.solution}
                />
              )
            case QUESTION_TYPES.FILL_WORD:
              return (
                <AddWordPreview
                  data={question}
                  defaultAnswer={answer
                    .sort((a: Answer, b: Answer) => a?.answer_position - b?.answer_position)
                    .map((e: Answer) => e.answer_text)}
                  corrects={
                    getCorrect(question?.answers || [], question?.qType) as {
                      id: string
                      answer: string
                      is_correct: boolean
                      answer_position: number
                    }[]
                  }
                />
              )
            case QUESTION_TYPES.ESSAY:
              return (
                <EssayQuestionPreview
                  data={question}
                  control={controlAnswer}
                  questionData={questionData}
                  name={`${questionData?.id}`}
                  index={index}
                  listGrades={listGrades}
                  setListGrades={setListGrades}
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                  activeRequirement={activeRequirement}
                  questionGradingId={questionGradingId}
                  activeQuestion={activeQuestion}
                  gradeStatus={gradeStatus}
                  fetchGrade={fetchGrade}
                  getQuizAttemptInfo={getQuizAttemptInfo}
                  setFilteredTabs={setFilteredTabs}
                  attemptAnswerData={attemptAnswerData}
                />
              )
            default:
              return <>Loại câu hỏi không hợp lệ</>
          }
        })()}
      </div>
    )
  }

  return (
    <div
      className={_.compact(['layout-left flex-grow-1', !isEssay && 'overflow-y-auto']).join(' ')}
    >
      <div className={isEssay ? 'essay-d-flex d-flex' : ''}>
        <div
          className={`${isEssay ? 'sapp-column-left-essay overflow-y-auto' : 'w-100'}`}
          style={{
            ...(isEssay ? { maxHeight: getMaxHeight } : {}),
          }}
        >
          {activeQuestion.map((questionData, index: number) => (
            <div
              className={clsx('grade-requirement grade-px-24', {
                'flex-grow-1': isEssay,
                active: index === activeRequirement,
                inactive: index !== activeRequirement,
              })}
              onClick={
                index !== activeRequirement ? () => handleRequirementClick(index) : undefined
              }
              key={questionData?.id}
            >
              <div className={`sapp-question-wrapper ${isEssay ? 'sapp-layout-essay' : ''}`}>
                <div className='sapp-question-header d-flex'>
                  <Skeleton active loading={loading}>
                    <div>
                      <span className='question'>
                        <strong>
                          Question {attemptAnswerData?.index}{' '}
                          {questionData?.question?.requirements?.length > 0 && isEssay
                            ? ` - Requirement ${index + 1} `
                            : ''}
                          :
                        </strong>
                        <span
                          className={clsx({
                            incorrect:
                              _.isEmpty(activeQuestion) || (!questionData?.is_correct && !isEssay),
                          })}
                        >
                          {isEssay ? (
                            <>
                              {questionData?.active === 'SUBMITED'
                                ? STATUS_ALL.CONDITIONS.SUBMITTED
                                : STATUS_ALL.CONDITIONS.UNSUBMITTED}
                            </>
                          ) : (
                            <>
                              {questionData?.is_correct
                                ? STATUS_ALL.CONDITIONS.CORRECT
                                : STATUS_ALL.CONDITIONS.INCORRECT}
                            </>
                          )}
                        </span>
                      </span>
                      {!isEssay && (
                        <span className='percentage'>
                          <GradeIcon iconType='globe' />
                          <span>{questionData?.question?.question_report?.ratio}%</span>
                        </span>
                      )}
                      <span>
                        Time Spent: {secondsToMinutesAndSeconds(questionData?.time_spent ?? 0)}
                      </span>
                    </div>
                    <div className='grade d-flex align-items-center'>
                      {isEssay &&
                        (!_.isEmpty(questionData?.question?.solution) ||
                          !_.isEmpty(
                            questionData?.question?.requirements?.[activeRequirement]?.explanation
                          )) &&
                        !loading && (
                          <div
                            className='view-solution sapp-cursor-pointer percentage'
                            onClick={() => setOpenSolutionId(questionData?.id)}
                          >
                            View Solution
                          </div>
                        )}
                      <div>
                        Grade:{' '}
                        <strong>
                          {isEssay ? getGradeForRequirement(questionData) : formatGrade(grade)}
                        </strong>
                      </div>
                    </div>
                  </Skeleton>
                </div>
                <div className='question-content'>{renderQuestions(questionData, index)}</div>
              </div>
              {openSolutionId === questionData?.id && isEssay && (
                <Solution
                  key={questionData?.id}
                  open={openSolutionId}
                  setOpen={setOpenSolutionId}
                  componentPosition={componentPosition}
                  setComponentPosition={setComponentPosition}
                >
                  <EditorReader
                    text_editor_content={replaceTextAlignCenterToWebKitCenter(
                      questionData?.question?.requirements?.[activeRequirement]?.explanation ||
                        questionData?.question?.solution
                    )}
                  />
                </Solution>
              )}
            </div>
          ))}
        </div>
        {isEssay && !loading && (
          <div className='question-comment' style={{ maxHeight: getMaxHeight }}>
            <CommentAndGrade
              listGrades={listGrades}
              setListGrades={setListGrades}
              questionGradingId={questionGradingId}
              editingItem={editingItem}
              setEditingItem={setEditingItem}
              fetchGrade={fetchGrade}
              activeQuestion={activeQuestion}
              getQuizAttemptInfo={getQuizAttemptInfo}
              activeRequirement={activeRequirement}
              loading={loading}
              gradeStatus={gradeStatus}
            ></CommentAndGrade>
          </div>
        )}
        <ModalPreviewFile
          open={openPreviewFile.status}
          setOpen={setOpenPreviewFile}
          url={openPreviewFile?.url || ''}
        />
      </div>
    </div>
  )
}

export default QuestionDetails

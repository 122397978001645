import { zodResolver } from '@hookform/resolvers/zod'
import { uniqueId } from 'lodash'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { VALIDATION_FIELD } from 'src/constants'
import { ICommonQuestionProps } from 'src/type/question-bank'
import { z } from 'zod'
import {
  VALIDATION_ANSWERS_MINLENGTH,
  defaultQuestionValues,
  validationQuestionSchema,
} from '../shared/consts'
import {
  ANSWER_CORRECT_TYPE,
  IInputQuestionProps,
  IQuestion,
  QUESTION_TYPES,
} from '../shared/interfaces'
import { validateAnswers } from '../shared/utils'
import LayoutQuestion from './LayoutQuestion'

interface IAnswer {
  id: string
  question: string
  answer: string
  is_correct?: ANSWER_CORRECT_TYPE
}
interface IInputProps extends IInputQuestionProps {
  answers?: IAnswer[]
}
const defaultValues = {
  ...defaultQuestionValues,
  answers: [
    { answer: '', question: '', id: uniqueId('answer_') },
    { answer: '', question: '', id: uniqueId('answer_') },
  ],
}

const MatchingQuestion = ({ open, onClose, type, id, data }: ICommonQuestionProps) => {
  const note = `Tạo ít nhất 2 bộ đáp án, bao gồm đáp án chính ở cột trái và đáp án tương ứng ở cột phải để hoàn tất tạo câu hỏi dạng này. Các bộ đáp án bị trống ở 1 trong 2 cột hoặc cả 2 đều sẽ bị hệ thống bỏ qua.`

  const validationSchema = z
    .object({
      ...validationQuestionSchema,
      answers: z.array(
        z.object({
          question: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
          answer: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
        })
      ),
    })
    .superRefine((arg, ctx) => {
      if (arg.answers?.length < 2) {
        ctx.addIssue(VALIDATION_ANSWERS_MINLENGTH())
      }
    })

  const useFormProp = useForm<IInputProps & { customAnswersError: string }>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  const {
    control,
    watch,
    formState: { errors, isSubmitted },
    setError,
    clearErrors,
  } = useFormProp

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  })

  const answers = watch('answers')

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        validateAnswers({
          setError,
          clearErrors,
          answers: fields,
          path: 'customAnswersError',
          checkEvery: 'NO',
        })
      })
    }
  }, [fields])

  const buildRequest = (data: IInputProps): { partialQuestion: Partial<IQuestion> } => {
    return {
      partialQuestion: {
        qType: QUESTION_TYPES.MATCHING,
        question_matchings: data.answers?.map((e, i) => ({
          content: (e.question ?? '').trim(),
          answer: {
            is_correct: true,
            answer: (e.answer ?? '').trim(),
            answer_position: i + 1,
          },
        })),
      },
    }
  }
  const [isBlocked, setIsBlocked] = useState(false)
  const fetchData = (data: IQuestion): Partial<IInputProps> => {
    if (!data) {
      return {}
    }
    if (data?.status === 'BLOCK') {
      setIsBlocked(true)
    }
    return {
      answers: data.question_matchings?.map((e) => ({
        question: e.content,
        answer: e.answer.answer,
        id: uniqueId('answer_'),
      })),
    }
  }

  return (
    <LayoutQuestion
      defaultValues={defaultValues}
      data={data}
      fetchData={fetchData}
      buildRequest={buildRequest}
      open={open}
      onClose={onClose}
      id={id}
      useForm={useFormProp}
      type={type}
      note={note}
    >
      {/* start:: answer */}
      <div className='mb-8'>
        <Row>
          <Col>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className='required'>Đáp Án</span>
            </label>
          </Col>
          <Col>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className='required'>Đáp Án Tương Ứng</span>
            </label>
          </Col>
        </Row>
        {answers?.map((field, index) => (
          <div key={field.id}>
            <Row>
              <Col className='mb-5'>
                <div className='d-flex align-items-center'>
                  <div className='w-100'>
                    <HookFormTextField
                      groupText={(index + 1).toString()}
                      control={control}
                      disabled={isBlocked}
                      name={`answers.${index}.question` as const}
                      placeholder={'Đáp án ' + (index + 1)}
                    ></HookFormTextField>
                  </div>
                </div>
              </Col>
              <Col className='mb-5'>
                <div className='d-flex align-items-center'>
                  <div className='w-100'>
                    <HookFormTextField
                      control={control}
                      disabled={isBlocked}
                      name={`answers.${index}.answer` as const}
                      placeholder={'Đáp án tương ứng ' + (index + 1)}
                    ></HookFormTextField>
                  </div>
                  {fields.length > 2 && (
                    <div className='ms-4'>
                      <ButtonIconOnly
                        disabled={isBlocked}
                        iconName={'trash'}
                        activeColor='danger'
                        onClick={() => {
                          setTimeout(() => {
                            remove(index)
                          })
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ))}
        <Row>
          <Col md={9}>
            <ErrorMessage>{errors?.['customAnswersError']?.message}</ErrorMessage>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonIcon
              title={'Thêm đáp án'}
              disabled={isBlocked}
              className='h-45px w-100 d-flex justify-content-start align-items-center'
              customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0'
              type='button'
              onClick={() => {
                setTimeout(() => {
                  append({ question: '', answer: '', id: uniqueId('answer_') })
                })
              }}
            >
              <KTIcon iconName='plus' className='fs-2 text-gray-500 p-3' />
            </ButtonIcon>
          </Col>
          <Col></Col>
        </Row>
      </div>
      {/* end:: answer*/}
    </LayoutQuestion>
  )
}
export default MatchingQuestion

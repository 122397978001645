import { debounce } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { ClassesApi } from 'src/apis/classes'
import { commonKeys } from 'src/constants/queryKeyFactory'
import { IResponse } from 'src/type'
import { IFacilities } from 'src/type/area'

const PAGE_SIZE = 10
const useInfiniteFacilities = (enabled: boolean) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e: string) => {
    setSearchText(e)
  }, 350)

  const fetchFacilities = async (
    page_index: number,
    page_size: number,
    name?: string
  ): Promise<IResponse<IFacilities>> => {
    try {
      const params: Record<string, any> = {}
      if (name) {
        params.search = `name=${name}`
      }
      const res = await ClassesApi.getFacilities({
        page_index: page_index,
        page_size: page_size,
        params,
      })
      return res
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery<IResponse<IFacilities>>({
      queryKey: commonKeys.facilities(searchText), // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchFacilities(pageParam, PAGE_SIZE, searchText)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.success) {
          return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
            ? lastPage.data.metadata.page_index + 1
            : false
        }
      },
      enabled: enabled,
      refetchOnWindowFocus: false,
    })

  return {
    facilities: data?.pages.flatMap((page) => page?.data?.facilities), // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
    debounceSearch,
  }
}

export default useInfiniteFacilities

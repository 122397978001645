import React, { useState } from 'react'
import { ArrowCollapseIcon } from 'src/common/icons'
import Processing from 'src/components/base/processing/Processing'
import './Accodian.scss'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { round } from 'lodash'
import SappTooltip from 'src/common/SappTooltip'

function Accodian({ explorer, type, action, isEnded }: any) {
  const [expand, setExpand] = useState(false)
  return (
    <div
      className={`sapp-parent-wrapper`}
      style={expand ? { maxHeight: `max-content`, height: 'max-content' } : {}}
    >
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <div className='d-flex align-items-center gap-3'>
          <div
            className={`sapp-collapse-wrapper d-flex align-items-center ${expand && 'expanded'}`}
            onClick={() => setExpand((prevState) => !prevState)}
          >
            <ArrowCollapseIcon />
          </div>
          {type === 'course-content' && (
            <SAPPCheckbox
              checked={explorer.checked}
              disabled={isEnded}
              onChange={() => action(explorer.id, !explorer.checked)}
            />
          )}
          <div className='sapp-text-truncate-1 sapp-text-primary fw-semibold fs-6'>
            <SappTooltip
              title={`${explorer?.name} ${
                explorer?.children?.length > 0 ? `(${explorer?.children?.length})` : ''
              }`}
              max_length={130}
            />
          </div>
        </div>
        {type === 'process' && (
          <div className='d-flex align-items-center gap-2'>
            <div className='w-150px'>
              <Processing value={explorer.process} />
            </div>
            <div className='w-55px text-end'>
              {round(
                (explorer?.learning_progress?.total_course_sections_completed /
                  explorer?.learning_progress?.total_course_sections) *
                  100,
                2
              ) || '0'}
              %
            </div>
          </div>
        )}
      </div>
      <>
        {explorer.child?.map((child: any) => {
          return (
            <div style={{ marginLeft: '20px' }} key={child.id}>
              <Accodian explorer={child} type={type} action={action} isEnded={isEnded} />
            </div>
          )
        })}
      </>
    </div>
  )
}

export default Accodian

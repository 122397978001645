import { DayOfWeek } from 'src/type'

export const monthsOfYear = Array.from({ length: 12 }, (_, i) => {
  const value = (i + 1).toString().padStart(2, '0') // '01', '02', etc.
  return { label: value, value }
})

export enum PROGRAM {
  ACCA = 'ACCA',
  CFA = 'CFA',
  CMA = 'CMA',
}

export enum Keyboard {
  SLASH = '/',
}

export enum DETAIL_TAB_TITLE {
  OVERVIEW = 'overview',
  SETTING = 'setting',
}

export enum Pagination {
  INDEX = 1,
  SIZE = 10,
}

export const EntranceLevelSelect = [
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'IIA',
    value: 'IIA',
  },
  {
    label: 'IB',
    value: 'IB',
  },
  {
    label: 'IIB',
    value: 'IIB',
  },
  {
    label: 'Not Attended',
    value: 'NOT_ATTENDED',
  },
  {
    label: 'Từ chối kiểm tra',
    value: 'F',
  },
]

export const EntranceLevel = {
  IA: 'IA',
  IIA: 'IIA',
  IB: 'IB',
  IIB: 'IIB',
  NOT_ATTENDED: 'Not Attended',
  F: 'Từ chối kiểm tra',
  EMPTY: '',
}

export const COMMON_TEXT = {
  DELETE: 'Delete',
  YES: 'Yes',
  NO: 'No',
}

export const daysOfWeek: { label: string; value: DayOfWeek }[] = (
  ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'] as const
).map((day) => ({
  label: day.charAt(0) + day.slice(1).toLowerCase(),
  value: day,
}))
export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_CURRENT_PAGE = 1

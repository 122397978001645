import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Row } from 'antd'
import { BaseOptionType } from 'antd/es/select'
import clsx from 'clsx'
import { isEmpty, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import { KTIcon } from 'src/_metronic/helpers'
import { RolesAPI } from 'src/apis/roles'
import { createStaffs, StaffAPI, uploadAvatarStaff } from 'src/apis/staffs'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import DepartmentSelect from 'src/components/base/select/common/DepartmentSelect'
import ProgramSelect from 'src/components/base/select/common/ProgramSelect'
import StaffLevelSelect from 'src/components/base/select/common/StaffLevelSelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import GridLayout from 'src/components/layout/grid'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PASSWORD,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  PageLink,
  STATUS_FORM,
  VALIDATE_PASSWORD,
} from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import useDepartmentPositions from 'src/hooks/useDepartmentPositions'
import { EStatusUser, IError, Role } from 'src/type'
import { IRolesAll, IStaffs } from 'src/type/staffs.'
import { sizeInBytes, validatePassword } from 'src/utils'
import { removeEmptyKey } from 'src/utils/removeEmptyKey'
import { staffCreateSchema } from './schema'
import styles from './styles.module.scss'

const MAXIMUM_JOB_POSITION = 2

interface DepartmentOptions {
  label: string
  value: string
  unit: string
}

const CreateStaff = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [roles, setRoles] = useState<IRolesAll>()
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { profileMe } = useUserContext()
  const [currentDepartment, setCurrentDepartment] = useState<DepartmentOptions | null>(null)

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.metadata?.total_pages
    const pageIndex = roles?.metadata?.page_index as number
    const pageSize = roles?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      try {
        const res = await fetchRoles(pageIndex + 1, pageSize, params)
        if (res) {
          const results = roles?.roles?.concat(res?.data?.roles)
          setRoles({
            metadata: res.data.metadata,
            roles: results,
          })
        }
      } catch {}
    }
  }

  const [roleLoading, setRoleLoading] = useState(false)
  const getRoles = async ({ params }: any) => {
    setRoleLoading(false)
    try {
      const res = await fetchRoles(1, 20, params)
      setRoles(res?.data)
    } catch {
      setRoleLoading(false)
    }
  }

  const requiredJobPosition = currentDepartment
    ? [
        'Customer Experience CFA',
        'Customer Experience CMA',
        'Customer Experience ACCA',
        'Custermer Experience Cert/Dip',
        'Customer Experience',
      ].includes(currentDepartment.label)
    : false

  const requiredProgram = !!currentDepartment && currentDepartment.unit !== 'HO'

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<IStaffs>({
    resolver: zodResolver(staffCreateSchema({ requiredJobPosition, requiredProgram })),
    mode: 'onChange',
    defaultValues: {
      position_levels: [{ staff_level_id: '', staff_position_id: '' }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'position_levels',
    rules: {
      maxLength: MAXIMUM_JOB_POSITION,
    },
  })

  const department = watch('department_id')
  const { provinces, isLoading: isProvincesLoading } = useDepartmentPositions(department)

  const onSubmitCreate = async (data: IStaffs) => {
    const { email, full_name, username, avatar } = data
    let payload = {
      ...data,
      email: email.toLowerCase(),
      full_name: full_name,
      password: data.password,
      username: username.toLowerCase(),
      status: EStatusUser.ACTIVE,
    }

    if (data.password) {
      if (!validatePassword.test(data.password)) {
        setError('password', { message: VALIDATE_PASSWORD })
        return
      } else {
        clearErrors('password')
        clearErrors('confirmPassword')
      }
    } else {
      delete payload.password
    }
    setLoading(true)

    try {
      const res = await createStaffs(removeEmptyKey({ ...payload }))
      const dataStaffs = res?.data
      toast.success('Create Successfully!')
      setUploadSuccess(true)

      if (allowRenderUpdateRole) {
        const roleIds = data?.roles?.map((role) => role) || []
        try {
          await StaffAPI.updateRoles({
            id: dataStaffs?.id,
            role_ids: roleIds,
          })
        } catch (error) {
          console.error('Error updating staff roles:', error)
        }
      }

      if (isUndefined(avatar)) {
        navigate(PageLink.STAFFS)
      }

      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({ staffId: dataStaffs?.id, avatar: avatar }).then(() =>
          navigate(PageLink.STAFFS)
        )
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setUploadSuccess(false)
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<IStaffs> = async (data) => {
    if (
      !isUndefined(data.avatar) &&
      (data?.avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    onSubmitCreate(data)
  }

  const optionRole: BaseOptionType[] = roles
    ? roles.roles.map((role) => ({
        label: role.name,
        value: role.id,
      }))
    : []

  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.STAFFS),
    })
  }

  const handleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderUpdateRole = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_STAFF_GR.GET_ASSIGN_PERMISSION_STAFF) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const appendDefaultPosition = () =>
    append({
      staff_position_id: '',
      staff_level_id: '',
    })

  useEffect(() => {
    reset()
    setUploadSuccess(true)
  }, [id])

  useEffect(() => {
    if (allowRenderUpdateRole) {
      getRoles({})
    }
  }, [])

  return (
    <CreateEditLayout
      onSave={handleSubmitForm}
      onCancel={handleCancel}
      loading={loading}
      pageTitle={`Create Staff`}
      maxWidth={1200}
    >
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush sapp-pt--image py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Avatar</h2>
              </div>
            </div>

            <div className='card-body text-center pt-0'>
              <SAPPHookUploadFile
                name='avatar'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={avatarDefault}
                accept={ACCEPT_UPLOAD_MIME_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                uploadSuccess={uploadSuccess}
                removeAvatar={avatarDefault}
              />
            </div>
          </div>

          {id && (
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Status</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <HookFormSelectAntd
                  size='large'
                  name='status'
                  control={control}
                  dropdownStyle={{ minWidth: 'fit-content' }}
                  placeholder='Status'
                  filterOption={true}
                  defaultValue={STATUS_FORM[0].value}
                  options={STATUS_FORM}
                />
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_ecommerce_add_product_general'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush pb-4 pt-8'>
                  <div className='card-body pt-0'>
                    <GridLayout isListFilters xs={24} sm={24} xl={24} gutter={[36, 36]}>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />

                      <HookFormTextField
                        control={control}
                        name='username'
                        label='Username'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_USERNAME}
                      />

                      <HookFormTextField
                        control={control}
                        name='email'
                        label='Email'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_EMAIL}
                      />

                      {!id && (
                        <HookFormTextField
                          control={control}
                          name='password'
                          label='Password'
                          type='password'
                          required={!isEmpty(watch('confirmPassword'))}
                          guideline={GUIDELINE_PASSWORD}
                        />
                      )}

                      {!id && (
                        <HookFormTextField
                          control={control}
                          name='confirmPassword'
                          label='Confirm Password'
                          required={!isEmpty(watch('password'))}
                          type='password'
                          guideline={GUIDELINE_PASSWORD}
                        />
                      )}

                      <HookFormTextField
                        control={control}
                        name='phone'
                        label='Phone Number'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_PHONE}
                      />

                      <DepartmentSelect
                        control={control}
                        name='department_id'
                        label='Department'
                        required
                        onChange={(_, option) => {
                          setCurrentDepartment(option as DepartmentOptions)
                          remove()
                          appendDefaultPosition()
                        }}
                      />

                      {!!department && (
                        <div>
                          <SappLabel label='Job Position' required={requiredJobPosition} />
                          {errors.position_levels && (
                            <small className='text-danger mt-2 lh-1'>
                              {errors?.position_levels.message}
                            </small>
                          )}
                          {fields.map((field, index) => {
                            const selectedPositions = watch('position_levels')
                              ?.filter((_, i) => i !== index)
                              ?.map((pos) => pos.staff_position_id)
                              ?.filter(Boolean)

                            return (
                              <Row
                                className={clsx(
                                  'justify-content-between position-relative',
                                  styles.job_position
                                )}
                                gutter={[12, 12]}
                                key={field.id}
                              >
                                <Col span={12}>
                                  {/* 1st Col - Subject */}
                                  <HookFormSelectAntd
                                    name={`position_levels.${index}.staff_position_id`}
                                    control={control}
                                    placeholder={`${provinces?.[0]?.position?.name ?? 'Board'}`}
                                    label='Title'
                                    loading={isProvincesLoading}
                                    onSearch={() => {}}
                                    options={
                                      provinces
                                        ?.filter(
                                          (item) =>
                                            !selectedPositions.includes(item.staff_position_id)
                                        )
                                        ?.map((item) => ({
                                          label: item.position.name,
                                          value: item.staff_position_id,
                                        })) ?? [{}]
                                    }
                                    maxCount={2}
                                  />
                                </Col>
                                <Col span={index > 0 ? 11 : 12}>
                                  {/* 1st Col - Subject */}
                                  <StaffLevelSelect
                                    size='large'
                                    label='Level'
                                    control={control}
                                    classNameHeight={'sapp-h-45px'}
                                    name={`position_levels.${index}.staff_level_id`}
                                  />
                                </Col>
                                {index > 0 && (
                                  <Col span={2} md={1} className={clsx(styles.trashContainer)}>
                                    <div className={styles.trashFiller} />
                                    <ButtonIconOnly
                                      iconName='trash'
                                      activeColor='danger'
                                      onClick={() => remove(index)}
                                      className={styles.trashIcon}
                                    />
                                  </Col>
                                )}
                              </Row>
                            )
                          })}
                          {fields.length < MAXIMUM_JOB_POSITION && (
                            <ButtonIcon
                              title='Add job position'
                              className={`d-flex align-items-center px-3 py-4 sapp-color-import-student border border-gray-300 rounded btn btn-primary ${
                                fields.length > 0 && 'mt-5'
                              }`}
                              customButton='btn-sm bg-white'
                              onClick={appendDefaultPosition}
                              // loading={isSubjectsLoading}
                            >
                              <KTIcon
                                iconName='plus'
                                iconType='solid'
                                className='text-inverse-light'
                              />
                            </ButtonIcon>
                          )}
                        </div>
                      )}

                      <ProgramSelect
                        control={control}
                        label='Program'
                        name='course_category_id'
                        required={requiredProgram}
                        placeholder='CFA'
                        classNameHeight='sapp-h-40'
                      />

                      {allowRenderUpdateRole && (
                        <HookFormSelectMultiple
                          name='roles'
                          control={control}
                          placeholder='Role'
                          options={optionRole}
                          label='Role'
                          required
                          loading={roleLoading}
                          onSearch={(e) => {
                            if (e === undefined) {
                              return
                            }
                            getRoles({ params: { name: e } })
                          }}
                          handleNextPage={(e: any) => handlNextPageRole({ params: { name: e } })}
                        />
                      )}

                      <HookFormSelectAntd
                        size='large'
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        label='Gender'
                        defaultValue={GENDER_FORM?.[0]?.value}
                        options={GENDER_FORM}
                      />
                    </GridLayout>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default withAuthComponents(CreateStaff)

import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotificationAPI } from 'src/apis/notifications'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { GroupReceiverTable } from 'src/components/courses/group-receiver/table/GroupReceiverTable'
import GridLayout from 'src/components/layout/grid'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GROUPS_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import { ITabs, Role } from 'src/type'
import { INotificationGroupAll } from 'src/type/notification'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Notification',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.listGroups,
  },
]
const fieldNames = ['name', 'sort_type', 'fromDate', 'toDate']
const initialValues: any = {
  name: '',
  sort_type: '',
  fromDate: '',
  toDate: '',
}
const ListGroupReceiver = () => {
  const [listGroup, setListNotification] = useState<INotificationGroupAll>()
  const navigate = useNavigate()
  const { profileMe } = useUserContext()
  const allowRenderCreateGroups = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GROUPS_GR.CREATE_GROUP) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const queryParams = {
    name: searchParams.get('name') ?? '',
    sort_type: searchParams.get('sort_type'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }
  const validateSchema = z.object({
    name: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    sort_type: z.string().optional(),
  })

  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validateSchema),
    mode: 'onChange',
  })
  const handleChangeParams = (currentPage: number, size: number) => {
    const query = {
      page_index: currentPage,
      page_size: size,
      name: getValues('name'),
      sort_type: replaceValueAll(getValues('sort_type')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }
    const queryString = Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const getParams = (
    name: string,
    sort_type: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    name: name,
    sort_type,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)
  const checkDatefield =
    queryParams.name || queryParams.sort_type || queryParams.fromDate || queryParams.toDate
  const showDatefield =
    getValues('name') || getValues('sort_type') || getValues('fromDate') || getValues('toDate')

  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.sort_type ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      checkDatefield ? 'updated_at' : 'created_at'
    )
  )
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    listGroup?.groups || []
  )

  const fetchGroupList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await NotificationAPI.getNotificationGroups(currentPage, pageSize, params)
      setListNotification(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        getValues('sort_type'),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        showDatefield ? 'updated_at' : 'created_at'
      )
    )
    setLoading(true)
    fetchGroupList(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((field) => {
      setValue(field, initialValues[field])
    })
    setLoading(true)
    navigate('/list-group')
    fetchGroupList(1, 10, { dateField: 'created_at' })
  }

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.listGroups} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='pt-10 px-10'>
          <GridLayout isListFilters>
            {/* begin:: Search */}
            <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
              <HookFormTextField
                control={control}
                name='name'
                placeholder='Search'
                defaultValue={queryParams?.name}
                isListScreen
                onSubmit={onSubmit}
              />
            </div>
            {/* end:: Search */}
            <HookFormSelectAntd
              name='sort_type'
              placeholder='Sort by'
              control={control}
              size='large'
              defaultValue={queryParams?.sort_type ?? ''}
              classNameHeight='sapp-h-40'
              options={FILTER_SELECTALL_SORTBY}
            />
            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </GridLayout>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          <>
            {allowRenderCreateGroups && (
              <ButtonIconPrimary
                iconName='plus'
                title='Create Group'
                size='small'
                onClick={() => navigate('/create-group')}
              />
            )}
          </>
        </div>
        <GroupReceiverTable
          checkedList={checkedList}
          fetchList={fetchGroupList}
          filterParams={filterParams}
          getParams={getParams}
          isCheckedAll={isCheckedAll}
          loading={loading}
          list={listGroup}
          queryParams={queryParams}
          setLoading={setLoading}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          handleChangeParams={handleChangeParams}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ListGroupReceiver)

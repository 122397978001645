import { TEACHER_PRIORITY_STATUS } from 'src/constants/users'
import { Metadata } from './common'
import { IDetail, IStudents, ITeacherFacility } from './students'
import { UserStatus } from 'src/type/index'
export interface ITeacher extends IStudents {
  id?: string
  gender?: string
  created_at?: Date
  updated_at?: Date
  key?: string
  type?: string
  hubspot_contact_id?: string
  detail?: IDetail
  user_contacts?: [
    {
      id?: string
      email?: string
      phone?: string
    }
  ]
  teacher_status?: string
  teacher_teachable_instances?: TeacherTeachableInstanceForm[]
  staff_ids?: string[]
  dob?: Date
  facility_ids: string[]
  current_company?: string
  attachment_files?: any[]
}

export interface ITeacherList {
  data: ITeacher[]
  metadata: Metadata
  users: ITeacher[]
}

export interface ITeacherClassList {
  data: ITeacherClass[]
  metadata: Metadata
}

export interface ITeacherClass {
  id: string
  class?: IClass
  lecture?: ITeacherInClass
  parts?: IPart[]
}

export interface IClass {
  id?: string
  course_id?: string
}

export interface ITeacherInClass {
  id?: string
  created_at?: Date
  updated_at?: Date
  key?: string
  username?: string
  type?: string
  hubspot_contact_id?: string
  detail?: {
    id?: string
    full_name?: string
    sex?: string | null
    avatar?: {
      '40x40'?: string
      '50x50'?: string
      '150x150'?: string
      '160x160'?: string
      ORIGIN?: string
    }
    email?: string
  }
  user_contacts?: [
    {
      id?: string
      email?: string
      phone?: string
    }
  ]
}

export interface ITeacherData extends ITeacherInClass {
  id: string
  code: string
  status?: UserStatus | undefined
  teacher_facilities: ITeacherFacility[]
}

export interface IPart {
  id: string
  name?: string
  course_section_link_parents?: [
    {
      id: string
      position?: number
    }
  ]
}

export interface IPartTeacher {
  id: string
  name?: string
  lectures: ITeacherInClass[]
}

export interface IPartTeacherResponse {
  data: IPartTeacher[]
}

export interface TeacherTeachableInstance {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  category_id: string
  subject_id: string
  user_id: string
  category: Category
  subject: Subject
  feature_section_instances: FeatureSection[]
}

// Define the structure for the form values

export interface TeacherTeachableInstanceForm {
  category_id: string
  subject_id: string
  feature_sections: FeatureSection[]
  // Array of selected section IDs
}
export interface FeatureSection {
  feature_section_id: string // Array of selected section IDs
  priority_status?: TeacherPriorityStatus | ''
}
export interface StaffInstance {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  staff_id: string
  user_id: string
  staff: Staff
}

export interface Staff {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  key: string
  username: string
  password: string
  nick_name: any
  status: string
  detail_id: string
}

export interface Category {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  name: string
  description: any
}

export interface Subject {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  course_category_id: string
  name: string
  code: string
}

export interface FeatureSection {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  name: string
  code: string
  subject_id: string
}

export interface TeachableInstance {
  category: Category
  subject: Subject
  feature_section_instances: FeatureSectionInstance[]
}

export interface Category {
  name: string
}

export interface Subject {
  code: string
}

export interface FeatureSectionInstance {
  code: string
}

export type TeacherPriorityStatus = keyof typeof TEACHER_PRIORITY_STATUS

export enum TeachingProgramMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

//#region Edit teaching program
export interface UpdateTeachingPrograms {
  teacher_teachable_instances: TeacherTeachablePut[]
  category_primary_id: string
  course_category_id: string
}

export interface TeacherTeachablePut {
  category_id: string
  subject_id: string
  feature_sections: FeatureSectionPut[]
}

export interface FeatureSectionPut {
  feature_section_id?: string
  priority_status?: string
}

//#endregion Edit teaching program

import dayjs from 'dayjs'
import { VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import { zString } from 'src/helper/validation'
import { CONSTRUCTION_MODE } from 'src/type/classes'
import { z } from 'zod'

const MIN_DURATION_HOURS = 1.5
const MAX_DURATION_HOURS = 3.5

const classStandardScheduleSchema = z
  .array(
    z
      .object({
        day_of_week: z.string(),
        start_time: zString,
        end_time: zString,
      })
      .refine(
        ({ start_time, end_time }) => {
          const now = dayjs().startOf('day') // Use today's date but override time
          const start = now
            .hour(Number(start_time.split(':')[0]))
            .minute(Number(start_time.split(':')[1]))
          const end = now
            .hour(Number(end_time.split(':')[0]))
            .minute(Number(end_time.split(':')[1]))

          const duration = end.diff(start, 'hour', true)

          return duration >= MIN_DURATION_HOURS && duration <= MAX_DURATION_HOURS
        },
        {
          message: `Class duration must be between ${MIN_DURATION_HOURS} and ${MAX_DURATION_HOURS} hours`,
          path: ['end_time'],
        }
      )
  )
  .min(1)

const baseClassSchema = z.object({
  name: zString.max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
  code: zString.max(1000, VALIDATE_FIELD_MAX_LENGTH('Code', 1000)),
  status: z.string().optional(),
  instruction_mode: zString,
  type: zString,
  capacity: z.number({ required_error: VALIDATION_FIELD }).min(1),
  lecture_id: z.string().optional(),
  mentor_id: z.string().optional(),
  description: z.string().optional().nullable(),
  examination_subject_id: z.string().optional().nullable(),
  duration_type: zString.optional(),
  flexible_days: z.number().optional().nullable(),
  started_at: z.date().optional().nullable(),
  finished_at: z.date().optional().nullable(),
  opening_at: z.date().optional().nullable(),
  reason: z.string().optional().nullable(),
  course_id: z.string().optional().nullable(),
  room_id: z.string().optional().nullable(),
})

// Discriminated Union (fixing missing `instruction_mode`)
export const classSchema = z
  .discriminatedUnion('instruction_mode', [
    baseClassSchema.extend({
      instruction_mode: z.literal('ONLINE'),
      facility_id: z.any(), // Online: facility_id can be anything
      course_id: z.string().optional().nullable(),
    }),

    baseClassSchema.extend({
      instruction_mode: z.enum([
        CONSTRUCTION_MODE.BLENDED,
        CONSTRUCTION_MODE.LIVE_ONLINE,
        CONSTRUCTION_MODE.OFFLINE,
      ]),
      facility_id: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD), // Offline: facility_id required
      course_id: zString,
      create_hybrid_class: z.boolean().optional(),
      class_standard_schedules: classStandardScheduleSchema,
    }),
    baseClassSchema.extend({
      instruction_mode: z.literal('HYBRID').optional(),
    }),
  ])
  .superRefine((data, ctx) => {
    if (data.instruction_mode === 'ONLINE' && data.duration_type === 'FIXED') {
      if (!data.started_at) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: VALIDATION_FIELD,
          path: ['started_at'],
        })
      }
      if (!data.finished_at) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: VALIDATION_FIELD,
          path: ['finished_at'],
        })
      }
    }
    // Ensure flexible_days is required only when duration_type is 'Flexible'

    if (data.duration_type === 'Flexible' && !data.flexible_days) {
      console.error('Validation Error: Flexible days are required when duration type is Flexible')
    }
    if (data.duration_type === 'Flexible') {
      data.finished_at = undefined // Automatically remove finished_at
      data.started_at = undefined
      data.opening_at = undefined
    }

    if (data.instruction_mode !== CONSTRUCTION_MODE.ONLINE) {
      data.opening_at = undefined
    }
  })

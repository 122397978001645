export const QuizResultTableStyles = `.sapp_quiz_result__package {
  font-family: "Inter", sans-serif;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  overflow-x:auto;
  overflow-y: hidden;
  max-width: 1024px;
  min-height: 1024px;
  background-color:#fff;
}
.sapp_quiz_result__package *,
.sapp_quiz_result__package ::before,
.sapp_quiz_result__package ::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #dcdddd;
  /* 2 */
}

.sapp_quiz_result__package ::before,
.sapp_quiz_result__package ::after {
  --tw-content: "";
}

.sapp_quiz_result__package html,
.sapp_quiz_result__package :host {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  -o-tab-size: 4;
  tab-size: 4;
  /* 3 */
  font-family: Inter, sans-serif;
  /* 4 */
  font-feature-settings: normal;
  /* 5 */
  font-variation-settings: normal;
  /* 6 */
  -webkit-tap-highlight-color: transparent;
  /* 7 */
}

.sapp_quiz_result__package body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

.sapp_quiz_result__package hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 1px;
  /* 3 */
}

.sapp_quiz_result__package abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.sapp_quiz_result__package h1,
.sapp_quiz_result__package h2,
.sapp_quiz_result__package h3,
.sapp_quiz_result__package h4,
.sapp_quiz_result__package h5,
.sapp_quiz_result__package h6 {
  font-size: inherit;
  font-weight: inherit;
}

.sapp_quiz_result__package a {
  color: inherit;
  text-decoration: inherit;
}

.sapp_quiz_result__package b,
.sapp_quiz_result__package strong {
  font-weight: bolder;
}

.sapp_quiz_result__package code,
.sapp_quiz_result__package kbd,
.sapp_quiz_result__package samp,
.sapp_quiz_result__package pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  /* 1 */
  font-feature-settings: normal;
  /* 2 */
  font-variation-settings: normal;
  /* 3 */
  font-size: 1em;
  /* 4 */
}

.sapp_quiz_result__package small {
  font-size: 80%;
}

.sapp_quiz_result__package sub,
.sapp_quiz_result__package sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.sapp_quiz_result__package sub {
  bottom: -0.25em;
}

.sapp_quiz_result__package sup {
  top: -0.5em;
}

.sapp_quiz_result__package table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

.sapp_quiz_result__package button,
.sapp_quiz_result__package input,
.sapp_quiz_result__package optgroup,
.sapp_quiz_result__package select,
.sapp_quiz_result__package textarea {
  font-family: inherit;
  /* 1 */
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

.sapp_quiz_result__package button,
.sapp_quiz_result__package select {
  text-transform: none;
}

.sapp_quiz_result__package button,
.sapp_quiz_result__package [type=button],
.sapp_quiz_result__package [type=reset],
.sapp_quiz_result__package [type=submit] {
  -webkit-appearance: button;
  /* 1 */
  background-color: transparent;
  /* 2 */
  background-image: none;
  /* 2 */
}

.sapp_quiz_result__package :-moz-focusring {
  outline: auto;
}

.sapp_quiz_result__package :-moz-ui-invalid {
  box-shadow: none;
}

.sapp_quiz_result__package progress {
  vertical-align: baseline;
}

.sapp_quiz_result__package ::-webkit-inner-spin-button,
.sapp_quiz_result__package ::-webkit-outer-spin-button {
  height: auto;
}

.sapp_quiz_result__package [type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

.sapp_quiz_result__package ::-webkit-search-decoration {
  -webkit-appearance: none;
}

.sapp_quiz_result__package ::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

.sapp_quiz_result__package summary {
  display: list-item;
}

.sapp_quiz_result__package blockquote,
.sapp_quiz_result__package dl,
.sapp_quiz_result__package dd,
.sapp_quiz_result__package h1,
.sapp_quiz_result__package h2,
.sapp_quiz_result__package h3,
.sapp_quiz_result__package h4,
.sapp_quiz_result__package h5,
.sapp_quiz_result__package h6,
.sapp_quiz_result__package hr,
.sapp_quiz_result__package figure,
.sapp_quiz_result__package p,
.sapp_quiz_result__package pre {
  margin: 0;
}

.sapp_quiz_result__package fieldset {
  margin: 0;
  padding: 0;
}

.sapp_quiz_result__package legend {
  padding: 0;
}

.sapp_quiz_result__package ol,
.sapp_quiz_result__package ul,
.sapp_quiz_result__package menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sapp_quiz_result__package dialog {
  padding: 0;
}

.sapp_quiz_result__package textarea {
  resize: vertical;
}

.sapp_quiz_result__package input::-moz-placeholder,
.sapp_quiz_result__package textarea::-moz-placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

.sapp_quiz_result__package input::placeholder,
.sapp_quiz_result__package textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

.sapp_quiz_result__package button,
.sapp_quiz_result__package [role=button] {
  cursor: pointer;
}

.sapp_quiz_result__package :disabled {
  cursor: default;
}

.sapp_quiz_result__package img,
.sapp_quiz_result__package svg,
.sapp_quiz_result__package video,
.sapp_quiz_result__package canvas,
.sapp_quiz_result__package audio,
.sapp_quiz_result__package iframe,
.sapp_quiz_result__package embed,
.sapp_quiz_result__package object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

.sapp_quiz_result__package img,
.sapp_quiz_result__package video {
  max-width: 100%;
  height: auto;
}

.sapp_quiz_result__package [hidden] {
  display: none;
}

.sapp_quiz_result__package [type=text],
.sapp_quiz_result__package input:where(:not([type])),
.sapp_quiz_result__package [type=email],
.sapp_quiz_result__package [type=url],
.sapp_quiz_result__package [type=password],
.sapp_quiz_result__package [type=number],
.sapp_quiz_result__package [type=date],
.sapp_quiz_result__package [type=datetime-local],
.sapp_quiz_result__package [type=month],
.sapp_quiz_result__package [type=search],
.sapp_quiz_result__package [type=tel],
.sapp_quiz_result__package [type=time],
.sapp_quiz_result__package [type=week],
.sapp_quiz_result__package [multiple],
.sapp_quiz_result__package textarea,
.sapp_quiz_result__package select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

.sapp_quiz_result__package [type=text]:focus,
.sapp_quiz_result__package input:where(:not([type])):focus,
.sapp_quiz_result__package [type=email]:focus,
.sapp_quiz_result__package [type=url]:focus,
.sapp_quiz_result__package [type=password]:focus,
.sapp_quiz_result__package [type=number]:focus,
.sapp_quiz_result__package [type=date]:focus,
.sapp_quiz_result__package [type=datetime-local]:focus,
.sapp_quiz_result__package [type=month]:focus,
.sapp_quiz_result__package [type=search]:focus,
.sapp_quiz_result__package [type=tel]:focus,
.sapp_quiz_result__package [type=time]:focus,
.sapp_quiz_result__package [type=week]:focus,
.sapp_quiz_result__package [multiple]:focus,
.sapp_quiz_result__package textarea:focus,
.sapp_quiz_result__package select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}

.sapp_quiz_result__package input::-moz-placeholder,
.sapp_quiz_result__package textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

.sapp_quiz_result__package input::placeholder,
.sapp_quiz_result__package textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

.sapp_quiz_result__package ::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.sapp_quiz_result__package ::-webkit-date-and-time-value {
  min-height: 1.5em;
}

.sapp_quiz_result__package ::-webkit-date-and-time-value {
  text-align: inherit;
}

.sapp_quiz_result__package ::-webkit-datetime-edit {
  display: inline-flex;
}

.sapp_quiz_result__package ::-webkit-datetime-edit,
.sapp_quiz_result__package ::-webkit-datetime-edit-year-field,
.sapp_quiz_result__package ::-webkit-datetime-edit-month-field,
.sapp_quiz_result__package ::-webkit-datetime-edit-day-field,
.sapp_quiz_result__package ::-webkit-datetime-edit-hour-field,
.sapp_quiz_result__package ::-webkit-datetime-edit-minute-field,
.sapp_quiz_result__package ::-webkit-datetime-edit-second-field,
.sapp_quiz_result__package ::-webkit-datetime-edit-millisecond-field,
.sapp_quiz_result__package ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

.sapp_quiz_result__package select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  print-color-adjust: exact;
}

.sapp_quiz_result__package [multiple],
.sapp_quiz_result__package [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  print-color-adjust: unset;
}

.sapp_quiz_result__package [type=checkbox],
.sapp_quiz_result__package [type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

.sapp_quiz_result__package [type=checkbox] {
  border-radius: 0px;
}

.sapp_quiz_result__package [type=radio] {
  border-radius: 100%;
}

.sapp_quiz_result__package [type=checkbox]:focus,
.sapp_quiz_result__package [type=radio]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.sapp_quiz_result__package [type=checkbox]:checked,
.sapp_quiz_result__package [type=radio]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.sapp_quiz_result__package [type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  .sapp_quiz_result__package [type=checkbox]:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}

.sapp_quiz_result__package [type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  .sapp_quiz_result__package [type=radio]:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}

.sapp_quiz_result__package [type=checkbox]:checked:hover,
.sapp_quiz_result__package [type=checkbox]:checked:focus,
.sapp_quiz_result__package [type=radio]:checked:hover,
.sapp_quiz_result__package [type=radio]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

.sapp_quiz_result__package [type=checkbox]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (forced-colors: active) {
  .sapp_quiz_result__package [type=checkbox]:indeterminate {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}

.sapp_quiz_result__package [type=checkbox]:indeterminate:hover,
.sapp_quiz_result__package [type=checkbox]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

.sapp_quiz_result__package [type=file] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

.sapp_quiz_result__package [type=file]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

.sapp_quiz_result__package *,
.sapp_quiz_result__package ::before,
.sapp_quiz_result__package ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sapp_quiz_result__package ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sapp_quiz_result__package .absolute {
  position: absolute;
}

.sapp_quiz_result__package .relative {
  position: relative;
}

.sapp_quiz_result__package .-left-3 {
  left: -0.75rem;
}

.sapp_quiz_result__package .-right-28 {
  right: -7rem;
}

.sapp_quiz_result__package .-right-3 {
  right: -0.75rem;
}

.sapp_quiz_result__package .right-1 {
  right: 0.25rem;
}

.sapp_quiz_result__package .top-0 {
  top: 0px;
}

.sapp_quiz_result__package .top-0.5 {
  top: 0.125rem;
}

.sapp_quiz_result__package .top-1 {
  top: 0.25rem;
}

.sapp_quiz_result__package .mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.sapp_quiz_result__package .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.sapp_quiz_result__package .my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.sapp_quiz_result__package .ml-1 {
  margin-left: 0.25rem;
}

.sapp_quiz_result__package .ml-2 {
  margin-left: 0.5rem;
}

.sapp_quiz_result__package .ml-2\.5 {
  margin-left: 0.625rem;
}

.sapp_quiz_result__package .ml-6 {
  margin-left: 1.5rem;
}

.sapp_quiz_result__package .mr-2 {
  margin-right: 0.5rem;
}

.sapp_quiz_result__package .mr-2\.5 {
  margin-right: 0.625rem;
}

.sapp_quiz_result__package .mr-6 {
  margin-right: 1.5rem;
}

.sapp_quiz_result__package .inline-block.mr-6 {
  min-width: 70px;
}

.sapp_quiz_result__package .mt-3 {
  margin-top: 0.75rem;
}

.sapp_quiz_result__package .mt-5 {
  margin-top: 1.25rem;
}

.sapp_quiz_result__package .flex {
  display: flex;
}

.sapp_quiz_result__package .table {
  display: table;
}

.sapp_quiz_result__package .h-2 {
  height: 0.5rem;
}

.sapp_quiz_result__package .h-full {
  height: 100%;
}

.sapp_quiz_result__package .min-h-8 {
  min-height: 30px;
}

.sapp_quiz_result__package .min-h-default {
  min-height: 40px;
}

.sapp_quiz_result__package .w-2 {
  width: 0.5rem;
}

.sapp_quiz_result__package .w-8 {
  width: 2rem;
}

.sapp_quiz_result__package .w-\[70px\] {
  width: 70px;
}

.sapp_quiz_result__package .w-\[calc\(100\%-141px\)\] {
  width: calc(100% - 141px);
}

.sapp_quiz_result__package .w-full {
  width: 100%;
}

.sapp_quiz_result__package .w-max {
  width: -moz-max-content;
  width: max-content;
}

.sapp_quiz_result__package .min-w-8 {
  min-width: 30px;
}

.sapp_quiz_result__package .min-w-default {
  min-width: 40px;
}

.sapp_quiz_result__package .max-w-40 {
  max-width: 10rem;
}

.sapp_quiz_result__package .max-w-\[1114px\] {
  max-width: 1114px;
}

.sapp_quiz_result__package .max-w-xs {
  max-width: 20rem;
}

.sapp_quiz_result__package .table-auto {
  table-layout: auto;
}

.sapp_quiz_result__package .-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.sapp_quiz_result__package .translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.sapp_quiz_result__package .translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.sapp_quiz_result__package .rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.sapp_quiz_result__package .cursor-not-allowed {
  cursor: not-allowed;
}

.sapp_quiz_result__package .cursor-pointer {
  cursor: pointer;
}

.sapp_quiz_result__package .flex-wrap {
  flex-wrap: wrap;
}

.sapp_quiz_result__package .items-center {
  align-items: center;
}

.sapp_quiz_result__package .justify-center {
  justify-content: center;
}

.sapp_quiz_result__package .gap-2 {
  gap: 0.5rem;
}

.sapp_quiz_result__package .gap-3 {
  gap: 0.75rem;
}

.sapp_quiz_result__package .gap-4 {
  gap: 1rem;
}

.sapp_quiz_result__package .gap-5 {
  gap: 1.25rem;
}

.sapp_quiz_result__package .gap-6 {
  gap: 1.5rem;
}

.sapp_quiz_result__package .overflow-hidden {
  overflow: hidden;
}

.sapp_quiz_result__package .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sapp_quiz_result__package .text-nowrap {
  text-wrap: nowrap;
}

.sapp_quiz_result__package .rounded-full {
  border-radius: 9999px;
}

.sapp_quiz_result__package .rounded-md {
  border-radius: 0.375rem;
}

.sapp_quiz_result__package .border {
  border-width: 1px;
}

.sapp_quiz_result__package .border-0 {
  border-width: 0px;
}

.sapp_quiz_result__package .border-b {
  border-bottom-width: 1px;
}

.sapp_quiz_result__package .border-dotted {
  border-style: dotted;
}

.sapp_quiz_result__package .\!border-gray-2 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(241 241 241/var(--tw-border-opacity)) !important;
}

.sapp_quiz_result__package .border-active {
  --tw-border-opacity: 1;
  border-color: rgb(255 184 0/var(--tw-border-opacity));
}

.sapp_quiz_result__package .border-gray-1 {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 161/var(--tw-border-opacity));
}

.sapp_quiz_result__package .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219/var(--tw-border-opacity));
}

.sapp_quiz_result__package .bg-gray-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 161/var(--tw-bg-opacity));
}

.sapp_quiz_result__package .bg-gray-3 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241/var(--tw-bg-opacity));
}

.sapp_quiz_result__package .bg-gray-4 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249/var(--tw-bg-opacity));
}

.sapp_quiz_result__package .bg-primary {
  --tw-bg-opacity: 1;
  background-color: #ffc107;
}

.sapp_quiz_result__package .p-0 {
  padding: 0px;
}

.sapp_quiz_result__package .p-0\.5 {
  padding: 0.125rem;
}

.sapp_quiz_result__package .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.sapp_quiz_result__package .px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.sapp_quiz_result__package .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.sapp_quiz_result__package .py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.sapp_quiz_result__package .py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.sapp_quiz_result__package .text-left {
  text-align: left;
}

.sapp_quiz_result__package .text-center {
  text-align: center;
}

.sapp_quiz_result__package .text-3xl {
  font-size: 32px;
  line-height: 40px;
}

.sapp_quiz_result__package .text-base {
  font-size: 16px;
  line-height: 24px;
}

.sapp_quiz_result__package .text-medium-sm {
  font-size: 14px;
  line-height: 16.9px;
}

.sapp_quiz_result__package .text-sm {
  font-size: 14px;
  line-height: 24px;
}

.sapp_quiz_result__package .text-xl {
  font-size: 20px;
  line-height: 25px;
}

.sapp_quiz_result__package .text-xsm {
  font-size: 13px;
  line-height: 19.5px;
}

.sapp_quiz_result__package .font-bold {
  font-weight: 700;
}

.sapp_quiz_result__package .font-medium {
  font-weight: 500;
}

.sapp_quiz_result__package .font-normal {
  font-weight: 400;
}

.sapp_quiz_result__package .font-semibold {
  font-weight: 600;
}

.sapp_quiz_result__package .font-thin {
  font-weight: 100;
}

.sapp_quiz_result__package .leading-4 {
  line-height: 1rem;
}

.sapp_quiz_result__package .leading-4\.5 {
  line-height: 17px;
}

.sapp_quiz_result__package .leading-4\.8 {
  line-height: 18px;
}

.sapp_quiz_result__package .leading-8 {
  line-height: 2rem;
}

.sapp_quiz_result__package .leading-8\.5 {
  line-height: 33px;
}

.sapp_quiz_result__package .text-bw-1 {
  --tw-text-opacity: 1;
  color: rgb(20 20 20/var(--tw-text-opacity));
}

.sapp_quiz_result__package .text-gray-1 {
  --tw-text-opacity: 1;
  color: rgb(161 161 161/var(--tw-text-opacity));
}

.sapp_quiz_result__package .text-gray-5 {
  --tw-text-opacity: 1;
  color: rgb(126 130 153/var(--tw-text-opacity));
}

.sapp_quiz_result__package .text-gray-6 {
  --tw-text-opacity: 1;
  color: rgb(216 216 229/var(--tw-text-opacity));
}

.sapp_quiz_result__package .text-state-error {
  --tw-text-opacity: 1;
  color: #B90E0A;
}

.sapp_quiz_result__package .text-state-success {
  --tw-text-opacity: 1;
  color: #397839;
}

.sapp_quiz_result__package .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.sapp_quiz_result__package .underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.sapp_quiz_result__package .shadow-0 {
  --tw-shadow: 0;
  --tw-shadow-colored: 0;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.sapp_quiz_result__package .hover\\:border-active:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 184 0/var(--tw-border-opacity));
}

.sapp_quiz_result__package .hover\\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: #ffc107;
}

.sapp_quiz_result__package .hover\\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.sapp_quiz_result__package .hover\\:text-primary:hover {
  --tw-text-opacity: 1;
  color: #ffc107;
}
.sapp_quiz_result__package .border-dashed {
  border-style: dashed;
 }

.sapp_quiz_result__package .border-none {
  border-style: none;
}

@media (min-width: 768px) {
  .sapp_quiz_result__package .md\\:justify-between {
    justify-content: space-between;
  }
}
`

import React, { FC } from 'react'

interface EmptyStateGraphicProps extends React.SVGProps<SVGSVGElement> {}
const EmptyStateGraphic: FC<EmptyStateGraphicProps> = (props) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='258' height='150' fill='none'>
      <g clipPath='url(#a)'>
        <path
          fill='#F3F4F6'
          fillRule='evenodd'
          d='M61.279 149.056a90.144 90.144 0 0 1-3.795-4.635 10.183 10.183 0 0 0-8.083-3.995c-6.113-.006-15.442-.006-15.442-.006-6.09 0-11.025-4.942-11.025-11.039 0-6.097 4.936-11.04 11.025-11.04H68.8c6.09 0 11.026-4.942 11.026-11.038 0-6.097-4.937-11.04-11.026-11.04H11.026C4.936 96.264 0 91.322 0 85.225c0-6.097 4.936-11.04 11.026-11.04h39.693c6.09 0 11.026-4.941 11.026-11.038s-4.937-11.04-11.026-11.04h-21.61c-6.09 0-11.026-4.943-11.026-11.04 0-6.096 4.937-11.039 11.026-11.039H57.45c2.757 0 5.398-1.119 7.316-3.1C81.045 10.305 103.72 0 128.78 0c33.959 0 63.537 18.925 78.779 46.802a10.18 10.18 0 0 0 8.929 5.294c6.697.01 16.374.01 16.374.01 6.089 0 11.026 4.941 11.026 11.04 0 6.096-4.937 11.038-11.026 11.038h-21.61c-6.089 0-11.026 4.943-11.026 11.04 0 6.096 4.937 11.039 11.026 11.039h35.723c6.089 0 11.026 4.943 11.026 11.039 0 6.097-4.937 11.039-11.026 11.039h-26.024a10.192 10.192 0 0 0-9.393 6.245 89.904 89.904 0 0 1-15.282 24.469H61.278v.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M225.189 148.443H95.171a.612.612 0 0 0 0 1.225h130.018a.613.613 0 0 0 0-1.225ZM88.65 148.443H35.643a.612.612 0 0 0 0 1.225H88.65a.613.613 0 0 0 0-1.225Z'
          clipRule='evenodd'
        />
        <path
          fill='#E5E7EB'
          d='M200.583 41.546v76.451c0 5.256-4.294 9.556-9.544 9.556H66.961c-4.187 0-7.766-2.734-9.044-6.51a9.535 9.535 0 0 1-.5-3.046V41.546c0-5.256 4.295-9.556 9.544-9.556h124.077c2.028 0 3.912.644 5.464 1.731a9.547 9.547 0 0 1 4.081 7.825Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M58.028 92.104V61.96a.612.612 0 1 0-1.222 0v30.144a.612.612 0 1 0 1.222 0ZM58.744 38.049c1.367-3.193 4.542-5.446 8.217-5.446h46.453a.612.612 0 0 0 0-1.224H66.961c-4.176 0-7.786 2.558-9.34 6.187a.613.613 0 0 0 1.124.483ZM173.539 32.603h17.499a8.89 8.89 0 0 1 5.114 1.621 8.936 8.936 0 0 1 3.819 7.324v5.157a.612.612 0 1 0 1.222 0v-5.157c0-3.432-1.711-6.484-4.341-8.327a10.105 10.105 0 0 0-5.815-1.842h-17.499a.612.612 0 0 0 0 1.224h.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          d='M152.862 149.056h-47.723l3.866-17.428.906-4.073h11.752a69.76 69.76 0 0 0-.216.872c-.454 1.863-.012 3.571 1.098 4.885 1.323 1.589 3.625 2.604 6.454 2.604 1.849 0 3.483-.431 4.76-1.194 2.268-1.327 3.436-3.657 2.792-6.295l-.215-.872h11.751l2.04 9.21 2.733 12.291h.002Z'
        />
        <path
          fill='#E5E7EB'
          d='m122.547 133.313-13.541-1.685.906-4.073h11.752c-.072.287-.144.573-.215.872-.454 1.863-.012 3.572 1.098 4.886ZM150.13 136.764l-16.369-2.042c2.268-1.326 3.437-3.657 2.792-6.295-.072-.299-.144-.585-.214-.872h11.75l2.041 9.209Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m137.118 128.168.028.115c.312 1.28.236 2.496-.155 3.58a.611.611 0 1 1-1.15-.415c.314-.871.368-1.846.116-2.876l-.213-.871a.61.61 0 0 1 .594-.758h11.752c.286 0 .534.2.597.479l2.04 9.21 2.732 12.292a.61.61 0 0 1-.597.745h-47.723a.611.611 0 0 1-.596-.745l3.866-17.428a.612.612 0 1 1 1.193.265l-3.701 16.684H152.1l-2.567-11.547-1.934-8.73h-10.481Z'
          clipRule='evenodd'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M121.07 127.406a103.3 103.3 0 0 0-.217.878c-.503 2.067-.007 3.963 1.225 5.424 1.42 1.704 3.884 2.821 6.922 2.821a.612.612 0 0 0 0-1.224c-2.618 0-4.76-.912-5.985-2.384-.99-1.172-1.377-2.692-.973-4.348.071-.299.141-.584.213-.869a.612.612 0 1 0-1.186-.297l.001-.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          d='M191.038 46.325v66.894H66.961V46.325c0-2.628 2.147-4.778 4.772-4.778h114.534c2.624 0 4.77 2.15 4.77 4.778h.001Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M67.572 113.22V46.326c0-2.291 1.873-4.166 4.16-4.166h10.33a.612.612 0 0 0 0-1.224h-10.33c-2.96 0-5.383 2.425-5.383 5.39v66.894a.612.612 0 0 0 1.222 0h.001ZM154.896 42.16h31.373c2.287 0 4.159 1.874 4.159 4.166v4.192a.612.612 0 1 0 1.223 0v-4.192c0-2.966-2.422-5.39-5.382-5.39h-31.373a.612.612 0 0 0 0 1.224Z'
          clipRule='evenodd'
        />
        <path
          fill='#D1D5DB'
          d='M200.583 113.219v4.778c0 5.257-4.294 9.556-9.544 9.556H66.961c-4.187 0-7.766-2.734-9.044-6.51a9.526 9.526 0 0 1-.5-3.046v-4.778h143.166Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M199.972 113.832h-24.607a.612.612 0 0 1 0-1.225h25.218c.338 0 .611.275.611.613v4.778c0 5.593-4.569 10.168-10.155 10.168H66.961c-3.1 0-5.889-1.409-7.755-3.62a.611.611 0 1 1 .934-.79 8.919 8.919 0 0 0 6.821 3.186h124.077c4.913 0 8.933-4.025 8.933-8.944v-4.166h.001ZM58.028 113.832v4.166a.612.612 0 1 1-1.222 0v-4.778c0-.338.274-.613.611-.613h97.558a.613.613 0 0 1 0 1.225H58.028Z'
          clipRule='evenodd'
        />
        <path
          fill='#D1D5DB'
          d='M156.309 149.056h-54.618l.024-.119c.572-2.557 3.185-4.66 5.81-4.66h42.95c2.624 0 5.238 2.103 5.81 4.66l.024.119Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M155.501 148.445c-.76-2.002-2.888-3.555-5.025-3.555a.612.612 0 0 1 0-1.224c2.894 0 5.776 2.319 6.406 5.138l.027.13a.609.609 0 0 1-.599.734h-54.618a.613.613 0 0 1-.599-.734l.027-.13c.63-2.82 3.512-5.138 6.406-5.138h26.235a.612.612 0 1 1 0 1.224h-26.235c-2.14 0-4.271 1.558-5.026 3.555h53.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#F3F4F6'
          d='M217.624 101.561c0 2.878-2.353 5.234-5.228 5.234h-76.324c-2.875 0-5.227-2.356-5.227-5.234V55.667c0-2.878 2.352-5.233 5.227-5.233h76.324c2.875 0 5.228 2.355 5.228 5.233v45.894Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M131.456 71.412V55.665c0-2.542 2.077-4.622 4.616-4.622h76.323c2.539 0 4.617 2.08 4.617 4.622v45.894c0 2.542-2.077 4.622-4.617 4.622h-76.323c-2.539 0-4.616-2.08-4.616-4.622V85.314a.613.613 0 0 0-1.223 0v16.245c0 3.214 2.627 5.846 5.839 5.846h76.323c3.212 0 5.84-2.632 5.84-5.846V55.664c0-3.215-2.628-5.846-5.84-5.846h-76.323c-3.212 0-5.839 2.63-5.839 5.846V71.41a.612.612 0 1 0 1.223 0Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          d='M187.793 92.19c7.489-7.498 7.489-19.655 0-27.153-7.489-7.498-19.63-7.498-27.119 0-7.488 7.498-7.488 19.655 0 27.152 7.489 7.499 19.63 7.499 27.119 0Z'
        />
        <path
          fill='#FFB800'
          d='M170.756 71.45c-.667-.431-1.212-.134-1.212.66v13.007c0 .794.546 1.092 1.212.66l9.845-6.379c.666-.432.666-1.138 0-1.571l-9.845-6.378v.001Z'
        />
        <path
          fill='#D1D5DB'
          d='M110.798 32.519v17.245c0 .452.288.59.641.308l7.56-6.056h46.126c3.608 0 6.562-2.956 6.562-6.57V32.52c0-3.614-2.953-6.57-6.562-6.57h-47.767c-1.55 0-2.976.542-4.1 1.454a6.552 6.552 0 0 0-2.46 5.116Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m119.214 44.63-7.394 5.922c-.421.336-.823.328-1.1.195-.277-.133-.534-.442-.534-.98V32.52a7.167 7.167 0 0 1 2.688-5.593.612.612 0 1 1 .765.954 5.946 5.946 0 0 0-2.231 4.639v16.794l7.208-5.775a.607.607 0 0 1 .382-.134h35.118a.612.612 0 0 1 0 1.225h-34.903l.001-.001ZM170.297 42.412a7.164 7.164 0 0 0 2.001-4.965V32.52c0-3.95-3.228-7.181-7.173-7.181H128.04a.612.612 0 0 0 0 1.224h37.086c3.272 0 5.95 2.681 5.95 5.957v4.928a5.934 5.934 0 0 1-1.661 4.117.613.613 0 0 0 .882.848Z'
          clipRule='evenodd'
        />
        <path
          fill='#D1D5DB'
          d='M86.284 58.079v1.306S91.506 62 99.341 56.77c1.306 2.615 5.223 2.615 5.223 2.615V58.08c0-4.316-3.525-7.845-7.834-7.845h-2.611c-4.308 0-7.835 3.53-7.835 7.845Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M105.176 59.386V58.08c0-4.65-3.801-8.456-8.446-8.456h-2.611c-3.695 0-6.857 2.408-7.995 5.735a.612.612 0 0 0 1.158.397c.973-2.846 3.677-4.907 6.838-4.907h2.612c3.973 0 7.223 3.254 7.223 7.232v1.307a.612.612 0 0 0 1.223 0h-.002Z'
          clipRule='evenodd'
        />
        <path
          fill='#F3F4F6'
          d='M86.284 59.386v3.923a7.832 7.832 0 0 0 3.84 6.732 7.718 7.718 0 0 0 3.995 1.112h2.611a7.708 7.708 0 0 0 3.996-1.112 7.834 7.834 0 0 0 3.839-6.732v-3.922s-3.918 0-5.223-2.615c-7.835 5.23-13.058 2.614-13.058 2.614Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M103.953 59.963a9.407 9.407 0 0 1-1.872-.372.612.612 0 0 1 .365-1.169c1.121.35 2.118.353 2.118.353.338 0 .612.274.612.612v3.923a8.444 8.444 0 0 1-4.136 7.256 8.318 8.318 0 0 1-4.31 1.2h-2.611a8.312 8.312 0 0 1-4.306-1.197 8.444 8.444 0 0 1-4.14-7.26v-3.922a.612.612 0 1 1 1.223 0v3.923a7.222 7.222 0 0 0 3.542 6.207 7.104 7.104 0 0 0 3.68 1.024h2.613a7.1 7.1 0 0 0 3.684-1.027 7.217 7.217 0 0 0 3.538-6.204v-3.347Z'
          clipRule='evenodd'
        />
        <path
          fill='#F3F4F6'
          d='M75.812 102.531c.053 1.439 1.28 2.615 2.717 2.615h32.514c1.435 0 2.65-1.176 2.702-2.615 0 0 .551-15.977-2.702-20.918-1.437-2.198-5.42-4.106-5.42-4.106-1.307-.614-3.538-1.124-4.975-1.124H90.202c-1.436 0-3.708.418-5.053.928 0 0-.326.131-.849.354v.012c-1.49.655-4.531 2.157-5.77 3.937-3.382 4.849-2.717 20.918-2.717 20.918l-.001-.001ZM124.159 73.39l.651-1.961c.36-1.078 1.808-2.192 3.215-2.474l7.937-1.59c1.408-.282 2.275.63 1.928 2.025l-.038.156c-.35 1.395-1.775 2.821-3.169 3.17l-2.69.674-2.612 3.922-5.222-3.922Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m129.232 76.432 1.369-2.056a.61.61 0 1 1 1.017.679l-1.727 2.594a.61.61 0 0 1-.876.15l-5.223-3.922a.612.612 0 0 1-.213-.682s.292-.883.652-1.963c.414-1.24 2.054-2.555 3.674-2.88l7.937-1.59c.953-.19 1.712.065 2.185.561.473.497.691 1.269.457 2.209l-.039.16a4.454 4.454 0 0 1-.607 1.347.612.612 0 1 1-1.023-.67c.205-.314.361-.644.442-.97l.039-.16c.113-.451.071-.832-.154-1.07-.226-.238-.604-.296-1.06-.205l-7.937 1.59c-1.195.24-2.448 1.152-2.754 2.067l-.513 1.542 4.354 3.27v-.001ZM83.95 77.162a.521.521 0 0 1 .11-.06c.53-.227.861-.359.861-.359a.611.611 0 0 1 .455 1.136s-.28.113-.738.307a.496.496 0 0 1-.093.052c-1.419.623-4.335 2.031-5.515 3.725-.974 1.398-1.578 3.77-1.975 6.399-.956 6.335-.632 14.144-.632 14.146.042 1.114.993 2.025 2.106 2.025h4.82a.612.612 0 0 1 0 1.224h-4.82c-1.76 0-3.263-1.442-3.327-3.201 0 0-.328-7.938.644-14.378.43-2.847 1.127-5.403 2.181-6.916 1.27-1.821 4.332-3.392 5.924-4.1h-.001ZM89.599 105.756h21.443c1.76 0 3.249-1.441 3.312-3.204 0-.001.27-7.892-.69-14.333-.424-2.843-1.1-5.406-2.111-6.944-1.507-2.302-5.667-4.32-5.667-4.32a.611.611 0 0 0-.529 1.103s3.806 1.798 5.174 3.89c.939 1.428 1.53 3.816 1.924 6.452.947 6.342.678 14.11.678 14.11-.04 1.113-.98 2.024-2.091 2.024H89.599a.612.612 0 0 0 0 1.224v-.002Z'
          clipRule='evenodd'
        />
        <path
          fill='#F3F4F6'
          d='m103.266 77.311 11.909 4.336c1.349.492 3.138-.064 3.971-1.234l5.012-7.024 5.222 3.921-6.583 12.088c-.689 1.26-2.423 2.189-3.853 2.06l-11.763-1.073-3.917-13.074h.002Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M115.397 81.076c1.108.397 2.57-.06 3.253-1.018l5.012-7.025a.61.61 0 0 1 .863-.133l5.223 3.921a.613.613 0 0 1 .17.783l-6.583 12.088c-.795 1.456-2.794 2.525-4.444 2.376a.612.612 0 0 1 .11-1.22c1.211.11 2.678-.675 3.261-1.742l6.329-11.623-4.298-3.228-4.648 6.514c-.981 1.379-3.088 2.033-4.679 1.454-.01-.004-.02-.007-.029-.012l-9.344-3.97a.612.612 0 0 1 .478-1.127l9.326 3.963Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          d='M239.59 19.159v17.345c0 3.817-3.12 6.939-6.93 6.939h-17.324c-3.813 0-6.93-3.122-6.93-6.939V19.16c0-3.816 3.118-6.938 6.93-6.938h17.324c3.811 0 6.93 3.122 6.93 6.938Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M209.017 28.49v-9.33c0-3.48 2.842-6.326 6.318-6.326h17.324c3.474 0 6.318 2.847 6.318 6.326a.612.612 0 0 0 1.223 0c0-4.153-3.394-7.55-7.541-7.55h-17.324c-4.148 0-7.541 3.396-7.541 7.55v9.33a.612.612 0 1 0 1.223 0Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          d='M253.448 22.628v10.408c0 3.817-2.634 5.255-5.839 3.208l-7.379-4.682a4.242 4.242 0 0 1-.641-.469v-6.52c.19-.175.415-.331.641-.47l7.379-4.682c3.205-2.048 5.839-.61 5.839 3.207Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m240.557 24.62 7.381-4.685c1.365-.871 2.597-1.068 3.5-.573.902.494 1.399 1.64 1.399 3.266v10.408c0 1.625-.497 2.77-1.399 3.266-.902.495-2.135.297-3.5-.574a.61.61 0 0 0-.844.188.615.615 0 0 0 .186.846c1.842 1.174 3.528 1.282 4.744.614 1.217-.667 2.035-2.147 2.035-4.34V22.63c0-2.193-.819-3.672-2.035-4.34-1.216-.668-2.903-.56-4.744.616l-7.378 4.682a.612.612 0 0 0 .655 1.034v-.002Z'
          clipRule='evenodd'
        />
        <path
          fill='#D1D5DB'
          d='M218.571 24.408c0-2.994 2.074-4.145 4.609-2.558l4.947 3.095c2.536 1.587 2.536 4.184 0 5.771l-4.947 3.098c-2.536 1.587-4.61.436-4.61-2.558v-6.848h.001Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M238.978 31.212v5.292c0 3.48-2.844 6.326-6.318 6.326h-17.324a6.308 6.308 0 0 1-4.804-2.229.61.61 0 1 0-.929.795 7.525 7.525 0 0 0 5.733 2.658h17.324c4.147 0 7.541-3.397 7.541-7.55v-5.292a.611.611 0 1 0-1.222 0h-.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#D1D5DB'
          d='m245.748 110.563-23.372 23.4-5.274-2.264-2.267-5.288 23.378-23.399 7.535 7.552v-.001Z'
        />
        <path
          fill='#F9FAFB'
          d='m245.807 110.62 2.262-2.265a2.143 2.143 0 0 0 0-3.02l-4.523-4.528a2.135 2.135 0 0 0-3.015 0l-2.262 2.264 7.538 7.549Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m231.536 125.774 16.965-16.986a2.758 2.758 0 0 0 0-3.886l-4.524-4.528a2.748 2.748 0 0 0-3.88 0l-25.6 25.631a.611.611 0 0 0 .864.865l25.6-25.632a1.526 1.526 0 0 1 2.151 0l4.523 4.529a1.528 1.528 0 0 1 0 2.154l-16.964 16.987a.611.611 0 0 0 .864.865l.001.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          fillRule='evenodd'
          d='m217.4 131.995 22.617-22.645a.422.422 0 1 0-.598-.599L216.8 131.397a.424.424 0 0 0 .599.599l.001-.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M243.076 136.199c-.574 1.211-1.623 2.59-3.04 2.79-.993.14-1.883-.156-2.708-.604-.918-.501-1.757-1.19-2.558-1.778-1.109-.815-2.104-1.113-3.02-1.088-.917.024-1.765.377-2.583.927-1.43.963-2.771 2.57-4.345 3.726-3.065 2.254-7.299 1.9-10.267-.185a.636.636 0 0 0-.729 1.04c3.397 2.387 8.242 2.748 11.748.17 1.559-1.145 2.885-2.741 4.302-3.696.607-.408 1.227-.692 1.908-.711.681-.018 1.41.236 2.234.841.845.621 1.735 1.342 2.703 1.869 1.061.578 2.214.927 3.493.747 1.83-.259 3.269-1.941 4.009-3.504a.635.635 0 1 0-1.147-.545v.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          d='m222.376 133.961-11.308 3.772 3.767-11.323 2.267 5.287 5.274 2.264Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m222.043 133.428 3.024-3.093a.611.611 0 1 1 .874.856l-3.127 3.198a.6.6 0 0 1-.244.152l-11.308 3.772a.613.613 0 0 1-.772-.775l3.766-11.323a.612.612 0 0 1 1.142-.047l1.195 2.788a.612.612 0 1 1-1.124.482l-.561-1.308-2.873 8.635 10.009-3.338-.001.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#E5E7EB'
          d='M61.998 99.701v49.687h-37.22a4.964 4.964 0 0 1-4.962-4.968V94.732c0 2.734 2.233 4.97 4.963 4.97h37.22Z'
        />
        <path
          fill='#D1D5DB'
          d='M61.998 89.764V99.7h-37.22a4.959 4.959 0 0 1-3.392-1.348 4.967 4.967 0 0 1-1.57-3.621c0-2.733 2.233-4.968 4.963-4.968H62h-.001Z'
        />
        <path
          fill='#F9FAFB'
          d='M24.778 97.5a2.756 2.756 0 0 1-1.89-.752 2.752 2.752 0 0 1-.874-2.016 2.77 2.77 0 0 1 2.764-2.767h37.22V97.5h-37.22Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M32.054 99.09h-7.276a4.348 4.348 0 0 1-2.976-1.184 4.354 4.354 0 0 1-1.376-3.174c0-1.247.578-2.473 1.49-3.273a.612.612 0 1 0-.807-.92 5.576 5.576 0 0 0-1.905 4.193c0 1.666.674 3.041 1.76 4.067a5.57 5.57 0 0 0 3.813 1.515h7.276a.612.612 0 0 0 0-1.224ZM61.387 90.377H33.915a.612.612 0 0 1 0-1.225h28.083c.338 0 .612.274.612.612v9.938a.611.611 0 0 1-.612.612h-6.695a.612.612 0 0 1 0-1.224h6.084v-8.713Z'
          clipRule='evenodd'
        />
        <path fill='#F9FAFB' d='M57.035 104.67H26.212v39.75h30.823v-39.75Z' />
        <path fill='#E5E7EB' d='M37.234 95.975v13.316l-4.987-1.665-4.988 1.665V95.975h9.975Z' />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M27.871 96.588v5.967a.612.612 0 0 1-1.223 0v-6.58c0-.338.274-.612.612-.612h5.53a.612.612 0 0 1 0 1.225h-4.919ZM36.623 108.442V95.975a.612.612 0 1 1 1.223 0v13.316a.612.612 0 0 1-.806.581l-4.793-1.599-4.795 1.599a.611.611 0 1 1-.386-1.161l4.988-1.665a.608.608 0 0 1 .386 0l4.183 1.396ZM61.387 99.702v19.514a.612.612 0 1 0 1.222 0V99.702a.612.612 0 1 0-1.222 0Z'
          clipRule='evenodd'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='M61.387 148.776v-13.187c0-.338.274-.613.611-.613.338 0 .612.275.612.613v13.799a.612.612 0 0 1-.612.613h-37.22a5.576 5.576 0 0 1-5.573-5.581V94.733a.612.612 0 1 1 1.222 0v49.687a4.352 4.352 0 0 0 4.352 4.356h36.608Z'
          clipRule='evenodd'
        />
        <path
          fill='#D1D5DB'
          d='m77.914 18.636 7.82 12.46s1.578 8.665-12.251 17.363c-13.828 8.7-20.939 3.517-20.939 3.517l-7.82-12.461 33.19-20.88Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m77.722 19.479-22.954 14.44a.61.61 0 1 1-.65-1.036l23.471-14.766a.607.607 0 0 1 .843.193l7.82 12.461c.041.066.07.14.084.217 0 0 1.74 9.016-12.528 17.99-14.265 8.975-21.623 3.494-21.623 3.494a.598.598 0 0 1-.157-.169l-3.633-5.788a.612.612 0 0 1 1.036-.652l3.562 5.677c.836.524 7.65 4.277 20.166-3.599 12.517-7.873 12.097-15.648 11.988-16.63L77.723 19.48l-.001-.001Z'
          clipRule='evenodd'
        />
        <path
          fill='#F9FAFB'
          d='M62.464 38.492c2.385-.375 5.462-2.309 6.834-4.298L88.61 6.229c1.372-1.99.545-3.308-1.841-2.933L53.225 8.582c-2.385.376-5.46 2.311-6.834 4.3L27.078 40.845c-1.372 1.99-.544 3.311 1.841 2.933l33.545-5.287Z'
        />
        <path
          fill='#99A1B7'
          fillRule='evenodd'
          d='m82.813 15.699 6.3-9.123c.876-1.27.93-2.348.503-3.027-.425-.678-1.42-1.097-2.943-.858L53.13 7.977c-2.527.398-5.784 2.45-7.24 4.555L26.574 40.498c-.876 1.27-.93 2.35-.503 3.028.426.679 1.42 1.1 2.942.858l33.544-5.287c2.528-.397 5.788-2.446 7.242-4.554a.611.611 0 1 0-1.006-.696c-1.29 1.87-4.183 3.688-6.426 4.04l-33.545 5.288c-.496.078-.911.077-1.237-.008-.217-.056-.386-.145-.478-.293-.093-.147-.1-.338-.057-.56.065-.33.244-.706.53-1.12l19.312-27.966c1.293-1.869 4.183-3.688 6.425-4.041L86.863 3.9c.497-.077.912-.076 1.238.009.218.056.387.145.48.293.093.147.1.337.055.559-.065.33-.244.706-.53 1.12l-6.3 9.123a.613.613 0 0 0 1.007.697V15.7Z'
          clipRule='evenodd'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h258v150H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmptyStateGraphic

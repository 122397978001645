import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Divider, Row, Space } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { isArray, isEmpty, pickBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { ClassesApi } from 'src/apis/classes'
import MinusSquared from 'src/common/CustomIcons/MinusSquared'
import ErrorMessage from 'src/common/ErrorMessage'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import RangePicker from 'src/components/base/rangeDateTime/RangePicker'
import FacilitySelect from 'src/components/base/select/common/FacilitySelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import AddClassroom from 'src/components/classes/add-classroom'
import AddCourse from 'src/components/classes/add-course/AddCourse'
import AddExam from 'src/components/classes/add-exam/AddExam'
import ListLecture from 'src/components/classes/create/ListLecture'
import ListMentor from 'src/components/classes/create/ListMentor'
import ListUser from 'src/components/classes/create/ListUser'
import { classSchema as editClassSchema } from 'src/components/classes/profile/setting/schema'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import { daysOfWeek, PageLink } from 'src/constants'
import {
  categoryCourseType,
  CONFIRM_CHANGE_COURE,
  CONSTRUCTION_MODE_OPTIONS,
  OPTIONS_OF_DURATION,
  OPTIONS_OF_STATUS,
  OPTIONS_OF_TYPE_CLASS,
  TITLE_OPTIONS_CLASS,
} from 'src/constants/classes'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { DayOfWeek, ExamSubject, IErrorFormClass, IRoom } from 'src/type'
import { CONSTRUCTION_MODE, IClass, IPostClass } from 'src/type/classes'
import { IClassType, ICourse } from 'src/type/courses'
import './createClass.styles.scss'
import { classSchema } from './schema'

const defaultValues = {
  name: '',
  code: '',
  status: '',
  facility_id: '',
  instruction_mode: '',
  type: '',
  description: '',
}

const WEEKDAYS: DayOfWeek[] = daysOfWeek.slice(0, 5).map((day) => day.value) // [ Monday to Friday ]
const WEEKENDS: DayOfWeek[] = daysOfWeek.slice(5).map((day) => day.value) // [ Saturday, Sunday ]

const WEEKDAY_HOURS = { min: 18, max: 22 } // Allowed time for weekdays (18:00 - 22:00)
const WEEKEND_HOURS = { min: 8, max: 22 } // Allowed time for weekends (08:00 - 22:00)

const CreateClass = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { confirm, contextHolder } = useConfirm()

  const [loading, setLoading] = useState<boolean>(false)
  const [selectedCourse, setSelectedCourse] = useState<ICourse | undefined>(undefined)
  const [modals, setModals] = useState({
    course: false,
    exam: false,
    classroom: false,
  })

  // Open a modal and close others
  const openModal = (type: 'course' | 'exam' | 'classroom') => {
    setModals({ course: false, exam: false, classroom: false, [type]: true })
  }

  // Close all modals
  const closeModal = () => {
    setModals({ course: false, exam: false, classroom: false })
  }

  const [typeClass, setTypeClass] = useState<string | undefined>(undefined)
  const [user, setUser] = useState<any>([])
  const listUserRef = useRef<any>(null)
  const [selectedExam, setSelectedExam] = useState<ExamSubject | undefined>()
  const [selectedClassroom, setSelectedClassroom] = useState<IRoom | undefined>()
  const [validateCourse, setValidateCourse] = useState<boolean>(false)
  const [classId, setClassId] = useState<string>('')
  const [classDetail, setClassDetail] = useState<IClass>()
  const [loadTeacher, setLoadTeacher] = useState<boolean>(false)

  const useFormProp = useForm<IPostClass>({
    resolver: zodResolver(classId ? editClassSchema : classSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useFormProp

  const { fields, remove, append, replace } = useFieldArray({
    control,
    name: 'class_standard_schedules',
  })

  const type = watch('type')
  const constructionMode = watch('instruction_mode')
  const startDate = watch('started_at')
  const durationType = watch('duration_type')

  const showCreateHybridClass =
    type === IClassType.LESSON &&
    (constructionMode === CONSTRUCTION_MODE.OFFLINE ||
      constructionMode === CONSTRUCTION_MODE.BLENDED)

  const onSubmit = async (data: IPostClass) => {
    setLoading(true)

    let request: IPostClass = {
      ...data,
      status: data.status || 'DRAFT',
    }

    try {
      if (classId) {
        Object.assign(request, { id: classId })
        if (
          classDetail?.course_id !== request.course_id &&
          classDetail?.class_lectures &&
          classDetail?.class_lectures?.length > 0
        ) {
          confirm({
            okButtonCaption: 'Yes',
            cancelButtonCaption: 'No',
            body: [CONFIRM_CHANGE_COURE],
            onClick: async () => {
              request.lectures = []
              const res = await ClassesApi.updateClass(request)
              if (res) {
                setClassDetail(res.data)
                toast.success('Class updated successfully!')
              }
              setLoadTeacher(true)
            },
            onClose: () => {
              return
            },
          })
        } else {
          const res = await ClassesApi.updateClass(request)
          if (res) {
            setClassDetail(res.data)
            toast.success('Class updated successfully!')
          }
        }
      } else {
        const res = await ClassesApi.createClass({
          data: {
            ...pickBy(request, (value) => (isArray(value) ? value.length > 0 : value)),
          },
        })
        if (res) {
          toast.success('Class saved successfully!')
          setClassId(res?.data?.id || '')
          setClassDetail(res.data)
        }
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IErrorFormClass) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
      // do nothing
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }

  const fetchClass = async () => {
    if (!classId) return
    const res = await ClassesApi.detail(classId)
    if (res) {
      setClassDetail(res.data)
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${PageLink.CLASSES + (location.search || '')}`),
    })
  }

  const handleChangeType = () => {
    setSelectedCourse(undefined)
  }

  const handleSave = () => {
    setValidateCourse(true)
    handleSubmit(
      (data: any) => {
        if (selectedCourse?.id || user?.length === 0 || getValues('type') === IClassType.TRIAL) {
          onSubmit(data)
        }
      },
      (errors) => {
        console.error('Validation Errors:', errors) // Log validation errors here
      }
    )()
  }

  useEffect(() => {
    setSelectedExam(undefined)

    selectedCourse && setValue('course_id', selectedCourse.id)
  }, [selectedCourse])

  useEffect(() => {
    selectedExam && setValue('examination_subject_id', selectedExam.id)
  }, [selectedExam])

  useEffect(() => {
    setValue('room_id', selectedClassroom?.id, { shouldDirty: true })
  }, [selectedClassroom])

  useEffect(() => {
    if (classId && listUserRef?.current) {
      listUserRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [classId])

  useEffect(() => {
    if (classId) {
      setValue('status', 'DRAFT')
    }
  }, [classId])

  useEffect(() => {
    if (constructionMode === CONSTRUCTION_MODE.ONLINE) {
      setValue('duration_type', 'FIXED') // ✅ Set default dynamically
    }
  }, [constructionMode, setValue])

  const selectedDays = watch('class_standard_schedules')

  return (
    <CreateEditLayout
      customPrimaryButton={
        <ButtonPrimary
          type='submit'
          title='Save'
          onClick={handleSave}
          loading={loading}
          size='small'
        />
      }
      onCancel={handleCancel}
      loading={loading}
      pageTitle={classId ? TITLE_OPTIONS_CLASS.updateClass : TITLE_OPTIONS_CLASS.createClass}
    >
      <div className='create-class'>
        {contextHolder}
        <div className='card-body border-0 d-block px-10 py-0'>
          <Row gutter={[24, 32]}>
            <Col span={24}>
              <HookFormTextField
                label={TITLE_OPTIONS_CLASS.name}
                required
                control={control}
                name='name'
                placeholder=' '
              />
            </Col>
            <Col span={classId ? 12 : 24}>
              <HookFormTextField
                label={TITLE_OPTIONS_CLASS.code}
                required
                control={control}
                name='code'
                placeholder=' '
              />
            </Col>
            {classId && (
              <Col span={12}>
                <HookFormSelectAntd
                  required
                  name='status'
                  control={control}
                  placeholder=' '
                  label={TITLE_OPTIONS_CLASS.status}
                  classNameHeight='sapp-h-45px'
                  options={OPTIONS_OF_STATUS}
                />
              </Col>
            )}
            <Col span={12}>
              <HookFormSelectAntd
                required
                name='instruction_mode'
                control={control}
                placeholder=' '
                classNameHeight='sapp-h-45px'
                label={TITLE_OPTIONS_CLASS.constructionMode}
                options={CONSTRUCTION_MODE_OPTIONS}
                onChange={(data) => {
                  if (data !== CONSTRUCTION_MODE.ONLINE) {
                    setValue('duration_type', 'FIXED')
                  }
                }}
              />
            </Col>
            <Col span={12}>
              <FacilitySelect
                control={control}
                name='facility_id'
                placeholder='Facility'
                label={TITLE_OPTIONS_AREA.facility}
                onChange={(e) => {
                  if (e === '' || e === undefined) {
                    setValue('facility_id', null, { shouldDirty: true })
                  }
                }}
                allowClear={constructionMode === CONSTRUCTION_MODE.ONLINE}
                required={constructionMode !== CONSTRUCTION_MODE.ONLINE}
              />
            </Col>
            <Col span={12}>
              <HookFormSelectAntd
                required
                name='type'
                control={control}
                placeholder=' '
                onChange={() => {
                  handleChangeType()
                }}
                label={TITLE_OPTIONS_CLASS.type}
                classNameHeight='sapp-h-45px'
                options={OPTIONS_OF_TYPE_CLASS}
              />
            </Col>
            <Col span={12}>
              <HookFormTextField
                label={TITLE_OPTIONS_CLASS.maximumStudents}
                required
                control={control}
                name='capacity'
                onChange={(e) => setValue('capacity', Number(e.target.value))}
                placeholder=' '
                type='number'
                minNumber={1}
                requiredZero={true}
              />
            </Col>
            {constructionMode === CONSTRUCTION_MODE.ONLINE && (
              <Col span={24}>
                <Space size={'large'} align='center'>
                  <SappLabel required label={TITLE_OPTIONS_CLASS.duration} className='mb-0' />
                  <HookFormRadioGroup
                    direction='horizontal'
                    separator={false}
                    name='duration_type'
                    control={control}
                    justify='start'
                    gap={24}
                    labelClass='fw-semibold sapp-table-class-field'
                    defaultValue={`${OPTIONS_OF_DURATION.valueFixed}`}
                    options={[
                      {
                        label: `${OPTIONS_OF_DURATION.labelFixed}`,
                        value: `${OPTIONS_OF_DURATION.valueFixed}`,
                      },
                      {
                        label: `${OPTIONS_OF_DURATION.labelFlexible}`,
                        value: `${OPTIONS_OF_DURATION.valueFlexible}`,
                      },
                    ]}
                  />
                </Space>
              </Col>
            )}

            {showCreateHybridClass && (
              <Col span={24}>
                <Space size={'small'} align='center'>
                  <HookFormCheckBox
                    name='create_hybrid_class'
                    id='create_hybrid_class'
                    control={control}
                    defaultValue={false}
                  />
                  <SappLabel
                    label={TITLE_OPTIONS_CLASS.createHybridClass}
                    className='mb-0'
                    htmlFor='create_hybrid_class'
                  />
                </Space>
              </Col>
            )}
            {constructionMode === CONSTRUCTION_MODE.ONLINE &&
              durationType === `${OPTIONS_OF_DURATION.valueFixed}` && (
                <>
                  <Col span={12}>
                    <HookFormDateTime control={control} name='opening_at' label='Opening Date' />
                  </Col>
                  <Col md={12}>
                    <RangePicker
                      control={control}
                      names={['started_at', 'finished_at']}
                      allowClear={false}
                      label='Start & End Date'
                      formatFunction={(date) => date?.toDate()}
                      required
                    />
                    {(errors.started_at || errors.finished_at) && (
                      <small className='text-danger mt-2 lh-1'>
                        {errors?.started_at?.message ?? errors?.finished_at?.message ?? ''}
                      </small>
                    )}
                  </Col>
                </>
              )}
            {constructionMode === CONSTRUCTION_MODE.ONLINE &&
              durationType === `${OPTIONS_OF_DURATION.valueFlexible}` && (
                <Col span={24}>
                  <div className='position-relative'>
                    <HookFormTextField
                      label='Number of Days'
                      required
                      control={control}
                      onChange={(e) => setValue('flexible_days', Number(e.target.value))}
                      name='flexible_days'
                      placeholder=' '
                      type='number'
                      postFix={<div className='px-2'>days</div>}
                      minNumber={1}
                      maxNumber={100000}
                      requiredZero={true}
                    />
                  </div>
                </Col>
              )}

            {constructionMode && constructionMode !== CONSTRUCTION_MODE.ONLINE && (
              <>
                <Col span={24}>
                  <Space direction='vertical' className={clsx('w-100')}>
                    <SappLabel label='Learning Schedules' required />
                    <HookFormDateTime
                      label='Start Date'
                      control={control}
                      name='started_at'
                      placeholder='01/01/2025'
                      required
                      format='DD/MM/YYYY'
                      disabledDate={(current) => {
                        return current && current < dayjs().endOf('day')
                      }}
                      onChange={(data) => {
                        if (data !== null) {
                          const dayOfWeekLabel = dayjs(data).format('dddd')
                          const matchedDay = daysOfWeek.find((day) => day.label === dayOfWeekLabel)

                          if (matchedDay) {
                            replace({
                              day_of_week: matchedDay.value, // Ensure type safety
                              start_time: null,
                              end_time: null,
                            })
                          }
                        } else {
                          remove()
                        }
                      }}
                    />
                  </Space>
                </Col>
                {fields.map((field, index) => {
                  // Get already selected days (excluding the current field)
                  const takenDays =
                    selectedDays
                      ?.map((item, i) => (i !== index ? item.day_of_week : null))
                      .filter(Boolean) ?? []

                  // Filter days to exclude already selected ones
                  const availableDays = daysOfWeek.filter((day) => !takenDays.includes(day.value))
                  return (
                    <Col span={24} key={field.id}>
                      <Row gutter={[24, 24]}>
                        {/* 1st Col - Subject */}
                        <Col span={10}>
                          <HookFormSelectAntd
                            required
                            name={`class_standard_schedules.${index}.day_of_week`}
                            control={control}
                            options={availableDays}
                            defaultValue={field.day_of_week}
                            label='Day Of Week'
                            disabled={index === 0}
                          />
                        </Col>
                        <Col span={14}>
                          <RangePicker
                            required
                            control={control}
                            label='Start Time - End Time'
                            picker='time'
                            format='HH:mm'
                            placeholder={['From', 'To']}
                            names={[
                              `class_standard_schedules.${index}.start_time`,
                              `class_standard_schedules.${index}.end_time`,
                            ]}
                            formatFunction={(date) => date?.format('HH:mm')}
                            disabledTime={(_, type) => {
                              const selectedDay = getValues(
                                `class_standard_schedules.${index}.day_of_week`
                              )

                              if (selectedDay === null || selectedDay === undefined) return {} // No restrictions if no day is selected

                              const isWeekday = WEEKDAYS.includes(selectedDay)
                              const isWeekend = WEEKENDS.includes(selectedDay)

                              const { min: minHour, max: maxHour } = isWeekday
                                ? WEEKDAY_HOURS
                                : isWeekend
                                ? WEEKEND_HOURS
                                : { min: 0, max: 23 } // Default to full-day if invalid

                              if (type === 'start') {
                                return {
                                  disabledHours: () => [
                                    ...Array(minHour).keys(), // Disable hours before minHour
                                    ...Array.from(
                                      { length: 24 - maxHour - 1 },
                                      (_, i) => i + maxHour + 1
                                    ), // Disable hours after maxHour
                                  ],
                                }
                              }

                              return {
                                disabledHours: () => [
                                  ...Array(minHour).keys(),
                                  ...Array.from(
                                    { length: 24 - maxHour - 1 },
                                    (_, i) => i + maxHour + 1
                                  ),
                                ],
                                disabledMinutes: (selectedHour) => {
                                  if (selectedHour === maxHour) {
                                    return Array.from({ length: 59 }, (_, i) => i + 1) // Only allow 00 minutes at 22:00
                                  }
                                  return []
                                },
                              }
                            }}
                          />
                          {errors.class_standard_schedules?.[index]?.end_time?.message && (
                            <small className='text-danger mt-2 lh-1'>
                              {errors.class_standard_schedules?.[index]?.end_time?.message ?? ''}
                            </small>
                          )}
                        </Col>
                        {fields.length > 1 && index >= 1 && (
                          <Col span={24}>
                            <Space
                              className='text-gray-1000 cursor-pointer btn-delete-schedule'
                              onClick={() => remove(index)}
                            >
                              <MinusSquared />
                              <span>Remove This Schedule</span>
                            </Space>
                          </Col>
                        )}
                        {index !== 2 && (
                          <Divider
                            variant='dashed'
                            style={{ borderColor: '#DBDFE9' }}
                            className='m-0'
                          />
                        )}
                      </Row>
                    </Col>
                  )
                })}

                {fields.length >= 1 && startDate && fields.length < 3 && (
                  <Col span={24}>
                    <div
                      className='d-inline-flex align-items-center text-primary cursor-pointer btn-add-schedule'
                      style={{ gap: '8px' }}
                      onClick={() =>
                        append({
                          day_of_week: null,
                          start_time: null,
                          end_time: null,
                        })
                      }
                    >
                      <KTIcon iconName='plus-square' iconType='outline' sizeIcon='fs-1' />
                      <span>Add Schedule</span>
                    </div>
                  </Col>
                )}
              </>
            )}

            {/* start:: course*/}
            {!isEmpty(getValues('type')) && (
              <Col span={24}>
                <SappLabel label={TITLE_OPTIONS_CLASS.course} required />

                <div className='position-relative'>
                  <ButtonIcon
                    title={`${selectedCourse?.id !== undefined ? ' ' : 'Add Course'}`}
                    className='d-flex justify-content-start align-items-center w-100'
                    customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0 position-relative'
                    type='button'
                    onClick={() => {
                      openModal('course')
                      setTypeClass(getValues('type'))
                    }}
                  >
                    {selectedCourse?.id !== undefined ? (
                      <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                        {selectedCourse?.name}
                      </div>
                    ) : (
                      <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                    )}
                    <KTIcon
                      iconName='notepad-edit'
                      className={`fs-1 text-gray-500 ps-5 pe-1 sapp-icon-last ${
                        selectedCourse?.id !== undefined ? 'first' : ''
                      }`}
                      iconType='outline'
                    />
                  </ButtonIcon>
                  {selectedCourse?.id !== undefined && (
                    <div className='sapp-icon-last'>
                      <ButtonIconOnly
                        iconName='trash'
                        onClick={handleChangeType}
                        iconType='outline'
                        bg={'sapp-custom-bg-transparent'}
                        activeColor='danger'
                        iconGrayColor={'500'}
                      />
                    </div>
                  )}
                </div>
                {errors.course_id && constructionMode !== CONSTRUCTION_MODE.ONLINE && (
                  <small className='text-danger mt-2 lh-1'>{errors.course_id?.message ?? ''}</small>
                )}
                <>
                  {selectedCourse?.id === undefined &&
                    validateCourse &&
                    getValues('type') !== IClassType.TRIAL &&
                    user?.length > 0 && (
                      <ErrorMessage>Course must contain at least 1 element</ErrorMessage>
                    )}
                </>
              </Col>
            )}
            {/* end:: course*/}
            {selectedCourse?.id !== undefined &&
              categoryCourseType.some((e: any) =>
                e.includes(selectedCourse?.course_categories[0]?.name)
              ) && (
                <>
                  {/* start:: exam*/}
                  <Col span={24}>
                    <SappLabel
                      label={'Exam'}
                      required={getValues('type') !== IClassType.TRIAL && user?.length > 0}
                    />
                    <div className='position-relative'>
                      <ButtonIcon
                        title={`${selectedExam?.id !== undefined ? ' ' : 'Exam'}`}
                        className='d-flex justify-content-start align-items-center w-100'
                        customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0 position-relative'
                        type='button'
                        onClick={() => {
                          openModal('exam')
                        }}
                      >
                        {selectedExam?.id !== undefined ? (
                          <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                            {selectedExam?.examination.name ?? '-'}
                          </div>
                        ) : (
                          <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                        )}
                        <div
                          className={`sapp-icon-last ${
                            selectedExam?.id !== undefined ? 'first' : ''
                          }`}
                        >
                          <KTIcon
                            iconName='notepad-edit'
                            className='fs-1 text-gray-500 ps-5 pe-1'
                            iconType='outline'
                          />
                        </div>
                      </ButtonIcon>
                      {selectedExam?.id !== undefined && (
                        <div className='sapp-icon-last'>
                          <ButtonIconOnly
                            iconName='trash'
                            onClick={() => {
                              setSelectedExam(undefined)
                            }}
                            iconType='outline'
                            bg={'sapp-custom-bg-transparent'}
                            activeColor='danger'
                            iconGrayColor={'500'}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* end:: exam*/}
                </>
              )}
            {classId && classDetail?.instruction_mode !== CONSTRUCTION_MODE.ONLINE && (
              <Col span={24}>
                <SappLabel label={'Classroom'} required />
                <div className='position-relative'>
                  <ButtonIcon
                    title={`${!selectedClassroom?.id ? 'Add Classroom' : ''}`}
                    className={clsx(
                      'sapp-h-45px d-flex justify-content-start align-items-center opacity-100 w-100'
                    )}
                    customButton={`btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-font-medium text-gray-500 position-relative  p-0 ${
                      selectedClassroom?.id !== undefined ? 'position-relative' : ''
                    }`}
                    type='button'
                    onClick={() => {
                      if (getValues('type') !== '') {
                        openModal('classroom')
                        setTypeClass(getValues('type'))
                      }
                    }}
                  >
                    {selectedClassroom?.id !== undefined ? (
                      <div className='px-4 sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4'>
                        {selectedClassroom?.name}
                      </div>
                    ) : (
                      <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                    )}
                  </ButtonIcon>
                  {selectedClassroom?.id !== undefined && (
                    <div
                      className='sapp-icon-last'
                      style={{
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <ButtonIconOnly
                        iconName='trash'
                        onClick={() => {
                          setSelectedClassroom(undefined)
                        }}
                        iconType='outline'
                        activeColor='danger'
                        iconGrayColor={'500'}
                      />
                    </div>
                  )}
                </div>
                {errors.room_id && (
                  <small className='text-danger mt-2 lh-1'>{errors.room_id?.message ?? ''}</small>
                )}
              </Col>
            )}
            {/*Link Meeting*/}
            {classId &&
              [CONSTRUCTION_MODE.HYBRID, CONSTRUCTION_MODE.LIVE_ONLINE].includes(
                constructionMode ?? ''
              ) && (
                <Col span={24}>
                  <HookFormTextField
                    required
                    label={'Meeting Link'}
                    control={control}
                    type='url'
                    name='link_meeting'
                  />
                </Col>
              )}

            <Col span={24}>
              <SappLabel label={TITLE_OPTIONS_CLASS.describe} />
              <HookFormEditor
                height={300}
                placeholder=' '
                name='description'
                control={control}
                math={true}
                className='w-100 fs-6'
                resourceLocation={RESOURCE_LOCATION.COURSE}
                object_id={undefined}
              />
            </Col>
          </Row>
        </div>
        <FormProvider {...useFormProp}>
          <AddCourse
            open={modals.course}
            closeModal={closeModal}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            typeClass={typeClass}
          />
          <AddExam
            open={modals.exam}
            closeModal={closeModal}
            id={selectedCourse?.id}
            course_id={classDetail?.course_id as string}
            course_category_id={selectedCourse?.course_categories?.[0]?.id}
            course_category_type={selectedCourse?.course_categories?.[0]?.name}
            selectedExam={selectedExam}
            setSelectedExam={setSelectedExam}
          />
          {classDetail?.instruction_mode !== CONSTRUCTION_MODE.ONLINE && (
            <AddClassroom
              open={modals.classroom}
              closeModal={closeModal}
              selectedClassroom={selectedClassroom}
              setSelectedClassroom={setSelectedClassroom}
            />
          )}
        </FormProvider>
        {/* List metor sẽ xuất hiện sau khi thêm class thành công*/}
        {classId && (
          <div className='card mt-2 mt-xl-8 mt-10' ref={listUserRef}>
            <ListMentor title='Danh sách trợ giảng' classId={classId} />
          </div>
        )}
        {/* List lecture sẽ xuất hiện sau khi thêm class thành công*/}
        {classId && classDetail?.course_id && (
          <div className='card mt-2 mt-xl-8' ref={listUserRef}>
            <ListLecture
              title='Danh sách giảng viên'
              classId={classId}
              fetchClass={fetchClass}
              loadTeacher={loadTeacher}
            />
          </div>
        )}
        {/* List user sẽ xuất hiện sau khi thêm class thành công*/}
        {classId && (
          <div className='card mt-2 mt-xl-8' ref={listUserRef}>
            <ListUser
              title='Danh sách học viên'
              getValues={getValues}
              classId={classId}
              setUserStudent={setUser}
            />
          </div>
        )}
      </div>
    </CreateEditLayout>
  )
}

export default CreateClass

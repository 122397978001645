import { Avatar, Space, TablePaginationConfig, TableProps } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import Text from 'src/components/base/text'
import SappTable from 'src/components/ui/table/SappTable'
import { PageLink } from 'src/constants'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { useLessonListContext } from 'src/context/LessonContext'
import { VersionHistoryItem } from 'src/type/courses/versionHistory'

const fetchVersionHistory = (id: string, pageIndex: number, pageSize: number) => {
  return LessonApi.getVersionHistory({
    page_index: pageIndex,
    page_size: pageSize,
    course_id: id,
  })
}

const VersionHistory = () => {
  const { id } = useParams()
  const { setVersion } = useLessonListContext()
  const navigate = useNavigate()
  const DEFAULT_PAGE_SIZE = 10
  const DEFAULT_CURRENT_PAGE = 1
  const queryClient = useQueryClient()

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: DEFAULT_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const { mutate: restoreVersion } = useMutation({
    mutationFn: async (version: string) => {
      if (!id) {
        return Promise.reject('No id provided')
      }
      return await LessonApi.restoreLessonVersion(id, version)
    },
    onSuccess: ({ success, error }) => {
      if (success === false) {
        toast.error(error?.message)
        return
      }

      queryClient.invalidateQueries(lessonKeys.versionHistoryBase())
      toast.success('Version restore Successfully!')
    },
  })

  const { data, isSuccess, isLoading, isFetching } = useQuery({
    queryKey: lessonKeys.versionHistory(id!, {
      page_index: pagination.current!,
      page_size: pagination.pageSize!,
    }),
    queryFn: () => fetchVersionHistory(id!, pagination.current!, pagination.pageSize!),
    enabled: !!id,
    keepPreviousData: true,
    staleTime: 5000,
    onSuccess: () => {
      setPagination((prev) => ({
        ...prev,
      }))
    },
  })

  const columns: TableProps<VersionHistoryItem>['columns'] = [
    {
      title: 'Version',
      dataIndex: 'version',
      render: (value: VersionHistoryItem['version'], record) => (
        <Text
          className='text-info cursor-pointer'
          onClick={() => {
            setVersion(value)
            navigate(
              `${PageLink.COURSES}/${id}/${PageLink.LESSONS}/${PageLink.VERSION_HISTORY}/${value}`
            )
          }}
        >
          v.{value} {record.is_active && '(Current)'}
        </Text>
      ),
    },
    {
      title: 'Last updated',
      dataIndex: 'updated_at',
      render: (value: VersionHistoryItem['updated_at']) =>
        value && `${dayjs(value).format('DD/MM/YYYY HH:ss')}`,
    },
    {
      title: 'Changed by',
      dataIndex: 'staff',
      render: (value: VersionHistoryItem['staff']) => (
        <Space direction='horizontal'>
          <Avatar src={value.detail.avatar.ORIGIN} />
          <Text fallback=''>{value.detail.full_name}</Text>
        </Space>
      ),
    },
    {
      title: '',
      dataIndex: 'version',
      render: (value: VersionHistoryItem['version'], record) =>
        !record.is_active && (
          <Text className='text-info cursor-pointer' onClick={() => restoreVersion(value)}>
            Restore
          </Text>
        ),
    },
  ]

  const handleChangeParams = (currentPage: number, size: number) => {
    const queryParam = {
      page_index: currentPage,
      page_size: size,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  return isSuccess ? (
    <SappTable<VersionHistoryItem>
      columns={columns}
      fetchData={() => fetchVersionHistory(id!, pagination.current!, pagination.pageSize!)}
      data={data.data.data}
      pagination={{
        ...pagination,
        total: data?.data.metadata.total_records,
        showSizeChanger: true,
      }}
      setPagination={setPagination}
      fetchTableData={() => {}}
      loading={isLoading || isFetching}
      handleChangeParams={handleChangeParams}
    />
  ) : (
    <div />
  )
}

export default VersionHistory

import { Dispatch, SetStateAction, useEffect } from 'react'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import SappTable from 'src/components/base/SappTable'
import { KTCardBody } from 'src/_metronic/helpers'
import SappTooltip from 'src/common/SappTooltip'
import { IProductCategoryList } from 'src/type/shop/product'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  htmlToRaw,
} from 'src/utils'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { useNavigate } from 'react-router-dom'
import ShopActionBox from '../shared/ShopActionBox'
import { INewOrUpdatePopup } from 'src/type/shop/common'
import { ProductAPI } from 'src/apis/short-course/product'
import toast from 'react-hot-toast'

// define headers
const headers = [
  {
    label: 'Title',
    className: 'min-w-175px',
  },
  {
    label: 'Parent',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Descriptions',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Slug',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Count',
    className: 'min-w-75px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

interface IQueryPrams {
  name: string | null
  sort_type: string | null
  fromDate: Date | string
  toDate: Date | string
}

interface IProps {
  categories: IProductCategoryList | undefined
  setCategories: Dispatch<SetStateAction<any>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  loadData: (page_index: number, page_size: number, object?: Object) => void
  queryParam: IQueryPrams
  getParams: any
  reloadPage: () => void
  setOpenNewOrUpdate: Dispatch<SetStateAction<INewOrUpdatePopup>>
}

const TableProductCategory = ({
  categories,
  setCategories,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  loadData,
  queryParam,
  getParams,
  reloadPage,
  setOpenNewOrUpdate,
}: IProps) => {
  const navigate = useNavigate()

  const showQueryParams =
    queryParam.name || queryParam.sort_type || queryParam.fromDate || queryParam.toDate

  /**
   * Một hàm dùng để change params trên router
   */
  const filterParamsPagination = () => {
    const dateQueryFromDate =
      queryParam?.fromDate instanceof Date ? getDateInfo(queryParam.fromDate) : null
    const dateQueryToDate =
      queryParam?.toDate instanceof Date ? getDateInfo(queryParam.toDate) : null
    return cleanParamsAPI(
      getParams(
        queryParam.name ?? '',
        queryParam.sort_type ?? '',
        queryParam.fromDate && dateQueryFromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        queryParam.toDate && dateQueryToDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showQueryParams ? 'updated_at' : 'created_at'
      )
    )
  }

  /**
   * Call API get Categories
   */
  useEffect(() => {
    setLoading(true)
    loadData(1, 10, cleanedParams)
  }, [])

  /**
   * Một hàm dùng để đổi params trên router
   * @param {number} currentPage  - trang page hiện tại
   * @param {number} size  - lượng bản ghi của page
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      name: queryParam.name ?? '',
      sort_type: queryParam.sort_type ?? '',
      fromDate: queryParam.fromDate instanceof Date ? formatDate(queryParam.fromDate) : '',
      toDate: queryParam.toDate instanceof Date ? formatDate(queryParam.toDate) : '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  /**
   * Call API khi change pagination
   * @param {number} page_index  - trang page hiện tại
   * @param {number} page_size  - lượng bản ghi của page
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    loadData(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  /**
   * handle open Update popup
   * @param {string} id  - id của productCategory muốn chỉnh sửa
   */
  const handleOpenUpdate = async (id: string) => {
    try {
      const res = await ProductAPI.getProdcutCategory(id)
      setOpenNewOrUpdate({ type: 'edit', data: res.data, isOpen: true })
    } catch (error) {
      // do nothing
    }
  }

  /**
   * handle to delete productCategory
   * @param {string} id  - id của productCategory muốn xoá
   */
  const deleteProductCategory = async (id: string) => {
    try {
      await ProductAPI.deleteProductCategory(id)
      toast.success('Categories deleted successfully!')
      reloadPage()
    } catch (error: any) {
      // do nothing
    }
  }

  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        data={categories?.productCategories}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {categories?.productCategories?.map((category, index) => {
          const isChecked = checkedList && checkedList.includes(category.id)
          return (
            <tr key={`product-category-${category.id}`}>
              <td>
                <SAPPCheckbox
                  checkTarget='#kt_table_users .form-check-input'
                  checked={isChecked}
                  onChange={() => {
                    toggleCheck(category.id)
                  }}
                />
              </td>
              <td className='text-start' onClick={() => handleOpenUpdate(category.id)}>
                <SappTooltip title={category?.name} link='' max_length={80} />
              </td>
              <td className='text-start'>{category?.parent?.name}</td>
              <td className='text-start mw-500px'>
                <div className='mw-500px sapp-text-truncate-2'>
                  {' '}
                  {category?.describe ? htmlToRaw(category?.describe) : ''}
                </div>
              </td>
              <td className='text-start'>{category?.slug}</td>
              <td className='text-start'>{category?.product_count}</td>
              <td className='text-start'>
                <ShopActionBox
                  id={category.id}
                  setLoading={setLoading}
                  reloadPage={reloadPage}
                  onDelete={deleteProductCategory}
                  onEdit={handleOpenUpdate}
                />
              </td>
            </tr>
          )
        })}
      </SappTable>
      <PagiantionSAPP
        currentPage={categories?.metadata?.page_index || 1}
        pageSize={categories?.metadata?.page_size || 10}
        totalItems={categories?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableProductCategory

import { useEffect, useState } from 'react'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { UseCheckedResult } from 'src/hooks/use-checked'
import { htmlToRaw } from 'src/utils'
import { IQuestion } from '../shared/interfaces'
import { listType } from '../shared/providers/QuestionProvider'
import QuestionActionsCell from './QuestionActionsCell'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_QUESTIONS_GR, TITLE_TOPIC_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { EXHIBIT_TEXT } from 'src/constants'

type Props = {
  handleOpenFormEdit: any
  handleDeleteQuestion: any
  handleOpenPreview: any
  questionState: {
    listQuestion: (IQuestion & {
      number_of_requirements?: number
      number_of_exhibits?: number
    })[]
    pageIndex: number
    pageSize: number
    totalRecords: number
    loading: boolean
  }
  title: string
  listType: listType
  topicId?: string
  getListQuestions: (
    type: listType,
    page_index?: number | undefined,
    page_size?: number | undefined,
    topic_id?: string | undefined
  ) => void
  useChecked: UseCheckedResult<IQuestion>
}

const TableListQuestion = ({
  handleOpenFormEdit,
  handleOpenPreview,
  handleDeleteQuestion,
  questionState,
  title,
  listType,
  topicId,
  getListQuestions,
  useChecked,
}: Props) => {
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked
  const [headers, setHeaders] =
    useState<{ key?: string | undefined; label: string; className?: string }[]>()
  const { profileMe } = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderPreview = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_QUESTIONS_GR.VIEW_QUESTION) ||
      role.permissions?.includes(TITLE_QUESTIONS_GR.EDIT_QUESTION) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDelete = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_QUESTIONS_GR.REMOVE_QUESTION) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowAction = allowRenderEdit || allowRenderPreview || allowRenderDelete
  useEffect(() => {
    let partOfHeader: { key: string; label: string; className?: string }[] = []
    if (listType === 'constructed') {
      partOfHeader = [
        {
          label: 'Requirement',
          key: 'requirement',
          className: 'w-100px fs-7 lh-1 fw-bold text-center',
        },
        {
          label: EXHIBIT_TEXT,
          key: 'exhibits',
          className: 'w-100px fs-7 lh-1 fw-bold text-center',
        },
      ]
    }
    if (listType === 'multiple') {
      partOfHeader = [
        {
          label: 'Type',
          key: 'type_of_question',
          className: 'w-200px fs-7 lh-1 fw-bold text-nowrap',
        },
      ]
    }
    const newHeaders = [
      {
        label: 'List Questions',
        key: 'question_content',
        className: 'sapp-flex-1 fs-7 lh-1 fw-bold min-w-250px',
      },
      ...partOfHeader,
      {
        label: 'Level',
        key: 'level',
        className: 'w-150px fs-7 lh-1 fw-bold',
      },
      {
        label: 'Layout',
        key: 'layout',
        className: 'w-150px fs-7 lh-1 fw-bold',
      },
      {
        label: 'Action',
        key: 'action',
        className: 'w-35px fs-7 lh-1 fw-bold',
      },
    ]
    setHeaders(newHeaders)
  }, [])

  return (
    <>
      <div className={`fw-bold fs-6 mt-8`}>
        {title} ({questionState?.totalRecords ?? 0})
      </div>
      <div className='card card-flush border-gray-300 h-xl-100 mt-3 sapp-shadow-none'>
        <div className='container'>
          <div className='row p-5'>
            <div className='table-responsive'>
              <SappTable
                headers={headers}
                loading={questionState.loading}
                data={questionState.listQuestion}
                isCheckedAll={isCheckedAll}
                onChange={() => {
                  toggleCheckAll(!isCheckedAll, true)
                }}
                hasCheck={true}
                showHashtag={false}
                classTable={'table align-middle table-row-dashed fs-6 gy-4 dataTable no-footer'}
                classString={'border-0'}
              >
                {questionState.loading ? (
                  <>
                    {headers?.map((header) => (
                      <LoadingTable key={header.key} headers={headers.map((e) => e.key)} />
                    ))}
                  </>
                ) : (
                  questionState.listQuestion?.map((question, val) => {
                    let isChecked = false
                    if (question.id) {
                      isChecked = checkedList.includes(question.id)
                    }

                    return (
                      <tr className='border-0' key={question.id}>
                        <td className='fs-6 lh-1 fw-semibold'>
                          <SAPPCheckbox
                            checked={isChecked}
                            onChange={() => {
                              question.id && toggleCheck(question.id)
                            }}
                          />
                        </td>
                        <td>
                          <div
                            onClick={() => handleOpenFormEdit(question.id, question.qType)}
                            className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                          >
                            {htmlToRaw(question.question_content)}
                          </div>
                        </td>
                        {listType === 'multiple' ? (
                          <>
                            <td className='text-capitalize'>
                              {question?.qType?.replaceAll('_', ' ')?.toLowerCase()}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className='text-center'>{question.number_of_requirements}</td>
                            <td className='text-center'>{question.number_of_exhibits}</td>
                          </>
                        )}
                        <td className='text-capitalize'>{question.level.toLowerCase()}</td>
                        <td className='text-capitalize'>
                          {question.display_type.toLowerCase() === 'horizontal'
                            ? '1 Col'
                            : '2 Cols'}
                        </td>
                        {allowAction && (
                          <td className='text-end w-35px'>
                            <QuestionActionsCell
                              handleOpenFormEdit={() =>
                                handleOpenFormEdit(question.id, question.qType)
                              }
                              handleDeleteQuestion={handleDeleteQuestion}
                              handleOpenPreview={handleOpenPreview}
                              id={question.id}
                              qType={question.qType}
                            />
                          </td>
                        )}
                      </tr>
                    )
                  })
                )}
              </SappTable>
            </div>

            <PagiantionSAPP
              currentPage={questionState.pageIndex}
              handlePaginationChange={(page: number, pageSize: number) => {
                getListQuestions(listType, page, pageSize, topicId)
              }}
              pageSize={questionState.pageSize}
              totalItems={questionState.totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TableListQuestion

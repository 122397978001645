import { debounce } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { DepartmentAPI } from 'src/apis/department'
import { departmentKeys } from 'src/constants'
import { IDepartmentRes, IResponse } from 'src/type'

const useInfiniteDepartments = (enabled?: boolean) => {
  const PAGE_SIZE = 10
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e: string) => {
    setSearchText(e)
  }, 350)

  const fetchDepartments = async (
    page_index: number,
    page_size: number,
    name?: string
  ): Promise<IResponse<IDepartmentRes>> => {
    try {
      const res = await DepartmentAPI.getList({
        page_index: page_index,
        page_size: page_size,
        params: {
          name: name,
        },
      })
      return res
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery<IResponse<IDepartmentRes>>({
      queryKey: departmentKeys.all(searchText), // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchDepartments(pageParam, PAGE_SIZE, searchText || undefined)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.success) {
          return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
            ? lastPage.data.metadata.page_index + 1
            : false
        }
      },
      enabled: !!searchText && enabled,
      refetchOnWindowFocus: false,
    })

  return {
    departments: data?.pages.flatMap((page) => page?.data?.data), // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
    debounceSearch,
  }
}

export default useInfiniteDepartments

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CoursesAPI } from 'src/apis/courses'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { DEBOUNCED_NUMBER, TYPE_COURSE, VALIDATION_FIELD } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { ICoursePart, IKeySection } from 'src/type/courses'
import { changeNodeAtPath } from '../tree-course'
import './CreateSection.scss'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import SappDrawer from 'src/components/base/SappDrawer'
import { useConfirm } from 'src/hooks/use-confirm'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import { IErrorCourse } from 'src/type'

import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import { useQuery } from 'react-query'
import { debounce } from 'lodash'
interface Iprops {
  open: boolean
  setOpen: any
  data?: any
  type?: string
  id?: string
  path?: any
  treeData?: Object[]
  treeIndex?: any
  setData?: any
  positionPart?: number
  positionSubsection?: number
}

interface IOption {
  label: string
  value: string
  current?: boolean
}

interface IFilter {
  name?: string
  page_index: number
}

const CreateSection = ({
  open,
  setOpen,
  data,
  type,
  id,
  path,
  treeData,
  setData,
  positionPart,
  positionSubsection,
}: Iprops) => {
  //   const {id} = useParams()
  const { getCourseDetail, idCourse, setIdChild } = useCourseContext()
  const [loading, setloading] = useState(false)
  const [defaultEditor, setDefaultEditor] = useState<any>()
  // const {control, setValue, getValues, reset, handleSubmit} = useForm()
  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    code: z.string().trim().optional(),
    description: z.any().optional(),
    short_name: z.any().optional(),
    key_section_before: z.array(z.string()).optional(),
    key_section_after: z.array(z.string()).optional(),
  })
  const { confirm, contextHolder } = useConfirm()

  /**
   * @description state này để lưu filter gồm name và page_index của key content before và after
   */
  const [filterKeySectionBefore, setFilterKeySectionBefore] = useState<IFilter>({
    name: '',
    page_index: 1,
  })
  const [filterKeySectionAfter, setFilterKeySectionAfter] = useState<IFilter>({
    name: '',
    page_index: 1,
  })

  /**
   * @description state này để lưu options của key content before và after
   */
  const [keySectionBeforeOptions, setKeyContentBeforeOptions] = useState<IOption[]>([])
  const [keySectionAfterOptions, setKeyContentAfterOptions] = useState<IOption[]>([])

  /**
   * @description Handles pagination for key content after.
   */
  const handleNextPageBefore = () => {
    if (dataKeyContentBefore?.metadata?.total_records > keySectionBeforeOptions?.length) {
      setFilterKeySectionBefore((prev) => ({
        ...prev,
        page_index: (prev.page_index || 1) + 1,
      }))
    }
  }

  const handleNextPageAfter = () => {
    if (dataKeyContentAfter?.metadata?.total_records > keySectionAfterOptions?.length) {
      setFilterKeySectionAfter((prev) => ({
        ...prev,
        page_index: (prev.page_index || 1) + 1,
      }))
    }
  }

  /**
   * @description Handles search key content before and after with debounce.
   */
  const handleSearchKeyContentBefore = useMemo(() => {
    return debounce((text: string | undefined) => {
      setFilterKeySectionBefore({
        name: text || '',
        page_index: 1,
      })
      setKeyContentBeforeOptions([])
    }, DEBOUNCED_NUMBER)
  }, [])

  const handleSearchKeyContentAfter = useMemo(() => {
    return debounce((text: string | undefined) => {
      setFilterKeySectionAfter({
        name: text || '',
        page_index: 1,
      })
      setKeyContentAfterOptions([])
    }, DEBOUNCED_NUMBER)
  }, [])

  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const { control, setValue, reset, handleSubmit, setError } = useFormProp

  const parentId = useMemo(() => data?.parent_id || id, [data?.parent_id, id])

  const isSectionOrSubSection = useMemo(() => {
    if (type) {
      return [TYPE_COURSE.SECTION, TYPE_COURSE.SUBSECTION].includes(type)
    }
  }, [type])

  useEffect(() => {
    /**
     * @description Reset filter and options when parentId or idCourse change.
     */
    setFilterKeySectionAfter({ name: '', page_index: 1 })
    setFilterKeySectionBefore({ name: '', page_index: 1 })
    setKeyContentAfterOptions([])
    setKeyContentBeforeOptions([])
  }, [parentId, idCourse])

  /**
   * @description useQuery key content Before
   */
  const { data: dataKeyContentBefore, isLoading: isLoadingKeyContentBefore } = useQuery(
    [
      'keySectionBefore',
      idCourse,
      filterKeySectionBefore?.page_index,
      filterKeySectionBefore?.name,
      data?.id,
      parentId,
    ],
    async () => {
      const params = {
        course_id: idCourse,
        course_section_id: data?.id,
        page_size: 10,
        parent_id: parentId,
        key_after: false,
        ...filterKeySectionBefore,
      }
      if (type === TYPE_COURSE.SECTION) {
        delete params.parent_id
      }

      const response = await CoursesAPI.getKeyContent({
        params,
      })
      return response.data
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setKeyContentBeforeOptions((prev) => {
          const newOptions =
            data?.data?.map((key: IKeySection) => ({
              value: key.id,
              label: key.name,
              current: key.current,
            })) || []

          const mergedOptions = [...prev, ...newOptions]
          const uniqueOptions = mergedOptions.filter(
            (option, index, self) => index === self.findIndex((o) => o.value === option.value) // Loại bỏ các phần tử có `value` trùng nhau
          )

          return uniqueOptions
        })
      },
      enabled: isSectionOrSubSection,
      refetchOnWindowFocus: false,
    }
  )

  /**
   * @description useQuery key content After
   */
  const { data: dataKeyContentAfter, isLoading: isLoadingKeyContentAfter } = useQuery(
    [
      'keySectionAfter',
      idCourse,
      filterKeySectionAfter?.page_index,
      filterKeySectionAfter?.name,
      data?.id,
      parentId,
    ],
    async () => {
      const params = {
        course_id: idCourse,
        course_section_id: data?.id,
        parent_id: parentId,
        key_after: true,
        page_size: 10,
        ...filterKeySectionAfter,
      }

      if (type === TYPE_COURSE.SECTION) {
        delete params.parent_id
      }

      const response = await CoursesAPI.getKeyContent({
        params,
      })
      return response.data
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setKeyContentAfterOptions((prev) => {
          const newOptions =
            data?.data?.map((key: IKeySection) => ({
              value: key.id,
              label: key.name,
              current: key.current,
            })) || []

          const mergedOptions = [...prev, ...newOptions]
          const uniqueOptions = mergedOptions.filter(
            (option, index, self) => index === self.findIndex((o) => o.value === option.value)
          )

          return uniqueOptions
        })
      },
      enabled: isSectionOrSubSection,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (data) {
      setValue('code', data.code)
      setValue('name', data.name)
      setValue('short_name', data.short_name)
      setValue('description', data.description || '')
      setDefaultEditor(data.description)
      if (isSectionOrSubSection) {
        setValue(
          'key_section_before',
          data?.key_section_before?.map((item: IKeySection) => item.id)
        )
        setValue(
          'key_section_after',
          data?.key_section_after?.map((item: IKeySection) => item.id)
        )
      }
    }
  }, [data, type])

  const handleResetForm = () => {
    setValue('code', '')
    setValue('name', '')
    setValue('short_name', '')
    setValue('description', '')
    setValue('key_section_before', [])
    setValue('key_section_after', [])
    setDefaultEditor('')
  }

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  const onSubmit = async (dataValue: Partial<ICoursePart>) => {
    setloading(true)
    try {
      if (data) {
        await CoursesAPI.updateSections({
          course_id: data?.course_id,
          code: dataValue.code,
          description: dataValue.description?.trim(),
          name: dataValue.name?.trim(),
          short_name: dataValue.short_name?.trim(),
          course_section_id: id,
          position: data?.position,
          text_editor_files: textEditorFiles,
          key_section_before: dataValue?.key_section_before,
          key_section_after: dataValue?.key_section_after,
        })
        setIdChild(data?.id)
        const getNodeKey = ({ treeIndex }: any) => treeIndex
        getCourseDetail(idCourse)

        const newNode = {
          ...data,
          code: dataValue.code,
          description: dataValue.description,
          name: dataValue.name?.trim(),
          short_name: dataValue.short_name?.trim(),
          key_section_before: dataValue?.key_section_before,
        }
        if (treeData) {
          setData(changeNodeAtPath({ treeData: treeData, path, getNodeKey, newNode }))
        }
        setOpen(() => {
          handleResetForm()
          return { status: false, data: undefined, type: type }
        })
      } else {
        try {
          if (type === TYPE_COURSE.SECTION) {
            const res = await CoursesAPI.createCoursePart({
              code: dataValue.code,
              description: dataValue.description?.trim(),
              short_name: dataValue.short_name?.trim(),
              name: dataValue.name?.trim(),
              course_id: id,
              text_editor_files: textEditorFiles,
              position: (positionPart || 0) + 1,
              key_section_before: dataValue?.key_section_before,
            })
            setIdChild(res?.data?.id)
          } else if (type === TYPE_COURSE.SUBSECTION) {
            const res = await CoursesAPI.createCourseChapter({
              code: dataValue.code,
              description: dataValue.description?.trim(),
              name: dataValue.name?.trim(),
              parent_id: id,
              course_id: idCourse,
              text_editor_files: textEditorFiles,
              position: (positionSubsection || 0) + 1,
              key_section_before: dataValue?.key_section_before,
            })
            setIdChild(res?.data?.id)
          } else if (type === TYPE_COURSE.UNIT) {
            const res = await CoursesAPI.createCourseUnit({
              code: dataValue.code,
              description: dataValue.description?.trim(),
              name: dataValue.name?.trim(),
              parent_id: id,
              course_id: idCourse,
              text_editor_files: textEditorFiles,
              position: (positionSubsection || 0) + 1,
            })
            setIdChild(res?.data?.id)
          } else if (type === TYPE_COURSE.PARTIALCHAPTER) {
            const res = await CoursesAPI.createCoursePartialChapter({
              code: dataValue.code,
              description: dataValue.description?.trim(),
              short_name: dataValue.short_name?.trim(),
              name: dataValue.name?.trim(),
              course_id: id,
            })
            setIdChild(res?.data?.id)
          }
          getCourseDetail(idCourse)
          setloading(false)
          setOpen(() => {
            handleResetForm()
            return { status: false, data: undefined, type: type }
          })
        } catch (error: any) {
          error?.response?.data?.error?.others?.forEach((e: IErrorCourse) => {
            const errorMessage = e?.errors?.[0]?.message
            setError(e.property, { message: errorMessage })
          }, {})
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setloading(false)
    }
  }
  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        setloading(false)
        reset()
        setOpen(() => {
          handleResetForm()
          return { status: false, data: undefined, type: type }
        })
      },
    })
  }
  const renderTitle = useCallback(() => {
    switch (type) {
      case TYPE_COURSE.SECTION:
      case TYPE_COURSE.PARTIALCHAPTER:
        if (!data) {
          return 'Create Section'
        } else return 'Edit Section'
      case TYPE_COURSE.SUBSECTION:
        if (!data) {
          return 'New Subsection'
        } else return 'Edit Subsection'
      case TYPE_COURSE.UNIT:
        if (!data) {
          return 'Create Unit'
        }
        return 'Edit Unit'
    }
    // return ''
  }, [type, data])
  const renderTitleLabel = useCallback(() => {
    switch (type) {
      case TYPE_COURSE.SECTION:
      case TYPE_COURSE.PARTIALCHAPTER:
        return 'Section'
      case TYPE_COURSE.SUBSECTION:
        return 'Subsection'
      case TYPE_COURSE.UNIT:
        return 'Unit'
    }
    // return ''
  }, [type])
  return (
    <SappDrawer
      rootClassName='sapp-question_essay-drawer'
      open={open}
      title={renderTitle() || ''}
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Save'}
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleClose}
      width='55%'
      loading={loading}
      footerClassName='border-top-0'
    >
      {contextHolder}
      <div className='mb-10 fv-row'>
        <HookFormTextField
          control={control}
          name='name'
          placeholder=''
          label={`${renderTitleLabel()} Name`}
          required
        />
      </div>

      {isSectionOrSubSection && (
        <div className='mb-10 fv-row'>
          <HookFormTextField
            control={control}
            name='short_name'
            placeholder=''
            label={`${renderTitleLabel()} Short Name`}
          />
        </div>
      )}
      {isSectionOrSubSection && (
        <>
          <div className='mb-10 fv-row'>
            <HookFormSelectMultiple
              control={control}
              name='key_section_before'
              label={`${renderTitleLabel()} Key Content Before`}
              loading={isLoadingKeyContentBefore}
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                handleSearchKeyContentBefore(e)
              }}
              options={keySectionBeforeOptions || []}
              handleNextPage={handleNextPageBefore}
              defaultValue={data?.key_section_before?.map((item: IKeySection) => ({
                label: item.name,
                value: item.id,
                current: item.current,
              }))}
            />
          </div>
          {data?.id && (
            <div className='mb-10 fv-row'>
              <HookFormSelectMultiple
                control={control}
                name='key_section_after'
                label={`${renderTitleLabel()} Key Content After`}
                loading={isLoadingKeyContentAfter}
                onSearch={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  handleSearchKeyContentAfter(e)
                }}
                options={keySectionAfterOptions || []}
                handleNextPage={handleNextPageAfter}
                defaultValue={data?.key_section_after?.map((item: IKeySection) => ({
                  label: item.name,
                  value: item.id,
                  current: item.current,
                }))}
              />
            </div>
          )}
        </>
      )}

      <div className='mb-8 fv-row'>
        {data?.id && (
          <HookFormTextField
            control={control}
            name='code'
            placeholder=''
            disabled
            label={`${renderTitleLabel()} Code`}
          />
        )}
      </div>

      <div className='fv-row'>
        <HookFormEditor
          control={control}
          name='description'
          placeholder=''
          defaultValue={defaultEditor}
          label='Describe'
          resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
          object_id={
            TYPE_COURSE.SECTION || TYPE_COURSE.CHAPTER || TYPE_COURSE.UNIT ? data?.id : undefined
          }
          setDataFile={setFileEditor}
        />
      </div>
      {/* <SAPPDialogButtonsCancelSubmit
          cancelClick={handleClose}
          cancelButtonCaption={'Cancel'}
          okButtonCaption={'Save' ?? ''}
          okOnClick={handleSubmit(onSubmit)}
          className='justify-content-end d-flex m-0'
          loading={loading}
        /> */}
      {/* </div> */}
    </SappDrawer>
  )
}
export default memo(CreateSection)

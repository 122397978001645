function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Partial<Record<keyof DirtyFields, unknown>>
>(dirtyFields: DirtyFields, values: Values): Partial<Values> {
 

  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    if (!dirtyFields[key]) return prev;
    
    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === "object" && values[key] !== undefined
          ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key], // Remove ?? undefined to preserve null values
    };
  }, {} as Partial<Values>);

  return dirtyValues;
}

export default getDirtyValues;

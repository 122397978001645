import { Collapse as AntCollapse, CollapseProps } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'
import ArrowDown from 'src/common/CustomIcons/ArrowDown'
import ArrowUp from 'src/common/CustomIcons/ArrowUp'
import styles from './styles.module.scss'

interface CustomCollapseProps extends CollapseProps {
  className?: string
}

const Collapse: FC<CustomCollapseProps> = ({ className, ...props }) => {
  return (
    <AntCollapse
      {...props}
      className={clsx(styles.collapse, className)}
      expandIcon={({ isActive }) => (isActive ? <ArrowDown /> : <ArrowUp />)}
    />
  )
}

export default Collapse

import dayjs from 'dayjs'
import { KTCardBody } from 'src/_metronic/helpers'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'

const headers = [
  {
    label: 'ID',
    className: 'sapp-min-w-100px',
  },
  {
    label: 'Kỳ Thi',
    className: 'min-w-130px',
  },
  {
    label: 'Ngày Thi',
    className: 'min-w-130px',
  },
]
interface IProps {
  examList: any
  loading: boolean
  selectedExam: any
  toggleCheck: any
  handlePaginationChange: any
}
// define headers

const TableExamACCA = ({
  examList,
  loading,
  selectedExam,
  toggleCheck,
  handlePaginationChange,
}: IProps) => {
  return (
    <KTCardBody className='px-0'>
      <SappTable
        headers={headers}
        loading={loading}
        data={examList}
        isCheckedAll={false}
        hasCheckAll={false}
        onChange={() => {}}
      >
        {loading ? (
          <>
            <LoadingTable headers={[...headers, 1]} />
          </>
        ) : (
          <>
            {examList?.data?.map((e: any) => {
              const isChecked = selectedExam?.id === e.id

              return (
                <tr key={e.id}>
                  <td>
                    <SAPPRadio
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(e)
                      }}
                    />
                  </td>
                  <td className='text-start'>{e?.code_exam ?? '-'}</td>
                  <td className='text-start '>{e?.examination.name}</td>
                  <td className='text-start'>
                    {(() => {
                      if (e.end_date === null) {
                        return '-'
                      }
                      return dayjs(e.end_date).format('DD/MM/YYYY')
                    })() ?? '-'}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={examList?.metadata?.page_index || 1}
        pageSize={examList?.metadata?.page_size || 10}
        totalItems={examList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}
export default TableExamACCA

import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { IBlogCategory, IBlogCategoryList } from 'src/type/shop/blog'
import { IBlogCategoryUpdate } from 'src/type/shop/blog'

export class ShopCategoryAPI {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IBlogCategoryList>> {
    return fetcher(`shop-blogs/categories?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getBlogCategory(id: string): Promise<IResponse<IBlogCategory>> {
    return fetcher(`/shop-blogs/categories/${id}`)
  }

  static createBlogCategory({
    data,
  }: {
    data: IBlogCategoryUpdate
  }): Promise<IResponse<IBlogCategory>> {
    return fetcher('/shop-blogs/categories', { method: 'POST', data: data })
  }

  static updateBlogCategory({
    id,
    data,
  }: {
    id: string
    data: IBlogCategoryUpdate
  }): Promise<IResponse<IBlogCategory>> {
    return fetcher(`/shop-blogs/categories/${id}`, { method: 'PUT', data: data })
  }

  static delete(id: string) {
    return fetcher(`shop-blogs/categories/${id}`, {
      method: 'DELETE',
    })
  }

  static deleteCategories(id: string[]) {
    return fetcher(`shop-blogs/categories/bulk-delete`, {
      method: 'POST',
      data: {
        shopBlogCategoryIds: id,
      },
    })
  }
}

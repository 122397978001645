import { zodResolver } from '@hookform/resolvers/zod'
import { isUndefined } from 'lodash'
import moment from 'moment'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import ClassroomApi from 'src/apis/classroom'
import ClassroomCalendarApi from 'src/apis/classroom-calendar'
import HookFormWeekday from 'src/common/HookFormWeekday'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SappLabel from 'src/components/base/label/SappLabel'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import SappDrawer from 'src/components/base/SappDrawer'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTag from 'src/components/base/tag/HookFormTag'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {
  CLASSROOM_STATUS_ENUM,
  REPEAT_TYPE,
  TIME_REPEAT_ENUM,
  VALIDATE_MAX_NAME,
  VALIDATE_MIN_NAME,
  VALIDATE_NUMBER,
  VALIDATE_SETTING,
  VALIDATION_FIELD,
  WEEK_DAY_LABELS,
} from 'src/constants'
import { getDayOrWeekday, getDayOrWeekdayUTC } from 'src/helper/calendarHelper'
import { useConfirm } from 'src/hooks/use-confirm'
import { IRoomList } from 'src/type/area'
import { z } from 'zod'
import './style.css'
import { convertLocalToUTC, getDayIndex } from 'src/utils'
import { ICreateAndUpdateRoomCalendar, IEventDetail } from 'src/type'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import dayjs from 'dayjs'
import { ISelectEvent } from 'src/pages/classroom-calendar'

interface IProps {
  open: boolean
  onClose: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  id: string
  loading: boolean
  roomId: string
  selectedEvent?: ISelectEvent
  refresh: () => void
}

interface IInputProps {
  name: string
  isRepeat: boolean
  interval: number
  description_repeat: string
  room_id?: string
  end_date_repeat: Date
  frequency: string
  registration: any
  lessee_tag: any
  type: string
  description: string
  approve_all_event: string
  weekdays: any
  approveDisable: boolean
}

const CreateAndUpdateEvent = ({
  onClose,
  open,
  setLoading,
  id,
  loading,
  roomId,
  selectedEvent,
  refresh,
}: IProps) => {
  const [classroomList, setClassroomList] = useState<IRoomList>()
  const { confirm, contextHolder } = useConfirm()
  const [event, setEvent] = useState<IEventDetail>()
  const [indexDayList, setIndexDayList] = useState<number[]>([])
  const validationSchema = z
    .object({
      name: z
        .string({ required_error: VALIDATION_FIELD })
        .min(1, { message: VALIDATE_MIN_NAME })
        .max(100, { message: VALIDATE_MAX_NAME }),
      isRepeat: z.boolean().default(false),
      interval: z
        .string()
        .regex(/^[1-9][0-9]*$/, VALIDATE_NUMBER)
        .transform((val) => parseInt(val))
        .refine((val) => (!val ? true : val >= 0), { message: VALIDATE_SETTING })
        .refine((val) => (!val ? true : val <= 100), { message: VALIDATE_SETTING })
        .or(z.number().int(VALIDATE_NUMBER).min(1).max(52))
        .optional(),
      description_repeat: z.string().optional(),
      room_id: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
      end_date_repeat: z.date().optional(),
      frequency: z.string(),
      registration: z.object(
        {
          fromDate: z.any(),
          toDate: z.any(),
        },
        { required_error: VALIDATION_FIELD }
      ),
      lessee_tag: z.array(z.string().or(z.object({}).passthrough())),
      type: z
        .string({ required_error: VALIDATION_FIELD })
        .min(1, { message: VALIDATION_FIELD })
        .default(REPEAT_TYPE.NO_REPEAT),
      description: z.string().optional(),
      approve_all_event: z.string().optional().default('false'),
      weekdays: z.any().optional(),
      approveDisable: z.boolean().default(false),
    })
    .refine((data) => data.type === REPEAT_TYPE.NO_REPEAT || !!data.end_date_repeat, {
      message: VALIDATION_FIELD,
      path: ['end_date_repeat'],
    })
    .refine(
      (data) =>
        data.frequency !== TIME_REPEAT_ENUM.DAYS ||
        (data.interval && data.interval >= 1 && data.interval <= 31),
      {
        message: 'Interval for day must be between 1 and 31.',
        path: ['interval'],
      }
    )
    .refine(
      (data) =>
        data.frequency !== TIME_REPEAT_ENUM.WEEKS ||
        (data.interval && data.interval >= 1 && data.interval <= 52),
      {
        message: 'Interval for week must be between 1 and 52.',
        path: ['interval'],
      }
    )
    .refine(
      (data) =>
        data.frequency !== TIME_REPEAT_ENUM.MONTHS ||
        (data.interval && data.interval >= 1 && data.interval <= 12),
      {
        message: 'Interval for month must be between 1 and 12.',
        path: ['interval'],
      }
    )
    .refine(
      (data) =>
        data.frequency !== TIME_REPEAT_ENUM.YEARS ||
        (data.interval && data.interval >= 1 && data.interval <= 2),
      {
        message: 'Interval for year must be between 1 and 2.',
        path: ['interval'],
      }
    )

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setError,
    setValue,
    clearErrors,
    resetField,
  } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      type: REPEAT_TYPE.NO_REPEAT,
      isRepeat: false,
      interval: 1,
      room_id: '',
      description_repeat: '',
      frequency: TIME_REPEAT_ENUM.WEEKS,
      approve_all_event: 'false',
      approveDisable: false,
    },
  })

  const fetchClassroom = async (pageIndex: number, pageSize: number = 50, params?: object) => {
    try {
      const res = await ClassroomApi.getRoomList({
        page_index: pageIndex,
        page_size: pageSize,
        params: { ...params, status: CLASSROOM_STATUS_ENUM.ACTIVE },
      })
      if (pageIndex === 1) {
        setClassroomList(res.data)
      } else {
        setClassroomList((prev: any) => {
          return {
            metadata: res.data.metadata,
            classRooms: [...(prev.classRooms ?? []), ...(res.data.classRooms ?? [])],
          }
        })
      }
    } catch (error) {}
  }

  const fetchLessee = async (params: {
    search?: string
    page_index: number
    page_size: number
  }) => {
    try {
      const res = await ClassroomCalendarApi.getLesseTag(params)
      return res
    } catch (error) {}
  }

  const handleResetForm = () => {
    setClassroomList(undefined)
    onClose()
    reset()
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: handleResetForm,
    })
  }

  const handleValidationRepeat = (data: IInputProps) => {
    clearErrors('interval')
    clearErrors('end_date_repeat')
    clearErrors('type')
    clearErrors('weekdays')
    let isValid = true
    if (data.interval < 1 || !getValues('interval')) {
      setError('interval', { message: 'Invalid Value' })
      isValid = false
    }
    if (!data.end_date_repeat) {
      setError('end_date_repeat', { message: 'Invalid Value' })
      isValid = false
    }
    if (!data.type) {
      setError('type', { message: 'Invalid Value' })
      isValid = false
    }
    if (!data.frequency) {
      setError('frequency', { message: 'Invalid Value' })
      isValid = false
    }
    if (
      data.frequency === 'weeks' &&
      data.weekdays.filter((item: boolean) => item === true).length === 0
    ) {
      setError('weekdays', { message: 'Invalid Value' })
      isValid = false
    }
    return isValid
  }

  const onSubmit = async (data: IInputProps) => {
    if (isUndefined(data?.lessee_tag)) {
      setError('lessee_tag', { message: VALIDATION_FIELD })
      return
    }

    if (!data.end_date_repeat) {
      setError('end_date_repeat', { message: VALIDATION_FIELD })
    }

    if (data.type === REPEAT_TYPE.CUSTOM && !handleValidationRepeat(data)) {
      return
    }
    setLoading(true)
    const { name, room_id, type, description, lessee_tag } = data
    const end_date_repeat = data.end_date_repeat
      ? data.end_date_repeat
      : new Date(data?.registration?.fromDate).setFullYear(
          new Date(data?.registration?.fromDate).getFullYear() + 2
        )
    let payload: ICreateAndUpdateRoomCalendar = {
      name,
      room_id,
      type,
      description: description ? description : null,
      start_date: data?.registration?.fromDate,
      end_date: data?.registration?.toDate,
      end_date_repeat: end_date_repeat
        ? new Date(
            convertLocalToUTC(new Date(`${dayjs(end_date_repeat).format('YYYY-MM-DD')}T23:59:59`))
          ).toISOString()
        : handleExpandTime(data?.registration?.fromDate),
      lessee_tag: lessee_tag?.map((item: { id?: string; name?: string }) => {
        return item.id ? item.id : item.name
      })[0],
      old_start_date:
        selectedEvent?.startDate && !!id
          ? new Date(convertLocalToUTC(selectedEvent?.startDate))?.toISOString()
          : '',
      old_end_date:
        selectedEvent?.endDate && !!id
          ? new Date(convertLocalToUTC(selectedEvent?.endDate))?.toISOString()
          : '',
      approve_all_event: data.approve_all_event === 'true' ? true : false,
    }
    if (REPEAT_TYPE.NO_REPEAT !== data.type) {
      payload = {
        ...payload,
        ...handlIntervalTime(data),
      }
    } else {
      payload.frequency = REPEAT_TYPE.NO_REPEAT
    }
    try {
      if (id) {
        delete payload.room_id
        await ClassroomCalendarApi.editScheduleRental(id, room_id ?? '', payload)
        toast.success('Update Successfully!')
      } else {
        delete payload.old_start_date
        delete payload.old_end_date
        delete payload.approve_all_event
        await ClassroomCalendarApi.createScheduleRental(payload)
        toast.success('Create Successfully!')
      }
      handleResetForm()
      refresh()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleExpandTime = (date: Date) => {
    return new Date(date.setFullYear(date.getFullYear() + 2)).toISOString()
  }

  const handleRangeWeekdayIndex = (weekdays: Array<number>, startDate: Date) => {
    const current =
      Number(getDayOrWeekdayUTC(startDate, 'weekdayIndex')) === 0
        ? 7
        : Number(getDayOrWeekdayUTC(startDate, 'weekdayIndex'))
    let startIndex = weekdays.indexOf(current)
    let arr: any[] = weekdays
    if (startIndex === -1) {
      arr = weekdays?.map((item: number) => {
        if (current < item) {
          return item - 1
        }
        return item
      })
      startIndex = arr.indexOf(current)
    }
    const part1 = arr.slice(startIndex)
    const part2 = arr.slice(0, startIndex)
    return part1.concat(part2)
  }

  const handlIntervalTime = (data: IInputProps) => {
    const day_of_week = [
      Number(getDayOrWeekdayUTC(data.registration?.fromDate, 'weekdayIndex')) === 0
        ? 7
        : Number(getDayOrWeekdayUTC(data.registration?.fromDate, 'weekdayIndex')),
    ]
    const day_of_month = data.registration?.fromDate
      ? [getDayOrWeekdayUTC(data.registration?.fromDate, 'day') ?? {}]
      : []
    const month_of_year = data.registration?.fromDate
      ? [getDayOrWeekdayUTC(data.registration?.fromDate, 'monthly')]
      : []
    switch (data.type) {
      case REPEAT_TYPE.DAILY: {
        return {
          frequency: 'days',
          interval: 1,
        }
      }
      case REPEAT_TYPE.WEEKLY: {
        return {
          day_of_week,
          frequency: 'weeks',
          interval: 1,
        }
      }
      case REPEAT_TYPE.MONTHLY: {
        const day_of_month = [getDayOrWeekdayUTC(data.registration?.fromDate, 'day') ?? {}]
        return {
          day_of_month,
          frequency: 'months',
          interval: 1,
        }
      }
      case REPEAT_TYPE.YEARLY: {
        return {
          day_of_month,
          month_of_year,
          frequency: 'years',
          interval: 1,
        }
      }
      case REPEAT_TYPE.EVERYDAY: {
        return {
          day_of_week: handleRangeWeekdayIndex([1, 2, 3, 4, 5], data.registration?.fromDate),
          month_of_year,
          frequency: 'weeks',
          interval: 1,
        }
      }
      case REPEAT_TYPE.CUSTOM: {
        let weeklyValue: number[] = []
        watch('weekdays')?.forEach((item: boolean, index: number) => {
          if (item) {
            weeklyValue.push(index + 1)
          }
        })
        return {
          interval: Number(getValues('interval')),
          frequency: getValues('frequency'),
          ...(getValues('frequency') === 'weeks'
            ? {
                day_of_week: handleRangeWeekdayIndex(weeklyValue, data?.registration?.fromDate),
              }
            : getValues('frequency') === 'months'
            ? {
                day_of_month,
              }
            : {
                day_of_month,
                month_of_year,
              }),
        }
      }
    }
  }

  const handleSelectTypeRepeat = () => {
    if (watch('registration')?.fromDate) {
      return [
        {
          value: REPEAT_TYPE.NO_REPEAT,
          label: 'Does not repeat',
        },
        {
          value: REPEAT_TYPE.DAILY,
          label: 'Daily',
        },
        {
          value: REPEAT_TYPE.WEEKLY,
          label: `Weekly on ${getDayOrWeekday(watch('registration')?.fromDate, 'weekday')}`,
        },
        {
          value: REPEAT_TYPE.MONTHLY,
          label: `Monthly on ${getDayOrWeekday(watch('registration')?.fromDate, 'monthly')}`,
        },
        {
          value: REPEAT_TYPE.YEARLY,
          label: `Annualy on the  ${getDayOrWeekday(watch('registration')?.fromDate, 'monthDay')} `,
        },
        {
          value: REPEAT_TYPE.EVERYDAY,
          label: 'Every weekday (Monday to Friday)',
        },
        {
          value: REPEAT_TYPE.CUSTOM,
          label: 'Custom',
        },
      ]
    }
    return [
      {
        value: REPEAT_TYPE.NO_REPEAT,
        label: 'Does not repeat',
      },
      {
        value: REPEAT_TYPE.DAILY,
        label: 'Daily',
      },
      {
        value: REPEAT_TYPE.EVERYDAY,
        label: 'Every weekday (Monday to Friday)',
      },
      {
        value: REPEAT_TYPE.CUSTOM,
        label: 'Custom',
      },
    ]
  }

  const fetScheduleDetail = async () => {
    try {
      resetField('registration')
      const res = await ClassroomCalendarApi.getSheduleRoomDetail(id, roomId)
      const data = res.data
      const type = !!data?.schedule?.recurring_pattern_schedule
        ? data?.schedule?.recurring_pattern_schedule?.type
        : REPEAT_TYPE.NO_REPEAT
      setValue('name', selectedEvent?.data?.title ?? '')
      setValue('type', type)
      setValue('description', selectedEvent?.data?.description ?? '')
      setValue('lessee_tag', data.lessee_tags)
      setValue('room_id', data.room_id)
      if (selectedEvent?.startDate || selectedEvent?.endDate) {
        setValue('registration', {
          fromDate: selectedEvent?.startDate,
          toDate: selectedEvent?.endDate,
        })
      }
      if (!!data.schedule.recurring_pattern_schedule) {
        data.schedule.recurring_pattern_schedule.end_date &&
          setValue(
            'end_date_repeat',
            new Date(
              `${dayjs(data.schedule.recurring_pattern_schedule.end_date).format(
                'YYYY-MM-DD'
              )}T16:59:59Z`
            )
          )
        if (data?.schedule?.recurring_pattern_schedule?.frequency === 'weeks') {
          if (!selectedEvent?.startDate) return
          const indexDay = getDayOrWeekday(new Date(selectedEvent?.startDate), 'weekdayIndex')
          let weekdayValues = Array(WEEK_DAY_LABELS.length).fill(false)
          let indexDayArr: number[] = []
          if (indexDay && indexDay > data.schedule.recurring_pattern_schedule.day_of_week[0]) {
            data.schedule.recurring_pattern_schedule.day_of_week?.map(
              (value: number, index: number) => {
                weekdayValues[value === 7 ? 0 : value] = true
                indexDayArr.push(value)
              }
            )
          } else {
            data.schedule.recurring_pattern_schedule.day_of_week?.forEach(
              (value: number, index: number) => {
                weekdayValues[value - 1] = true
                indexDayArr.push(value - 1)
              }
            )
          }
          setIndexDayList(indexDayArr)
          setValue('weekdays', weekdayValues)
        }
        setValue('frequency', data?.schedule?.recurring_pattern_schedule?.frequency ?? '')
        setValue('interval', data?.schedule?.recurring_pattern_schedule?.interval ?? '')
        setEvent(data)
      }
      await fetchClassroom(1, 10, { search: `name=${data?.room?.name}` })
    } catch (error) {}
  }

  const handleNextPageClassroom = () => {
    if (
      classroomList?.metadata.page_index &&
      classroomList?.metadata.page_size &&
      classroomList?.metadata.page_index < classroomList?.metadata.total_pages
    ) {
      fetchClassroom(classroomList?.metadata.page_index + 1, classroomList?.metadata.page_size)
    }
  }

  const disabledFromDate = (current: any) => {
    return current < moment().startOf('day') // Không cho phép các ngày trước hôm nay
  }

  const disabledEndDateCustom = (current: any) => {
    return current <= watch('registration')?.toDate // Không cho phép các ngày trước hôm nay
  }

  const handleValidateApprove = () => {
    setValue('approveDisable', false)
    if (!getValues('type')) {
      return
    }
    if (!id || !event) {
      return
    }
    const changeWeekdays = getValues('weekdays')?.filter((isChoose: boolean, index: number) => {
      if (isChoose && !indexDayList.includes(index)) {
        return index
      }
    })
    if (
      (getValues('type') &&
        getValues('type') !== event?.schedule.recurring_pattern_schedule.type) ||
      getValues('frequency') !== event?.schedule.recurring_pattern_schedule.frequency ||
      Number(getValues('interval')) !==
        Number(event?.schedule.recurring_pattern_schedule.interval) ||
      getValues('end_date_repeat').getTime() !==
        new Date(
          `${dayjs(event.schedule.recurring_pattern_schedule.end_date).format(
            'YYYY-MM-DD'
          )}T16:59:59Z`
        ).getTime() ||
      (getValues('frequency') === 'weeks' && changeWeekdays?.length > 0)
    ) {
      setValue('approveDisable', true)
      setValue('approve_all_event', 'true')
    } else {
      setValue('approveDisable', false)
    }
  }

  const getDefaultRoom = async () => {
    try {
      const res = await ClassroomApi.getRoomDetail(roomId)
      if (res.data && res.data.status !== CLASSROOM_STATUS_ENUM.CLOSE) {
        setClassroomList({
          metadata: { total_pages: 0, total_records: 0, page_index: 0, page_size: 0 },
          classRooms: [res.data],
        })
        setValue('room_id', roomId)
      }
    } catch {}
  }

  useEffect(() => {
    const value = Array(WEEK_DAY_LABELS.length)
      .fill(false)
      .map((_: boolean, index: number) =>
        getDayIndex(watch('registration')?.fromDate) === index ? true : false
      )
    setValue('weekdays', value)
  }, [watch('registration')?.fromDate])

  useEffect(() => {
    handleValidateApprove()
  }, [
    watch('type'),
    watch('registration'),
    watch('end_date_repeat'),
    watch('frequency'),
    watch('interval'),
    watch('weekdays'),
  ])

  const renderRepeatOn = useMemo(() => {
    switch (watch('frequency')) {
      case 'weeks':
        return (
          <HookFormWeekday
            defaultValue={Array.from({ length: 7 }).map(() => false)}
            control={control}
            dayIndex={getDayIndex(watch('registration')?.fromDate)}
            name='weekdays'
          />
        )
      case 'months':
        return (
          <div>
            <HookFormSelectAntd
              control={control}
              name=''
              placeholder='Select'
              showSearch
              options={[]}
            />
          </div>
        )
    }
  }, [watch('frequency'), watch('registration')?.fromDate])

  const typeRepeat = useMemo(() => {
    const letter = watch('interval') > 1 ? 's' : ''
    return [
      {
        label: `Day${letter}`,
        value: 'days',
      },
      {
        label: `Week${letter}`,
        value: 'weeks',
      },
      {
        label: `Month${letter}`,
        value: 'months',
      },
      {
        label: `Years${letter}`,
        value: 'years',
      },
    ]
  }, [watch('interval')])

  useEffect(() => {
    if (open) {
      id && selectedEvent && fetScheduleDetail()
      !id && roomId && getDefaultRoom()
    }
  }, [open])

  return (
    <SappDrawer
      rootClassName='sapp-question_essay-drawer'
      open={open}
      title={id ? 'Edit Event' : 'Add Event'}
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Save'}
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleCancel}
      width='50%'
      loading={loading}
    >
      {contextHolder}
      <div className='mb-8 fv-row'>
        <HookFormTextField
          control={control}
          name='name'
          label='Event Name'
          required
          placeholder='Event Name'
        />
      </div>
      <div className='mb-8 fv-row'>
        <HookFormSelectAntd
          control={control}
          name=''
          label='Event Type'
          required
          placeholder='Lịch cho thuê'
          showSearch
          handleNextPage={() => {}}
          options={[
            {
              value: 'Lịch cho thuê',
              label: 'Lịch cho thuê',
            },
          ]}
          disabled={true}
        />
      </div>
      <div className='mb-8 fv-row'>
        <HookFormTag
          name='lessee_tag'
          placeholder=''
          control={control}
          fetch={fetchLessee}
          label='Lessee'
          className='sapp-tag-form-custom'
          classCustomTag='sapp-min-w--tag'
          dataName='lessee_tags'
          disabled={!!id}
          required
          isSingleSelection
        />
      </div>
      <div className='mb-8 fv-row'>
        <RangeDateTimePicker
          control={control}
          name='registration'
          label='Time'
          required
          allowClear={true}
          placeholder={['Start Date', 'End Date']}
          showTime
          disabledDate={disabledFromDate}
          format='DD/MM/YYYY HH:mm'
        />
      </div>
      <div className='mb-8 fv-row'>
        <HookFormSelectAntd
          control={control}
          name='type'
          label='Repeat'
          defaultValue={REPEAT_TYPE.NO_REPEAT}
          handleNextPage={handleNextPageClassroom}
          options={handleSelectTypeRepeat()}
          required
        />
      </div>
      {![REPEAT_TYPE.NO_REPEAT, ''].includes(watch('type')) && (
        <div className='border rounded-2 mt-8 p-5'>
          {watch('type') === REPEAT_TYPE.CUSTOM && (
            <>
              <div className='row align-items-center mb-5'>
                <div className='col-3'>
                  <SappLabel label='Repeat Every' required />
                </div>
                <div className='col-8 row'>
                  <div className='col-3'>
                    <HookFormTextField
                      control={control}
                      name='interval'
                      className='sapp-max-w-283'
                      type='number'
                    />
                  </div>
                  <div className='col-6'>
                    <HookFormSelectAntd
                      className='sapp-w-175px col-5'
                      control={control}
                      name='frequency'
                      defaultValue={TIME_REPEAT_ENUM.WEEKS}
                      placeholder='Repeat'
                      required
                      showSearch
                      options={typeRepeat}
                    />
                  </div>
                </div>
              </div>
              {watch('frequency') === 'weeks' && (
                <div className='mb-5 row align-items-center'>
                  <div className='col-3'>
                    <SappLabel label='Repeat On' required />
                  </div>
                  <div className='col-9'>{renderRepeatOn}</div>
                </div>
              )}
            </>
          )}
          <div className='row align-items-center rounded'>
            <div className='col-3'>
              <SappLabel label='End On' required />
            </div>
            <div className='col-9'>
              <HookFormDateTime
                control={control}
                name='end_date_repeat'
                disabledDate={disabledEndDateCustom}
                placeholder='To date'
              />
            </div>
          </div>
        </div>
      )}
      <div className='mb-8 mt-8 fv-row'>
        <HookFormSelectAntd
          control={control}
          name='room_id'
          disabled={!!id}
          label='Classroom'
          placeholder='Classroom'
          required
          onSearch={(text: string) => {
            if (text.trim()) {
              fetchClassroom(1, 10, { search: `name=${text}` })
            }
          }}
          onClear={() => {
            if (classroomList?.metadata.page_index === 0) {
              fetchClassroom(1, 10)
            }
          }}
          onFocus={() => {
            if (!classroomList?.classRooms.length) {
              fetchClassroom(1, 10)
            }
          }}
          showSearch
          handleNextPage={handleNextPageClassroom}
          options={(classroomList?.classRooms ?? [])?.map((item: { id: string; name: string }) => {
            return {
              value: item.id,
              label: item.name,
            }
          })}
        />
      </div>
      <div className='mb-8 fv-row'>
        <HookFormEditor
          height={352}
          placeholder=''
          label='Description'
          name='description'
          control={control}
          math={true}
          className='w-100 fs-6 lh-1 sapp-table-class-field'
          defaultValue={getValues('description')}
          resourceLocation={RESOURCE_LOCATION.COURSE}
          object_id={undefined}
        />
      </div>
      {!!id && (
        <div>
          <SappLabel label='Edit recurring event' />
          <HookFormRadioGroup
            control={control}
            name='approve_all_event'
            disabled={watch('approveDisable')}
            options={[
              { label: 'This Event', value: 'false' },
              { label: 'This and following events', value: 'true' },
            ]}
            hasBorder={false}
          />
        </div>
      )}
    </SappDrawer>
  )
}

export default CreateAndUpdateEvent

import React, { useState } from 'react'
import { Control } from 'react-hook-form'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import useUniversities from 'src/hooks/useUniversities'
import HookFormSelectAntd from '../HookFormSelectAntd'

interface UniversitySelectProps {
  control: Control<any>
  disabled?: boolean
  selectedUniversity?: { value?: string; label?: string }
}
const UniversitySelect: React.FC<UniversitySelectProps> = ({
  control,
  disabled,
  selectedUniversity,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  // Example usage for multiple hooks
  const { universities, isFetchingUniversities } = useUniversities(dropdownVisible)
  const baseOptions =
    universities?.map((university) => ({
      label: university.name,
      value: university.id,
    })) ?? []

  const options = selectedUniversity
    ? getSelectOptions(baseOptions, selectedUniversity)
    : baseOptions

  return (
    <HookFormSelectAntd
      showSearch
      classNameHeight='sapp-h-45px'
      name='university_id'
      control={control}
      placeholder='Please select'
      disabled={disabled}
      loading={isFetchingUniversities}
      onDropdownVisibleChange={(enabledVisible) => setDropdownVisible(enabledVisible)}
      options={options}
    />
  )
}

export default UniversitySelect

import clsx from 'clsx'
import { Key, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import ClassroomApi from 'src/apis/classroom'
import SAPPListGroup from 'src/common/SAPPListGroup'
import ActionCell from 'src/components/base/action/ActionCell'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import Search from 'src/components/base/search'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import { FACILITY_STATUS_ENUM } from 'src/constants'
import { DELETE_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { cleanParamsAPI, getStaffFacilityPosition } from 'src/utils'
import AreaProfileAddStaff from 'src/components/classroom/area/add-staff/AreaProfileAddStaff'
import { IFacility, IStaffFacility, IStaffFacilityList } from 'src/type'
import SappTable from 'src/components/ui/table/SappTable'
import { TablePaginationConfig } from 'antd'

interface IProps {
  loading: boolean
  areaDetail: IFacility | undefined
}

interface IParams {
  page_size: number
  page_index: number
  text?: string
}

const initialValues: IParams = {
  text: '',
  page_size: 10,
  page_index: 1,
}

const getParams = (text: string, page_index: number, page_size: number) => ({
  text,
  page_index,
  page_size,
})

/**
 * Component hiển thị danh sách nhân viên của một cơ sở
 *
 * @param {IProps} props - Các props được truyền vào component
 * @param {boolean} props.loading - Trạng thái loading
 * @param {IFacility | undefined} props.areaDetail - Thông tin chi tiết cơ sở
 * @returns {JSX.Element} Component AreaProfileStaffList
 */
const AreaProfileStaffList = ({ loading, areaDetail }: IProps) => {
  const { contextHolder, confirm } = useConfirm()
  const [staffList, setStaffList] = useState<IStaffFacilityList>()
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  const [searchParams, setSearchParams] = useState<IParams>(initialValues)
  const [openAddStaff, setOpenAddStaff] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Map<number, React.Key[]>>(new Map())
  const selectedRowId = useMemo(() => [...selectedRowKeys.values()].flat(), [selectedRowKeys])

  /**
   * Mở popup thêm nhân viên
   */
  const handleOpenAddStaff = () => {
    setOpenAddStaff(true)
  }

  /**
   * Thay đổi người quản lý cơ sở
   *
   * @param {string} id - ID của nhân viên được chọn làm người quản lý
   */
  const handleChangeManager = async (id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi người phụ trách cơ sở chính?'],
      onClick: async () => {
        try {
          const data = { staff_id: id }
          await ClassroomApi?.changeFacilityManager(areaDetail?.id as string, data)
          setStaffList((prev) => {
            if (!prev) return prev
            return {
              ...prev,
              staffs: prev.staffs.map((staff) => ({
                ...staff,
                staff_facility_manager_instances: staff.staff_facility_manager_instances.map(
                  (item) => ({
                    ...item,
                    is_primary: staff.id === id,
                  })
                ),
              })),
            }
          })
          toast.success('Thay đổi người phụ trách cơ sở chính thành công')
        } catch (error) {}
      },
    })
  }

  /**
   * Tải dữ liệu danh sách nhân viên
   *
   * @param {Object} params - Tham số truy vấn
   */
  const loadData = async (params?: Object) => {
    try {
      const res = await ClassroomApi.getStaffsInFacility(areaDetail?.id as string, params as Object)
      setStaffList(res?.data)
      setPagination({
        ...pagination,
        total: res?.data?.metadata?.total_records || 0,
      })
    } catch (error) {}
  }

  /**
   * Xóa một nhân viên khỏi cơ sở
   *
   * @param {string} id - ID của nhân viên cần xóa
   */
  const handleDelete = (id: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DELETE_POPUPCONFIRM],
      onClick: async () => {
        try {
          const data = {
            staff_ids: [id],
          }
          await ClassroomApi.deleteStaffsFromFacility(areaDetail?.id as string, data)
          const cleanedParams = cleanParamsAPI(
            getParams(searchParams?.text || '', searchParams?.page_index, searchParams?.page_size)
          )
          loadData(cleanedParams)
          toast.success('Delete Successfully!')
        } catch (err) {}
      },
    })
  }

  /**
   * Xóa nhiều nhân viên khỏi cơ sở
   *
   * @param {string[]} staff_ids - Danh sách ID của các nhân viên cần xóa
   */
  const handleDeleteMultiStaff = async (staff_ids: string[]) => {
    try {
      const data = {
        staff_ids: staff_ids,
      }
      await ClassroomApi?.deleteStaffsFromFacility(areaDetail?.id as string, data)
      setOpenPopupDelete(false)
      setSelectedRowKeys(new Map())
      const cleanedParams = cleanParamsAPI(
        getParams(searchParams?.text || '', searchParams?.page_index, searchParams?.page_size)
      )
      loadData(cleanedParams)
      toast.success('Delete Successfully!')
    } catch (err) {}
  }

  /**
   * Xử lý khi thay đổi giá trị tìm kiếm
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - Sự kiện thay đổi input
   */
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
      page_size: searchParams?.page_size,
      page_index: 1,
    }
    setSearchParams(() => cleanedParams)
  }

  /**
   * Xử lý khi tìm kiếm
   */
  const handleSearch = () => {
    const cleanedParams = cleanParamsAPI(
      getParams(searchParams?.text || '', 1, searchParams?.page_size)
    )
    loadData(cleanedParams)
  }

  /**
   * Lọc danh sách nhân viên đã chọn, loại bỏ người quản lý
   */
  const filteredStaffList = useMemo<string[]>(() => {
    return selectedRowId
      .map((id) => id.toString())
      .filter((staffId: string) => {
        const staff = staffList?.staffs?.find((s) => s?.id === staffId)
        return !staff?.staff_facility_manager_instances?.some((instance) => instance?.is_primary)
      })
  }, [selectedRowId, staffList])

  /**
   * Sắp xếp lại danh sách nhân viên để người quản lý luôn ở đầu
   */
  const managerFirstStaffList = useMemo(() => {
    if (!staffList?.staffs) return staffList

    const sortedStaffs = [...staffList?.staffs]?.sort(
      (a, b) =>
        +b?.staff_facility_manager_instances?.some((instance) => instance?.is_primary) -
        +a?.staff_facility_manager_instances?.some((instance) => instance?.is_primary)
    )

    return staffList.staffs === sortedStaffs ? staffList : { ...staffList, staffs: sortedStaffs }
  }, [staffList])

  const filteredSelectedRowKeys = useMemo(() => {
    const newMap = new Map<number, Key[]>()

    selectedRowKeys.forEach((keys, key) => {
      const filteredKeys = keys.filter((id) => {
        const staff = managerFirstStaffList?.staffs?.find((s) => s.id === id)
        return !staff?.staff_facility_manager_instances?.some((instance) => instance?.is_primary)
      })

      if (filteredKeys.length > 0) {
        newMap.set(key, filteredKeys)
      }
    })

    return newMap
  }, [selectedRowKeys, managerFirstStaffList])

  const columns = [
    {
      title: 'Code',
      dataIndex: 'key',
      key: 'key',
      render: (text: string) => <span className='text-start'>{text}</span>,
    },
    {
      title: 'Name',
      dataIndex: ['detail', 'full_name'],
      key: 'name',
      render: (text: string) => <span className='text-start'>{text}</span>,
    },
    {
      title: 'Phone Number',
      dataIndex: ['detail', 'phone'],
      key: 'phone',
      render: (text: string) => <span className='text-start'>{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: ['detail', 'email'],
      key: 'email',
      render: (text: string) => <span className='text-start'>{text}</span>,
    },
    {
      title: 'Facility Manager',
      dataIndex: 'staff_facility_manager_instances',
      key: 'manager',
      render: (_: IStaffFacility, record: IStaffFacility) => {
        const isManager = record?.staff_facility_manager_instances?.some((item) => item?.is_primary)
        return (
          <div className='text-center'>
            <SAPPRadio checked={isManager} onChange={() => handleChangeManager(record?.id)} />
          </div>
        )
      },
    },
    {
      title: 'Job position',
      key: 'position',
      render: (_: IStaffFacility, record: IStaffFacility) => (
        <span className='text-start'>{getStaffFacilityPosition(record)}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: IStaffFacility, record: IStaffFacility) => (
        <ActionCell>
          <div
            className='menu-item px-3'
            onClick={() => {
              handleDelete(record?.id)
            }}
          >
            <div className='menu-link px-3'>Delete</div>
          </div>
        </ActionCell>
      ),
    },
  ]

  /**
   * Lấy dữ liệu cho bảng
   *
   * @param {number} page - Số trang
   * @param {number} pageSize - Kích thước trang
   * @param {Record<string, any>} params - Tham số truy vấn
   */
  const fetchData = (page: number, pageSize: number, params?: Record<string, any>) => {
    loadData({
      ...params,
      page_index: page,
      page_size: pageSize,
      facility_id: areaDetail?.id,
    } as Record<string, any>)
  }

  /**
   * Lấy dữ liệu cho bảng
   *
   * @param {number} current - Số trang hiện tại
   * @param {number} pageSize - Kích thước trang
   * @param {Record<string, any>} params - Tham số truy vấn
   */
  const fetchTableData = (current: number, pageSize: number, params?: Record<string, any>) => {
    loadData({
      ...params,
      page_index: current,
      page_size: pageSize,
      facility_id: areaDetail?.id,
    } as Record<string, any>)
  }

  /**
   * Xử lý khi thay đổi tham số phân trang
   *
   * @param {number} current - Số trang hiện tại
   * @param {number} pageSize - Kích thước trang
   */
  const handleChangeParams = (current: number, pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize,
    }))
  }

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10 flex sapp-min-h-100vh'>
        <div className='py-8 px-10 '>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${clsx({
              ' mb-5': staffList?.metadata?.total_records,
            })}`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search staff'}
                smallSearch={true}
                onSubmit={handleSearch}
                isListScreen
              ></Search>

              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearch()
                }}
                className='ms-4'
                loading={loading}
                size='small'
              />
            </div>
            {areaDetail?.status === FACILITY_STATUS_ENUM.ACTIVE && (
              <ButtonIconPrimary
                iconName={'plus'}
                title={'Add Staff'}
                onClick={handleOpenAddStaff}
                size='small'
              />
            )}
          </div>
          <div className='d-flex align-items-center w-100 justify-content-between'>
            <h5 className='fw-bold fs-6 mt-8'>
              Danh sách nhân viên ({staffList?.metadata?.total_records || 0})
            </h5>
            {filteredStaffList.length > 0 && (
              <SAPPListGroup
                selected={filteredStaffList}
                deleteItems={() => handleDeleteMultiStaff(filteredStaffList)}
                openPopup={openPopupDelete}
                setOpenPopup={setOpenPopupDelete}
                title='Delete Selected'
                body='Bạn có chắc chắn muốn xóa không?'
                okButtonCaption='Yes'
                cancelButtonCaption='No'
              />
            )}
          </div>
          <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
            <div className='container'>
              <div className='row px-4 py-8 sapp-overflow-y-layout'>
                <SappTable
                  columns={columns}
                  data={managerFirstStaffList?.staffs || []}
                  loading={loading}
                  pagination={pagination}
                  setPagination={setPagination}
                  fetchData={fetchData}
                  fetchTableData={fetchTableData}
                  handleChangeParams={handleChangeParams}
                  showCheckbox={true}
                  filterParams={searchParams}
                  setSelection={setSelectedRowKeys}
                  selections={selectedRowKeys}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openAddStaff && (
        <AreaProfileAddStaff
          open={openAddStaff}
          setOpen={setOpenAddStaff}
          id={areaDetail?.id as string}
          getFacilityStaff={loadData}
        />
      )}
    </>
  )
}
export default AreaProfileStaffList

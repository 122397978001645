import { Select, Tooltip } from 'antd'
import { capitalize } from 'lodash'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import SappBaseTable from 'src/common/SappBaseTable'
import ActionCell from 'src/components/base/action/ActionCell'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { FACILITY_STATUS, FACILITY_STATUS_ENUM, PageLink, ROOM_TYPE_ENUM } from 'src/constants'
import { CONFIRM_STATUS_CHANGE, DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IRoom, IRoomList } from 'src/type/area'
import { ICommonTable } from 'src/type/common'
import { cleanParamsAPI, mappingAddress } from 'src/utils'

interface IProps extends ICommonTable {
  roomList?: IRoomList
  fetchRoomList: (currentPage: number, pageSize: number, params?: Object) => void
  getParams: (
    search?: string,
    facility_id?: string,
    type?: string,
    status?: string
  ) => {
    search?: string
    facility_id?: string
    type?: string
    status?: string
  }
  queryParams: {
    search: string
    type: string
    facility_id: string
    status: string
  }
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Name',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Code',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Facility',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Type',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Capacity',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Deferred student',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'new student',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'mode',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'status',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'address',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Action',
    className: 'pe-5 w-25px',
  },
]

const RoomTable = ({
  roomList,
  loading,
  setLoading,
  filterParams,
  fetchRoomList,
  getParams,
  queryParams,
  isCheckedAll,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()
  const { control, setValue } = useForm()

  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams?.search?.trimStart()?.trimEnd() ?? '',
      queryParams?.facility_id ?? '',
      queryParams?.type ?? '',
      queryParams?.status ?? ''
    )
  )

  const handleDelete = (id: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        try {
          await ClassroomApi.deleteRoom(id)
          delete filterParamsPagination.search
          const filterParam = { ...filterParamsPagination }
          if (queryParams?.search?.trimStart()?.trimEnd()) {
            filterParam['search'] = `name=${queryParams?.search
              ?.trimStart()
              ?.trimEnd()},code=${queryParams?.search?.trimStart()?.trimEnd()}`
          }
          fetchRoomList(1, 10, filterParam)
          toast.success('Delete Successfully!')
        } catch (err) {}
      },
    })
  }

  const handleChangeStatus = (status: string, id?: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [CONFIRM_STATUS_CHANGE],
      onClick: async () => {
        try {
          await ClassroomApi.editRoom(id, { status })
          delete filterParamsPagination.search
          const filterParam = { ...filterParamsPagination }
          if (queryParams?.search?.trimStart()?.trimEnd()) {
            filterParam['search'] = `name=${queryParams?.search
              ?.trimStart()
              ?.trimEnd()},code=${queryParams?.search?.trimStart()?.trimEnd()}`
          }
          fetchRoomList(1, 10, filterParam)
          toast.success('Update Status Successfully!')
        } catch (err) {
          const resetValue =
            status === FACILITY_STATUS_ENUM.CLOSE
              ? FACILITY_STATUS_ENUM.ACTIVE
              : FACILITY_STATUS_ENUM.CLOSE
          setValue(`status-${id}`, resetValue)
        }
      },
      onClose: () => {
        const resetValue =
          status === FACILITY_STATUS_ENUM.CLOSE
            ? FACILITY_STATUS_ENUM.ACTIVE
            : FACILITY_STATUS_ENUM.CLOSE
        setValue(`status-${id}`, resetValue)
      },
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchRoomList(page_index || 1, page_size || 10)
  }, [])

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      name: currenPage,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchRoomList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  return (
    <>
      {contextHolder}
      <SappBaseTable
        headers={headers}
        loading={loading}
        data={roomList?.classRooms}
        isCheckedAll={isCheckedAll}
        hasCheck={false}
        dataResponse={roomList}
        handlePaginationChange={handlePaginationChange}
        totalItems={roomList?.metadata.total_records}
      >
        {roomList?.classRooms?.map((room: IRoom, index: number) => {
          const address = mappingAddress([
            room?.address,
            room?.facility?.address,
            room?.facility?.ward?.name_with_type,
            room?.facility?.ward?.district?.name_with_type,
            room?.facility?.ward?.district?.province?.name_with_type,
          ])
          return (
            <tr key={room?.id}>
              <td>
                {index + 1 + (roomList?.metadata?.page_index - 1) * roomList?.metadata?.page_size}
              </td>
              <td className='text-start text-hover-primary cursor-pointer  text-gray-800'>
                <Link
                  to={`${PageLink.CLASSROOM_ROOM_DETAIL}/${room?.id}/overview`}
                  className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                >
                  {room?.name ?? '--'}
                </Link>
              </td>
              <td className='text-start'>{room?.code ?? '--'}</td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>{room?.facility?.name || '-'}</div>
              </td>
              <td className='text-start'>
                {ROOM_TYPE_ENUM[room?.type as keyof typeof ROOM_TYPE_ENUM] || '-'}
              </td>
              <td className='text-start'>{room?.capacity || '-'}</td>
              <td className='text-start'>{room?.deferred_student || '-'}</td>
              <td className='text-start'>{room?.new_student || '-'}</td>
              <td className='text-start'>{capitalize(room?.mode) || '-'}</td>
              <td className='text-start'>
                <HookFormSelectAntd
                  control={control}
                  name={`status-${room?.id}`}
                  required
                  showSearch
                  disabled={room?.facility?.status === FACILITY_STATUS_ENUM.CLOSE}
                  onChange={(value) => {
                    if (!value) return
                    handleChangeStatus(value as string, room?.id)
                  }}
                  defaultValue={room.status}
                  options={FACILITY_STATUS}
                />
              </td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>
                  <Tooltip title={address} color='white'>
                    {address || '-'}
                  </Tooltip>
                </div>
              </td>
              <td className='sapp-absolute-column'>
                <ActionCell>
                  <div
                    className='menu-item px-3'
                    onClick={() => {
                      navigate(`${PageLink.CLASSROOM_ROOM_DETAIL}/${room?.id}/setting`)
                    }}
                  >
                    <div className='menu-link px-3'>Edit</div>
                  </div>
                  <div className='menu-item px-3' onClick={() => handleDelete(room?.id)}>
                    <div className='menu-link px-3'>Delete</div>
                  </div>
                </ActionCell>
              </td>
            </tr>
          )
        })}
      </SappBaseTable>
    </>
  )
}

export default RoomTable

import { Col, Row } from 'antd'
import { Gutter, RowProps } from 'antd/es/grid/row'
import React from 'react'

interface GridLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  gutter?: Gutter | [Gutter, Gutter]
  xs?: number
  sm?: number
  xl?: number
  isListFilters?: boolean
  rowProps?: RowProps // Separate RowProps into a dedicated prop
}

const GridLayout: React.FC<GridLayoutProps> = ({
  children,
  gutter = [16, 16],
  xs = 24,
  sm,
  xl,
  rowProps = {},
  isListFilters = false,
  ...divProps
}) => {
  const colProps = {
    xs,
    sm: isListFilters ? sm ?? 12 : sm,
    xl: isListFilters ? xl ?? 6 : xl,
  }
  const flattenedChildren = React.Children.toArray(children) // Flatten children

  return (
    <div {...divProps}>
      <Row gutter={gutter} {...rowProps}>
        {flattenedChildren.map((child, index) => (
          <Col key={index} {...colProps}>
            {child}
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default GridLayout

import { ConfigProvider, Skeleton, Space, Tree } from 'antd'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import SwitcherClosed from 'src/common/CustomIcons/SwitcherClosed'
import SwitcherExpanded from 'src/common/CustomIcons/SwitcherExpanded'
import EmptyStateGraphic from 'src/common/graphic/EmptyStateGraphic'
import ActionCell from 'src/components/base/action/ActionCell'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappDrawer from 'src/components/base/SappDrawer'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { PageLink } from 'src/constants'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { DrawerProvider } from 'src/context/DrawerContext'
import { useLessonListContext } from 'src/context/LessonContext'
import { useConfirm } from 'src/hooks/use-confirm'
import { transformToAntdTreeData } from 'src/pages/courses/lessons/LessonListPage'
import LessonDrawer from '../drawer'
import './LessonList.styles.scss'

export type DrawerType = 'EDIT' | 'DETAIL' | null
const LessonList = () => {
  const { data, pageState, pageSizeState } = useLessonListContext()
  const { data: lessons, isSuccess, isLoading, isFetching } = data

  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { id } = useParams()
  const { confirm, contextHolder } = useConfirm()
  const [page, setPage] = pageState
  const [pageSize, setPageSize] = pageSizeState

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleEdit = (key: string) => {
    navigate(`${PageLink.COURSES}/${id}/lessons/${key}/edit`)
  }

  const handleViewDetail = (key: string) => {
    navigate(`${PageLink.COURSES}/${id}/lessons/${key}`)
    setDrawerOpen(true)
  }

  const { mutate: handleDelete } = useMutation({
    mutationFn: async (id: string) => {
      return await LessonApi.deleteLesson(id)
    },
    onSuccess: ({ success, error }) => {
      if (success === false) {
        toast.error(error?.message)
        return
      }

      toast.success('Delete Lesson Successfully!')
      queryClient.invalidateQueries(lessonKeys.base)
    },
  })

  return (
    <div className='h-100 d-flex flex-column lesson-list'>
      {contextHolder}
      <Space className='d-flex justify-content-end w-100'>
        <ButtonSecondary
          title='Versions'
          size='small'
          onClick={() =>
            navigate(`${PageLink.COURSES}/${id}/${PageLink.LESSONS}/${PageLink.VERSION_HISTORY}`)
          }
        />
        <ButtonIconPrimary
          title='New Lesson'
          iconName='plus'
          size='small'
          onClick={() => navigate(`${PageLink.COURSES}/${id}/lessons/create`)}
        />
      </Space>

      {isLoading || isFetching ? (
        <Skeleton />
      ) : (
        <>
          {isSuccess && transformToAntdTreeData(lessons.data.data).length === 0 && (
            <div className='grid place-items-center flex-grow-1'>
              <div className='text-center'>
                <EmptyStateGraphic />
                <p className='mt-5 text-gray-1000'>
                  There are no lessons available at the moment. Start creating one now!
                </p>
              </div>
            </div>
          )}
          <div className='mt-7'>
            {isSuccess && transformToAntdTreeData(lessons.data.data).length > 0 && (
              <ConfigProvider
                theme={{
                  components: {
                    Tree: {
                      nodeHoverBg: '#fffdf6',
                    },
                  },
                }}
              >
                <Tree
                  blockNode
                  selectable={false}
                  treeData={transformToAntdTreeData(lessons.data.data)}
                  autoExpandParent={true}
                  switcherIcon={({ expanded }) =>
                    expanded ? <SwitcherExpanded /> : <SwitcherClosed />
                  }
                  className='lesson-list__tree'
                  titleRender={(data) => {
                    return (
                      <div className='d-flex justify-content-between'>
                        <span>{data.title}</span>
                        <Space>
                          {data.isOnline && (
                            <span className='badge badge-light-info'>
                              {' '}
                              {data.isOnline && 'Online'}
                            </span>
                          )}
                          {data.id && (
                            <ActionCell>
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link'
                                  onClick={() => {
                                    handleViewDetail(data.key)
                                  }}
                                >
                                  View Detail
                                </div>
                                <div
                                  className='menu-link'
                                  onClick={() => {
                                    handleEdit(data.key)
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  className='menu-link'
                                  onClick={() => {
                                    confirm({
                                      okButtonCaption: 'Yes',
                                      cancelButtonCaption: 'No',
                                      body: [
                                        `Are you sure want to delete ${data.title}?`,
                                        'This action cannot be reversed.',
                                      ],
                                      onClick: () => handleDelete(data.key),
                                    })
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            </ActionCell>
                          )}
                        </Space>
                      </div>
                    )
                  }}
                />
              </ConfigProvider>
            )}
          </div>
          <div className='mt-auto'>
            <PagiantionSAPP
              currentPage={page}
              totalItems={lessons?.data.metadata.total_records}
              pageSize={pageSize}
              showPagination={0}
              handlePaginationChange={(page: number, pageSize: number) => {
                setPage(page)
                setPageSize(pageSize)
              }}
            />
          </div>
        </>
      )}
      <SappDrawer
        width='50%'
        open={drawerOpen}
        title={'Lesson Detail'}
        showFooter={false}
        handleSubmit={() => {}}
        handleClose={() => {
          setDrawerOpen(false)
          navigate(`${PageLink.COURSES}/${id}/lessons`)
        }}
      >
        <DrawerProvider
          value={{
            drawerOpen: drawerOpen,
            setDrawerOpen: setDrawerOpen,
          }}
        >
          <LessonDrawer />
        </DrawerProvider>
      </SappDrawer>
    </div>
  )
}

export default LessonList

import React, { FC } from 'react'

interface SwitcherExpandedProps extends React.SVGProps<SVGSVGElement> {}
const SwitcherExpanded: FC<SwitcherExpandedProps> = (props) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='10' height='8' fill='none'>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M5 8c-.393 0-.764-.198-1-.533L.25 2.133A1.407 1.407 0 0 1 .132.737 1.244 1.244 0 0 1 1.25 0h7.5c.473 0 .906.285 1.118.737.212.452.166.992-.118 1.396L6 7.467A1.225 1.225 0 0 1 5 8Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default SwitcherExpanded

import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import DeactivateAccount from 'src/components/my-profile/setting-profile/DeactivateAccount'
import EditProfileTeacher from './EditProfileTeacher'

const SettingTeacher = () => {
  const { id } = useParams()
  const [confirmDeactive, setConfirmDeactive] = useState(false)

  const blockedTeacher = async () => {
    await UsersAPI.blocked([id as string]).then(() => toast.success('Blocked Successfully!'))
  }

  return (
    <>
      <EditProfileTeacher />

      <DeactivateAccount
        confirmDeactive={confirmDeactive}
        setConfirmDeactive={setConfirmDeactive}
        blockedUser={blockedTeacher}
      />
    </>
  )
}

export default SettingTeacher

import { Select } from 'antd'
import clsx from 'clsx'
import { debounce, isEmpty, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import {
  Control,
  FieldErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import { ClassesApi } from 'src/apis/classes'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PASSWORD,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  STATUS_FORM,
} from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { teachingStatusArray } from 'src/constants/users/teacher'
import useStaffs from 'src/hooks/useStaffs'
import { EStatusUser } from 'src/type'
import { IFacilities, IFacility } from 'src/type/area'
import { IUploadFile } from 'src/type/common'
import { IExpandInfo } from 'src/type/students'
import { ITeacher, TeacherTeachableInstanceForm } from 'src/type/teacher'
import Subjects from './Subjects'
import styles from './TeacherPostForm.module.scss'

export interface IUpdateTeacher extends IExpandInfo {
  email: string
  full_name: string
  phone: number
  address?: string
  username: string
  status: string | undefined
  sex: string | null
  avatar?: any
  teacher_teachable_instances?: TeacherTeachableInstanceForm[]
  category_primary_id?: string
  attachment_files?: any[]
  facility_ids: string[]
  current_company?: string
  dob?: Date
}
interface IteacherPostForm {
  control: Control<ITeacher, any>
  setValue: UseFormSetValue<ITeacher>
  setError: UseFormSetError<ITeacher>
  errors: FieldErrors<ITeacher>
  watch: UseFormWatch<ITeacher>
  uploadSuccess?: boolean
}

const TeacherPostForm = ({
  control,
  setValue,
  setError,
  errors,
  watch,
  uploadSuccess,
}: IteacherPostForm) => {
  const [text, setText] = useState<string | undefined>(undefined)
  const [facilityList, setFacilityList] = useState<IFacilities>()
  const { id } = useParams()
  const { staffs, hasNextPage, fetchNextPage, refetch, isFetchingNextPage, isLoading } = useStaffs({
    status: EStatusUser.ACTIVE,
    text: text,
  })

  const debounceSearchStaff = debounce((e) => {
    setText(e)
  }, 300)

  useEffect(() => {
    refetch()
  }, [text])

  const handleOnchangeFile = (files: IUploadFile[]) => {
    setValue(
      'attachment_files',
      files?.map((e: IUploadFile) => ({
        id: e?.id,
        name: e?.name,
        file_key: e?.file_key,
        suffix_type: e?.suffix_type,
        type: 'FILE',
      }))
    )
  }

  const fetchFacility = async (pageIndex: number = 1, pageSize: number = 50, params?: Object) => {
    try {
      const res = await ClassesApi.getFacilities({
        page_index: pageIndex,
        page_size: pageSize,
        params,
      })
      if (res.data.metadata.page_index === 1) {
        setFacilityList(() => ({
          metadata: res?.data?.metadata,
          facilities: res?.data?.facilities ?? [],
        }))
      } else {
        setFacilityList((prev: IFacilities | undefined) => ({
          metadata: res?.data?.metadata,
          facilities: [...(prev?.facilities ?? []), ...(res?.data?.facilities ?? [])].filter(
            (item: IFacility, index: number, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }))
      }
    } catch (error) {}
  }

  const handlNextPageFacility = async (params: Object) => {
    const totalPages = facilityList?.metadata?.total_pages
    const pageIndex = facilityList?.metadata?.page_index as number
    const pageSize = facilityList?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      await fetchFacility(pageIndex + 1, pageSize, params)
    }
  }
  return (
    <div className='form d-flex flex-column flex-lg-row position-relative'>
      <div className={clsx('d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10')}>
        <div className={clsx('card card-flush sapp-pt--image', styles.avatar)}>
          <div className='card-header'>
            <div className='card-title'>
              <h2>Avatar</h2>
            </div>
          </div>

          <div className='card-body text-center pt-0'>
            <SAPPHookUploadFile
              name='avatar'
              control={control}
              setValue={setValue}
              setError={setError}
              imagePreview={avatarDefault}
              accept={ACCEPT_UPLOAD_MIME_TYPE}
              maxFileSize={DEFAULT_MAX_FILE_SIZE}
              uploadSuccess={uploadSuccess}
              removeAvatar={avatarDefault}
            />
          </div>
        </div>

        {id && (
          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Status</h2>
              </div>
            </div>

            <div className='card-body pt-0'>
              <HookFormSelectAntd
                size='large'
                name='status'
                control={control}
                dropdownStyle={{ minWidth: 'fit-content' }}
                placeholder='Status'
                filterOption={true}
                className='fs-6'
                options={STATUS_FORM}
              />
            </div>
          </div>
        )}
      </div>

      <div>
        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_ecommerce_add_product_general'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush py-4 pt-10'>
                  <div className='card-body pt-0'>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='username'
                        label='Username'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_USERNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='email'
                        label='Email'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_EMAIL}
                      />
                    </div>
                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='password'
                          label='Password'
                          type='password'
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}

                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='confirmPassword'
                          label='Confirm Password'
                          type='password'
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}
                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField control={control} name='address' label='Address' />
                      </div>
                    )}
                    <div className={`${!id ? '' : 'mb-10'} fv-row`}>
                      <HookFormTextField
                        control={control}
                        name='phone'
                        label='Phone Number'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_PHONE}
                      />
                    </div>
                    <div className={`fv-row mt-10 mb-10`}>
                      <HookFormSelectAntd
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        label='Gender'
                        required
                        options={GENDER_FORM}
                      />
                    </div>

                    <div className={`fv-row mt-10 mb-10`}>
                      <HookFormDateTime control={control} name='dob' label='D.O.B' />
                    </div>
                    <div className={`fv-row mt-10 mb-10`}>
                      <HookFormSelectMultiple
                        name='facility_ids'
                        control={control}
                        label='Facility'
                        options={
                          facilityList?.facilities?.map((item: any) => {
                            return { label: item?.name, value: item?.id }
                          }) ?? []
                        }
                        required
                        onFocus={(e) => {
                          fetchFacility(1, 50)
                        }}
                        onSearch={(text: string | undefined) => {
                          if (!isUndefined(text)) {
                            fetchFacility(1, 50, { search: `name=${text}` })
                          }
                        }}
                        handleNextPage={handlNextPageFacility}
                        loading={false}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='current_company'
                        label='Current company'
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <SappLabel label='File chứng chỉ đi kèm' />
                      <UploadMulti
                        fileList={watch('attachment_files')}
                        setFileList={handleOnchangeFile}
                        isScreenList={false}
                        error={errors.attachment_files}
                        resourceLocation={RESOURCE_LOCATION.UPLOAD}
                        label='Resources'
                        disabled={false}
                        guideline={[
                          'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx, zip. Kích thước tối đa 500MB.',
                        ]}
                        object_id={undefined}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='job_title' label='Job Title' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormSelectAntd
                        size='large'
                        control={control}
                        name='teacher_status'
                        label='Teaching Status'
                        required
                        showSearch
                        classNameHeight='sapp-h-40'
                        options={teachingStatusArray}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <SappLabel label={'Person in Charge'} required={true} />
                      <HookFormSelectMultiple
                        name={'staff_ids'}
                        control={control}
                        placeholder='Person in Charge'
                        options={
                          staffs
                            ? staffs?.map((staff) => {
                                return {
                                  value: staff.id,
                                  label: staff.username,
                                }
                              })
                            : staffs
                        }
                        defaultValue={[]}
                        loading={isLoading || isFetchingNextPage}
                        onSearch={(e: any) => {
                          debounceSearchStaff(e)
                        }}
                        handleNextPage={() => hasNextPage && fetchNextPage()}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='facebook' label='Facebook' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='linkedin' label='LinkedIn' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='youtube' label='YouTube' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormEditor
                        control={control}
                        name='describe'
                        placeholder=''
                        label='Describe'
                        resourceLocation={RESOURCE_LOCATION.USER}
                        object_id={''}
                        setDataFile={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errors?.category_primary_id?.message && (
          <p className='text-danger my-7 text-right'>{errors?.category_primary_id?.message}</p>
        )}
        <div className='mt-7'>
          <SappLabel label={'Teaching Program'} required={true} className='mb-0' />
          <Subjects control={control} setValue={setValue} setError={setError} watch={watch} />
        </div>
      </div>
    </div>
  )
}

export default TeacherPostForm

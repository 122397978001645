import clsx from 'clsx'
import React from 'react'
import AssignedTo from 'src/components/base/assigned'
import UserStatusCell from 'src/components/user-management/UserStatusCell'

interface IProps {
  title: string
  body?: string | undefined | number | null | React.ReactNode
  roles?: Array<any>
  status?: 'ACTIVE' | 'BLOCKED' | 'INACTIVE' | 'MERGED'
  align?: 'align-self-center' | 'align-self-start'
}

const ItemProfile = ({ title, body, roles, status, align = 'align-self-center' }: IProps) => {
  return (
    <div className='row mb-7'>
      <label className={clsx(`col-6 col-lg-4 fw-semibold text-muted`, align)}>{title}</label>

      {body && (
        <div className='col-6 col-lg-8'>
          <span className='fw-semibold text-gray-800 fs-6'>{body}</span>
        </div>
      )}

      {roles && (
        <div className='col-6 col-lg-8'>
          <AssignedTo roles={roles} />
        </div>
      )}

      {status && (
        <div className='col-6 col-lg-8'>
          <UserStatusCell status={status} />
        </div>
      )}
    </div>
  )
}

export default ItemProfile

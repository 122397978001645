import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import PopupViewer from 'src/common/PopupViewer'
import ClassroomCalendarApi from 'src/apis/classroom-calendar'
import { IEventDetail } from 'src/type'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { convertTimeUTCToLocalTime } from 'src/utils'
import {
  CALENDAR_ROOM_TYPE,
  CLASSROOM_STATUS_ENUM,
  FORMAT_DATETIME,
  REPEAT_TYPE,
} from 'src/constants'
import { getDayOrWeekday } from 'src/helper/calendarHelper'
import toast from 'react-hot-toast'
import { IEvent } from 'sapp-common-package/dist/types'
import dayjs from 'dayjs'
import './style.css'
import { Spin } from 'antd'
import { useConfirm } from 'src/hooks/use-confirm'
import { DELETE_POPUPCONFIRM } from 'src/constants/lang'

interface IProps {
  open: { open: boolean; id: string; date: Date | null; data: IEvent | null }
  roomId: string
  setOpen: Dispatch<SetStateAction<IOpenData>>
  onEdit: () => void
  onDeleteRecuring: (id: string, startDate?: Date, endDate?: Date) => void
  onDetailEvent: (
    event: IEventDetail,
    data: {
      open: boolean
      id: string
      date: Date | null
      data: IEvent | null
    }
  ) => void
  onRefresh: () => void
}

interface IOpenData {
  open: boolean
  id: string
  date: Date | null
  status: 'view' | 'edit' | 'create' | null
  data: IEvent | null
}

const DetailCalendarRoom = ({
  open,
  setOpen,
  roomId,
  onEdit,
  onDeleteRecuring,
  onDetailEvent,
  onRefresh,
}: IProps) => {
  const [event, setEvent] = useState<IEventDetail | null>()
  const [loading, setLoading] = useState(false)
  const { confirm, contextHolder } = useConfirm()
  const handleCancel = () => {
    setEvent(null)
    setOpen && setOpen({ open: false, id: '', date: null, status: null, data: null })
  }
  const fetchEvent = async () => {
    setLoading(true)
    try {
      const res = await ClassroomCalendarApi.getSheduleRoomDetail(open.id, roomId)
      onDetailEvent(res.data, open)
      setEvent(res.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const genEventType = (type?: string, text?: string) => {
    if (type === CALENDAR_ROOM_TYPE.LEARNING) {
      return <span className='badge-info'>{text ?? 'Lịch học'}</span>
    }
    if (type === CALENDAR_ROOM_TYPE.RENTAL) {
      return <span className='badge-success'>{text ?? 'Lịch thuê'}</span>
    }
    return <span className='badge-warning'>{text ?? 'Lịch nghỉ lễ năm'}</span>
  }

  const handleEdit = () => {
    onEdit && onEdit()
    setOpen({ open: false, id: open.id, date: open.date, status: 'edit', data: null })
  }

  const repeatType = useMemo(() => {
    const weekdays = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']
    if (!event?.schedule?.recurring_pattern_schedule && !event?.schedule?.is_holiday) {
      return 'Does not repeat'
    }
    const startDate = event?.schedule.start_date
      ? `${dayjs(open?.date).format('YYYY-MM-DD')}T${convertTimeUTCToLocalTime(
          event?.schedule.start_time
        )}`
      : ''
    const localTime = new Date(startDate)
    const endDate =
      event?.schedule?.recurring_pattern_schedule?.end_date &&
      dayjs(
        `${dayjs(event?.schedule?.recurring_pattern_schedule?.end_date).format(
          'YYYY-MM-DD'
        )}T16:59:59Z`
      ).format('MMM D, YYYY')
    switch (event?.schedule?.recurring_pattern_schedule?.type) {
      case REPEAT_TYPE.NO_REPEAT:
        return 'Does not repeat'
      case REPEAT_TYPE.DAILY:
        return 'Daily'
      case REPEAT_TYPE.WEEKLY:
        return `Weekly on ${getDayOrWeekday(localTime, 'weekday')}`
      case REPEAT_TYPE.MONTHLY:
        return `Monthly on ${getDayOrWeekday(localTime, 'monthly')}`
      case REPEAT_TYPE.YEARLY:
        return `Annualy on the  ${getDayOrWeekday(localTime, 'monthDay')} `
      case REPEAT_TYPE.EVERYDAY:
        return `Every Weekday`
      case REPEAT_TYPE.CUSTOM: {
        const letter = event.schedule.recurring_pattern_schedule.interval > 1 ? 's' : ''
        const interval = event?.schedule?.recurring_pattern_schedule.interval
        if (event.schedule.recurring_pattern_schedule.frequency === 'weeks') {
          return interval === 1
            ? `Repeat weekly, until ${endDate}`
            : `Repeat every ${event?.schedule?.recurring_pattern_schedule.interval} week${letter}, until ${endDate}`
        } else if (event.schedule.recurring_pattern_schedule.frequency === 'days') {
          return interval === 1
            ? `Repeat daily, until ${endDate}`
            : `Occurs every ${event?.schedule?.recurring_pattern_schedule.interval} day${letter}, until ${endDate}`
        } else if (event.schedule.recurring_pattern_schedule.frequency === 'months') {
          return interval === 1
            ? `Repeat monthly, until ${endDate}`
            : `Repeat every ${event?.schedule?.recurring_pattern_schedule.interval} month${letter}, until ${endDate}`
        } else {
          return interval === 1
            ? `Repeat yearly, until ${endDate}`
            : `Repeat every ${event?.schedule?.recurring_pattern_schedule.interval} year${letter},, until ${endDate}`
        }
      }
    }
  }, [event])

  const handleDeleteSingle = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DELETE_POPUPCONFIRM],
      onClick: async () => {
        try {
          await ClassroomCalendarApi.deleteScheduleRental(open.id, roomId)
          toast.success('Delete Successfully')
          onRefresh()
          setOpen({ open: false, id: '', date: null, status: null, data: null })
        } catch (error) {}
      },
      onClose: () => {
        setOpen((prev: IOpenData) => ({ ...prev, open: true }))
      },
    })
  }

  const handleDelete = async () => {
    if (!event?.schedule.recurring_pattern_id) {
      setOpen((prev: IOpenData) => ({ ...prev, open: false }))
      handleDeleteSingle()
    } else {
      onDeleteRecuring(open.id, open.data?.startDate, open.data?.endDate)

      setOpen({ open: false, id: open.id, date: null, status: null, data: null })
    }
  }

  useEffect(() => {
    if (open.id && open.open) {
      fetchEvent()
    } else {
      setEvent(null)
    }
  }, [open])

  return (
    <>
      {contextHolder}
      <PopupViewer title='Event Infomation' open={open?.open} onClose={handleCancel} size='lg'>
        {loading ? (
          <div className='w-full d-flex justify-content-center align-items-center h-100 min-h-300px'>
            <Spin size='large' />
          </div>
        ) : (
          <div className='px-3'>
            <div className='row sapp-border-info h-60px align-items-center'>
              <div className='col-4 sapp-wawrning-text'>Event name</div>
              <div className='col-8 fw-semibold'>{open?.data?.title ?? '--'}</div>
            </div>
            <div className='row sapp-border-info h-60px align-items-center'>
              <div className='col-4 sapp-wawrning-text'>Event type</div>
              <div className='col-8'>
                <div className='d-flex align-items-center fw-semibold'>
                  <div>{genEventType(event?.type)}</div>
                </div>
              </div>
            </div>
            {event?.type === CALENDAR_ROOM_TYPE.LEARNING && (
              <div className='row sapp-border-info h-60px align-items-center'>
                <div className='col-4 sapp-wawrning-text'>Class code</div>
                <div className='col-8 fw-semibold'>{event?.class?.code ?? '--'}</div>
              </div>
            )}
            {event?.type === CALENDAR_ROOM_TYPE.RENTAL && (
              <div className='row sapp-border-info h-60px align-items-center'>
                <div className='col-4 sapp-wawrning-text'>Lessee</div>
                <div className='col-8 fw-semibold'>{event?.lessee_tags[0]?.name ?? '--'}</div>
              </div>
            )}
            <div className='row sapp-border-info h-60px align-items-center'>
              <div className='col-4 sapp-wawrning-text'>Start time</div>
              <div className='col-8 fw-semibold'>
                {open.data?.startDate ? dayjs(open.data?.startDate).format(FORMAT_DATETIME) : ''}
              </div>
            </div>
            <div
              className={`row ${open.id !== '' && 'sapp-border-info'} h-60px align-items-center`}
            >
              <div className='col-4 sapp-wawrning-text'>End time</div>
              <div className='col-8 fw-semibold'>
                {open.data?.endDate ? dayjs(open.data?.endDate).format(FORMAT_DATETIME) : ''}
              </div>
            </div>
            {event?.type === CALENDAR_ROOM_TYPE.RENTAL && (
              <div className='row sapp-border-info h-60px align-items-center'>
                <div className='col-4 sapp-wawrning-text'>Repeat</div>
                <div className='col-8'>
                  <div className='fw-semibold'>{repeatType}</div>
                </div>
              </div>
            )}
            {(event?.type === CALENDAR_ROOM_TYPE.LEARNING ||
              event?.type === CALENDAR_ROOM_TYPE.RENTAL) && (
              <div className='row h-60px align-items-center'>
                <div className='col-4 sapp-wawrning-text'>Description</div>
                <div className='col-8'>
                  <div dangerouslySetInnerHTML={{ __html: open.data?.description ?? '' }}></div>
                </div>
              </div>
            )}
            {event?.type === CALENDAR_ROOM_TYPE.RENTAL &&
              event.room.status !== CLASSROOM_STATUS_ENUM.CLOSE && (
                <div className='d-flex w-100 justify-content-center align-items-center gap-5 mt-5'>
                  <ButtonSecondary title='Delete' onClick={handleDelete} />
                  <ButtonPrimary title='Edit Event' onClick={handleEdit} />
                </div>
              )}
          </div>
        )}
      </PopupViewer>
    </>
  )
}

export default DetailCalendarRoom

import React from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'

/**
 * Props cho component ModalHeader
 */
interface SelectionConfirmModalHeaderProps {
  /**
   * Tiêu đề của modal
   */
  title: string
  /**
   * Số lượng item đã chọn
   */
  selectedCount: number
  /**
   * Hiển thị số lượng item đã chọn hay không
   */
  showSelectedCount: boolean
  /**
   * Nội dung nút hủy
   */
  cancelButtonCaption: string
  /**
   * Nội dung nút xác nhận
   */
  okButtonCaption: string
  /**
   * Hàm xử lý khi nhấn nút hủy
   */
  onCancel: () => void
  /**
   * Hàm xử lý khi nhấn nút xác nhận
   */
  onSubmit: () => void
  /**
   * Trạng thái loading
   */
  loading?: boolean
}

/**
 * Component hiển thị header của modal với tiêu đề và các nút điều khiển
 *
 * @param {SelectionConfirmModalHeaderProps} props - Props của component
 * @returns {JSX.Element} Component ModalHeader
 */
const SelectionConfirmModalHeader: React.FC<SelectionConfirmModalHeaderProps> = ({
  title,
  selectedCount,
  showSelectedCount,
  cancelButtonCaption,
  okButtonCaption,
  onCancel,
  onSubmit,
  loading = false,
}) => {
  return (
    <>
      <div className='sapp-title-modal-create-class fs-3'>{title}</div>
      <div className='d-flex justify-content-end align-items-center'>
        {showSelectedCount && (
          <div className='fw-bold fs-6 text-primary me-8'>{selectedCount} Selected</div>
        )}
        <SAPPDialogButtonsCancelSubmit
          type='button'
          cancelClick={onCancel}
          cancelButtonCaption={cancelButtonCaption}
          okButtonCaption={okButtonCaption}
          okOnClick={onSubmit}
          className='justify-content-between d-flex flex-row-reverse'
          classNameCancel='fw-bold me-0'
          classNameSubmit='fw-bold me-5'
          loading={loading}
        />
      </div>
    </>
  )
}

export default SelectionConfirmModalHeader

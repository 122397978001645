import { fetcher } from 'src/services/request'
import {
  getContentListRes,
  IGetLessonRes,
  ILessonForm,
  ILessonsRes,
  IResponse,
  sortType,
} from 'src/type'
import { VersionHistoryRes } from 'src/type/courses/versionHistory'

interface getListParam {
  page_index?: number
  page_size?: number
  course_id: string
  version?: string
}
interface getContentListParam {
  lesson_id?: string
}

const CourseTimeline = 'course-timeline'

export class LessonApi {
  static getList(params: getListParam): Promise<IResponse<ILessonsRes>> {
    return fetcher(`/${CourseTimeline}`, {
      params: params,
    })
  }

  static getContentList(
    courseId: string,
    params?: getContentListParam
  ): Promise<IResponse<getContentListRes>> {
    return fetcher(`/${CourseTimeline}/${courseId}/content-list`, {
      params: params,
    })
  }

  static createLesson(data: ILessonForm): Promise<IResponse<any>> {
    return fetcher(`/${CourseTimeline}`, {
      data: data,
      method: 'POST',
    })
  }

  static editLesson(data: ILessonForm, id: string): Promise<IResponse<any>> {
    return fetcher(`/${CourseTimeline}/${id}`, {
      data: data,
      method: 'PUT',
    })
  }

  static getLesson(id: string): Promise<IResponse<IGetLessonRes>> {
    return fetcher(`/${CourseTimeline}/${id}`)
  }

  static deleteLesson(id: string): Promise<IResponse<IGetLessonRes>> {
    return fetcher(`/${CourseTimeline}/${id}`, {
      method: 'DELETE',
    })
  }

  static getVersionHistory(
    params: getListParam & { sort?: sortType }
  ): Promise<IResponse<VersionHistoryRes>> {
    return fetcher(`/${CourseTimeline}/${params.course_id}/version`, {
      params: {
        page_index: params.page_index,
        page_size: params.page_size,
        sort: params.sort,
      },
    })
  }

  static restoreLessonVersion(course_id: string, version: string): Promise<IResponse<any>> {
    return fetcher(`/${CourseTimeline}/${course_id}/version`, {
      data: {
        version: version,
      },
      method: 'PUT',
    })
  }
}

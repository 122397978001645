import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { LessonFormProvider } from 'src/context/LessonContext'
import LessonDetail from '../detail'

const LessonDrawer = () => {
  const { lessonId } = useParams()

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: lessonKeys.getOne(lessonId!),
    queryFn: () =>
      lessonId ? LessonApi.getLesson(lessonId) : Promise.reject('No course_id provided'),
  })

  isLoading && <div />
  return isSuccess ? (
    <LessonFormProvider
      value={{
        data: data.data,
      }}
    >
      <LessonDetail />
    </LessonFormProvider>
  ) : (
    <div />
  )
}

export default LessonDrawer

import { useQuery } from 'react-query'
import { CoursesAPI } from 'src/apis/courses'
import { courseCategoryQueryKeys, Pagination } from 'src/constants'
import { Program } from 'src/type'
import { ICourseCategories, ICourseCategory } from 'src/type/courses'

const useCourseCategories = (name: Program) => {
  // Step 2: Use React Query to fetch the data
  const {
    data: courseCategories,
    isLoading,
    error,
  } = useQuery<ICourseCategories, unknown, ICourseCategory>(
    courseCategoryQueryKeys.list({
      page_index: Pagination.INDEX,
      page_size: Pagination.SIZE,
      params: { with_children: true, name },
    }),
    async () => {
      const res = await CoursesAPI.getCategory({
        page_index: Pagination.INDEX,
        page_size: Pagination.SIZE,
        params: {
          with_children: true,
          name,
        },
      })
      return res?.data
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      select: (data) => {
        // Return the first category after transformation
        return data?.course_categories[0]
      },
      enabled: !!name,
    }
  )

  // Step 4: Return both the state and the setter function along with other data
  return { courseCategories, isLoading, error }
}

export default useCourseCategories

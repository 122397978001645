import { Select } from 'antd'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import SappBaseTable from 'src/common/SappBaseTable'
import ActionCell from 'src/components/base/action/ActionCell'
import Search from 'src/components/base/search'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import { FACILITY_STATUS, FACILITY_STATUS_ENUM, PageLink, ROOM_TYPE_ENUM } from 'src/constants'
import { CONFIRM_STATUS_CHANGE, DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IFacility, IRoom, IRoomList } from 'src/type/area'
import { cleanParamsAPI, mappingAddress } from 'src/utils'

const headers = [
  {
    label: 'Classroom',
    key: 'code',
    className: 'fs-7 fw-bold min-w-200px',
  },
  {
    label: 'code',
    key: 'code',
    className: 'min-w-100px',
  },
  {
    label: 'type',
    key: 'type',
    className: 'fs-7 fw-bold min-w-100px',
  },
  {
    label: 'capacity',
    key: 'capacity',
    className: 'min-w-80px',
  },
  {
    label: 'deferred students',
    key: 'deferred_students',
    className: 'fs-7 fw-bold min-w-150px',
  },
  {
    label: 'new student',
    key: 'new_student',
    className: 'fs-7 fw-bold min-w-150px',
  },
  {
    label: 'status',
    key: 'status',
    className: 'min-w-100px',
  },
  {
    label: 'address',
    key: 'address',
    className: 'fs-7 fw-bold min-w-200px',
  },
]
interface IProps {
  loading: boolean
  areaDetail: IFacility | undefined
}
const initialValues: { search: string } = {
  search: '',
}

const getParams = (search: string) => ({
  search,
})

const AreaProfileRoomList = ({ loading, areaDetail }: IProps) => {
  const navigate = useNavigate()
  const { contextHolder, confirm } = useConfirm()
  const [roomList, setRoomList] = useState<IRoomList>()
  const { control, setValue } = useForm()

  const [searchParams, setSearchParams] = useState<any>(initialValues)
  const loadData = async (pageIndex: number, pageSize: number, params?: Object) => {
    try {
      const res = await ClassroomApi.getRoomList({
        page_index: pageIndex,
        page_size: pageSize,
        params,
      })
      setRoomList(res.data)
    } catch (error) {}
  }

  const handleDelete = (id: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        try {
          await ClassroomApi.deleteRoom(id)
          const cleanedParams = cleanParamsAPI(getParams(searchParams?.search))
          loadData(1, 10, cleanedParams)
          toast.success('Delete Successfully!')
        } catch (err) {}
      },
    })
  }

  const handleChangeStatus = (status: string, id?: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [CONFIRM_STATUS_CHANGE],
      onClick: async () => {
        try {
          await ClassroomApi.editRoom(id, { status })
          loadData(1, 10, { facility_id: areaDetail?.id })
          toast.success('Update Status Successfully!')
        } catch (err) {
          const resetValue =
            status === FACILITY_STATUS_ENUM.CLOSE
              ? FACILITY_STATUS_ENUM.ACTIVE
              : FACILITY_STATUS_ENUM.CLOSE
          setValue(`status-${id}`, resetValue)
        }
      },
      onClose: () => {
        const resetValue =
          status === FACILITY_STATUS_ENUM.CLOSE
            ? FACILITY_STATUS_ENUM.ACTIVE
            : FACILITY_STATUS_ENUM.CLOSE
        setValue(`status-${id}`, resetValue)
      },
    })
  }

  const handleSearchChange = (e: any) => {
    const cleanedParams = {
      search: `name=${e.target.value.toLowerCase()},code=${e.target.value.toLowerCase()} `,
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const handleSearch = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.search))
    loadData(1, roomList?.metadata?.page_size || 10, cleanedParams)
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.search))
    loadData(page_index, page_size, cleanedParams)
  }

  useEffect(() => {
    loadData(1, 10, { facility_id: areaDetail?.id })
  }, [])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10 flex sapp-min-h-100vh'>
        <div className='py-8 px-10 '>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${clsx({
              ' mb-5': roomList?.metadata?.total_records,
            })}`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search classroom'}
                smallSearch={true}
                onSubmit={handleSearch}
                isListScreen
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearch()
                }}
                className='ms-4'
                loading={loading}
                size='small'
              />
            </div>
            {areaDetail?.status === FACILITY_STATUS_ENUM.ACTIVE && (
              <ButtonIconPrimary
                iconName={'plus'}
                title={'Create ClassRoom'}
                onClick={() =>
                  navigate(
                    `${PageLink.CLASSROOM_ROOM_CREATE}?area_name=${areaDetail?.name}&facility_id=${areaDetail?.id}`
                  )
                }
                size='small'
              />
            )}
          </div>
          <h5 className='fw-bold fs-6 mt-8'>
            Danh sách classroom ({roomList?.metadata.total_records || 0})
          </h5>
          <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
            <div className='container'>
              <div className='row px-4 py-8 sapp-overflow-y-layout'>
                <SappBaseTable
                  headers={headers}
                  loading={loading}
                  data={roomList?.classRooms}
                  hasCheck={false}
                  dataResponse={roomList}
                  handlePaginationChange={handlePaginationChange}
                  totalItems={roomList?.metadata.total_records}
                  showHashtag
                >
                  {roomList?.classRooms?.map((item: IRoom, idx: number) => {
                    const roomType =
                      ROOM_TYPE_ENUM[item?.type as keyof typeof ROOM_TYPE_ENUM] || '-'
                    const address = mappingAddress([
                      item?.address,
                      areaDetail?.address,
                      item?.facility?.ward?.name_with_type,
                      item?.facility?.ward?.district?.name_with_type,
                      item?.facility?.ward?.district?.province?.name_with_type,
                    ])
                    return (
                      <tr className='border-0' key={item?.id ?? idx}>
                        <td>
                          {idx +
                            1 +
                            (roomList?.metadata?.page_index - 1) * roomList?.metadata?.page_size}
                        </td>
                        <td className='text-start'>
                          <Link
                            className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                            to={`${PageLink.CLASSROOM_ROOM_DETAIL}/${item.id}/detail`}
                          >
                            {item?.name}
                          </Link>
                        </td>
                        <td className='text-start'>{item?.code}</td>
                        <td className='text-start'>{roomType}</td>
                        <td className='text-start'>{item?.capacity}</td>
                        <td className='text-start'>{item?.deferred_student}</td>
                        <td className='text-start'>{item?.new_student}</td>
                        <td className='text-start'>
                          <HookFormSelectAntd
                            control={control}
                            name={`status-${item?.id}`}
                            required
                            showSearch
                            disabled={areaDetail?.status === FACILITY_STATUS_ENUM.CLOSE}
                            onChange={(value) => {
                              if (!value) return
                              handleChangeStatus(value as string, item.id)
                            }}
                            defaultValue={item?.status}
                            options={FACILITY_STATUS}
                          />
                        </td>
                        <td className='text-start'>{address}</td>
                        <td className='text-start'>
                          <ActionCell>
                            <div
                              className='menu-item px-3'
                              onClick={() =>
                                navigate(`${PageLink.CLASSROOM_ROOM_DETAIL}/${item.id}/setting`)
                              }
                            >
                              <div className='menu-link px-3'>Edit</div>
                            </div>
                            <div
                              className='menu-item px-3'
                              onClick={() => {
                                handleDelete(item?.id)
                              }}
                            >
                              <div className='menu-link px-3'>Delete</div>
                            </div>
                          </ActionCell>
                        </td>
                      </tr>
                    )
                  })}
                </SappBaseTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AreaProfileRoomList

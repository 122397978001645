import { PROGRAM } from 'src/constants'
import { createRequiredContext } from 'src/helper/createRequiredContext'
import { IExamDetail } from 'src/type'

interface IExamProvider {
  data?: IExamDetail
  program?: PROGRAM
}

export const [ExamProvider, useExam] = createRequiredContext<IExamProvider>()

import { fetcher } from 'src/services/request'
import { ICalendarReq, ILesseeTag, IResponse } from 'src/type'

export default class ClassroomCalendarApi {
  static getSheduleRoomDetail = (id: string, roomId: string) => {
    return fetcher(`/room-schedules/${id}/room/${roomId}`)
  }

  static getScheduleOfRoom = (params: object) => {
    return fetcher(`/room-schedules`, {
      params,
    })
  }

  static createScheduleRental = (data: ICalendarReq) => {
    return fetcher('/room-schedules/room', {
      data,
      method: 'POST',
    })
  }

  static editScheduleRental = (id: string, roomId: string, data: ICalendarReq) => {
    return fetcher(`/room-schedules/${id}/room/${roomId}`, {
      data,
      method: 'PUT',
    })
  }

  static getLesseTag = (params?: object): Promise<IResponse<ILesseeTag>> => {
    return fetcher(`/lessee_tags`, {
      params,
    })
  }

  static deleteScheduleRental = (
    id: string,
    roomId: string,
    data?: {
      approve_all_event: boolean
      old_start_date: Date | string
      old_end_date: Date | string
    }
  ) => {
    return fetcher(`/room-schedules/${id}/room/${roomId}`, {
      method: 'DELETE',
      data,
    })
  }
}

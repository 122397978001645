import { Dispatch, SetStateAction } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { MY_PROFILE } from 'src/constants'
// import ActivityProfile from '../my-profile/activity-profile'
// import BillingProfile from '../my-profile/billing-profile'
// import LogsProfile from '../my-profile/logs-profile'
// import ReferralsProfile from '../my-profile/refferals-profile'
// import SecurityProfile from '../my-profile/security-profile'
// import StatementsProfile from '../my-profile/stament-profile'
import { IStaffDetail } from 'src/type/staffs.'
import OverviewStaffProfile from './staff-overview'
import SettingStaff from './staff-setting'

interface IProps {
  staffDetail: IStaffDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setStaffDetail: Dispatch<SetStateAction<any>>
}

const RenderStaffProfile = ({ staffDetail, setLoading, loading, setStaffDetail }: IProps) => {
  const location = useLocation()
  const { id } = useParams()

  const renderProfile = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case MY_PROFILE.OVERVIEW:
        return (
          <OverviewStaffProfile
            staffDetail={staffDetail}
            loading={loading}
            setStaffDetail={setStaffDetail}
          />
        )
      case MY_PROFILE.SETTING:
        return (
          <SettingStaff
            staffDetail={staffDetail}
            // setLoading={setLoading}
            setStaffDetail={setStaffDetail}
            // loading={loading}
          />
        )
      // case MY_PROFILE.SECURITY:
      //   return <SecurityProfile />
      // case MY_PROFILE.ACTIVITY:
      //   return <ActivityProfile />
      // case MY_PROFILE.BILLING:
      //   return <BillingProfile />
      // case MY_PROFILE.STATEMENTS:
      //   return <StatementsProfile />
      // case MY_PROFILE.CERTIFICATE:
      //   return <ReferralsProfile />
      // case MY_PROFILE.LOGS:
      //   return <LogsProfile />
      default:
        return (
          <OverviewStaffProfile
            staffDetail={staffDetail}
            loading={loading}
            setStaffDetail={setStaffDetail}
          />
        )
    }
  }
  return <>{renderProfile()}</>
}

export default RenderStaffProfile

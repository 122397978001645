import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { cleanParamsAPI, getStaffFacilityPosition } from 'src/utils'
import SappTable from 'src/components/ui/table/SappTable'
import { replaceValueAll } from 'src/utils/string'
import { IStaffFacilityList, IStaffFacility, IDepartmentRes, IStaffPositions } from 'src/type'
import ClassroomApi from 'src/apis/classroom'
import { DepartmentAPI } from 'src/apis/department'
import { ICourseCategories } from 'src/type/courses'
import { CoursesAPI } from 'src/apis/courses'
import LayoutTable from 'src/common/LayoutTable'
import FilterLayout from './FilterLayout'
import { TablePaginationConfig } from 'antd'
import { KTCardBody } from 'src/_metronic/helpers'

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  staffState: IStaffFacilityList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchListStaffs: (page_index: number, page_size: number, params?: Object) => void
  pageSize?: number
  getParams: (
    text: string,
    course_category_id?: string,
    staff_position_id?: string,
    department_ids?: string[]
  ) => {
    text: string
    course_category_id?: string
    staff_position_id?: string
    department_ids?: string[]
  }
  queryParams: {
    text: string
    course_category_id?: string
    staff_position_id?: string
    department_ids?: string[]
    page_index: number
    page_size: number
  }
  selected: Map<number, React.Key[]>
  setSelected: Dispatch<SetStateAction<Map<number, React.Key[]>>>
}

interface IFilterOptions {
  departmentList: IDepartmentRes
  positionList: IStaffPositions
  programList: ICourseCategories
}

interface IParams {
  text?: string
  department_ids?: string[]
  staff_position_id?: string
  course_category_id?: string
}

// define columns for SappTable
const columns = [
  {
    title: 'Code',
    dataIndex: 'key',
    key: 'key',
    render: (text: string) => <span className='text-start'>{text}</span>,
  },
  {
    title: 'Name',
    dataIndex: ['detail', 'full_name'],
    key: 'name',
    render: (text: string) => <span className='text-start'>{text}</span>,
  },
  {
    title: 'Phone Number',
    dataIndex: ['detail', 'phone'],
    key: 'phone',
    render: (text: string) => <span className='text-start'>{text}</span>,
  },
  {
    title: 'Email',
    dataIndex: ['detail', 'email'],
    key: 'email',
    render: (text: string) => <span className='text-start'>{text}</span>,
  },
  {
    title: 'Job position',
    key: 'position',
    render: (_: IStaffFacility, record: IStaffFacility) => (
      <span className='text-start'>{getStaffFacilityPosition(record)}</span>
    ),
  },
  {
    title: 'Program',
    dataIndex: ['course_category', 'name'],
    key: 'program',
    render: (text: string) => <span className='text-start'>{text}</span>,
  },
]

/**
 * Component hiển thị bảng danh sách nhân viên cơ sở
 *
 * @param {IProps} props - Các props được truyền vào component
 * @param {number} props.currentPage - Trang hiện tại
 * @param {Dispatch<SetStateAction<number>>} props.setCurrentPage - Hàm cập nhật trang hiện tại
 * @param {IStaffFacilityList | undefined} props.staffState - Trạng thái danh sách nhân viên
 * @param {boolean} props.loading - Trạng thái loading
 * @param {Dispatch<SetStateAction<boolean>>} props.setLoading - Hàm cập nhật trạng thái loading
 * @param {(currenPage: number, size: number) => void} props.handleChangeParams - Hàm xử lý khi thay đổi tham số
 * @param {(page_index: number, page_size: number, params?: Object) => void} props.fetchListStaffs - Hàm lấy danh sách nhân viên
 * @param {number} props.pageSize - Kích thước trang
 * @param {Function} props.getParams - Hàm lấy tham số
 * @param {Object} props.queryParams - Tham số truy vấn
 * @param {Map<number, React.Key[]>} props.selected - Danh sách các item đã chọn
 * @param {Dispatch<SetStateAction<Map<number, React.Key[]>>>} props.setSelected - Hàm cập nhật danh sách item đã chọn
 * @returns {JSX.Element} Component FacilityStaffTable
 */
const FacilityStaffTable = ({
  staffState,
  loading,
  setLoading,
  fetchListStaffs,
  getParams,
  queryParams,
  selected,
  setSelected,
}: IProps) => {
  const initialSearchParams = {
    text: '',
    department_ids: [],
    staff_position_id: '',
    course_category_id: '',
  }
  const [searchParams, setSearchParams] = useState<IParams>(initialSearchParams)
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({
    departmentList: {
      metadata: { total_pages: 0, total_records: 0, page_index: 1, page_size: 10 },
      data: [],
    },
    positionList: {
      metadata: { total_pages: 0, total_records: 0, page_index: 1, page_size: 10 },
      staffs: [],
    },
    programList: {
      metadata: { total_pages: 0, total_records: 0, page_index: 1, page_size: 10 },
      course_categories: [],
    },
  })

  const { control, getValues, reset, setValue } = useForm({
    mode: 'onSubmit',
  })

  /**
   * Xử lý khi chuyển đến trang tiếp theo
   *
   * @param {number} totalPages - Tổng số trang
   * @param {number} pageIndex - Số trang hiện tại
   * @param {number} pageSize - Kích thước trang
   * @param {(page_index: number, page_size: number, params?: Object) => void} fetchData - Hàm lấy dữ liệu
   * @param {Object} params - Tham số truy vấn
   */
  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageIndex && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }

  /**
   * Cập nhật danh sách bộ lọc
   *
   * @template T - Kiểu dữ liệu của khóa trong IFilterOptions
   * @param {T} key - Khóa của bộ lọc cần cập nhật
   * @param {any} data - Dữ liệu mới cần cập nhật
   * @param {string} listKey - Khóa danh sách dữ liệu trong object
   */
  const updateFilterList = <T extends keyof IFilterOptions>(key: T, data: any, listKey: string) => {
    setFilterOptions((prev) => ({
      ...prev,
      [key]: {
        metadata: data.metadata,
        [listKey]:
          data.metadata.page_index === 1
            ? data[listKey]
            : [...(prev[key] as any)[listKey], ...data[listKey]],
      },
    }))
  }

  /**
   * Lấy danh sách phòng ban
   *
   * @param {number} page_index - Số trang
   * @param {number} page_size - Kích thước trang
   * @param {Object} params - Tham số truy vấn
   */
  const fetchDepartment = async (
    page_index: number = 1,
    page_size: number = 10,
    params?: Object
  ) => {
    try {
      const res = await DepartmentAPI.getList({ params, page_index, page_size })

      updateFilterList('departmentList', res?.data, 'data')
    } catch (error) {
      console.error('Error fetching departments:', error)
    }
  }

  /**
   * Lấy danh sách vị trí nhân viên
   *
   * @param {number} page_index - Số trang
   * @param {number} page_size - Kích thước trang
   * @param {Object} params - Tham số truy vấn
   */
  const fetchStaffPosition = async (
    page_index: number = 1,
    page_size: number = 10,
    params?: Object
  ) => {
    const queryParams = { page_index, page_size, ...params }
    try {
      const res = await ClassroomApi.getStaffPositionsForFacility({ params: queryParams as Object })
      updateFilterList('positionList', res.data, 'staffs')
    } catch (error) {
      console.error('Error fetching titles:', error)
    }
  }

  /**
   * Lấy danh sách chương trình
   *
   * @param {number} page_index - Số trang
   * @param {number} page_size - Kích thước trang
   * @param {Object} params - Tham số truy vấn
   */
  const fetchProgram = async (page_index: number = 1, page_size: number = 10, params?: Object) => {
    try {
      const res = await CoursesAPI.getCategoryInfinite({ page_index, page_size, params })
      updateFilterList('programList', res.data, 'course_categories')
    } catch (error) {
      console.error('Error fetching programs:', error)
    }
  }

  /**
   * Đặt lại bộ lọc
   */
  const handleResetFilter = () => {
    reset()
    setValue('text', '')
    setValue('course_category_id', '')
    setValue('department_ids', '')
    setValue('staff_position_id', '')
    const cleanedParams = cleanParamsAPI(getParams('', '', '', []))
    setSearchParams(initialSearchParams)
    setLoading(true)
    fetchListStaffs(1, 10, cleanedParams)
  }

  /**
   * Xử lý khi submit form
   */
  const onSubmit = () => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('text')?.trim()),
        getValues('course_category_id'),
        getValues('staff_position_id'),
        getValues('department_ids')
      )
    )
    setSearchParams(() => {
      return cleanedParams
    })
    setLoading(true)
    fetchListStaffs(1, queryParams?.page_size || 10, cleanedParams)
  }

  /**
   * Lấy dữ liệu cho bảng
   *
   * @param {number} page - Số trang
   * @param {number} pageSize - Kích thước trang
   * @param {Record<string, any>} params - Tham số truy vấn
   */
  const fetchData = (page: number, pageSize: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim() || '',
        searchParams?.course_category_id,
        searchParams?.staff_position_id,
        searchParams?.department_ids
      )
    )
    fetchListStaffs(page, pageSize, cleanedParams)
  }

  /**
   * Lấy dữ liệu cho bảng
   *
   * @param {number} current - Số trang hiện tại
   * @param {number} pageSize - Kích thước trang
   * @param {Record<string, any>} params - Tham số truy vấn
   */
  const fetchTableData = (current: number, pageSize: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim() || '',
        searchParams?.course_category_id,
        searchParams?.staff_position_id,
        searchParams?.department_ids
      )
    )
    fetchListStaffs(current, pageSize, cleanedParams)
  }

  /**
   * Xử lý khi thay đổi tham số phân trang
   *
   * @param {number} current - Số trang hiện tại
   * @param {number} pageSize - Kích thước trang
   */
  const handleChangeParams = (current: number, pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize,
    }))
  }

  return (
    <KTCardBody className='px-0'>
      <LayoutTable
        loading={loading}
        onReset={handleResetFilter}
        onSubmit={onSubmit}
        isFullScreen
        layoutFilter={
          <FilterLayout
            control={control}
            queryParams={queryParams}
            onSubmit={onSubmit}
            filterOptions={filterOptions}
            handleNextPage={handleNextPage}
            fetchDepartment={fetchDepartment}
            fetchStaffPosition={fetchStaffPosition}
            fetchProgram={fetchProgram}
          />
        }
        layoutAction={<></>}
        layoutTable={
          <SappTable
            columns={columns}
            data={staffState?.staffs || []}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            fetchData={fetchData}
            fetchTableData={fetchTableData}
            handleChangeParams={handleChangeParams}
            showCheckbox={true}
            filterParams={searchParams}
            setSelection={setSelected}
            selections={selected}
          />
        }
      />
    </KTCardBody>
  )
}

export default FacilityStaffTable

export const classroomKeys = {
  all: () => ['classroom'] as const,
  baseList: () => [...classroomKeys.all(), 'list'],
  list: (
    page_index: number,
    page_size: number,
    params?: {
      mode?: string
      status?: string
      name?: string
      code?: string
      capacity?: number
    }
  ) => [...classroomKeys.baseList(), { page_index, page_size, ...params }] as const,
}

import { useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import ButtonDropdown from 'src/components/base/button/ButtonDropdown'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import { PageLink } from 'src/constants'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { ILessonForm } from 'src/type'
import LessonForm from '../form'
import { LessonFormMethods } from '../form/LessonForm'

export type SaveType = 'SAVE_ONLY' | 'SAVE_NEW'
const LessonCreate = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [saveType, setSaveType] = useState<SaveType>('SAVE_ONLY')
  const lessonFormRef = useRef<LessonFormMethods>(null)
  const [courseContentEmpty, setCourseContentEmpty] = useState(true)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: ILessonForm) => {
      return await LessonApi.createLesson(data)
    },
    onSuccess: ({ success, error }) => {
      if (success === false) {
        toast.error(error?.message)
        return
      }

      if (saveType === 'SAVE_ONLY') {
        navigate(`${PageLink.COURSES}/${id}/${PageLink.LESSONS}`)
      }

      if (saveType === 'SAVE_NEW') {
        if (lessonFormRef.current) {
          lessonFormRef.current.resetForm()
        }
      }

      queryClient.invalidateQueries(id ? lessonKeys.list(id) : lessonKeys.base)
      queryClient.invalidateQueries(id ? lessonKeys.contentList(id) : lessonKeys.base)
      toast.success('Create Lesson Successfully!')
    },
  })

  // Trigger form submission and pass data to mutate
  const handleSave = () => {
    if (lessonFormRef.current) {
      lessonFormRef.current.submitForm()
    }
  }

  return (
    <CreateEditLayout
      classNameLayout='pt-11'
      pageTitle='Create Lesson'
      onCancel={() => navigate(`${PageLink.COURSES}/${id}/${PageLink.LESSONS}`)}
      customPrimaryButton={
        <ButtonDropdown
          title='Save'
          isListScreen
          onClick={() => {
            setSaveType('SAVE_ONLY')
            handleSave()
          }}
          loading={isLoading}
          disabled={courseContentEmpty}
        >
          <Dropdown.Item
            as='button'
            onClick={() => {
              setSaveType('SAVE_NEW')
              handleSave()
            }}
          >
            Save & New
          </Dropdown.Item>
        </ButtonDropdown>
      }
    >
      <LessonForm
        onSubmit={mutate}
        ref={lessonFormRef}
        defaultValues={{
          course_id: id!,
          name: '',
          standard_study_hour: 3,
          description: '',
          is_online: false,
          is_review_allowed: false,
          course_section_ids: [],
        }}
        saveType={saveType}
        setCourseContentEmpty={setCourseContentEmpty}
      />
    </CreateEditLayout>
  )
}

export default LessonCreate

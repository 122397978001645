import { zodResolver } from '@hookform/resolvers/zod'
import { isUndefined } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import { createStudent, uploadFile } from 'src/apis/user'
import { SappHeading2 } from 'src/common/SappHeading'
import withAuthComponents from 'src/components/auth/with-auth-components'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  ACCOUNT_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_HUBSPOT_CONTACT_ID,
  GUIDELINE_PASSWORD,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  IS_STAFF,
  MESSAGE_VALIDATION_USERNAME,
  PageLink,
  PASSWORD_NOT_MATCH,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MAX_HUBSPOT_CONTACT_ID,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_HUBSPOT_CONTACT_ID,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PASSWORD,
  VALIDATE_PHONE,
  VALIDATION_FIELD,
} from 'src/constants'
import { LANG_PLACEHOLDER, LANG_USER } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { EEntranceLevel, EStatusUser, IError } from 'src/type'
import { CourseNameLevel } from 'src/type/courses'
import { IStudentDetail, IStudents } from 'src/type/students'
import { phoneRegExp, sizeInBytes, usernamePattern, validatePassword } from 'src/utils'
import { z } from 'zod'

const CreateStudent = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [studentDetail, setStudentDetail] = useState<IStudentDetail>()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [acountType, setAccountType] = useState('')
  const typeEmployee = acountType === 'EMPLOYEE'

  const DEFAULT_SCHEMA = {
    full_name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    email: z.string({ required_error: VALIDATION_FIELD }).email(),
    username: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(usernamePattern), {
        message: MESSAGE_VALIDATION_USERNAME,
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
    level: z.string().optional(),
    cma_level: z.string().optional(),
    acca_level: z.string().optional(),
    phone: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    status: z.string().optional(),
    avatar: z.any(),
    is_sapp_operator: z.string().optional(),
    type_user: z.string().min(1, { message: VALIDATION_FIELD }),
    sex: z.string().optional(),
    password: z.string().optional().default(''),
    confirmPassword: z.string().optional().default(''),
  }

  const schemaCreate = z
    .object(
      Object.assign({}, DEFAULT_SCHEMA, {
        hubspot_contact_id: z
          .string({ required_error: VALIDATION_FIELD })
          .min(3, { message: VALIDATE_MIN_HUBSPOT_CONTACT_ID })
          .max(20, { message: VALIDATE_MAX_HUBSPOT_CONTACT_ID }),
      })
    )
    .refine((data) => data.password === data.confirmPassword, {
      message: PASSWORD_NOT_MATCH,
      path: ['confirmPassword'],
    })

  const schemaCode = z
    .object(
      Object.assign({}, DEFAULT_SCHEMA, {
        employee_code: z
          .string({ required_error: VALIDATION_FIELD })
          .min(3, { message: VALIDATE_MIN_HUBSPOT_CONTACT_ID })
          .max(20, { message: VALIDATE_MAX_HUBSPOT_CONTACT_ID }),
      })
    )
    .refine((data) => data.password === data.confirmPassword, {
      message: PASSWORD_NOT_MATCH,
      path: ['confirmPassword'],
    })

  const validation = useMemo(
    () => (typeEmployee ? schemaCode : schemaCreate),
    [typeEmployee, schemaCreate, schemaCode]
  )

  const { control, handleSubmit, setValue, setError, clearErrors, reset } = useForm<IStudents>({
    resolver: zodResolver(validation),
    mode: 'onSubmit',
  })

  const onSubmitCreate = async (data: IStudents) => {
    const payload = {
      email: data?.email.toLowerCase(),
      level: data?.level || null,
      cma_level: data?.cma_level || null,
      acca_level: data?.acca_level || null,
      full_name: data?.full_name,
      password: data?.password,
      phone: data?.phone,
      username: data?.username.toLowerCase(),
      status: EStatusUser.ACTIVE,
      sex: data?.sex,
      is_sapp_operator: data?.is_sapp_operator === 'true' ? true : false,
      hubspot_contact_id: data?.hubspot_contact_id || undefined,
      employee_code: data?.employee_code || undefined,
      type_user: data?.type_user,
    }

    if (data?.password) {
      if (!validatePassword.test(data.password)) {
        setError('password', { message: VALIDATE_PASSWORD })
        return
      } else {
        clearErrors('password')
      }
    } else {
      delete payload.password
    }

    setLoading(true)
    try {
      const res = await createStudent(payload)
      const dataStudent = res?.data
      toast.success('Create Student Successfully!')
      setUploadSuccess(true)
      if (isUndefined(data?.avatar)) {
        navigate(PageLink.STUDENTS)
      }

      if (!isUndefined(data?.avatar)) {
        await uploadFile({ userId: dataStudent?.id, avatar: data?.avatar }).then(() =>
          navigate(PageLink.STUDENTS)
        )
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setUploadSuccess(false)
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (
      !isUndefined(data.avatar) &&
      (data?.avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (
      !isUndefined(data.id_front) &&
      (data?.id_front?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (
      !isUndefined(data.id_back) &&
      (data?.id_back?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    onSubmitCreate(data)
  }

  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.STUDENTS),
    })
  }

  // mở popup confỉm khi submit
  const handleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  const courseLevel = [
    {
      name: CourseNameLevel.LEVEL,
      label: 'CFA Level',
      defaultValue: studentDetail?.detail?.level,
    },
    {
      name: CourseNameLevel.CMA,
      label: 'CMA Level',
      defaultValue: studentDetail?.detail?.cma_level,
    },
    {
      name: CourseNameLevel.ACCA,
      label: 'ACCA Level',
      defaultValue: studentDetail?.detail?.acca_level,
    },
  ]

  useEffect(() => {
    setValue('hubspot_contact_id', '')
    setValue('employee_code', '')
  }, [typeEmployee])

  return (
    <CreateEditLayout
      onSave={handleSubmitForm}
      onCancel={handleCancel}
      loading={loading}
      pageTitle={'Create Student'}
    >
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush py-4 sapp-pt--image'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Avatar</h2>
              </div>
            </div>

            <div className='card-body text-center pt-0'>
              <SAPPHookUploadFile
                name='avatar'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={studentDetail?.detail?.avatar?.['150x150'] ?? avatarDefault}
                accept={ACCEPT_UPLOAD_MIME_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                uploadSuccess={uploadSuccess}
                removeAvatar={avatarDefault}
              />
            </div>
          </div>

          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <SappHeading2 title={LANG_USER.TEST_ACCOUNT} />
              </div>
            </div>

            <div className='card-body pt-0'>
              <HookFormSelectAntd
                name='is_sapp_operator'
                control={control}
                dropdownStyle={{ minWidth: 'fit-content' }}
                defaultValue={IS_STAFF[0].value}
                className='sapp-h-45px'
                options={IS_STAFF}
              />
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='create student'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush pb-4 pt-10'>
                  <div className='card-body pt-0'>
                    <div className='mb-10 fv-row'>
                      <HookFormSelectAntd
                        label='Account Type'
                        name='type_user'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        required
                        onChange={(e: any) => setAccountType(e)}
                        options={ACCOUNT_TYPE}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name={typeEmployee ? 'employee_code' : 'hubspot_contact_id'}
                        label={typeEmployee ? LANG_USER.EMPLOYEE_CODE : 'Code'}
                        required
                        guideline={
                          typeEmployee
                            ? ['Mã code tài khoản học viên của nhân sự SAPP']
                            : GUIDELINE_HUBSPOT_CONTACT_ID
                        }
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='username'
                        label='Username'
                        required
                        guideline={GUIDELINE_USERNAME}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='email'
                        label='Email'
                        required
                        guideline={GUIDELINE_EMAIL}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='password'
                        label='Password'
                        type='password'
                        guideline={GUIDELINE_PASSWORD}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='confirmPassword'
                        label='Confirm Password'
                        type='password'
                        guideline={GUIDELINE_PASSWORD}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='phone'
                        label='Phone Number'
                        required
                        guideline={GUIDELINE_PHONE}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormSelectAntd
                        label='Gender'
                        size='large'
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        options={GENDER_FORM}
                      />
                    </div>

                    {courseLevel.map((course, idx) => (
                      <HookFormSelectAntd
                        size='large'
                        name={course.name}
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        label={course.label}
                        filterOption={true}
                        className={idx !== courseLevel.length - 1 ? 'mb-10' : ''}
                        key={idx}
                        options={[
                          { label: EEntranceLevel.IA, value: EEntranceLevel.IA },
                          { label: EEntranceLevel.IIA, value: EEntranceLevel.IIA },
                          { label: EEntranceLevel.IB, value: EEntranceLevel.IB },
                          { label: EEntranceLevel.IIB, value: EEntranceLevel.IIB },
                        ]}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default withAuthComponents(CreateStudent)

import EditClass from './EditClass'

interface IProps {
  classDetail: any | undefined
}

const SettingClassUser = ({ classDetail }: IProps) => {
  return <EditClass classDetail={classDetail} />
}

export default SettingClassUser

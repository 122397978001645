import { LabelHTMLAttributes } from 'react'

interface IProps extends LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean
  label: string
  isLabel?: boolean
}

const SappLabel = ({ label, required, className, isLabel = false, ...props }: IProps) => {
  return (
    <label
      className={`text-gray-800 ${
        isLabel ? 'sapp-label fw-bold' : 'sapp-fs-14 fw-semibold sapp-mb-10px sapp-line--height'
      } ${className ?? ''}`}
      {...props}
    >
      <span className={`${required ? 'required' : ''}`}>{label}</span>
    </label>
  )
}

export default SappLabel

import { Dispatch, SetStateAction, useEffect } from 'react'
// import TopSelling from 'src/components/my-profile/overview-profile/TopSelling'
import { IStaffDetail } from 'src/type/staffs.'
import StaffProfileDetail from './StaffProfileDetail'

interface IProps {
  staffDetail: IStaffDetail | undefined
  loading: boolean
  setStaffDetail: Dispatch<SetStateAction<any>>
}

const OverviewStaffProfile = ({ staffDetail, loading, setStaffDetail }: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <StaffProfileDetail
        staffDetail={staffDetail}
        loading={loading}
        setStaffDetail={setStaffDetail}
      />
    </>
  )
}

export default OverviewStaffProfile

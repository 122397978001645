import { Checkbox } from 'antd'
import clsx from 'clsx'
import { Control, Controller } from 'react-hook-form'
import { WEEK_DAY_LABELS } from 'src/constants'
import './RepeatEvent.scss'

interface IRepeatEvent {
  control: Control<any>
  name: string
  defaultValue: boolean[]
  dayIndex?: number
}

const HookFormWeekday = ({ control, name, defaultValue, dayIndex }: IRepeatEvent) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <div className='sapp-flex-1 d-flex gap-5'>
          {WEEK_DAY_LABELS.map((day: string, index: number) => (
            <div key={index} className='d-flex align-items-center'>
              <Checkbox
                id={`drawer-repeat-${index}`}
                className='d-none'
                checked={field.value[index]}
                disabled={dayIndex === index}
                onChange={(e) => {
                  const updatedDays = [...field.value]
                  if (updatedDays[index]) {
                    updatedDays[index] = false
                  } else {
                    updatedDays[index] = true
                  }
                  field.onChange(updatedDays)
                }}
              />
              <label
                htmlFor={`drawer-repeat-${index}`}
                className={`drawer-repeat-checkbox-label d-flex justify-content-center align-items-center h-25px w-25px rounded-circle fs-6 fw-medium ${clsx(
                  field.value[index] && 'checked'
                )} ${index === dayIndex && 'checked'}`}
              >
                {day}
              </label>
            </div>
          ))}
        </div>
      )}
    />
  )
}

export default HookFormWeekday

import React, { FC } from 'react'

interface MinusSquaredProps extends React.SVGProps<SVGSVGElement> {}
export const MinusSquared: FC<MinusSquaredProps> = (props) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='currentColor'>
      <path
        fill='currentColor'
        d='M14.92 1.65a5.43 5.43 0 0 1 5.43 5.43v7.84a5.432 5.432 0 0 1-5.43 5.43H7.08a5.43 5.43 0 0 1-5.43-5.43V7.08a5.43 5.43 0 0 1 5.43-5.43h7.84ZM6.33 11.93h9.34a.93.93 0 1 0 0-1.86H6.33a.93.93 0 0 0 0 1.86ZM14.92.25H7.08A6.83 6.83 0 0 0 .25 7.08v7.84a6.83 6.83 0 0 0 6.83 6.83h7.84a6.83 6.83 0 0 0 6.83-6.83V7.08A6.83 6.83 0 0 0 14.92.25Z'
      />
    </svg>
  )
}

export default MinusSquared

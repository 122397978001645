import React, { Dispatch, SetStateAction, useState } from 'react'
import sapp from 'src/_metronic/assets/media/logos/sapp.png'
import SappBreadCrumbs from 'src/components/base/breadcrumbs/SappBreadCrumbs'
import { ITabs } from 'src/type'
import { Drawer } from 'antd'
import Sidebar from 'src/components/layout/sidebar'

interface IProps {
  pageTitle: string | undefined
  breadcrumbs: ITabs[] | undefined
  setOpenNotifications: Dispatch<SetStateAction<boolean>>
}

const Header = ({ pageTitle, breadcrumbs, setOpenNotifications }: IProps) => {
  const [open, setOpen] = useState(false)

  // function open show drawer
  const showDrawer = () => {
    setOpen(true)
  }

  // function close show drawer
  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className='app-header sapp-custom-background-layout'>
      <div className='app-container container-xxl d-flex align-items-stretch flex-stack'>
        <div className='d-flex align-items-center d-lg-none'>
          {/* start:: button drawer */}
          <button
            className='btn btn-icon btn-color-gray-500 btn-active-color-primary ms-n3 me-1'
            onClick={showDrawer}
          >
            <i className='ki-outline ki-burger-menu-6 fs-2x'></i>
          </button>
          {/* end:: button show drawer */}

          {/* start:: show drawer */}
          <Drawer placement={'left'} closable={false} onClose={onClose} open={open} width={320}>
            <div className='d-flex h-100 sapp-bg-sidebar'>
              <Sidebar />
            </div>
          </Drawer>
          {/* end:: show drawer */}

          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <a href='/'>
              <img alt='Logo' src={sapp} className='h-30px sapp-w-60px' />
            </a>
          </div>
        </div>

        <div
          className='d-flex flex-stack justify-content-end flex-row-fluid'
          id='kt_app_navbar_wrapper'
        >
          <div className='page-entry d-flex flex-column flex-row-fluid'>
            <SappBreadCrumbs breadcrumbs={breadcrumbs} />

            <div className='page-title d-flex align-items-center'>
              <h1 className='page-heading d-flex text-gray-800 fw-bold fs-2x flex-column justify-content-center my-0 mt-2'>
                {pageTitle}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

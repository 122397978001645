import { Space } from 'antd'
import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { CLASS_PROFILE, TITLE_OPTIONS_CLASS } from 'src/constants/classes'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { pluralize } from 'src/helper/pluralize'
import { daysOfWeekInNumber } from 'src/type'
import { CONSTRUCTION_MODE, IClassDetail } from 'src/type/classes'
import { formatDateTime } from 'src/utils'
import ItemClassProfile from './ItemClassProfile'

interface IProps {
  classDetail?: IClassDetail
  loading: boolean
}

const ClassProfileDetail = ({ classDetail, loading }: IProps) => {
  const { id } = useParams()
  const getDurationFixed =
    classDetail?.started_at && classDetail?.finished_at != null
      ? `${formatDateTime(new Date(classDetail?.started_at), 'DD/MM/YYYY')} - ${formatDateTime(
          new Date(classDetail?.finished_at),
          'DD/MM/YYYY'
        )}`
      : ''

  return (
    <>
      {loading ? (
        <LoadingProfileDetail loadingAvatar={false} />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Overview'
            titleButton='Edit Class Info'
            link={`${CLASS_PROFILE}/${id}/setting`}
          />

          <div className='card-body p-9'>
            <ItemProfile title={TITLE_OPTIONS_CLASS.name} body={classDetail?.name} />
            <ItemProfile title={TITLE_OPTIONS_CLASS.code} body={classDetail?.code} />
            <ItemClassProfile
              title={TITLE_OPTIONS_CLASS.status}
              status={classDetail?.status.toLowerCase()}
              className='mb-7'
            />
            <ItemProfile
              title={TITLE_OPTIONS_CLASS.constructionMode}
              body={classDetail?.instruction_mode}
            />
            <ItemProfile title={TITLE_OPTIONS_AREA.facility} body={classDetail?.facility?.name} />
            <ItemProfile title={TITLE_OPTIONS_CLASS.type} body={classDetail?.type} />
            <ItemProfile
              title={TITLE_OPTIONS_CLASS.currentStudentsCount}
              body={`${classDetail?.current_student_in_class ?? 0}${
                classDetail?.capacity != null ? `/${classDetail.capacity}` : ''
              }`}
            />

            {classDetail?.duration_type === 'FIXED' &&
              classDetail?.instruction_mode !== CONSTRUCTION_MODE.HYBRID && (
                <ItemProfile title={TITLE_OPTIONS_CLASS.duration} body={getDurationFixed} />
              )}
            {classDetail?.duration_type === 'FLEXIBLE' &&
              classDetail?.instruction_mode !== CONSTRUCTION_MODE.HYBRID && (
                <ItemProfile
                  title={TITLE_OPTIONS_CLASS.duration}
                  body={pluralize('day', classDetail?.flexible_days)}
                />
              )}
            {classDetail?.instruction_mode !== CONSTRUCTION_MODE.ONLINE && (
              <ItemProfile
                title={'Standard Schedule'}
                body={
                  <>
                    <Space>
                      {classDetail?.class_standard_schedules?.map((item) => (
                        <div
                          className='badge badge-light-dark badge-lg'
                          style={{ fontWeight: 500 }}
                          key={item.day_of_week}
                        >
                          {item.day_of_week !== null
                            ? `${capitalize(daysOfWeekInNumber[item.day_of_week])} | `
                            : ''}
                          {item.start_time} - {item.end_time}
                        </div>
                      ))}
                    </Space>
                  </>
                }
              />
            )}

            <ItemProfile title={'Classroom name'} body={classDetail?.link_meeting} />

            {classDetail?.instruction_mode !== CONSTRUCTION_MODE.ONLINE && (
              <ItemProfile title={'Meeting Link'} body={classDetail?.link_meeting} />
            )}
            <ItemProfile title={TITLE_OPTIONS_CLASS.course} body={classDetail?.course?.name} />
            <ItemProfile
              title={TITLE_OPTIONS_CLASS.exam}
              body={classDetail?.examination_subject?.examination?.name || ''}
            />
            <ItemClassProfile
              title={TITLE_OPTIONS_CLASS.describe}
              body={classDetail?.description}
            />
            {classDetail?.reason !== null && classDetail?.status === 'BLOCKED' && (
              <ItemProfile title={TITLE_OPTIONS_CLASS.reason} body={classDetail?.reason} />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ClassProfileDetail

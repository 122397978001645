import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { ExamAPI } from 'src/apis/exams'
import DetailHeader from 'src/common/DetailHeader'
import ExamTabs from 'src/components/exam/ExamDetail/ExamTabs'
import { PageLink } from 'src/constants'
import { EXAM_DETAIL_URL } from 'src/constants/exam'
import { ExamProvider } from 'src/context/ExamContext'
import { ExamRouteParams, IExamDetail, ITabs } from 'src/type'

const ExamDetail = () => {
  const { program, id } = useParams<ExamRouteParams>()

  const fetchExamDetail = async () => {
    if (!id) {
      throw new Error('Exam ID is undefined')
    }
    const response = await ExamAPI.getDetail({ id: id })
    return response.data
  }

  const { data, isLoading, isSuccess } = useQuery<IExamDetail>({
    queryKey: ['ExamDetail', id],
    queryFn: fetchExamDetail,
    retry: 1,
    enabled: !!id,
    refetchOnWindowFocus: false,
  })

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${PageLink.EXAMS}/${program}/${id}/${EXAM_DETAIL_URL.OVERVIEW}`,
      title: 'Overview',
    },
    {
      link: `${PageLink.EXAMS}/${program}/${id}/${EXAM_DETAIL_URL.SETTING}`,
      title: 'Settings',
    },
    {
      link: `${PageLink.EXAMS}/${program}/${id}/${EXAM_DETAIL_URL.STUDENTS}`,
      title: 'Students',
    },
  ]

  return (
    <ExamProvider
      value={{
        data: data || null || undefined,
        program: program,
      }}
    >
      {isSuccess && (
        <>
          <DetailHeader title={data?.name || 'Exam Detail'} tabs={tabs} loading={isLoading} />
          <ExamTabs />
        </>
      )}
    </ExamProvider>
  )
}

export default ExamDetail

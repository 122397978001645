import React, { FC } from 'react'

interface SwitcherClosedProps extends React.SVGProps<SVGSVGElement> {}
const SwitcherClosed: FC<SwitcherClosedProps> = (props) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='8' height='10' fill='none'>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M.737.132A1.407 1.407 0 0 1 2.133.25L7.467 4c.335.236.533.607.533 1s-.198.764-.533 1L2.133 9.75c-.404.284-.944.33-1.396.118A1.244 1.244 0 0 1 0 8.75v-7.5C0 .777.285.344.737.132Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default SwitcherClosed

import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Row } from 'antd'
import clsx from 'clsx'
import { isUndefined } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import { KTIcon } from 'src/_metronic/helpers'
import { RolesAPI } from 'src/apis/roles'
import { StaffAPI, uploadAvatarStaff } from 'src/apis/staffs'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import DepartmentSelect from 'src/components/base/select/common/DepartmentSelect'
import ProgramSelect from 'src/components/base/select/common/ProgramSelect'
import StaffLevelSelect from 'src/components/base/select/common/StaffLevelSelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  MY_PROFILE,
  STAFF_PROFILE,
  STATUS_FORM,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import useDepartmentPositions from 'src/hooks/useDepartmentPositions'
import { staffSchema } from 'src/pages/staffs/schema'
import { IError, Role } from 'src/type'
import { IStaffDetail, IUpdateForm } from 'src/type/staffs.'
import { sizeInBytes } from 'src/utils'
import { z } from 'zod'
import styles from './styles.module.scss'

interface IProps {
  staffDetail: IStaffDetail | undefined
  setStaffDetail: Dispatch<SetStateAction<any>>
}
const MAXIMUM_JOB_POSITION = 2

interface DepartmentOptions {
  label: string
  value: string
  unit: string
}

const EditStaffProfile = ({ staffDetail, setStaffDetail }: IProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { profileMe } = useUserContext()

  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [roles, setRoles] = useState<any>([])
  const [isActive, setIsActive] = useState(false)
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const [roleLoading, setRoleLoading] = useState(false)
  const [currentDepartment, setCurrentDepartment] = useState<DepartmentOptions | null>({
    label: staffDetail?.department?.name ?? '',
    value: staffDetail?.department?.id ?? '',
    unit: staffDetail?.department?.unit ?? '',
  })

  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${STAFF_PROFILE}/${id}/overview`),
    })
  }
  const requiredJobPosition = currentDepartment
    ? [
        'Customer Experience CFA',
        'Customer Experience CMA',
        'Customer Experience ACCA',
        'Custermer Experience Cert/Dip',
        'Customer Experience',
      ].includes(currentDepartment.label)
    : false

  const requiredProgram = !!currentDepartment && currentDepartment.unit !== 'HO'

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<IUpdateForm>({
    resolver: zodResolver(z.object(staffSchema({ requiredJobPosition, requiredProgram }))),
    mode: 'onSubmit',
  })

  const onSubmit = async (data: IUpdateForm) => {
    const { email, full_name, phone, status, avatar, sex } = data

    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return
    setLoadingSubmit(true)

    try {
      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({ staffId: staffDetail?.id, avatar: avatar })
      }

      const paramsUpdate: IUpdateForm = {
        ...data,
        full_name,
        email: email.toLowerCase(),
        phone,
        status,
        roles: data.roles,
        avatar: undefined,
        sex,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      if (allowRenderEditRole) {
        try {
          await StaffAPI.updateRoles({
            id: staffDetail?.id,
            role_ids: paramsUpdate.roles,
          })
        } catch (error) {}
      }

      await StaffAPI.update({
        id: staffDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Successfully!')
          navigate(`${STAFF_PROFILE}/${staffDetail?.id}/${MY_PROFILE.OVERVIEW}`)
        })
        .catch((err) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error) {
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    setRoleLoading(true)
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      setRoleLoading(false)
      return res
    } catch (error) {
      setRoleLoading(false)
    }
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.metadata?.total_pages
    const pageIndex = roles?.metadata?.page_index as number
    const pageSize = roles?.metadata?.page_size as number

    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const newRoles = res?.data?.roles
      const updatedRoles = roles?.roles?.concat(newRoles)
      const uniqueRoles = Array.from(new Set(updatedRoles.map((role: any) => role.name))).map(
        (name) => {
          return updatedRoles.find((role: any) => role.name === name)
        }
      )

      setRoles({
        metadata: res?.data?.metadata,
        roles: uniqueRoles,
      })
    }
  }

  const getRoles = async ({ params }: any) => {
    const res: any = await fetchRoles(1, 10, params)
    setRoles(res?.data)
  }

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'position_levels',
    rules: {
      maxLength: MAXIMUM_JOB_POSITION,
    },
  })

  const appendDefaultPosition = () =>
    append({
      staff_position_id: '',
      staff_position_name: '',
      staff_level_id: '',
      staff_level_name: '',
    })

  const department = watch('department_id')
  const { provinces, isLoading: isProvincesLoading } = useDepartmentPositions(department)

  useEffect(() => {
    if (allowRenderEditRole) {
      getRoles({})
    }
  }, [])

  const optionRoles = roles?.roles?.map((role: any) => ({ name: role?.name, value: role?.id }))
  useEffect(() => {
    remove()
    if (!staffDetail?.id) return
    setValue('email', staffDetail?.detail?.email ?? '')
    setValue('full_name', staffDetail?.detail?.full_name ?? '')
    setValue('phone', staffDetail?.detail?.phone ?? '')
    setValue('status', staffDetail?.status ?? '')
    setValue('username', staffDetail?.username ?? '')
    setValue('roles', staffDetail?.roles?.map((role) => role.id) ?? [])
    setValue('sex', staffDetail?.detail?.sex ?? '')
    setValue('course_category_id', staffDetail?.course_category_id)
    setValue('department_id', staffDetail?.department_id)

    const positions = staffDetail?.staff_position_level_instances?.map((item) => {
      return {
        staff_position_id: item.staff_position.id,
        staff_position_name: item.staff_position.name,
        staff_level_id: item.staff_level.id,
        staff_level_name: item.staff_level.name,
      }
    })
    append(positions, { shouldFocus: false })
  }, [staffDetail])

  const defaultRoles = staffDetail?.roles?.map((role) => ({ name: role?.name, value: role?.id }))

  const uniqueRoles = Array.from(
    new Set([...(optionRoles || []), ...(defaultRoles || [])].map((role) => role.value))
  )
    .map((value) => {
      return [...(optionRoles || []), ...(defaultRoles || [])].find((role) => role.value === value)
    })
    .map(({ name, ...rest }) => ({ ...rest, label: name }))

  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEditEmail = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_STAFF_GR.CHANGE_EMAIL_STAFF) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_STAFF_GR.EDIT_STAFF) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderEditRole = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_STAFF_GR.GET_ASSIGN_PERMISSION_STAFF) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title='Profile Details' />

        <div id='kt_account_settings_profile_details' className='collapse show'>
          <div className='form'>
            <Row className='card-body border-top p-9 w-100' gutter={[12, 24]}>
              {/* Avatar */}
              <Col span={6} className='col-form-label fw-semibold fs-6'>
                Avatar
              </Col>
              <Col span={18}>
                <SAPPHookUploadFile
                  name='avatar'
                  control={control}
                  setValue={setValue}
                  setError={setError}
                  imagePreview={
                    staffDetail?.detail?.avatar?.['150x150'] ??
                    staffDetail?.detail?.avatar?.ORIGIN ??
                    avatar
                  }
                  accept={ACCEPT_UPLOAD_MIME_TYPE}
                  maxFileSize={DEFAULT_MAX_FILE_SIZE}
                  setShowAvatarDefault={setShowAvatarDefault}
                  removeAvatar={avatar}
                  disabled={!allowRenderEdit}
                />
              </Col>
              {/* Avatar */}

              {/* Fullname */}
              <Col span={6} className='col-form-label required fw-semibold fs-6'>
                {LANG_PLACEHOLDER.FULLNAME}
              </Col>

              <Col span={18} className='fv-row'>
                <HookFormTextField
                  name='full_name'
                  control={control}
                  placeholder={LANG_PLACEHOLDER.FULLNAME}
                  defaultValue={staffDetail?.detail?.full_name}
                  required
                  guideline={GUIDELINE_FULLNAME}
                  disabled={!allowRenderEdit}
                />
              </Col>
              {/* Fullname */}

              {/* Username */}
              <Col span={6} className='col-form-label required fw-semibold fs-6'>
                Username
              </Col>

              <Col span={18} className='fv-row'>
                <HookFormTextField
                  name='username'
                  control={control}
                  placeholder='Username'
                  defaultValue={staffDetail?.username}
                  disabled
                  required
                  guideline={GUIDELINE_USERNAME}
                />
              </Col>
              {/* Username */}

              {/* Email */}
              <Col span={6} className='col-form-label required fw-semibold fs-6'>
                Email
              </Col>
              <Col xs={14} md={16} className='fv-row'>
                <HookFormTextField
                  name='email'
                  control={control}
                  placeholder='Email'
                  defaultValue={staffDetail?.detail?.email}
                  required
                  disabled
                  guideline={GUIDELINE_EMAIL}
                />
              </Col>
              <Col xs={4} md={2} className='text-end'>
                <ButtonPrimary
                  onClick={() => setOpenEditEmail(true)}
                  title='Edit'
                  className='sapp-p-button--custom w-100'
                  disabled={!allowRenderEditEmail || !allowRenderEdit || !allowRenderEdit}
                />
              </Col>
              {/* Email */}

              {/* Phone */}
              <Col span={6} className='col-form-label required fw-semibold fs-6'>
                Phone
              </Col>

              <Col span={18} className='fv-row'>
                <HookFormTextField
                  name='phone'
                  control={control}
                  placeholder='Phone Number'
                  defaultValue={staffDetail?.detail?.phone}
                  required
                  disabled
                  guideline={GUIDELINE_PHONE}
                />
              </Col>
              {/* Phone */}

              <Col span={6} className='col-form-label required fw-semibold fs-6'>
                Department
              </Col>
              <Col span={18}>
                <DepartmentSelect
                  control={control}
                  name='department_id'
                  required
                  onChange={async (_, option) => {
                    setCurrentDepartment(option as DepartmentOptions)
                    remove() // Then remove field array items
                    appendDefaultPosition()
                  }}
                  detailData={
                    staffDetail && {
                      label: staffDetail?.department?.name,
                      value: staffDetail?.department?.id,
                    }
                  }
                />
              </Col>

              {!!department && (
                <>
                  <Col span={6}>
                    <SappLabel label='Job Position' required />
                    {errors.position_levels && (
                      <small className='text-danger mt-2 lh-1'>
                        {errors?.position_levels.message}
                      </small>
                    )}
                  </Col>

                  <Col span={18}>
                    {fields.map((field, index) => {
                      // Get all selected position IDs from previous fields
                      const selectedPositions = watch('position_levels')
                        ?.filter((_, i) => i !== index)
                        ?.map((pos) => pos.staff_position_id)
                        ?.filter(Boolean)

                      return (
                        <Row
                          className={clsx(
                            'justify-content-between position-relative',
                            styles.job_position
                          )}
                          gutter={[12, 12]}
                          key={field.id}
                        >
                          <Col span={12}>
                            <HookFormSelectAntd
                              name={`position_levels.${index}.staff_position_id`}
                              control={control}
                              placeholder={`${provinces?.[0]?.position?.name ?? 'Board'}`}
                              label='Title'
                              loading={isProvincesLoading}
                              onSearch={() => {}}
                              options={
                                provinces
                                  ?.filter(
                                    (item) => !selectedPositions.includes(item.staff_position_id)
                                  )
                                  ?.map((item) => ({
                                    label: item.position.name,
                                    value: item.staff_position_id,
                                  })) ?? [{}]
                              }
                              maxCount={2}
                            />
                          </Col>
                          <Col span={index >= 1 ? 11 : 12}>
                            {/* 1st Col - Subject */}
                            <StaffLevelSelect
                              size='large'
                              control={control}
                              classNameHeight={'sapp-h-45px'}
                              label='Level'
                              name={`position_levels.${index}.staff_level_id`}
                              detailData={{
                                label: field.staff_level_name ?? '',
                                value: field.staff_level_id,
                              }}
                            />
                          </Col>
                          {index >= 1 && (
                            <Col span={1} className={clsx(styles.trashContainer)}>
                              <div className={styles.trashFiller} />
                              <ButtonIconOnly
                                iconName='trash'
                                activeColor='danger'
                                onClick={() => {
                                  remove(index)
                                }}
                                className={styles.trashIcon}
                              />
                            </Col>
                          )}
                        </Row>
                      )
                    })}
                    {fields.length < MAXIMUM_JOB_POSITION && (
                      <ButtonIcon
                        title='Add job position'
                        className={`d-flex align-items-center px-3 py-4 sapp-color-import-student border border-gray-300 rounded btn btn-primary ${
                          fields.length > 0 && 'mt-5'
                        }`}
                        customButton='btn-sm bg-white'
                        onClick={appendDefaultPosition}
                        // loading={isSubjectsLoading}
                      >
                        <KTIcon iconName='plus' iconType='solid' className='text-inverse-light' />
                      </ButtonIcon>
                    )}
                  </Col>
                </>
              )}

              <Col
                span={6}
                className={`col-form-label fw-semibold fs-6 ${requiredProgram ?? 'required'}`}
              >
                Program
              </Col>
              <Col span={18} className='fv-row'>
                <ProgramSelect
                  control={control}
                  name='course_category_id'
                  required={requiredProgram}
                  placeholder='CFA'
                  classNameHeight='sapp-h-40'
                  detailData={
                    staffDetail && {
                      label: staffDetail?.course_category?.name,
                      value: staffDetail?.course_category?.id,
                    }
                  }
                />
              </Col>

              {/* Status */}
              <Col span={6} className='col-form-label required fw-semibold fs-6'>
                Status
              </Col>

              <Col span={18} className='fv-row'>
                <HookFormSelectAntd
                  name='status'
                  control={control}
                  size='large'
                  defaultValue={staffDetail?.status}
                  className='fs-6'
                  disabled={profileMe?.id === staffDetail?.id}
                  options={STATUS_FORM}
                />
              </Col>
              {/* Status */}

              {/* Role */}
              {allowRenderEditRole && (
                <>
                  <Col span={6} className='col-form-label fw-semibold fs-6'>
                    Role
                  </Col>

                  <Col span={18} className='fv-row'>
                    <HookFormSelectMultiple
                      name='roles'
                      control={control}
                      placeholder='Role'
                      options={uniqueRoles}
                      onSearch={(e: any) => getRoles({ params: { name: e } })}
                      handleNextPage={(e: any) => handlNextPageRole({ params: { name: e } })}
                      disabled={
                        profileMe?.roles?.find((role) => role.code)?.code !== CODE_ADMIN.SUPER_ADMIN
                      }
                      loading={roleLoading}
                    />
                  </Col>
                </>
              )}
              {/* Role */}

              {/* Gender */}
              <Col span={6} className='col-form-label fw-semibold fs-6'>
                Gender
              </Col>

              <Col span={18} className='fv-row'>
                <HookFormSelectAntd
                  size='large'
                  name='sex'
                  control={control}
                  dropdownStyle={{ minWidth: 'fit-content' }}
                  placeholder='Please select'
                  defaultValue={GENDER_FORM?.[0]?.value}
                  required
                  disabled={!allowRenderEdit}
                  options={GENDER_FORM}
                />
              </Col>
              {/* Gender */}
            </Row>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary title='Discard' className='me-2' onClick={handleCancel} />
              <ButtonPrimary
                title='Save Changes'
                onClick={handleSubmit(onSubmit)}
                loading={loadingSubmit}
              />
            </div>
          </div>
        </div>
        <ModalEditEmail
          open={openEditEmail}
          setOpen={setOpenEditEmail}
          isActive={isActive}
          setIsActive={setIsActive}
          currentEmail={staffDetail?.detail.email}
          setStaffDetail={setStaffDetail}
        />
      </div>
    </>
  )
}

export default EditStaffProfile

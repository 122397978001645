import { debounce } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { CoursesAPI } from 'src/apis/courses'
import { commonKeys } from 'src/constants/queryKeyFactory'
import { IResponse } from 'src/type'
import { ICourseCategories } from 'src/type/courses'

const PAGE_SIZE = 10
const useInfinitePrograms = (enabled: boolean = true) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e: string) => {
    setSearchText(e)
  }, 350)

  const fetchPrograms = async (
    page_index: number,
    page_size: number,
    name?: string
  ): Promise<IResponse<ICourseCategories>> => {
    try {
      const res = await CoursesAPI.getCategoryInfinite({
        page_index: page_index,
        page_size: page_size,
        params: {
          name: name,
        },
      })
      return res
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery<IResponse<ICourseCategories>>({
      queryKey: commonKeys.programs(searchText), // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchPrograms(pageParam, PAGE_SIZE, searchText || undefined)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.success) {
          return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
            ? lastPage.data.metadata.page_index + 1
            : false
        }
      },
      enabled: searchText !== undefined && enabled,
      refetchOnWindowFocus: false,
    })

  return {
    programs: data?.pages.flatMap((page) => page?.data?.course_categories), // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
    debounceSearch,
  }
}

export default useInfinitePrograms

import React, { useEffect } from 'react'
import DragDropQuestion from '../create-question/DragDropQuestion'
import EssayQuestion from '../create-question/EssayQuestion'
import FillUpQuestion from '../create-question/FillUpQuestion'
import MatchingQuestion from '../create-question/MatchingQuestion'
import MultiChoiceQuestion from '../create-question/MultiChoiceQuestion'
import OneChoiceQuestion from '../create-question/OneChoiceQuestion'
import SelectMissingWordQuestion from '../create-question/SelectMissingWordQuestion'
import TrueFalseQuestion from '../create-question/TrueFalseQuestion'
import { QUESTION_TYPES } from '../shared/interfaces'
import { useQuestionContext } from 'src/context/QuestionProvider'

type Props = {
  id?: string
  activeQuestForm: QUESTION_TYPES | null
  setActiveQuestForm: React.Dispatch<React.SetStateAction<QUESTION_TYPES | null>>
  openQuestForm: boolean
  setOpenQuestForm: React.Dispatch<React.SetStateAction<boolean>>
  isSingleQuestion: boolean
  handleReload?: () => void
}

const ActiveQuestForm = ({
  id,
  activeQuestForm,
  setActiveQuestForm,
  openQuestForm,
  setOpenQuestForm,
  isSingleQuestion,
  handleReload,
}: Props) => {
  const { setIsSingle } = useQuestionContext()

  const handleCloseAddNew = () => {
    if (handleReload && isSingleQuestion) {
      handleReload()
    }
    setOpenQuestForm(false)
    setActiveQuestForm(null)
  }

  useEffect(() => {
    setIsSingle(isSingleQuestion)
  }, [])

  return (
    <>
      {(() => {
        switch (activeQuestForm) {
          case QUESTION_TYPES.TRUE_FALSE:
            return (
              <TrueFalseQuestion
                id={id}
                type='True/False'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          case QUESTION_TYPES.ONE_CHOICE:
            return (
              <OneChoiceQuestion
                id={id}
                type='One choice'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          case QUESTION_TYPES.MULTIPLE_CHOICE:
            return (
              <MultiChoiceQuestion
                id={id}
                type='Multiple choice'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          case QUESTION_TYPES.MATCHING:
            return (
              <MatchingQuestion
                id={id}
                type='Matching'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          case QUESTION_TYPES.SELECT_WORD:
            return (
              <SelectMissingWordQuestion
                id={id}
                type='Select word'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          case QUESTION_TYPES.FILL_WORD:
            return (
              <FillUpQuestion
                id={id}
                type='Fill up'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          case QUESTION_TYPES.DRAG_DROP:
            return (
              <DragDropQuestion
                id={id}
                type='Drag Drop'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          case QUESTION_TYPES.ESSAY:
            return (
              <EssayQuestion
                id={id}
                type='Constructed'
                open={openQuestForm}
                onClose={handleCloseAddNew}
              />
            )
          default:
            return <></>
        }
      })()}
    </>
  )
}

export default ActiveQuestForm

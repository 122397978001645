import _ from 'lodash'
import { Link, useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { MY_PROFILE, TEACHER_PROFILE } from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { TEACHING_STATUS } from 'src/constants/users/teacher'
import { useTeacherContext } from 'src/context/user/teacher/TeacherContext'
import { useUserContext } from 'src/context/UserProvider'
import { processFields } from 'src/helper/teacherHelper'
import { Role } from 'src/type'
import { formatDate, formatDateTime } from 'src/utils'

const TeacherProfileDetail = () => {
  const { id } = useParams()
  const { teacherDetail, isLoading } = useTeacherContext()

  const userContact = teacherDetail?.user_contacts?.find((user) => user.is_default)
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  //#region Handle extracting program, subject and section
  const userStaffInstances = teacherDetail ? (teacherDetail?.user_staff_instances as []) : []
  const personInCharge = processFields(userStaffInstances, 'staff', 'username')
  //#endregion

  return (
    <>
      {isLoading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Profile Details'
            titleButton={allowRender ? 'Edit Profile' : ''}
            link={`${TEACHER_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
          />

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={teacherDetail?.code} />
            <ItemProfile
              title={LANG_PLACEHOLDER.FULLNAME}
              body={teacherDetail?.detail?.full_name}
            />
            <ItemProfile title='Username' body={teacherDetail?.username} />
            <ItemProfile title='Email' body={teacherDetail?.detail?.email ?? userContact?.email} />
            <ItemProfile
              title='Address'
              body={teacherDetail?.detail?.address ?? userContact?.address ?? '-'}
            />
            <ItemProfile
              title='Phone Number'
              body={teacherDetail?.detail?.phone ?? userContact?.phone}
            />
            <ItemProfile
              title='Account Status'
              status={teacherDetail?.status}
              align='align-self-center'
            />
            <ItemProfile
              title='Teaching Status'
              body={
                TEACHING_STATUS[teacherDetail?.teacher_status as keyof typeof TEACHING_STATUS] ||
                '-'
              }
            />
            <ItemProfile
              title='Person in charge'
              body={
                personInCharge.length > 0
                  ? personInCharge.map((item) => (
                      <span className={`badge badge-light fs-7 my-1 me-1`} key={item}>
                        {item}
                      </span>
                    ))
                  : '-'
              }
            />
            <ItemProfile
              title='Gender'
              body={
                teacherDetail?.detail?.sex === 'MALE'
                  ? 'Male'
                  : teacherDetail?.detail?.sex === 'FEMALE'
                  ? 'Female'
                  : teacherDetail?.detail?.sex === 'OTHERS'
                  ? 'Others'
                  : '-'
              }
            />
            <ItemProfile title='Job Title' body={teacherDetail?.detail?.job_title ?? '-'} />
            <ItemProfile
              title='D.O.B'
              body={
                teacherDetail?.detail?.dob
                  ? formatDate(teacherDetail?.detail?.dob, 'DD/MM/YYYY')
                  : ''
              }
            />
            <ItemProfile
              title='Facility'
              body={
                teacherDetail?.teacher_facilities && teacherDetail?.teacher_facilities?.length > 0
                  ? teacherDetail?.teacher_facilities?.map((item) => (
                      <span className={`badge badge-light fs-7 my-1 me-1`} key={item?.id}>
                        {item?.facility?.name}
                      </span>
                    ))
                  : '-'
              }
            />
            <ItemProfile title='Current Company' body={teacherDetail?.detail?.current_company} />

            <ItemProfile
              title='Facebook'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.facebook ?? '#'}
                >
                  {teacherDetail?.detail?.facebook || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='Youtube'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.youtube ?? '#'}
                >
                  {teacherDetail?.detail?.youtube || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='LinkedIn'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.linkedin ?? '#'}
                >
                  {teacherDetail?.detail?.linkedin || '-'}
                </Link>
              }
            />

            <ItemProfile
              title='Certificate File'
              body={
                teacherDetail?.attachment_files && teacherDetail?.attachment_files?.length > 0 ? (
                  <UploadMulti
                    fileList={teacherDetail?.attachment_files?.map((item) => item.file) ?? []}
                    setFileList={() => {}}
                    isScreenList={false}
                    resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                    label='Resources'
                    disabled={false}
                    isshowUploadButton={false}
                  />
                ) : (
                  '-'
                )
              }
            />
            <ItemProfile
              title='Updated At'
              body={
                teacherDetail?.updated_at ? formatDateTime(new Date(teacherDetail?.updated_at)) : ''
              }
            />
            <ItemProfile
              title='Description'
              body={
                <div dangerouslySetInnerHTML={{ __html: teacherDetail?.detail?.describe ?? '' }} />
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default TeacherProfileDetail

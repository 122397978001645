import { ReactNode } from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'

interface IButtonDropdown {
  title: string | undefined
  onClick?: () => void
  className?: string
  children: ReactNode
  loading?: boolean
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
  isListScreen?: boolean
}

const ButtonDropdown = ({
  title,
  onClick,
  className = '',
  loading = false,
  disabled = false,
  children,
  type = 'button',
  isListScreen,
}: IButtonDropdown) => {
  return (
    <Dropdown
      as={ButtonGroup}
      className={`${className} ${isListScreen ? 'sapp-h-40' : 'sapp-button-dropdown'}`}
      align='end'
    >
      <ButtonPrimary
        type={type}
        loading={loading}
        onClick={onClick}
        title={title}
        size='small'
        disabled={disabled}
      />
      <Dropdown.Toggle
        split
        variant='primary'
        disabled={disabled || loading}
        className={`${isListScreen ? 'sapp-h-40' : 'sapp-h-45px'} h-100`}
      />
      <Dropdown.Menu className='min-100 p-3 mt-1'>{children}</Dropdown.Menu>
    </Dropdown>
  )
}

export default ButtonDropdown

import { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import LessonList from 'src/components/courses/lessons/list'
import VersionHistoryList from 'src/components/courses/version-history/list'
import PreviewVersion from 'src/components/courses/version-history/preview'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { LANG_COURSES, LANG_SIDEBAR } from 'src/constants/lang'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { LessonListProvider } from 'src/context/LessonContext'
import { AntdTreeNode, ILesson, ILessonContent, ITabs } from 'src/type'

const DEFAULT_PAGE_SIZE = 7

export const transformNode = (node: ILessonContent): AntdTreeNode => {
  return {
    key: node.course_section.id,
    title: node.course_section.name,
    children: node.child ? node.child.map(transformNode) : [],
  }
}

export const transformToAntdTreeData = (lessons: ILesson[]) => {
  return lessons.map((lesson) => ({
    key: lesson.id,
    id: lesson.id,
    title: lesson.name,
    children: lesson.content.map(transformNode),
    isOnline: lesson.is_online,
  }))
}

const LessonListPage = () => {
  const { id, version: versionParam } = useParams()
  const pageState = useState(1)
  const [page] = pageState
  const pageSizeState = useState(DEFAULT_PAGE_SIZE)
  const [pageSize] = pageSizeState

  const location = useLocation()

  const [version, setVersion] = useState<undefined | string>(versionParam)
  const isPreviewHistory = location.pathname.includes(`/version-history/${versionParam}`)
  const isVersionHistory = location.pathname.includes('/version-history') && !isPreviewHistory // Ensures no conflict

  const useQueryData = useQuery({
    queryKey: id ? lessonKeys.list(id, version, page, pageSize) : lessonKeys.base,
    queryFn: () =>
      id
        ? LessonApi.getList({
            page_index: page,
            page_size: pageSize,
            course_id: id,
            version: version,
          })
        : Promise.reject('No id provided'),
    enabled: !!id,
    keepPreviousData: true,
    staleTime: 5000,
  })

  const breadcrumbs: ITabs[] = [
    {
      link: '',
      title: LANG_SIDEBAR.lms,
    },
    {
      link: PageLink.COURSES,
      title: LANG_COURSES.courseList,
    },
    {
      link: ``,
      title: useQueryData.data?.data.course.name ?? '',
    },
    {
      link: `${PageLink.COURSES}/${id}/${PageLink.LESSONS}`,
      title: LANG_COURSES.lessonList,
    },
  ]

  // Always add Version History breadcrumb if the path includes it
  if (isVersionHistory || isPreviewHistory) {
    breadcrumbs.push({
      link: `${PageLink.COURSES}/${id}/${PageLink.LESSONS}/${PageLink.VERSION_HISTORY}`,
      title: LANG_COURSES.versionHistory,
    })
  }

  // Then, if it's specifically Preview History, add that after Version History
  if (isPreviewHistory) {
    breadcrumbs.push({
      link: '',
      title: LANG_COURSES.previewVersion,
    })
  }

  const getContent = () => {
    if (isPreviewHistory) return <PreviewVersion key={version} />
    if (isVersionHistory) return <VersionHistoryList />
    return <LessonList />
  }

  const getPageTitle = () => {
    if (isPreviewHistory) return LANG_COURSES.previewVersion
    if (isVersionHistory) return LANG_COURSES.versionHistory
    return LANG_COURSES.lessonList
  }

  return (
    <PageLayouts pageTitle={getPageTitle()} breadcrumbs={breadcrumbs} classNameLayout='h-100'>
      <LessonListProvider
        value={{
          version: version,
          setVersion: setVersion,
          data: useQueryData,
          pageState: pageState,
          pageSizeState: pageSizeState,
        }}
      >
        {useQueryData.isSuccess && <div className='card px-9 py-7 h-100'>{getContent()}</div>}
      </LessonListProvider>
    </PageLayouts>
  )
}

export default LessonListPage

import React, { ReactNode } from 'react'
import SappFilterButton from './SAPPFIlterButton'
import { KTCardBody } from 'src/_metronic/helpers'
import clsx from 'clsx'

interface IProps {
  layoutFilter: ReactNode
  onSubmit: () => void
  onReset: () => void
  loading: boolean
  layoutAction: ReactNode
  layoutTable: ReactNode
  isFullScreen?: boolean
}

const LayoutTable = ({
  layoutFilter,
  loading,
  onReset,
  onSubmit,
  layoutAction,
  layoutTable,
  isFullScreen = false,
}: IProps) => {
  return (
    <div className={clsx('card', { 'border-0': isFullScreen })}>
      {layoutFilter}
      <div className={clsx('card-header border-0 pt-5', { 'px-0': isFullScreen })}>
        <div className='d-flex'>
          <SappFilterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={onSubmit}
            resetClick={onReset}
            disabled={loading}
            loading={loading}
          />
        </div>
        {layoutAction}
      </div>
      <KTCardBody className={clsx({ 'px-0': isFullScreen })}>{layoutTable}</KTCardBody>
    </div>
  )
}

export default LayoutTable

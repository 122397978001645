import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { Control, useController, useWatch } from 'react-hook-form'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from '../label/SappLabel'
import './datetime.scss'

interface IProps extends RangePickerProps {
  control: Control<any>
  needConfirm?: boolean
  guideline?: string[]
  required?: boolean
  label?: string
  defaultValue?: [Dayjs | null, Dayjs | null]
  isListScreen?: boolean
  // name: string
  names: [string, string]
  formatFunction?: (date: Dayjs | null) => any // Custom format function
}

const { RangePicker: AntdRangePicker } = DatePicker
const RangePicker = ({
  guideline,
  control,
  required,
  label,
  // name,
  names,
  defaultValue,
  isListScreen,
  formatFunction = (date) => date?.toISOString(),
  ...props
}: IProps) => {
  const { field: from } = useController({ name: names[0], control })
  const { field: to } = useController({ name: names[1], control })
  const values = useWatch({ control, name: names })

  return (
    <>
      {label && <SappLabel label={label} required={required} />}
      <>
        <AntdRangePicker
          {...props}
          onChange={(dates) => {
            if (names && dates) {
              from.onChange(formatFunction(dates[0]) ?? null)
              to.onChange(formatFunction(dates[1]) ?? null)
            }
          }}
          value={[
            values[0]
              ? props.format
                ? dayjs(values[0], typeof props.format === 'string' ? props.format : undefined)
                : dayjs(values[0])
              : null,

            values[1]
              ? props.format
                ? dayjs(values[1], typeof props.format === 'string' ? props.format : undefined)
                : dayjs(values[1])
              : null,
          ]}
          className={clsx('createDateRangePicker w-100', props.className, {
            'sapp-h-40': isListScreen,
            'sapp-h-45px': !isListScreen,
          })}
        />
        <div>
          <GuidelineField guideline={guideline} />
          {/* <ErrorMessage>{fieldState.error?.message}</ErrorMessage> */}
        </div>
      </>
    </>
  )
}

export default RangePicker

const ArrowDown: React.FC<React.HTMLAttributes<HTMLSpanElement>> = (props) => {
  return (
    <span {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='14' height='9' fill='none'>
        <path
          fill='#99A1B7'
          d='M7 8.2a1 1 0 0 1-.71-.29l-6-6A1.004 1.004 0 0 1 1.71.49L7 5.78 12.29.49a1.004 1.004 0 1 1 1.42 1.42l-6 6A1 1 0 0 1 7 8.2Z'
        />
      </svg>
    </span>
  )
}

export default ArrowDown

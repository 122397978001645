import { UseQueryResult } from 'react-query'
import { createRequiredContext } from 'src/helper/createRequiredContext'
import { IGetLessonRes, ILessonsRes, IResponse } from 'src/type'

interface ILessonFormProvider {
  data: IGetLessonRes
}

interface ILessonListProvider {
  data: UseQueryResult<IResponse<ILessonsRes>, unknown>
  pageState: [number, React.Dispatch<React.SetStateAction<number>>]
  pageSizeState: [number, React.Dispatch<React.SetStateAction<number>>]
  version?: string
  setVersion: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const [LessonFormProvider, useLessonFormContext] =
  createRequiredContext<ILessonFormProvider>()
export const [LessonListProvider, useLessonListContext] =
  createRequiredContext<ILessonListProvider>()

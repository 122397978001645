import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { KTCardBody } from 'src/_metronic/helpers'
import { UsersAPI } from 'src/apis/user'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/ui/table/SappTable'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { useQueryResponse } from 'src/components/user-list/components/core/QueryResponseProvider'
import CodeUser from 'src/components/user-management/CodeUser'
import UserCell from 'src/components/user-management/UserCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import { STATUS_FORM, STUDENT_PROFILE } from 'src/constants'
import { CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, Role, IUserTableProps } from 'src/type'
import { UserActionsCell } from './columns/UserActionsCell'
import { cleanParamsAPI } from 'src/utils'
import { IStudentDetail, IStudents } from 'src/type/students'
import UserStaffCell from 'src/components/classes/profile/user/UserStaffCell'

const UsersTable = ({
  pagination,
  setPagination,
  studentList,
  handleChangeParams,
  queryParams,
  setSelected,
  selected,
}: IUserTableProps) => {
  const { isLoading, refetch } = useQueryResponse()
  const { confirm, contextHolder } = useConfirm()
  // TODO: reset lại table về mặc định nếu không call API
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderEditStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderResetPassword = hasPermission(
    profileMe?.roles,
    TITLE_GR.PUT_RESET_PASSWORD_USER_GR
  )
  const allowRenderResetPasswordStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.RESET_PASSWORD_STAFF
  )
  const allowRenderBlock = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderBlockUser = hasPermission(profileMe?.roles, TITLE_GR.REMOVE_USER_GR)
  const allowRenderBlockStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderEditEmail = hasPermission(profileMe?.roles, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR)
  const allowRenderEditEmailStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.CHANGE_EMAIL_STAFF
  )
  const allowRenderViewProfile = hasPermission(profileMe?.roles, TITLE_GR.VIEW_USER_GR)
  const allowRenderViewProfileStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.GET_STAFF)
  const allowRenderAction =
    allowRenderEdit ||
    allowRenderBlockUser ||
    allowRenderEditStaff ||
    allowRenderResetPassword ||
    allowRenderResetPasswordStaff ||
    allowRenderBlock ||
    allowRenderBlockStaff ||
    allowRenderEditEmail ||
    allowRenderEditEmailStaff ||
    allowRenderViewProfile ||
    allowRenderViewProfileStaff

  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })

  const changeStatusUser = (id: string, status: any) => {
    UsersAPI.updateUserStatus({ id, status })
      .then(() => toast.success('Update Successfully!'))
      .catch((error) => {
        // if (error?.response?.data?.error?.code === '403|000000') {
        //   toast.error(DENIED_PERMISSIONS)
        // }
      })
  }

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: () =>
        changeStatusUser(
          id,
          EStatusUser.ACTIVE === value
            ? EStatusUser.ACTIVE
            : EStatusUser.INACTIVE === value
            ? EStatusUser.INACTIVE
            : EStatusUser.BLOCKED
        ),
      onClose: () => cancel(true),
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }
  const filterParams = cleanParamsAPI({ ...queryParams })

  useEffect(() => {
    studentList?.users?.map((item: IStudentDetail | IStudents, index: number) => {
      setValue(`status${index}`, item.status)
    })
  }, [studentList?.users])

  useEffect(() => {
    setPagination((prev) => ({ ...prev, total: studentList?.metadata?.total_records }))
  }, [studentList])

  const columnsValue = [
    {
      title: 'ID',
      render: (record: IStudentDetail) => (
        <CodeUser code={record.hubspot_contact_id || record?.employee_code || '--'} />
      ),
    },
    {
      title: 'User',
      render: (_: IStudentDetail, record: IStudentDetail) => (
        <UserInfoCell
          user={record ?? ''}
          linkViewProfile={`${STUDENT_PROFILE}/${record?.id}/overview`}
        />
      ),
    },
    {
      title: 'Username',
      render: (record: IStudentDetail) => <UserCell data={record?.username ?? ''} />,
    },
    {
      title: 'Phone',
      render: (record: IStudentDetail) => (
        <UserCell data={record?.user_contacts?.[0]?.phone ?? ''} />
      ),
    },
    {
      title: 'Additional Emails',
      render: (record: IStudentDetail) => {
        const additionalEmails = record?.user_contacts
          ?.filter((email) => !email?.is_default)
          ?.map((item) => <UserCell key={item.email} data={item.email} />)

        return additionalEmails ?? '--'
      },
    },
    {
      title: 'Status',
      render: (_: IStudentDetail, record: IStudentDetail, index: number) => {
        return (
          <HookFormSelectAntd
            allowClear={false}
            name={`status${index}`}
            control={control}
            dropdownStyle={{ maxWidth: 100 }}
            placeholder='Status'
            filterOption={true}
            disabled={!allowRenderEdit}
            onChange={(selectedValue: unknown) =>
              handleChange(`status${index}`, selectedValue as string, record?.id)
            }
            classNameHeight='sapp-h-35px'
            options={STATUS_FORM}
          />
        )
      },
    },
    {
      title: 'Test Account',
      render: (record: IStudentDetail) => <UserStaffCell user={record} />,
    },
    {
      title: 'Type User',
      render: (record: IStudentDetail) => (
        <UserCell data={record?.type_user === 'STUDENT' ? 'Student' : 'Employee'} />
      ),
    },
    {
      title: 'Date',
      render: (record: IStudentDetail) => (
        <DatetimeColumn updated_at={record?.updated_at} created_at={record?.created_at} />
      ),
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record: IStudentDetail) => (
        <>
          {allowRenderAction && (
            <UserActionsCell
              id={record?.id}
              status={record?.status}
              email={record?.detail?.email}
            />
          )}
        </>
      ),
    },
  ]

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        handleChangeParams={handleChangeParams}
        filterParams={filterParams}
        columns={columnsValue}
        fetchData={refetch}
        data={studentList?.users ?? []}
        pagination={pagination}
        setPagination={setPagination}
        fetchTableData={refetch}
        loading={isLoading}
        showCheckbox
        setSelection={setSelected}
        selections={selected}
      />
    </KTCardBody>
  )
}

export { UsersTable }

import { useLocation, useParams } from 'react-router-dom'
import { AREA_PROFILE_URL } from 'src/constants/classroom'
import AreaProfileDetail from 'src/pages/classroom/area/detail/AreaProfileDetail'

import AreaProfileRoomList from 'src/pages/classroom/area/detail/AreaProfileRoomList'
import AreaProfileSetting from 'src/pages/classroom/area/detail/AreaProfileSetting'
import AreaProfileStaffList from 'src/pages/classroom/area/detail/AreaProfileStaffList'
import { IFacility } from 'src/type/area'

interface IProps {
  areaDetail: IFacility | undefined
  loading: boolean
  refetch: () => void
}

const RenderClassProfile = ({ areaDetail, loading, refetch }: IProps) => {
  const location = useLocation()
  const { id } = useParams()

  const renderClass = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case AREA_PROFILE_URL.OVERVIEW:
        return <AreaProfileDetail areaDetail={areaDetail} loading={loading} />
      case AREA_PROFILE_URL.SETTING:
        return <AreaProfileSetting areaDetail={areaDetail} loading={loading} refetch={refetch} />
      case AREA_PROFILE_URL.CLASSROOMLIST:
        return <AreaProfileRoomList loading={loading} areaDetail={areaDetail} />
      case AREA_PROFILE_URL.STAFF_LIST:
        return <AreaProfileStaffList areaDetail={areaDetail} loading={loading} />
      default:
        return <AreaProfileDetail areaDetail={areaDetail} loading={loading} />
    }
  }
  return <>{areaDetail && renderClass()}</>
}

export default RenderClassProfile

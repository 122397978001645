import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import LessonEdit from 'src/components/courses/lessons/edit'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'

const LessonEditPage = () => {
  const { lessonId } = useParams()

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: lessonKeys.getOne(lessonId!),
    queryFn: () =>
      lessonId ? LessonApi.getLesson(lessonId) : Promise.reject('No course_id provided'),
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isSuccess || !data) {
    return <div>Error loading lesson</div>
  }

  return <LessonEdit data={data.data} />
}

export default LessonEditPage

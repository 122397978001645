const ArrowUp: React.FC<React.HTMLAttributes<HTMLSpanElement>> = (props) => {
  return (
    <span {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='14' height='9' fill='none'>
        <path
          fill='#99A1B7'
          d='M7 .8a1 1 0 0 0-.71.29l-6 6a1.004 1.004 0 0 0 1.42 1.42L7 3.22l5.29 5.29a1.004 1.004 0 1 0 1.42-1.42l-6-6A1 1 0 0 0 7 .8Z'
        />
      </svg>
    </span>
  )
}

export default ArrowUp

import { Space, Tree } from 'antd'
import clsx from 'clsx'
import SwitcherClosed from 'src/common/CustomIcons/SwitcherClosed'
import SwitcherExpanded from 'src/common/CustomIcons/SwitcherExpanded'
import { Collapse } from 'src/components/base/collapse'
import ItemProfile from 'src/components/base/ItemProfile'
import Text from 'src/components/base/text'
import { useLessonFormContext } from 'src/context/LessonContext'
import { ILessonContent } from 'src/type'

type MappedLesson = {
  key: string
  title: string
  children?: MappedLesson[]
}

const mapCourseSections = (lessons: ILessonContent[]): MappedLesson[] => {
  return lessons.map(({ course_section_id, course_section, child }) => ({
    key: course_section_id,
    title: course_section.name,
    children: child ? mapCourseSections(child) : undefined,
  }))
}

const LessonDetail = () => {
  const { data } = useLessonFormContext()
  return (
    <Space direction='vertical' className='w-100' size={'middle'}>
      <Collapse
        defaultActiveKey={['1']}
        expandIconPosition={'end'}
        items={[
          {
            key: 1,
            label: <div className='fs-4 fw-bold'>Basic information</div>,
            children: (
              <div>
                <ItemProfile title={'Lesson Name'} body={data?.name} />
                <ItemProfile title={'Study Hour'} body={data?.standard_study_hour} />
                <ItemProfile title={'Online LMS'} body={data?.is_online ? 'Yes' : 'No'} />
                <ItemProfile title={'View on LMS'} body={data?.is_review_allowed ? 'Yes' : 'No'} />
                <ItemProfile
                  title={'Key Content Before'}
                  body={
                    <Space wrap>
                      {data?.key_content_before?.map((item, index) => (
                        <span key={index} className={clsx('badge badge-light-dark badge-lg')}>
                          {item.name} - {item.child.join(', ')}
                        </span>
                      ))}
                    </Space>
                  }
                />
                <ItemProfile
                  title={'Key Content After'}
                  body={
                    <Space wrap>
                      {data?.key_content_after?.map((item, index) => (
                        <span key={index} className={clsx('badge badge-light-dark badge-lg')}>
                          {item.name} - {item.child.join(', ')}
                        </span>
                      ))}
                    </Space>
                  }
                />
                <ItemProfile
                  title={'Description'}
                  body={<Text fallback='-'>{data.description}</Text>}
                />
              </div>
            ),
          },
        ]}
      />
      <Collapse
        expandIconPosition={'end'}
        defaultActiveKey={['1']}
        items={[
          {
            key: 1,
            label: <div className='fs-4 fw-bold'>Course Content</div>,
            children: (
              <Tree
                treeData={mapCourseSections(data.content ?? [])}
                autoExpandParent={true}
                switcherIcon={({ expanded }) =>
                  expanded ? (
                    <SwitcherExpanded className='text-primary' />
                  ) : (
                    <SwitcherClosed className='text-gray-1000' />
                  )
                }
                className='lessonFormTree'
              />
            ),
          },
        ]}
      />
    </Space>
  )
}

export default LessonDetail

import { useParams } from 'react-router-dom'
import TableImportLog from 'src/components/base/upload-file/TableImportLog/TableImportLog'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { ITabs } from 'src/type'

let defaultBreadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
]
export enum IMPORT_OBJECT_TYPE {
  'USER' = 'USER',
  'STAFF' = 'STAFF',
  'QUESTION' = 'QUESTION',
  'CLASS' = 'CLASS',
  'TEST_PARTICIPANT' = 'TEST_PARTICIPANT',
  'exam' = 'exam',
}
const ImportLog = () => {
  const params = useParams()

  const setBreadcrumbs = (type?: string) => {
    let breadcrumbs = defaultBreadcrumbs
    if (type === 'STAFF') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/STAFF`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'STUDENT') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/STUDENT`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'TEACHER') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/TEACHER`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'CLASS') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/CLASS`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'CLASS_STUDENT') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/CLASS_STUDENT`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'TEST_PARTICIPANT') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/TEST_PARTICIPANT`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'exam') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/exam`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'SCHEDULE_HOLIDAY') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/SCHEDULE_HOLIDAY`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
  }

  return (
    <PageLayouts
      pageTitle={LANG_SIDEBAR.importLog}
      breadcrumbs={setBreadcrumbs(params.type) ?? defaultBreadcrumbs}
    >
      <div className='card'>{!!params.type && <TableImportLog type={params.type} />}</div>
    </PageLayouts>
  )
}

export default ImportLog

import { Button, Space, Typography } from 'antd'
import { KTIcon } from 'src/_metronic/helpers'
import { VersionHistoryItem } from 'src/type/courses/versionHistory'
import './Preview.styles.scss'
import { CurrentVersion } from 'src/type'

interface VersionNavigatorProps {
  currentVersion: CurrentVersion
  onPrev: () => void
  onNext: () => void
  versionList: VersionHistoryItem[]
}

const VersionNavigator = ({
  currentVersion,
  onPrev,
  onNext,
  versionList,
}: VersionNavigatorProps) => {
  if (!versionList || versionList.length === 0) return null

  const currentIndex = versionList.findIndex((item) => item.version === currentVersion.version)
  const isFirst = currentIndex === 0
  const isLast = currentIndex === versionList.length - 1

  return (
    <Space className='version-navigator'>
      <Button
        shape='default'
        icon={<KTIcon iconName={'left'} />}
        onClick={onPrev}
        disabled={isFirst}
        size='small'
      />
      <Typography.Link style={{ fontSize: '16px' }}>
        v. {currentVersion.version} {currentVersion.is_active && '(Current)'}
      </Typography.Link>
      <Button
        shape='default'
        icon={<KTIcon iconName={'right'} />}
        onClick={onNext}
        disabled={isLast}
        size='small'
      />
    </Space>
  )
}

export default VersionNavigator

import { ConfigProvider, Space, Table, Tooltip } from 'antd'
import { TableProps } from 'antd/es/table'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { DepartmentAPI } from 'src/apis/department'
import ArrowDown from 'src/common/CustomIcons/ArrowDown'
import ArrowRight from 'src/common/CustomIcons/ArrowRight'
import ActionCell from 'src/components/base/action/ActionCell'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import GridLayout from 'src/components/layout/grid'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { departmentKeys, PageLink } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { IDepartmentTree, IDepartmentTreeTable } from 'src/type'
import { BUSINESS_UNITS } from 'src/type/department/enum'
import DepartmentCreate from '../DepartmentCreate'
import styles from './styles.module.scss'

const getBadgeColor = (unit: BUSINESS_UNITS) => {
  switch (unit) {
    case BUSINESS_UNITS.HO:
      return 'badge-primary'
    case BUSINESS_UNITS.ACCA:
      return 'badge-acca'
    case BUSINESS_UNITS.CFA:
      return 'badge-cfa'
    case BUSINESS_UNITS.CMA:
      return 'badge-cma'

    default:
      return 'badge-light-dark'
  }
}

const getAllKeys = (data: IDepartmentTree[]): string[] => {
  let keys: string[] = []

  // Traverse through each row in the data array
  data?.forEach((item) => {
    // Add the key of the current item to the array
    keys.push(item.id!)

    // If the item has child, recursively call getAllKeys to get keys from the child
    if (item.child) {
      keys = [...keys, ...getAllKeys(item.child)]
    }
  })

  return keys
}

const processTableData = (
  data: IDepartmentTree[],
  level = 0,
  parentLastItem = false,
  noSiblingArray: boolean[] = [],
  previousTreeId: string | null = null
) => {
  return data.map((item, index) => {
    if (item.tree_id !== previousTreeId) {
      noSiblingArray = []
    }

    const isLastItem = index === data.length - 1 // Check if it's the last child
    const isOnlyChild = data.length === 1
    // const haveNoSibling = data.find((row) => row.tree_id === item && level)
    noSiblingArray.push(isOnlyChild) // Add to the array
    const insideLastLevel = parentLastItem

    const updatedItem = {
      ...item,
      level,
      lastItem: isLastItem,
      insideLastLevel,
      isOnlyChild,
      noSiblingArray: [...noSiblingArray],
    }

    if (item.child && item.child.length > 0) {
      // Recursive to process nested item
      updatedItem.child = processTableData(
        item.child,
        level + 1,
        isLastItem,
        noSiblingArray,
        item.tree_id
      )
    }

    return updatedItem
  })
}

const fetchDepartments = async (filters: FieldValues) => {
  const response = await DepartmentAPI.getTree({
    page_index: 1,
    page_size: 10,
    params: {
      ...filters,
    },
  })
  return response.data.data
}

type IFilters = {
  name?: string
}
const createUrl = '/departments/create'
const DepartmentList = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [expandedRowKeys, setExpandedRowsKeys] = useState<string[]>([])
  const [isReset, setIsReset] = useState<boolean>(false)
  const { control, watch, handleSubmit, reset } = useForm<IFilters>({
    defaultValues: {
      name: undefined,
    },
  })

  const onSubmit: SubmitHandler<IFilters> = () => {
    refetch()

    setIsReset(false)
  }

  const filters = watch()
  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: [...departmentKeys.all(), isReset],
    queryFn: () => fetchDepartments(filters),
    select: (data) => {
      const processedData = processTableData(data)
      return processedData
    },
    onSuccess: (data) => {
      const allKeys = getAllKeys(data)
      setExpandedRowsKeys(allKeys)
    },
    refetchOnWindowFocus: false,
  })

  // const [expandedRowKeys, setExpandedRowsKeys] = useState<string[]>(allKeys)
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false)
  const { confirm, contextHolder } = useConfirm()
  const queryClient = useQueryClient()

  const { mutate: deleteExam } = useMutation({
    mutationFn: (examIds: string[]) => DepartmentAPI.delete(examIds),
    onSuccess: (res) => {
      if (res.success) {
        toast.success(res.data.message)
        queryClient.invalidateQueries(departmentKeys.all())
      }
    },
  })

  const handleDelete = (id: string[]) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xoá không?'],
      onClick: () => deleteExam(id),
    })
  }

  const columns: TableProps<IDepartmentTreeTable>['columns'] = [
    {
      title: 'DEPARTMENT NAME',
      dataIndex: 'name',
      render: (value, record) => {
        return (
          <Tooltip title={value} color='#ffffff'>
            <Link
              className={clsx('text-gray-800 text-hover-primary fs-6', styles.departmentName)}
              to={`${PageLink.DEPARTMENTS}/${record.id}`}
            >
              {value && record?.short_name
                ? `${value} (${record.short_name})`
                : value || record?.short_name || '-'}
            </Link>
          </Tooltip>
        )
      },
    },
    {
      title: 'UNIT',
      dataIndex: 'unit',
      width: 200,
      render: (value) => {
        return (
          <>
            <span className={clsx('badge fs-7 my-1 me-1 badge-primary')}>{BUSINESS_UNITS.HO}</span>
            {value !== BUSINESS_UNITS.HO && (
              <span className={clsx('badge fs-7 my-1 me-1', getBadgeColor(value))}>
                {value.replace('_', ' ')}
              </span>
            )}
          </>
        )
      },
    },
    {
      title: 'NUMBER OF EMPLOYEES',
      dataIndex: 'staff_count',
      width: 300,
      align: 'left',
      render: (value) => <p className={styles.noOfEmployeeCol}>{value}</p>,
    },
    {
      title: '',
      fixed: 'right',
      width: 10,
      render: (record) => (
        <div>
          <ActionCell height={21}>
            <div
              className='menu-item px-3'
              onClick={() => navigate(`${PageLink.DEPARTMENTS}/${record.id}/setting`)}
            >
              <div className='menu-link px-3'>Edit</div>
            </div>
            <div
              className='menu-item px-3'
              onClick={() => {
                return handleDelete([record.id])
              }}
            >
              <div className='menu-link px-3'>Delete</div>
            </div>
          </ActionCell>
        </div>
      ),
    },
  ]

  const handleReset = () => {
    reset({ name: undefined })

    setIsReset(true)
  }

  useEffect(() => {
    if (location.pathname === createUrl) {
      setOpenCreateDrawer(true)
    }
  }, [location])

  const generateTreeData = (data: any) => {
    return data?.map((item: any) => ({
      ...item,
      children: item.children ? generateTreeData(item.children) : undefined,
    }))
  }

  const treeData = generateTreeData(data)

  return (
    <Space
      direction='vertical'
      size='middle'
      style={{
        display: 'flex',
      }}
    >
      {contextHolder}
      <form className='card p-9' onSubmit={handleSubmit(onSubmit)}>
        <GridLayout isListFilters>
          {/* Tháng thi */}
          <HookFormTextField
            control={control}
            name='name'
            placeholder='Search Department'
            defaultValue={''}
          />
        </GridLayout>
        <div
          className={clsx(['d-flex justify-content-between align-items-stretch p-0 border-0 mt-5'])}
        >
          <div className='d-flex'>
            <ButtonSecondary
              title={'Reset'}
              className={`me-4`}
              onClick={handleReset}
              loading={false}
              disabled={isLoading || isFetching}
            />
            <ButtonPrimary
              title={'Search'}
              loading={false}
              disabled={isLoading || isFetching}
              onClick={() => {
                handleSubmit(onSubmit)
              }}
              type='submit'
            />
          </div>
          <div className={clsx('d-flex justify-content-between')}>
            {/* Don't show upload button when there're selected items */}

            <ButtonIcon
              title={'Create Department'}
              isListScreen
              onClick={() => navigate(createUrl)}
              type='button'
            >
              <KTIcon iconName='plus' className='fs-4' />
            </ButtonIcon>
          </div>
        </div>
      </form>
      <div className='card p-9 pt-7'>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: '#ffffff',
                headerSplitColor: '#ffffff',
                headerColor: '#99A1B7',
                borderColor: '#dbdfe9',
                cellPaddingBlock: 16,
              },
            },
          }}
        >
          {data && (
            <Table<IDepartmentTreeTable>
              tableLayout='auto'
              columns={columns}
              dataSource={treeData}
              pagination={false}
              loading={isLoading}
              rowKey={(record) => record.id!}
              expandable={{
                childrenColumnName: 'child',
                defaultExpandAllRows: true,
                expandedRowKeys: expandedRowKeys,
                fixed: 'right',
                onExpand: (expanded, record) => {
                  expanded
                    ? setExpandedRowsKeys([...expandedRowKeys, record.id as string])
                    : setExpandedRowsKeys(expandedRowKeys.filter((id) => id !== record.id))
                },
                expandIcon: ({ expanded, onExpand, record }) => {
                  if (record.child && record.child.length > 0) {
                    return expanded ? (
                      <ArrowDown
                        onClick={(e) => onExpand(record, e)}
                        className='cursor-pointer'
                        style={{ marginRight: 10 }}
                      />
                    ) : (
                      <ArrowRight
                        onClick={(e) => onExpand(record, e)}
                        className='cursor-pointer'
                        style={{ marginRight: 10 }}
                      />
                    )
                  }
                  return null
                },
              }}
              scroll={{ x: '1000px' }}
              rowClassName={(_, index) => {
                return clsx(index === 0 ? styles.firstRow : styles.row)
              }}
              rootClassName={styles.departmentTable}
            />
          )}
        </ConfigProvider>
      </div>
      <DepartmentCreate open={openCreateDrawer} setOpen={setOpenCreateDrawer} />
    </Space>
  )
}

export default DepartmentList

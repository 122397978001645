import dayjs from 'dayjs'

export const getDayOrWeekday = (
  date: Date,
  type: 'day' | 'weekday' | 'monthly' | 'monthDay' | 'weekdayIndex',
  isUTC: boolean = false
) => {
  const dayjsDate = dayjs(date)
  if (type === 'day') {
    return dayjsDate.date()
  }
  if (type === 'weekday') {
    return dayjsDate.format('dddd')
  }
  if (type === 'weekdayIndex') {
    return dayjsDate.day()
  }

  if (type === 'monthly') {
    return dayjsDate.format('DD')
  }

  if (type === 'monthDay') {
    return dayjsDate.format('MMMM DD')
  }
}

export const getDayOrWeekdayUTC = (
  date: Date,
  type: 'day' | 'weekday' | 'monthly' | 'weekdayIndex'
): string | number => {
  const utcDate = new Date(date)

  if (type === 'day') {
    return utcDate.getUTCDate()
  }
  if (type === 'weekday') {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return weekdays[utcDate.getUTCDay()]
  }
  if (type === 'weekdayIndex') {
    return utcDate.getUTCDay()
  }
  if (type === 'monthly') {
    return utcDate.getUTCMonth() + 1
  }
  return ''
}

export const areDatesOnSameDay = (startDate: Date, endDate: Date) => {
  const dayjsStart = dayjs(startDate)
  const dayjsEnd = dayjs(endDate)

  return dayjsStart.isSame(dayjsEnd, 'day')
}

import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MY_PROFILE, PROGRAM, TEACHER_PROFILE } from 'src/constants'
import { ITabs, Program } from 'src/type'
import ProgramTab from './ProgramTab'

const TeachingPrograms = () => {
  const { id } = useParams()

  const TeachingProgramsPath = `${TEACHER_PROFILE}/${id}/${MY_PROFILE.TEACHING_PROGRAMS}`
  const [program, setProgram] = useState<Program | undefined>()

  const path = {
    CFA: `${TeachingProgramsPath}/${PROGRAM.CFA.toLocaleLowerCase()}`,
    ACCA: `${TeachingProgramsPath}/${PROGRAM.ACCA.toLocaleLowerCase()}`,
    CMA: `${TeachingProgramsPath}/${PROGRAM.CMA.toLocaleLowerCase()}`,
    ['Cert/Dip']: `${TeachingProgramsPath}/cert-dip`,
  }

  const tabs: ITabs[] = [
    {
      link: path.CFA,
      title: PROGRAM.CFA,
    },
    {
      link: path.CMA,
      title: PROGRAM.CMA,
    },
    {
      link: path.ACCA,
      title: PROGRAM.ACCA,
    },
    {
      link: path['Cert/Dip'],
      title: 'Cert/Dip',
    },
  ]

  useEffect(() => {
    const programs: Array<Program> = [PROGRAM.CFA, PROGRAM.ACCA, PROGRAM.CMA, 'Cert/Dip']
    const activeProgram = programs.find((p) => {
      const key = p as keyof typeof path
      return location.pathname.startsWith(path[key])
    }) as Program | undefined

    setProgram(activeProgram || PROGRAM.CFA)
  }, [location.pathname])

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className={`card-header align-items-start pt-5 pb-4`}>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
            {tabs.map((tab) => (
              <li className='nav-item' key={tab.title}>
                <Link
                  className={`${
                    location.pathname.includes(tab.link)
                      ? 'active sapp-text--tabs'
                      : 'text-gray-500'
                  } nav-link sapp-fs-14 pt-0 pb-4 ms-0 me-10`}
                  to={tab.link}
                >
                  {tab.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <ProgramTab program={program!} />
      </div>
    </div>
  )
}

export default TeachingPrograms

import { divide, round } from 'lodash'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import withAuthComponents from 'src/components/auth/with-auth-components'
import Card from 'src/components/base/Card'
import PageLayouts from 'src/components/layout/PageLayouts'
import RenderTeacherProfile from 'src/components/teacher/TeacherDetail/RenderTeacherProfile'
import { MY_PROFILE, PageLink, PROGRAM, TEACHER_PROFILE, userKeys } from 'src/constants'
import { TeacherProvider } from 'src/context/user/teacher/TeacherContext'
import { ITabs } from 'src/type'
import { IStudentDetail } from 'src/type/students'

const TeacherProfile = () => {
  const { id } = useParams()

  const breadcrumbs: ITabs[] = [
    {
      link: `${TEACHER_PROFILE}/${id}/overview`,
      title: 'LMS',
    },
    {
      link: `${PageLink.TEACHERS}`,
      title: 'List Teachers',
    },
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'Teacher Profile',
    },
  ]

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${TEACHER_PROFILE}/${id}/${MY_PROFILE.OVERVIEW}`,
      title: 'Overview',
    },
    {
      link: `${TEACHER_PROFILE}/${id}/${MY_PROFILE.SETTING}`,
      title: 'Setting',
    },
    {
      link: `${TEACHER_PROFILE}/${id}/${MY_PROFILE.TEACHING_PROGRAMS}`,
      title: 'Teaching Programs',
    },
  ]

  const getTeacherDetail = async () => {
    const res = await UsersAPI.detail(id)
    return res.data
  }

  const { data: teacherDetail, isLoading } = useQuery<IStudentDetail>({
    queryKey: userKeys.detail(id!, 'teacher'),
    queryFn: () => getTeacherDetail(),
    enabled: !!id,
  })

  const inputFields = [
    teacherDetail?.key,
    teacherDetail?.detail?.full_name,
    teacherDetail?.username,
    teacherDetail?.detail?.email,
    teacherDetail?.detail?.phone,
    teacherDetail?.status,
    teacherDetail?.files?.[0]?.file_url,
  ]

  const countNonEmptyFields = () => {
    return inputFields.filter((input) => input !== undefined).length
  }

  return (
    <TeacherProvider
      value={{
        teacherDetail: teacherDetail || null || undefined,
        isLoading: isLoading,
      }}
    >
      <PageLayouts pageTitle='Teacher Profile' breadcrumbs={breadcrumbs}>
        {isLoading === false && (
          <>
            <Card
              userDetail={teacherDetail}
              tabs={tabs}
              loading={isLoading}
              progress={round(divide(countNonEmptyFields(), inputFields.length), 2)}
            />
            <RenderTeacherProfile />
          </>
        )}
      </PageLayouts>
    </TeacherProvider>
  )
}

export default withAuthComponents(TeacherProfile)

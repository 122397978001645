import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { MY_PROFILE, PROGRAM, TEACHER_PROFILE } from 'src/constants'
import SettingTeacher from './setting-profile'
import OverviewTeacherProfile from './teacher-overview'
import TeachingPrograms from './teaching-programs'

const RenderTeacherProfile = () => {
  const location = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()

  const renderProfile = () => {
    const pathSegment = location.pathname.split(`/${id}/`)[1]

    switch (true) {
      case pathSegment.startsWith(MY_PROFILE.OVERVIEW):
        return <OverviewTeacherProfile />
      case pathSegment.startsWith(MY_PROFILE.SETTING):
        return <SettingTeacher />
      case pathSegment.startsWith(MY_PROFILE.TEACHING_PROGRAMS):
        return <TeachingPrograms />
      default:
        return <OverviewTeacherProfile />
    }
  }

  useEffect(() => {
    const paths: Record<string, string> = {
      [`${TEACHER_PROFILE}/${id}/${MY_PROFILE.TEACHING_PROGRAMS}`]: `${TEACHER_PROFILE}/${id}/${
        MY_PROFILE.TEACHING_PROGRAMS
      }/${PROGRAM.CFA.toLocaleLowerCase()}`,
    }

    const targetPath = paths[location.pathname]
    if (targetPath) navigate(targetPath)
  }, [location])
  return <>{renderProfile()}</>
}

export default RenderTeacherProfile

import axios from 'axios'
import { isEmpty } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ImportLogAPI } from 'src/apis/import-log'
import LoadingTable from 'src/common/LoadingTable'
import NotData from 'src/components/NotData'
import UserStatusCell from 'src/components/user-management/UserStatusCell'
import { pageSizeOptionsDetail } from 'src/constants'
import PagiantionSAPP from '../pagination/PagiantionSAPP'
import { IImportLogDetailHeader, IMPORT_LOG_DETAIL_HEADER } from './ImportLogTableDetailHeader'

type Props = {
  type: string
  params: Readonly<Params<string>>
}
interface LogError {
  property: string
  errors: {
    code: string
    message: string
  }[]
}
interface Log {
  data: any
  errors: LogError[]
  rowIndex: string
}
interface File {
  file_type: string
  file_url: string
}

const ImportLogTableDetail = ({ params }: Props) => {
  const [logs, setLogs] = useState<Log[]>()
  const [headers, setHeader] = useState<IImportLogDetailHeader[]>()
  const [objectType, setObjectType] = useState()
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const importLog = await ImportLogAPI.getImportLogsById({ id: params.id ?? '' })
        const result = JSON.parse(importLog.data.result)

        if (result?.totalRows <= 0) {
          return
        }
        const jsonData = await axios.get(
          importLog.data.files.find((e: File) => e.file_type === 'REPORT')?.file_url
        )
        setObjectType(importLog?.data?.object_type)
        setLogs(jsonData.data)
      } finally {
        setLoading(false)
      }
    })()
  }, [params.id])

  useEffect(() => {
    const type =
      params.type !== 'exam'
        ? (params.type as keyof typeof IMPORT_LOG_DETAIL_HEADER)
        : (`${params.type}_${objectType}` as keyof typeof IMPORT_LOG_DETAIL_HEADER)

    let logHeaders: IImportLogDetailHeader[] = IMPORT_LOG_DETAIL_HEADER[type] || []

    setHeader(logHeaders)
  }, [logs])

  const checkErrorCell = (errors: LogError[] | undefined, property: string) => {
    return Array.isArray(errors) && errors.some((e) => e.property === property)
  }

  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const searchParams = new URLSearchParams(location.search)

  const queryParams = {
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const [currenPage, setCurrenPage] = useState(queryParams.page_index || 1)
  const [pageSize, setPageSize] = useState(queryParams.page_size || pageSizeOptionsDetail[0]?.value)

  // Calculate the start and end index for the current page
  const startIndex = (currenPage - 1) * pageSize
  const endIndex = startIndex + pageSize

  const routerUser =
    params.type === 'TEACHER'
      ? 'TEACHER'
      : params.type === 'STUDENT'
      ? 'STUDENT'
      : params.type === 'TEST_PARTICIPANT'
      ? 'TEST_PARTICIPANT'
      : 'STAFF'

  const handleChange = (page_index: number, page_size: number) => {
    setCurrenPage(page_index)
    setPageSize(page_size)
    navigate(
      `/import-history/detail/${routerUser}/${id}?page_index=${page_index}&page_size=${page_size}`
    )
  }
  // Slice the data to get the items for the current page
  const currentData = logs?.slice(startIndex, endIndex)
  const httpRegex =
    /^https?:\/\/(?:localhost|\b(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b)(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
  return (
    <div className='card-body py-4'>
      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
          <thead>
            {/* start:: header table */}
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='text-center min-w-50px pe-3'>#</th>
              {headers?.map((header) => (
                <th className={` ${header.className}`} key={header.id}>
                  {header.label}
                </th>
              ))}
              {/* <th className='min-w-100px'>Status</th> */}
              <th className='min-w-300px'>ERRORS</th>
            </tr>
            {/* end:: header table */}
          </thead>
          <tbody className='text-gray-600 fw-semibold'>
            {loading ? (
              <>
                {headers?.map((_header, i) => (
                  <LoadingTable key={i} headers={headers} />
                ))}
              </>
            ) : (
              currentData?.map((log, i) => {
                return (
                  <tr key={i}>
                    <td className='text-gray-600 fw-semibold text-center'>{log.rowIndex}</td>
                    {headers?.map((h) => {
                      return (
                        <Fragment key={h.id}>
                          {h.id !== 'status' ? (
                            <td
                              className={
                                (checkErrorCell(log?.errors, h.id) // Kiểm tra lỗi cho cell email
                                  ? 'text-danger ' // Nếu có lỗi, áp dụng class 'text-danger'
                                  : 'text-gray-600 ') + // Nếu không có lỗi, áp dụng class 'text-gray-600'
                                `${h.tdClassName ? h.tdClassName : ''} mw-300px `
                              }
                            >
                              {httpRegex.test(log.data[h.id]) ? (
                                <a
                                  className={`${h.cellClassName ? h.cellClassName : ''}`}
                                  href={log.data[h.id]}
                                >
                                  {log.data[h.id]}
                                </a>
                              ) : (
                                <span className={`${h.cellClassName ? h.cellClassName : ''}`}>
                                  {log.data[h.id] ?? '-'}
                                </span>
                              )}
                            </td>
                          ) : (
                            <td>
                              {log.data.status ? (
                                <UserStatusCell status={log.data.status} />
                              ) : (
                                <></>
                              )}
                            </td>
                          )}
                        </Fragment>
                      )
                    })}

                    <td>
                      {log.errors &&
                        Array.isArray(log.errors) &&
                        log.errors?.map((e, i) => {
                          return (
                            <div key={i} className={`min-w-300px ${i !== 0 ? ' mt-4' : ''}`}>
                              <span className='text-gray-800 fw-bold mb-1'>{e.property}</span>
                              <span className='text-gray-600 fw-semibold'>
                                {e.errors?.[0]?.message ? `: ${e.errors?.[0]?.message}` : ''}
                              </span>
                            </div>
                          )
                        })}
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
        {isEmpty(logs) && !loading && <NotData />}
      </div>
      <PagiantionSAPP
        currentPage={queryParams.page_index || 1}
        pageSize={queryParams.page_size || 50}
        totalItems={logs?.length}
        handlePaginationChange={handleChange}
        pageSizeOptionsDetail={pageSizeOptionsDetail}
      />
    </div>
  )
}

export default ImportLogTableDetail

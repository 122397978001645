import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { debounce } from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotificationAPI } from 'src/apis/notifications'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import GridLayout from 'src/components/layout/grid'
import PageLayouts from 'src/components/layout/PageLayouts'
import {
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
  NotificationTable,
} from 'src/components/notifications/components/table/NotificationTable'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_NOTIFICATIONS_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import { ITabs, Role } from 'src/type'
import { INotificationGroupAll, INotificationRes } from 'src/type/notification'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Notifications',
  },
]
const fieldNames = ['title', 'type', 'sort_type', 'filter_groups', 'status', 'fromDate', 'toDate']
const initialValues: any = {
  title: '',
  type: '',
  sort_type: '',
  filter_groups: '',
  status: '',
  fromDate: '',
  toDate: '',
}
const ListNotification = () => {
  const [listNoti, setListNoti] = useState<{
    notifications: INotificationRes[]
    meta: {
      total_pages: number
      total_records: number
      page_index: number
      page_size: number
    }
  }>()
  const navigate = useNavigate()
  const [groupNotification, setGroupNotification] = useState<INotificationGroupAll>()
  const { profileMe } = useUserContext()
  const allowRenderCreateNotifications = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_NOTIFICATIONS_GR.CREATE_NOTIFICATION) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const queryParams = {
    title: searchParams.get('title') ?? '',
    type: searchParams.get('type') ?? '',
    filter_groups: searchParams.get('filter_groups'),
    status: searchParams.get('status'),
    sort_type: searchParams.get('sort_type'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }
  const validateSchema = z.object({
    title: z.string().optional(),
    type: z.string().optional(),
    filter_groups: z.string().optional(),
    fromDate: z.any().optional(),
    status: z.string().optional(),
    toDate: z.any().optional(),
    sort_type: z.string().optional(),
  })
  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validateSchema),
    mode: 'onChange',
  })
  const handleChangeParams = (currentPage: number, size: number) => {
    const query = {
      page_index: currentPage,
      page_size: size,
      title: getValues('title'),
      type: replaceValueAll(getValues('type')),
      sort_type: replaceValueAll(getValues('sort_type')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      status: replaceValueAll(getValues('status')),
      filter_groups: replaceValueAll(getValues('filter_groups')),
    }
    const queryString = Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const getParams = (
    title: string,
    type: string,
    filter_groups: string,
    sort_type: string,
    status: string,
    fromDate?: any,
    toDate?: any
  ) => ({
    title: title,
    type: type,
    filter_groups,
    sort_type,
    status,
    fromDate: fromDate,
    toDate: toDate,
  })
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)
  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.title?.trimStart()?.trimEnd() ?? '',
      queryParams.type ?? '',
      queryParams.filter_groups ?? '',
      queryParams.sort_type ?? '',
      queryParams.status ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<INotificationRes>(
    listNoti?.notifications || []
  )
  const fetchNotiList = async (currentPage: number, pageSize: number, params?: Object) => {
    setLoading(true)
    try {
      const res = await NotificationAPI.getNotifications({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setListNoti(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('title').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('type')),
        replaceValueAll(getValues('filter_groups')),
        replaceValueAll(getValues('sort_type')),
        replaceValueAll(getValues('status')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )

    setLoading(true)
    fetchNotiList(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((field) => {
      setValue(field, initialValues[field])
    })
    setLoading(true)
    navigate(PageLink.NOTIFICATIONS)
    fetchNotiList(1, 10)
  }

  const fetchLectureClasses = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await NotificationAPI.getNotificationGroups(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageMentor = async (params: Object) => {
    const totalPages = groupNotification?.meta?.total_pages
    const pageIndex = groupNotification?.meta?.page_index as number
    const pageSize = groupNotification?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchLectureClasses(pageIndex + 1, pageSize, params)
      const results = groupNotification.groups.concat(res?.data?.groups)
      setGroupNotification({
        meta: res?.data?.meta,
        groups: results,
      })
    }
  }

  const getMentorClasses = async ({ params }: any) => {
    const resMentor = await fetchLectureClasses(1, 20, params)
    setGroupNotification(resMentor?.data)
  }

  const debounceSearchMentor = debounce((e) => {
    getMentorClasses({ params: { name: e } })
  }, 500)

  const sendToGroup = groupNotification?.groups?.map((group) => ({
    label: group.name,
    value: group.id,
  }))
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_NOTIFICATIONS_GR.EDIT_NOTIFICATION)
  const handleCreate = () => {
    navigate('/send-notifications')
  }

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.notifications} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-10 border-0 pt-10'>
          <GridLayout isListFilters>
            {/* begin:: Search */}
            <HookFormTextField
              control={control}
              name='title'
              placeholder='Search'
              defaultValue={queryParams?.title}
              isListScreen
              onSubmit={onSubmit}
            />
            {/* end:: Search */}

            <HookFormSelectAntd
              control={control}
              name='type'
              placeholder='Type'
              defaultValue={queryParams.type ?? ''}
              classNameHeight='sapp-h-40'
              options={NOTIFICATION_TYPE}
            />

            <HookFormSelectAntd
              control={control}
              name='filter_groups'
              placeholder='Send to'
              defaultValue={queryParams.filter_groups ?? ''}
              onFocus={async () => {
                if (!sendToGroup) {
                  await getMentorClasses({})
                  return
                }
              }}
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchMentor(e)
              }}
              handleNextPage={(e: any) => handlNextPageMentor({ params: { name: e } })}
              showSearch
              classNameHeight='sapp-h-40'
              options={sendToGroup ?? []}
            />

            <HookFormSelectAntd
              classNameHeight='sapp-h-40'
              control={control}
              name='status'
              placeholder='Status'
              defaultValue={queryParams.status ?? ''}
              options={NOTIFICATION_STATUS}
            />

            <HookFormSelectAntd
              name='sort_type'
              placeholder='Sort by'
              control={control}
              defaultValue={queryParams?.sort_type ?? ''}
              classNameHeight='sapp-h-40'
              options={FILTER_SELECTALL_SORTBY}
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </GridLayout>
        </div>
        <div className='card-header border-0 pt-5 px-10'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-xl-4 col-lg-6 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  titleSubmit='Search'
                  okClick={onSubmit}
                  resetClick={handleResetFilter}
                  disabled={false}
                />
              </div>
              {allowRenderCreateNotifications && (
                <div className='col-sm-6 col-xl-8 col-lg-6 px-xl-3 px-md-0 px-0 pe-xl-0'>
                  <div className='card-toolbar justify-content-end d-flex my-0 gap-5'>
                    <ButtonIconPrimary
                      iconName='plus'
                      title='Create Notification'
                      onClick={handleCreate}
                      size='small'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <NotificationTable
          checkedList={checkedList}
          fetchNotiList={fetchNotiList}
          filterParams={filterParams}
          getParams={getParams}
          isCheckedAll={isCheckedAll}
          loading={loading}
          notiList={listNoti}
          queryParams={queryParams}
          setLoading={setLoading}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          handleChangeParams={handleChangeParams}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ListNotification)

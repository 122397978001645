import SappDrawer from 'src/components/base/SappDrawer'
import Accodian from './AccodianItem'
import { useEffect, useState } from 'react'
import { ClassesApi } from 'src/apis/classes'
import { TreeHelper } from 'src/helper/tree'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import toast from 'react-hot-toast'

interface IProps {
  open: boolean
  setOpen: any
  data?: any
  title?: string
  type?: string
  id?: string
  exceptedSections?: any
  classId?: string
  refetch?: any
  studentId?: any
  isEnded?: boolean
  dataClass?: any
}

interface ICourseSection {
  checked: boolean
  children: Array<any>
  code: string
  id: string
  is_original: boolean
  name: string
  is_excepted: boolean
}

const ModalProcessing = ({
  open,
  setOpen,
  title = 'Learning Progress',
  type = 'process',
  id,
  exceptedSections,
  classId,
  refetch,
  studentId,
  isEnded,
  dataClass,
}: IProps) => {
  const [checkedAll, setCheckedAll] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [courseSection, setCourseSection] = useState<Array<{ id: string; status: boolean }>>([])

  const checkAll = (status: boolean) => {
    setCheckedAll(status)
    // let checkedAll = true
    for (let e of explorerData) {
      toggleChecked(explorerData, e.id, status)
    }
    return true
  }
  const isLearning = title === 'Learning Progress'

  const [explorerData, setExplorerData] = useState<any>([])

  const handleChecked = (e: string, status: boolean) => {
    setExplorerData((prev: any) => {
      const oldData = [...prev]
      const newData = toggleChecked(oldData, e, status)
      return newData
    })
  }
  // A function that recursively finds check if all node is checked
  function findNodeUnChecked(data: any): any {
    for (let node of data) {
      if (node.checked === false) {
        return false
      } else if (node.children && node.children.length > 0) {
        let result = findNodeUnChecked(node.children)
        if (!result) {
          return result
        }
      }
    }
    return true
  }
  function getUnchecked(data: any, target: 'removed' | 'added'): any {
    // Initialize an empty array to store the results
    let result = []
    // Loop through each element in the data
    for (let element of data) {
      // Trích xuất các phần tử bị bỏ ticked
      if (!element.checked && target === 'removed') {
        // Push the element to the result array
        const change = courseSection.find((el) => el.id === element.id)
        change?.status !== element.checked && result.push(element.id)
      }
      // Trích xuất các phần tử mới được ticked
      if (target === 'added' && element.checked) {
        const change = courseSection?.find((el) => el.id === element.id)
        change?.status !== element.checked && result.push(element.id)
      }
      // Check if the element has children
      if (element.children) {
        // Recursively call the function on the children and concatenate the result array
        result = result.concat(getUnchecked(element.children, target))
      }
    }
    // Return the result array
    return result
  }
  // A function that takes an array of data and an id of a node
  function toggleChecked(data: any, id: string, checked: boolean) {
    // A helper function that recursively finds the node with the given id and returns it
    function findNode(data: any, id: string): any {
      for (let node of data) {
        if (node.id === id) {
          return node
        } else if (node.children && node.children.length > 0) {
          let result = findNode(node.children, id)
          if (result) {
            return result
          }
        }
      }
      return null
    }

    // A helper function that recursively updates the checked status of the node and its children
    function updateNode(node: any, checked: boolean) {
      node.checked = checked
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          updateNode(child, checked)
        }
      }
    }

    // A helper function that recursively updates the checked status of the node and its ancestors
    function updateAncestors(data: any, node: any) {
      if (node.parent_id) {
        let parent = findNode(data, node.parent_id)
        if (parent) {
          // If at least one child of the parent are checked, then the parent is checked
          // Otherwise, the parent is not checked
          let oneChecked = false
          for (let child of parent.children) {
            if (child.checked) {
              oneChecked = true
              break
            }
          }
          parent.checked = oneChecked
          // Repeat the process for the parent's parent
          updateAncestors(data, parent)
        }
      }
    }

    // Find the node with the given id
    let node = findNode(data, id)
    if (node) {
      // Toggle the checked status of the node
      //   let checked = !node.checked
      // Update the node and its children
      updateNode(node, checked)
      // Update the node and its ancestors
      updateAncestors(data, node)
    }
    return data
  }
  const handleSubmit = async () => {
    if (classId && !studentId) {
      setLoading(true)
      const payload = {
        allow_course_section_ids: [...getUnchecked(explorerData, 'added')],
        excepted_course_section_ids: [...getUnchecked(explorerData, 'removed')],
      }

      try {
        await ClassesApi.editCourseContentClass(payload, classId)
        refetch()
        toast.success('Update successful')
        setOpen({ status: false })
      } catch (err) {
        setIsReset(true)
      } finally {
        setLoading(false)
      }
    } else if (classId && studentId) {
      setLoading(true)
      const payload = {
        allow_course_section_ids: [...getUnchecked(explorerData, 'added')],
        excepted_course_section_ids: [...getUnchecked(explorerData, 'removed')],
      }
      try {
        await ClassesApi.editCourseContentStudent(payload, classId, studentId)
        // refetch()
        toast.success('Update successful')
        setOpen({ status: false })
      } catch (err) {
        console.error(err)
        setIsReset(true)
      } finally {
        setLoading(false)
      }
    }
  }
  let resExcept: any
  useEffect(() => {
    // if (id) {
    async function fetchCourseList() {
      if (id) {
        setLoading(true)
        try {
          if (studentId && classId) {
            const res = await ClassesApi.getExceptedSectionStudent({
              id: classId,
              student_id: studentId,
            })
            setCourseSection(
              res.data?.course_section_data.map((item: { id: string; is_excepted: boolean }) => ({
                id: item.id,
                status: !item.is_excepted,
              }))
            )
            resExcept = res.data?.course_section_data?.map((course: ICourseSection) => ({
              ...course,
              checked: course?.is_excepted,
            }))
          } else {
            // resExcept = [...exceptedSections]
            // data trả về các phần tử có is_expected true là không check và is_expected: false là check
            setCourseSection(
              dataClass?.course_section_data?.map((item: { id: string; is_excepted: boolean }) => ({
                id: item.id,
                status: !item.is_excepted,
              }))
            )
            resExcept = dataClass?.course_section_data?.map((course: ICourseSection) => ({
              ...course,
              checked: course?.is_excepted,
            }))
          }

          // Kiểm tra nếu có dataClass và id

          // Lấy nội dung khóa học của học viên
          const res = await ClassesApi.getStudentCourseContent(id)
          setExplorerData(() => {
            // Map dữ liệu trả về để thêm trạng thái checked
            const newData = res.data?.map((item: ICourseSection) => {
              // Tìm section tương ứng trong danh sách các section bị loại trừ
              const isActiveCourseContent = resExcept?.find(
                (course: ICourseSection) => course?.id === item?.id
              )
              return {
                ...item,
                // Xác định trạng thái checked của section:
                // - Nếu có classId hoặc studentId:
                //   + Nếu section có trong danh sách loại trừ: checked = !is_excepted
                //   + Nếu không có: checked = false
                // - Nếu không có classId và studentId:
                //   + checked = true nếu section không có trong danh sách loại trừ
                checked:
                  classId || studentId
                    ? isActiveCourseContent
                      ? !isActiveCourseContent?.is_excepted
                      : false
                    : !resExcept?.includes(item?.id),
              }
            })

            // Chuyển đổi dữ liệu từ mảng phẳng sang cấu trúc cây
            return [...TreeHelper.convertFromArray(newData, { convert_original: true })]
          })
        } catch (err) {
          console.error(err)
        } finally {
          setLoading(false)
        }
      }
    }

    async function fetchCourseDetail() {
      const params = {
        user_id: studentId,
      }
      const res = await ClassesApi.getCourseDetail({ id: id, params: params })
      if (res) {
        let resProcess =
          res?.data?.course_sections_with_progress?.length > 0
            ? [...res?.data?.course_sections_with_progress]
            : []
        setExplorerData(() => {
          let newData = []
          for (let e of resProcess) {
            const percent = Math.round(
              (e?.learning_progress?.total_course_sections_completed /
                e.learning_progress.total_course_sections) *
                100
            )
            newData.push({ ...e, process: percent })
          }
          return [...TreeHelper.convertFromArray(newData, { convert_original: true })]
        })
      }
    }
    if (id) {
      type !== 'process' ? fetchCourseList() : fetchCourseDetail()
    } else {
      setExplorerData([])
    }
    setIsReset(false)
  }, [id, exceptedSections, studentId, classId, isReset])

  useEffect(() => {
    setCheckedAll(findNodeUnChecked(explorerData))
  }, [explorerData])

  /**
   * Xử lý đóng modal và reset dữ liệu
   * - Đóng modal bằng cách set status = false
   * - Giữ lại các thông tin type, id và studentId để có thể mở lại modal với cùng trạng thái
   */
  const hanldeClose = () => {
    setOpen({ status: false, type: type, id: id, studentId: studentId })
  }

  return (
    <SappDrawer
      open={open}
      width='50%'
      title={title}
      handleSubmit={handleSubmit}
      handleClose={hanldeClose}
      loading={loading}
      confirmOnclose={isLearning ? false : true}
      disabled={isEnded}
      classNameCancel={`fw-bold min-w-100px ${isLearning ? '' : 'me-5'}`}
      classNameSubmit={`${isLearning ? 'd-none' : ''}`}
      okButtonCaption={'Save'}
      cancelButtonCaption={`${isLearning ? 'Close' : 'Cancel'}`}
    >
      <>
        {type === 'course-content' && (
          <div className='d-flex align-items-center gap-3 mb-8'>
            <SAPPCheckbox
              checked={checkedAll}
              onChange={() => {
                checkAll(!checkedAll)
              }}
              disabled={isEnded}
            />
            <div className='sapp-text-truncate-1 sapp-text-primary fw-semibold fs-6'>
              Select All {explorerData?.length > 0 ? `(${explorerData?.length})` : ''}
            </div>
          </div>
        )}
        <div style={type === 'course-content' ? { marginLeft: '5px' } : {}}>
          {explorerData &&
            explorerData.map((item: any, index: number) => {
              return (
                <Accodian
                  explorer={item}
                  type={type}
                  action={handleChecked}
                  key={item.id}
                  isEnded={isEnded}
                />
              )
            })}
        </div>
      </>
    </SappDrawer>
  )
}
export default ModalProcessing

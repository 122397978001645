import { useState } from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { Select } from 'antd'
import { BUTTON_TEXT } from 'src/constants/lang'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import useShopFilter from 'src/hooks/use-shop-filter'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { INameAndId } from 'src/type/shop/common'
import TableRelatedProduct from './TableRelatedProduct'
import { ProductAPI } from 'src/apis/short-course/product'
import { IProductItem, IProductList } from 'src/type/shop/product'

interface IProps {
  initValue?: INameAndId[]
  open: boolean
  setOpen: any
  onAddFaq: (data: any) => void
}

interface IForm {
  title: string
  category_id: string
  faq_id: string
  sortType: string
  lastRegistry: {
    toDate: Date
    fromDate: Date
  }
}

const AddFAQs = ({ open, setOpen, onAddFaq, initValue }: IProps) => {
  const [productList, setProductList] = useState<IProductList>()
  const [loading, setLoading] = useState<boolean>(false)
  const { confirm, contextHolder } = useConfirm()

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll, setDefaultChecked } =
    useChecked<IProductItem>(productList?.products)

  /**
   * Handle Click Cancel
   */
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => setOpen(false),
    })
  }

  /**
   * Validate cho các trường filter
   */
  const validationSchema = z.object({
    title: z.string().optional(),
    category_id: z.string().optional(),
    faq_id: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  const { control, handleSubmit, getValues, reset } = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  /**
   * Fetch data khi vào page hoặc load lại page khi cập nhật bản ghi
   * @param {number}currentPage - Page hiện tại
   * @param {number}pageSize - lượng bản ghi cho 1 page
   * @param {Object}params - các tham số để filter
   */
  const fetchProductList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const dateInfoFromDate = getDateInfo(getValues('lastRegistry')?.fromDate)
      const dateInfoToDate = getDateInfo(getValues('lastRegistry')?.toDate)
      const cleanedParams = cleanParamsAPI(
        getParams(
          getValues('title')?.trim(),
          replaceValueAll(getValues('category_id')),
          replaceValueAll(getValues('faq_id')),
          replaceValueAll(getValues('sortType')),
          getValues('lastRegistry')
            ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
            : '',
          getValues('lastRegistry')
            ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
            : ''
        )
      )
      const res = await ProductAPI.getProducts({
        page_index: currentPage,
        page_size: pageSize,
        params: params ?? cleanedParams,
      })
      setProductList(res?.data)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    reset()
    setLoading(true)
    toggleCheckAll(false)
    fetchProductList(1, 10)
  }
  const {
    dataList: categoryList,
    getData: getCategory,
    debounceGetData: debounceGetCategory,
    handleNextPage,
  } = useShopFilter({
    callback: ProductAPI.getProducttCategories,
    type: 'product',
    key: 'productCategories',
  })

  /**
   * Mapping filter variable for calling api
   */
  const getParams = (
    title: string,
    category_id: string,
    faq_id: string,
    sortType: string,
    fromDate?: Date | string,
    toDate?: Date | string
  ) => ({
    title,
    category_id,
    faq_id,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
  })

  /**
   * Handle Filter cho Products
   */
  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('lastRegistry')?.fromDate)
    const dateInfoToDate = getDateInfo(getValues('lastRegistry')?.toDate)

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('title')?.trim(),
        replaceValueAll(getValues('category_id')),
        replaceValueAll(getValues('faq_id')),
        replaceValueAll(getValues('sortType')),
        getValues('lastRegistry')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('lastRegistry')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )
    setLoading(true)
    fetchProductList(1, 10, cleanedParams)
  }

  /**
   * Delete Bulk Products
   */
  const handleSelect = async (): Promise<void> => {
    if (!checkedList || checkedList.length <= 0) {
      return
    }
    const selection = productList?.products
      ?.filter((item) => {
        if (checkedList.includes(item.id)) {
          return item
        }
      })
      .map((product) => ({ id: product.id, name: product.title }))
    if (selection?.length) {
      onAddFaq(selection ?? [])
    }
  }

  return (
    <SappModal
      title=''
      hideHeader
      open={open}
      handleClose={handleCancel}
      classBody='py-10 modal-body px-lg-17 px-10'
      confirmOnclose
      showFooter={false}
      classNameBody='d-flex justify-content-center sapp-p-body-content'
      contentClassName='sapp-modal-content'
      dialogClassName=' modal-fullscreen modal-fullscreen-form-faq m-0'
      showClassHeightBody={false}
      classNameContent='sapp-p-body-content'
    >
      {contextHolder}
      <div className='d-flex justify-content-between px-7 align-items-center'>
        <div className='sapp-title-modal-create-class'>Add Related Product</div>
        <div className='d-flex align-items-center'>
          {checkedList.length > 0 && (
            <div className='sapp-selected-count fs-6 me-5'>{`${checkedList.length} Selected`}</div>
          )}
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption='Add'
            okOnClick={handleSelect}
            className='justify-content-between d-flex flex-row-reverse'
            classNameCancel='fw-bold me-0'
            classNameSubmit='fw-bold me-5'
            loading={loading}
          />
        </div>
      </div>
      <div className='px-7'>
        <div className='card-header border-0 pt-6'>
          <div className=''>
            <div className='row'>
              <div className='col'>
                <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                  <HookFormTextField
                    control={control}
                    name='title'
                    placeholder='Search question'
                    className='sapp-h-45px'
                  />
                </div>
              </div>
              <div className='col mt-2 '>
                <HookFormSelectAntd
                  name='category_id'
                  placeholder='Category'
                  control={control}
                  size='large'
                  showSearch
                  handleNextPage={handleNextPage}
                  onSearch={async (e) => {
                    debounceGetCategory(e)
                    return
                  }}
                  onFocus={async () => {
                    if (categoryList?.data?.length <= 0) {
                      await getCategory()
                      return
                    }
                  }}
                  onChange={async (e: any) => {
                    if (e === undefined) {
                      debounceGetCategory()
                    }
                  }}
                  loading={categoryList.loading}
                  allowClear
                  classNameHeight='sapp-h-45px'
                  options={categoryList.data?.map((e) => {
                    return { label: e.name, value: e.id }
                  })}
                />
              </div>
              <div className='col mt-2 '>
                <RangeDateTimePicker
                  control={control}
                  name='lastRegistry'
                  allowClear={true}
                  placeholder={['From Date', 'To Date']}
                  className='sapp-h-45px'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='container m-0'>
            <div className='row'>
              <div className='col-sm-4 col-lg-2 col-xl-2 px-xl-3 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  okClick={handleSubmit(onSubmit)}
                  resetClick={handleResetFilter}
                  titleSubmit={BUTTON_TEXT.SEARCH}
                  loading={loading}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
        <TableRelatedProduct
          initValue={initValue}
          productList={productList}
          setProductList={setProductList}
          loading={loading}
          setLoading={setLoading}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
          loadData={fetchProductList}
          setDefaultChecked={setDefaultChecked}
        />
      </div>
    </SappModal>
  )
}
export default AddFAQs

import { Program } from 'src/type'
import { UserType } from 'src/type/users'

export const userKeys = {
  all: ['users'] as const,
  detail: (id: string, user: UserType) => [...userKeys.all, user, { id }] as const,
  hubspotSubject: (program?: Program) => [...userKeys.all, { program }] as const,
  examSubjects: (subject_id: string) => [...userKeys.all, { subject_id }] as const,
}

export const teacherKeys = {
  all: (user_id?: string, program?: Program) =>
    ['teacher-teachable', { user_id, program }] as const,
}

export const staffKeys = {
  all: ['staff'] as const,
  levels: (text?: string) => [...staffKeys.all, 'levels', { text }] as const,
}

import { debounce } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { DepartmentAPI } from 'src/apis/department'
import { commonKeys, departmentKeys } from 'src/constants/queryKeyFactory'
import { IDepartmentPosition, IResponse } from 'src/type'
import { IProvinceList } from 'src/type/common'

const useDepartmentPositions = (id?: string, enabled?: boolean) => {
  const PAGE_SIZE = 10
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e: string) => {
    setSearchText(e)
  }, 350)

  const fetchPositions = async (
    id: string,
    page_index: number,
    page_size: number,
    text?: string
  ) => {
    try {
      const params: { text?: string } = {}
      if (text) {
        params.text
      }
      const res = await DepartmentAPI.getPositions(id, page_index, page_size, params)
      return res
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery<IResponse<IDepartmentPosition>>({
      queryKey: id ? departmentKeys.position(id, searchText) : undefined, // Query key
      queryFn: ({ pageParam = 1 }) => {
        if (id) {
          return fetchPositions(id!, pageParam, PAGE_SIZE, searchText)
        } else {
          return Promise.reject('No ID available')
        }
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.success) {
          return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
            ? lastPage.data.metadata.page_index + 1
            : false
        }
      },
      enabled: enabled && !!id,
      refetchOnWindowFocus: false,
    })

  return {
    provinces: data?.pages.flatMap((page) => page?.data?.department_positions), // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
    debounceSearch,
  }
}

export default useDepartmentPositions

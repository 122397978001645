import { Col, Collapse, Row, Select, Space } from 'antd'
import clsx from 'clsx'
import { groupBy } from 'lodash'
import { useCallback, useEffect } from 'react'
import { Controller, SubmitHandler, useFormContext, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { FeatureSectionApi } from 'src/apis/feature-section'
import { UsersAPI } from 'src/apis/user'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import {
  feactureSectionsKeys,
  MY_PROFILE,
  TEACHER_PRIORITY_OPTIONS,
  TEACHER_PRIORITY_STATUS,
  TEACHER_PROFILE,
  teacherKeys,
  userKeys,
} from 'src/constants'
import { useTeacherContext } from 'src/context/user/teacher/TeacherContext'
import { useTeachingProgramContext } from 'src/context/user/teacher/TeachingProgramContext'
import { useConfirm } from 'src/hooks/use-confirm'
import { IResponse } from 'src/type'
import { PROGRAM } from 'src/type/courses'
import { IFeatureSectionList } from 'src/type/feature-section'
import { UpdateTeachingPrograms } from 'src/type/teacher'
import { GroupedFeatureSections } from 'src/type/users/teacher'
import styles from './styles.module.scss'

const EditProgram = () => {
  const DEFAULT_PAGESIZE = 100
  const { program, data: teacherTeachableData, isPrimaryCategory } = useTeachingProgramContext()

  const { teacherDetail } = useTeacherContext()
  const { control, watch, setValue, handleSubmit, reset } = useFormContext<UpdateTeachingPrograms>()
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: featureSections, isSuccess } = useInfiniteQuery<
    IResponse<IFeatureSectionList>,
    unknown,
    GroupedFeatureSections
  >({
    queryKey: feactureSectionsKeys.list(1, DEFAULT_PAGESIZE, {
      course_category_name: program,
      sort: {
        'subject.name': 'ASC',
      },
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await FeatureSectionApi.getFeatureSections(pageParam, DEFAULT_PAGESIZE, {
        course_category_name: program,
        sort: {
          'subject.name': 'ASC',
        },
      })

      return res
    },
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
          ? lastPage.data.metadata.page_index + 1
          : false
      }
    },
    enabled: !!id && !!program,
    select: useCallback((data: InfiniteData<IResponse<IFeatureSectionList>>) => {
      const grouped = {
        pageParams: data.pageParams,
        pages: data.pages.map((page) => ({
          ...page,
          data: groupBy(page.data.feature_sections, (item) => item.subject.name),
        })),
      }
      return grouped
    }, []),
  })

  const { mutate: updateTeachingPrograms } = useMutation({
    mutationFn: (data: UpdateTeachingPrograms) =>
      UsersAPI.updateTeacherTeachable(teacherDetail?.id ?? '', data),
    onSuccess: (res) => {
      if (res.success) {
        toast.success(`Update ${program} teaching programs successfully`)
        queryClient.invalidateQueries(teacherKeys.all(id, program))
        queryClient.invalidateQueries(userKeys.detail(id!, 'teacher'))
        navigate(
          `${TEACHER_PROFILE}/${id}/${MY_PROFILE.TEACHING_PROGRAMS}/${program
            .toLowerCase()
            .replace('/', '-')}`
        )
      }
    },
  })

  const onSubmit: SubmitHandler<UpdateTeachingPrograms> = async (data) => {
    const filteredData = {
      ...data,
      teacher_teachable_instances: data.teacher_teachable_instances
        .map((instance) => ({
          ...instance,
          feature_sections: instance.feature_sections.filter((section) => {
            return Boolean(section.feature_section_id)
          }),
        }))
        .filter((item) => item.category_id && item.feature_sections.length > 0),
    }
    updateTeachingPrograms({
      ...filteredData,
      course_category_id: teacherTeachableData.pages[0].course_category_id,
    })
  }

  // mở popup confỉm khi submit
  const handleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  const teacherTeachableInstances =
    useWatch({
      control,
      name: 'teacher_teachable_instances', // Name of the field you want to watch
    }) ?? []

  const instancesLength = (
    Array.isArray(teacherTeachableInstances) ? teacherTeachableInstances : []
  )
    .flatMap((item) => item.feature_sections || []) // Ensure `feature_sections` is an array
    .filter((item) => item.feature_section_id).length

  const { confirm, contextHolder } = useConfirm()

  useEffect(() => {
    if (isSuccess) {
      const mappedData = Object.fromEntries(
        Object.entries(featureSections.pages[0].data).map(([key, arr]) => {
          return [
            key,
            {
              featureSections: arr.map(({ id }) => {
                const match = teacherTeachableData?.pages[0]?.teacher_teachables[key]?.find(
                  ({ feature_section_id }) => feature_section_id === id
                )
                return match ? match : null
              }),
              subject_id: arr[0]?.subject_id,
            },
          ]
        })
      )

      const teacherTeachableInstances = Object.values(mappedData).map((item) => {
        return {
          feature_sections: item.featureSections.map((i) => {
            return i !== null
              ? {
                  feature_section_id: i.feature_section_id,
                  priority_status: i.priority_status,
                }
              : {}
          }),
          subject_id: item.subject_id as string,
          category_id: teacherTeachableData?.pages[0]?.course_category_id,
        }
      })
      setValue('teacher_teachable_instances', teacherTeachableInstances)
    }
  }, [featureSections, program])

  return isSuccess && teacherTeachableInstances ? (
    <div>
      {contextHolder}
      <Space direction='vertical' size={'middle'} className='w-100 pb-7 border-bottom'>
        {isSuccess &&
          featureSections?.pages.map((page) => {
            if (program === PROGRAM.CFA || program === PROGRAM.CMA) {
              return Object.entries(page.data).map(([subject, rows], index) => {
                return (
                  <Collapse
                    expandIconPosition={'end'}
                    className={clsx(styles.collapse)}
                    key={subject}
                    defaultActiveKey={subject}
                    items={[
                      {
                        key: subject,
                        label: <span className={styles.collapseHeader}>{subject}</span>,
                        children: (
                          <div>
                            {rows?.map((instance, rowsIndex) => {
                              const featureSectionId = watch(
                                `teacher_teachable_instances.${index}.feature_sections.${rowsIndex}.feature_section_id`
                              )
                              return (
                                <Row
                                  className='my-4 w-100'
                                  justify={'space-between'}
                                  gutter={12}
                                  align={'middle'}
                                  key={instance.id}
                                >
                                  <Col
                                    xs={featureSectionId ? 12 : 24}
                                    lg={featureSectionId ? 16 : 24}
                                    xl={featureSectionId ? 18 : 24}
                                    xxl={featureSectionId ? 20 : 24}
                                  >
                                    <Controller
                                      control={control}
                                      name={`teacher_teachable_instances.${index}.feature_sections.${rowsIndex}.feature_section_id`}
                                      render={({ field }) => {
                                        return (
                                          <label
                                            className={clsx(
                                              'd-flex gap-2 cursor-pointer',
                                              styles.sectionCheckbox
                                            )}
                                          >
                                            <SAPPCheckbox
                                              onChange={(e) => {
                                                const isLastChecked =
                                                  instancesLength === 1 &&
                                                  e.target.checked === false &&
                                                  isPrimaryCategory === true

                                                if (isLastChecked) {
                                                  toast.error(
                                                    `You cannot uncheck the last item of this Primary Responsibility.`,
                                                    {
                                                      duration: 6000,
                                                    }
                                                  )
                                                  return
                                                }

                                                field.onChange(
                                                  e.target.checked ? instance.id : undefined
                                                )

                                                if (e.target.checked) {
                                                  setValue(
                                                    `teacher_teachable_instances.${index}.subject_id`,
                                                    instance.subject_id
                                                  )
                                                  setValue(
                                                    `teacher_teachable_instances.${index}.category_id`,
                                                    instance.subject.course_category.id
                                                  )
                                                  setValue(
                                                    `teacher_teachable_instances.${index}.feature_sections.${rowsIndex}.priority_status`,
                                                    TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN
                                                  )
                                                } else {
                                                  setValue(
                                                    `teacher_teachable_instances.${index}.feature_sections.${rowsIndex}.priority_status`,
                                                    undefined
                                                  )
                                                }
                                              }}
                                              checked={featureSectionId === instance.id}
                                              id={instance.id}
                                            />
                                            <span className={clsx('flex-grow-1', styles.strong)}>
                                              {instance?.name}
                                            </span>
                                          </label>
                                        )
                                      }}
                                    />
                                  </Col>
                                  {featureSectionId && (
                                    <Col xs={12} lg={8} xl={6} xxl={4}>
                                      <Controller
                                        name={`teacher_teachable_instances.${index}.feature_sections.${rowsIndex}.priority_status`}
                                        control={control}
                                        defaultValue={TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN}
                                        render={({ field }) => {
                                          return (
                                            <Select
                                              options={TEACHER_PRIORITY_OPTIONS}
                                              value={field.value}
                                              style={{ width: '100%' }}
                                              size='small'
                                              className={styles.select}
                                              onChange={(value) => field.onChange(value)}
                                            />
                                          )
                                        }}
                                      />
                                    </Col>
                                  )}
                                </Row>
                              )
                            })}
                          </div>
                        ),
                      },
                    ]}
                  />
                )
              })
            }
            if (program === PROGRAM.ACCA || program === PROGRAM.CERT_DIP) {
              const flatFeatureSections = Object.values(page.data).flatMap((entries) =>
                entries.map((entry) => entry)
              )
              return (
                <Collapse
                  expandIconPosition={'end'}
                  className={clsx(styles.collapse)}
                  key={program}
                  defaultActiveKey={[program]}
                  items={[
                    {
                      key: program,
                      label: <span className={styles.collapseHeader}>{program}</span>,
                      children: flatFeatureSections.map((section, index) => {
                        const featureSectionId = watch(
                          `teacher_teachable_instances.${index}.feature_sections.${0}.feature_section_id`
                        )
                        return (
                          <Row
                            className='my-4 w-100'
                            justify={'space-between'}
                            gutter={12}
                            align={'middle'}
                            key={section.id}
                          >
                            <Col span={featureSectionId ? 16 : 24}>
                              <Controller
                                control={control}
                                name={`teacher_teachable_instances.${index}.feature_sections.${0}.feature_section_id`}
                                render={({ field }) => {
                                  return (
                                    <label
                                      className={clsx(
                                        'd-flex gap-2 cursor-pointer',
                                        styles.sectionCheckbox
                                      )}
                                    >
                                      <SAPPCheckbox
                                        onChange={(e) => {
                                          field.onChange(e.target.checked ? section.id : '')
                                          if (e.target.checked) {
                                            setValue(
                                              `teacher_teachable_instances.${index}.subject_id`,
                                              section.subject_id
                                            )
                                            setValue(
                                              `teacher_teachable_instances.${index}.category_id`,
                                              section.subject.course_category.id
                                            )
                                            setValue(
                                              `teacher_teachable_instances.${index}.feature_sections.${0}.priority_status`,
                                              TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN
                                            )
                                          } else {
                                            setValue(
                                              `teacher_teachable_instances.${index}.feature_sections.${0}.priority_status`,
                                              ''
                                            )
                                          }
                                        }}
                                        checked={field.value === section.id}
                                        id={section.id}
                                      />
                                      <span className={clsx('flex-grow-1', styles.strong)}>
                                        {section?.name}
                                      </span>
                                    </label>
                                  )
                                }}
                              />
                            </Col>
                            {featureSectionId && (
                              <Col span={8}>
                                <Controller
                                  name={`teacher_teachable_instances.${index}.feature_sections.${0}.priority_status`}
                                  control={control}
                                  render={({ field }) => {
                                    return (
                                      <Select
                                        options={TEACHER_PRIORITY_OPTIONS}
                                        style={{ width: '100%' }}
                                        size='small'
                                        className={styles.select}
                                        onChange={(value) => field.onChange(value)}
                                        defaultValue={TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN}
                                        value={field.value}
                                      />
                                    )
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                        )
                      }),
                    },
                  ]}
                />
              )
            }
          })}
      </Space>
      <div className='d-flex justify-content-end mt-5'>
        <ButtonSecondary
          title='Cancel'
          onClick={() => reset()}
          link={`${TEACHER_PROFILE}/${id}/${MY_PROFILE.TEACHING_PROGRAMS}/${program
            .replace('/', '-')
            .toLowerCase()}`}
        />
        <ButtonPrimary
          title='Save'
          className='ms-4'
          onClick={handleSubmitForm}
          disabled={instancesLength === 0}
        />
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default EditProgram

import { Space, TablePaginationConfig } from 'antd'
import { useState } from 'react'
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappFilterButton from 'src/common/SAPPFIlterButton'
import SappModal from 'src/components/base/SappModal'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import SappTable from 'src/components/ui/table/SappTable'
import { CLASSROOM_TYPE, DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { classroomKeys } from 'src/constants/queryKeyFactory/classroom'
import { useConfirm } from 'src/hooks/use-confirm'
import { CONSTRUCTION_MODE } from 'src/type/classes'
import { IAddClassRoomFilter } from './type'
import { isArray, pickBy } from 'lodash'
import { IRoom } from 'src/type'
import { Key } from 'antd/es/table/interface'
import GridLayout from 'src/components/layout/grid'

interface IProps {
  open: boolean
  closeModal: () => void
  id?: any
  selectedClassroom?: IRoom
  setSelectedClassroom: React.Dispatch<React.SetStateAction<IRoom | undefined>>
}

const fetchClassroom = (pageIndex: number, pageSize: number, params?: Object) => {
  const filteredParams = pickBy(params, (value) => (isArray(value) ? value.length > 0 : value))
  return ClassroomApi.getRoomList({
    page_index: pageIndex,
    page_size: pageSize,
    params: filteredParams,
  })
}

const getClassroomMode = (mode?: string) => {
  if (['LIVE_ONLINE', 'HYBRID'].includes(mode || '')) {
    return 'VIRTUAL'
  }
  if (['OFFLINE', 'BLENDED'].includes(mode || '')) {
    return 'PHYSICAL'
  }
  return 'VIRTUAL' // Default case if needed
}

const AddClassroom = ({ open, closeModal, selectedClassroom, setSelectedClassroom }: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const { watch } = useFormContext()
  const constructionMode = watch('instruction_mode')

  const [queryParams, setQueryParams] = useState<IAddClassRoomFilter>({
    mode: getClassroomMode(constructionMode),
    status: 'ACTIVE',
    search: undefined,
    type: undefined,
  })
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: DEFAULT_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const { control, handleSubmit, reset } = useForm<IAddClassRoomFilter>({
    mode: 'onSubmit',
    defaultValues: {},
  })

  const { data, isSuccess, isLoading, isFetching, refetch } = useQuery({
    queryKey: classroomKeys.list(pagination.current!, pagination.pageSize!, queryParams),
    queryFn: () => fetchClassroom(pagination.current!, pagination.pageSize!, queryParams),
    keepPreviousData: true,
    staleTime: 5000,
    enabled: open === true && getClassroomMode(constructionMode) !== undefined,
    onSuccess: () => {
      setPagination((prev) => ({
        ...prev,
      }))
    },
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'name',
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
      key: 'mode',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
    },
  ]

  const handleClose = () => {
    let body = [DESCRIPTION_POPUPCONFIRM]
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: () => {
        closeModal()
        reset()
      },
    })
  }

  const handleChangeParams = (currentPage: number, size: number) => {
    const queryParam = {
      page_index: currentPage,
      page_size: size,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit: SubmitHandler<IAddClassRoomFilter> = (values) => {
    setQueryParams({
      ...values,
      mode: getClassroomMode(constructionMode),
      status: 'ACTIVE',
      search: `name=${values.search}, code=${values.search}`,
    })

    refetch() // Manually trigger query
  }
  return (
    <SappModal
      title=''
      hideHeader
      open={open}
      handleClose={handleClose}
      dialogClassName={'m-0 modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
      classNameBody={'sapp-m-h-unset'}
      showFooter={false}
    >
      {contextHolder}
      <Space direction='vertical' className='w-100' size={'large'}>
        <div className='d-flex justify-content-between px-0 align-items-center'>
          <div className='sapp-title-modal-create-class'>{`Add Classroom`}</div>
          <div className='d-flex align-items-center'>
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleClose}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={() => closeModal()}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              isListScreen
              loading={isLoading || isFetching}
            />
          </div>
        </div>
        <GridLayout>
          <HookFormTextField
            control={control}
            name='search'
            placeholder='Search by name and code'
            isListScreen
          />
          <HookFormSelectAntd
            name='type'
            placeholder='Type'
            control={control}
            classNameHeight='sapp-h-40'
            defaultValue={undefined}
            options={CLASSROOM_TYPE}
            disabled={constructionMode === CONSTRUCTION_MODE.LIVE_ONLINE}
          />
        </GridLayout>
        <SappFilterButton
          titleReset='Reset'
          titleSubmit='Search'
          okClick={handleSubmit(onSubmit)}
          resetClick={() => {
            reset()
            refetch()
          }}
          disabled={isLoading}
        />
        {isSuccess ? (
          <SappTable
            handleChangeParams={handleChangeParams}
            // filterParams={filterParams}
            columns={columns}
            fetchData={() => fetchClassroom(pagination.current!, pagination.pageSize!)}
            data={data?.data.classRooms}
            pagination={{
              ...pagination,
              total: data?.data.metadata.total_records,
              showSizeChanger: true,
            }}
            setPagination={setPagination}
            loading={isLoading || isFetching}
            fetchTableData={() => {}}
            rowSelection={{
              type: 'radio',
              defaultSelectedRowKeys: selectedClassroom ? [selectedClassroom.id as Key] : [],
              onSelect: (value: IRoom) => setSelectedClassroom(value),
            }}
          />
        ) : (
          <div />
        )}
      </Space>
    </SappModal>
  )
}

export default AddClassroom

import { Control } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { IDepartment, IStaffLevel } from 'src/type'
import { ICourseCategory } from 'src/type/courses'
import GridLayout from 'src/components/layout/grid'

interface IFilterOptions {
  departmentList: {
    metadata: {
      total_pages: number
      page_index: number
      page_size: number
    }
    data: IDepartment[]
  }
  positionList: {
    metadata: {
      total_pages: number
      page_index: number
      page_size: number
    }
    staffs: IStaffLevel[]
  }
  programList: {
    metadata: {
      total_pages: number
      page_index: number
      page_size: number
    }
    course_categories: ICourseCategory[]
  }
}

interface IProps {
  control: Control<any>
  queryParams: {
    text?: string
  }
  onSubmit: () => void
  filterOptions: IFilterOptions
  handleNextPage: (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => void
  fetchDepartment: (page_index: number, page_size: number, params?: Object) => void
  fetchStaffPosition: (page_index: number, page_size: number, params?: Object) => void
  fetchProgram: (page_index: number, page_size: number, params?: Object) => void
}

const FilterLayout = ({
  control,
  queryParams,
  onSubmit,
  filterOptions,
  handleNextPage,
  fetchDepartment,
  fetchStaffPosition,
  fetchProgram,
}: IProps) => {
  return (
    <div>
      <GridLayout>
        <HookFormTextField
          control={control}
          name='text'
          placeholder='Search staff name'
          defaultValue={queryParams?.text}
          onSubmit={onSubmit}
          isListScreen
          className='sapp-h-45px'
        />

        <HookFormSelectMultiple
          name='department_ids'
          placeholder='Department'
          loading={false}
          control={control}
          handleNextPage={() => {
            handleNextPage(
              filterOptions?.departmentList?.metadata.total_pages,
              filterOptions?.departmentList?.metadata.page_index,
              filterOptions?.departmentList?.metadata.page_size,
              fetchDepartment
            )
          }}
          onSearch={(text: string | undefined) => {
            fetchDepartment(1, 10, { search: `name=${text}` })
          }}
          onFocus={() => {
            fetchDepartment(1, 10)
          }}
          options={filterOptions?.departmentList?.data?.map((department: IDepartment) => ({
            label: department?.name,
            value: department?.id,
          }))}
        />
        <HookFormSelectAntd
          name='staff_position_id'
          placeholder='Staff Position'
          control={control}
          classNameHeight='sapp-h-45px'
          handleNextPage={() => {
            handleNextPage(
              filterOptions?.positionList?.metadata.total_pages,
              filterOptions?.positionList?.metadata.page_index,
              filterOptions?.positionList?.metadata.page_size,
              fetchStaffPosition
            )
          }}
          onSearch={(text: string | undefined) => {
            fetchStaffPosition(1, 10, { search: `name=${text}` })
          }}
          onFocus={() => {
            fetchStaffPosition(1, 10)
          }}
          showSearch
          options={filterOptions?.positionList?.staffs?.map((title: IStaffLevel) => ({
            label: title?.name,
            value: title?.id,
          }))}
        />
        <HookFormSelectAntd
          name='course_category_id'
          placeholder='Program'
          control={control}
          classNameHeight='sapp-h-45px'
          handleNextPage={() => {
            handleNextPage(
              filterOptions?.programList?.metadata.total_pages,
              filterOptions?.programList?.metadata.page_index,
              filterOptions?.programList?.metadata.page_size,
              fetchProgram
            )
          }}
          onSearch={(text: string | undefined) => {
            fetchProgram(1, 10, { search: `name=${text}` })
          }}
          onFocus={() => {
            fetchProgram(1, 10)
          }}
          showSearch
          options={filterOptions?.programList?.course_categories?.map(
            (program: ICourseCategory) => ({
              label: program?.name,
              value: program?.id,
            })
          )}
        />
      </GridLayout>
    </div>
  )
}

export default FilterLayout

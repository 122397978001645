import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import SappModal from 'src/components/base/SappModal'
import { useConfirm } from 'src/hooks/use-confirm'
import { IStaffFacility, IStaffFacilityList } from 'src/type'
import FacilityStaffTable from './FacilityStaffTable'
import ClassroomApi from 'src/apis/classroom'
import SelectionConfirmModalHeader from 'src/components/base/SelectionConfirmModalHeader'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  id: string
  getFacilityStaff: (params: Object) => void
}

/**
 * Component hiển thị popup thêm nhân viên vào cơ sở
 *
 * @param {IProps} props - Các props được truyền vào component
 * @param {boolean} props.open - Trạng thái mở/đóng popup
 * @param {Dispatch<SetStateAction<boolean>>} props.setOpen - Hàm cập nhật trạng thái mở/đóng popup
 * @param {string} props.id - ID của cơ sở
 * @param {(params: Object) => void} props.getFacilityStaff - Hàm lấy danh sách nhân viên của cơ sở
 * @returns {JSX.Element} Component AreaProfileAddStaff
 */
const AreaProfileAddStaff = ({ open, setOpen, id, getFacilityStaff }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [staffState, setStaffState] = useState<IStaffFacilityList>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Map<number, React.Key[]>>(new Map())
  const selectedRowId = useMemo(() => [...selectedRowKeys.values()].flat(), [selectedRowKeys])

  /**
   * Lấy tham số truy vấn
   *
   * @param {string} text - Từ khóa tìm kiếm
   * @param {string} course_category_id - ID danh mục khóa học
   * @param {string} staff_position_id - ID vị trí nhân viên
   * @param {string[]} department_ids - Danh sách ID phòng ban
   * @returns {Object} Tham số truy vấn
   */
  const getParams = (
    text: string,
    course_category_id?: string,
    staff_position_id?: string,
    department_ids?: string[]
  ) => ({
    text,
    course_category_id,
    staff_position_id,
    department_ids,
  })

  const queryParams = {
    text: '',
    department_ids: [],
    course_category_id: '',
    staff_position_id: '',
    page_index: 1,
    page_size: 10,
  }

  /**
   * Lấy danh sách nhân viên có thể thêm vào cơ sở
   *
   * @param {number} page_index - Số trang
   * @param {number} page_size - Kích thước trang
   * @param {Object} params - Tham số truy vấn
   */
  const fetchListStaffs = async (
    page_index: number,
    page_size: number,
    params?: {
      text?: string
      course_category_id?: string
      staff_position_id?: string
      department_ids?: string[]
    }
  ) => {
    try {
      const queryParams = { page_index, page_size, ...params }
      const res = await ClassroomApi.getValidStaffsForFacility({
        facility_id: id,
        params: queryParams,
      })
      setStaffState(res.data)
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const { confirm, contextHolder } = useConfirm()

  /**
   * Thêm nhân viên vào cơ sở
   */
  const handleAddStaffToFacility = async () => {
    const data = {
      staff_ids: selectedRowId.map((id) => id.toString()),
    }
    try {
      setLoading(true)
      await ClassroomApi.addStaffsForFacility(id, data)
      getFacilityStaff({ page_size: 10, page_index: 1 })
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  /**
   * Đóng popup
   */
  const handleClose = () => {
    setOpen(false)
    setSelectedRowKeys(new Map())
  }

  /**
   * Xử lý khi hủy thao tác
   */
  const handleCancel = () => {
    handleClose()
  }

  /**
   * Xử lý khi xác nhận thêm nhân viên
   */
  const handleSubmit = async () => {
    try {
      setLoading(true)
      setOpen(false)
      await handleAddStaffToFacility()
      setSelectedRowKeys(new Map())
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0 modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <SelectionConfirmModalHeader
          title='Add Staff'
          selectedCount={selectedRowId.length}
          showSelectedCount={(staffState?.staffs || [])?.length > 0}
          cancelButtonCaption='Cancel'
          okButtonCaption='Add'
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          loading={loading}
        />
        <FacilityStaffTable
          staffState={staffState}
          loading={loading}
          setLoading={setLoading}
          fetchListStaffs={fetchListStaffs}
          getParams={getParams}
          queryParams={queryParams}
          selected={selectedRowKeys}
          setSelected={setSelectedRowKeys}
        />
      </SappModal>
    </>
  )
}
export default AreaProfileAddStaff

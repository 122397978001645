import { debounce } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { CommonAPI } from 'src/apis'
import { commonKeys } from 'src/constants/queryKeyFactory'
import { IResponse } from 'src/type'
import { IProvinceList } from 'src/type/common'

const useInfiniteProvinces = (enabled: boolean) => {
  const PAGE_SIZE = 10
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e: string) => {
    setSearchText(e)
  }, 350)

  const fetchProvinces = async (
    page_index: number,
    page_size: number,
    name?: string
  ): Promise<IResponse<IProvinceList>> => {
    try {
      const params: Record<string, any> = {}
      if (name) {
        params.search = name
      }
      const res = await CommonAPI.getProvince(page_index, page_size, params)
      return res
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery<IResponse<IProvinceList>>({
      queryKey: commonKeys.provinces(searchText), // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchProvinces(pageParam, PAGE_SIZE, searchText)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.success) {
          return lastPage.data.metadata.page_index < lastPage.data.metadata.total_pages
            ? lastPage.data.metadata.page_index + 1
            : false
        }
      },
      enabled: enabled,
      refetchOnWindowFocus: false,
    })

  return {
    provinces: data?.pages.flatMap((page) => page?.data?.provinces), // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
    debounceSearch,
  }
}

export default useInfiniteProvinces

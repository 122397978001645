import { Row, Skeleton, Tree } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { LessonApi } from 'src/apis/courses/lesson'
import SwitcherClosed from 'src/common/CustomIcons/SwitcherClosed'
import SwitcherExpanded from 'src/common/CustomIcons/SwitcherExpanded'
import ItemProfile from 'src/components/base/ItemProfile'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonIconSecondary from 'src/components/ui/button-icon-secondary/ButtonIconSecondary'
import { PageLink } from 'src/constants'
import { lessonKeys } from 'src/constants/queryKeyFactory/lessonKeys'
import { useLessonListContext } from 'src/context/LessonContext'
import { transformToAntdTreeData } from 'src/pages/courses/lessons/LessonListPage'
import { AntdTreeNode } from 'src/type'
import VersionNavigator from './VersionNavigator'

const PreviewVersion = () => {
  const { data, pageState, version, setVersion } = useLessonListContext()
  const { data: courseTimelineData, isSuccess, isLoading } = data
  const versionList = courseTimelineData?.data.version_list ?? [] // Default to empty array
  const currentVersion = courseTimelineData?.data.current_version // Default to empty array

  const navigate = useNavigate()
  const { id } = useParams()
  const [page, setPage] = pageState
  const queryClient = useQueryClient()

  const { mutate: restoreVersion, isLoading: isRestoring } = useMutation({
    mutationFn: async (version: string) => {
      if (!id) {
        return Promise.reject('No id provided')
      }
      return await LessonApi.restoreLessonVersion(id, version)
    },
    onSuccess: ({ success, error }) => {
      if (success === false) {
        toast.error(error?.message)
        return
      }

      queryClient.invalidateQueries(lessonKeys.base)
      toast.success('Version restore Successfully!')
    },
  })

  type CustomTreeNode = AntdTreeNode & { isOnline: boolean }
  const [treeData, setTreeData] = useState<CustomTreeNode[]>([])

  const handlePrev = () => {
    if (versionList) {
      const index = versionList.findIndex((item) => item.version === version)
      if (index > 0) {
        setTreeData([]) // Reset tree data to prevent old data from flashing
        setVersion(versionList[index - 1].version)
      }
    }
  }

  const handleNext = () => {
    if (versionList) {
      const index = versionList.findIndex((item) => item.version === version)
      if (index < versionList.length - 1) {
        setTreeData([]) // Reset tree data to prevent old data from flashing
        setVersion(versionList[index + 1].version)
      }
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setTreeData(transformToAntdTreeData(courseTimelineData.data.data))
    }
  }, [data])

  return (
    <>
      {isSuccess && (
        <>
          <Row justify={'space-between'}>
            <ButtonIconSecondary
              loading={false}
              title='Back to version history'
              iconName='black-left'
              size='small'
              onClick={() =>
                navigate(
                  `${PageLink.COURSES}/${id}/${PageLink.LESSONS}/${PageLink.VERSION_HISTORY}`
                )
              }
            />

            <ButtonIconPrimary
              title='Restore'
              iconName='arrow-circle-left'
              iconType='outline'
              size='small'
              loading={isRestoring}
              disabled={currentVersion?.is_active === true}
              onClick={() => currentVersion && restoreVersion(currentVersion.version)}
            />
          </Row>

          <div className='my-8 fs-6'>
            <ItemProfile
              title={'Version'}
              align='align-self-center'
              body={
                <VersionNavigator
                  onNext={handleNext}
                  onPrev={handlePrev}
                  currentVersion={currentVersion!}
                  versionList={courseTimelineData.data.version_list}
                />
              }
            />
            <ItemProfile
              title={'Last update'}
              body={
                currentVersion?.updated_at
                  ? dayjs(currentVersion?.updated_at).format('DD/MM/YYYY HH:ss')
                  : ''
              }
            />
            <ItemProfile title={'Changed by'} body={currentVersion?.staff.detail.full_name} />
          </div>

          <div className=''>
            {isLoading ? (
              <Skeleton />
            ) : (
              isSuccess &&
              treeData.length > 0 && (
                <Tree
                  blockNode
                  selectable={false}
                  treeData={treeData} // Use state instead of calling function directly
                  autoExpandParent={true}
                  switcherIcon={({ expanded }) =>
                    expanded ? <SwitcherExpanded /> : <SwitcherClosed />
                  }
                  className='lesson-list__tree'
                  titleRender={(data) => (
                    <div className='d-flex justify-content-between'>
                      <span>{data.title}</span>
                      <div>
                        {data.isOnline && (
                          <div className='badge badge-light-info me-8'>
                            {data.isOnline && 'Online'}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                />
              )
            )}
          </div>
          <div className='mt-auto'>
            <PagiantionSAPP
              currentPage={page}
              totalItems={courseTimelineData?.data.metadata.total_records}
              pageSize={courseTimelineData?.data.metadata.page_size}
              showPagination={0}
              handlePaginationChange={(page: number) => {
                setPage(page)
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default PreviewVersion

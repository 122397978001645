/**
 * Returns the correct singular or plural form of a given word based on the count.
 * Supports regular pluralization by adding "s" and allows custom plural forms for irregular nouns.
 * Handles undefined count values with an optional fallback text.
 *
 * @param {string} word - The singular form of the word.
 * @param {number | undefined} count - The number determining singular or plural form.
 * @param {string} [pluralForm] - Optional custom plural form for irregular nouns (e.g., "child" → "children").
 * @param {string} [undefinedText=""] - Text to display when count is undefined (default is an empty string).
 * @returns {string} - The formatted string with the count and correct singular/plural form.
 *
 * @example
 * pluralize("student", 1); // "1 student"
 * pluralize("student", 5); // "5 students"
 * pluralize("child", 2, "children"); // "2 children"
 * pluralize("sheep", 3, "sheep"); // "3 sheep" (handles irregular plural)
 * pluralize("task", undefined, "tasks", "No tasks"); // "No tasks"
 */
export function pluralize(
  word: string,
  count?: number,
  pluralForm?: string,
  undefinedText: string = ''
): string {
  if (count === undefined) return undefinedText
  if (count === 1) return `${count} ${word}`
  return `${count} ${pluralForm || word + 's'}`
}
